import React from "react";

import Button from "components/Button";

export const ManageConditionsFooter: React.FC = () => (
  <>
    <Button variant="ghost" label="Previous" />
    <Button variant="solid" label="Next" ml={4} />
  </>
);
