import React, { useEffect } from "react";
import {
  GridItem,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  usePublishOfferMutation,
  useUpdateOfferMutation,
  useGetOfferLazyQuery,
  useGetSpecialConditionsQuery,
} from "generated/graphql";
import Wizard from "components/Wizard";
import { CUSTOM_OFFER_PAGE_LOCATION } from "routes/locations";
import useWizardQueryStringPersistor from "components/Wizard/hooks/useWizardQueryStringPersistor";
import useWizardStore from "components/Wizard/store";
import useToast from "hooks/useToast";
import { onQueryError } from "utils/queryHandlers";
import { OFFERS_PAGE_PATH, ROOT_PAGE_PATH } from "routes";
import { useSession } from "contexts/session";

import useFormHandler from "./hooks/useFormHandler";
import useCustomOfferStore from "./store";
import FormWizardFooter from "./components/CustomOfferFooter";
import { customOfferSteps } from "./steps";

const CustomOffer: React.FC = () => {
  const setOffer = useCustomOfferStore(store => store?.setOffer);
  const stepIndex = useWizardStore(store => store?.stepIndex);
  const [showToast] = useToast();
  const history = useHistory();
  const [t] = useTranslation();
  const { isAuthenticated } = useSession();

  const id = CUSTOM_OFFER_PAGE_LOCATION.parseLocationParams(
    window.location,
  )?.id;

  const [
    getOffer, {
      data,
    },
  ] = useGetOfferLazyQuery({
    variables: {
      id,
    },
    onCompleted: (offerData) => setOffer(offerData?.offer),
    fetchPolicy: "no-cache",
  });

  const {
    data: specialConditionsData,
  } = useGetSpecialConditionsQuery();

  /**
   * Runs this query on every step change.
   * Needed to ensure that the data will be up to date
   * even if the `re-fetch queries` won't be triggered
  */
  useEffect(() => {
    if (!id) {
      history.push(isAuthenticated ? OFFERS_PAGE_PATH : ROOT_PAGE_PATH);

      showToast({
        status: isAuthenticated ? "warning" : "info",
        title: t("errors.something_went_wrong"),
        description: isAuthenticated ? t("errors.invalid_custom_offer_id") : t("info.session_expired"),
        position: "bottom",
      });

      return;
    }

    getOffer().catch(error => onQueryError(error, showToast));
  }, [
    isAuthenticated,
    stepIndex,
    getOffer,
    showToast,
    history,
    id,
    t,
  ]);

  const initialStepIndex = useWizardQueryStringPersistor();
  const currentStepIndex = stepIndex + 1;

  const {
    onContinue,
    onSaveAsDraft,
    onSaveProgress,
    onPublish,
  } = useFormHandler({
    useUpdateOfferMutation,
    usePublishOfferMutation,
    specialConditions: specialConditionsData?.specialConditions,
  });

  return (
    <GridItem
      flexDir="column"
      display="flex"
      colSpan={12}
      h="full"
    >
      <Wizard
        initialStepIndex={initialStepIndex}
        steps={customOfferSteps}
        onStepClick={() => onSaveProgress(currentStepIndex)}
      />

      <FormWizardFooter
        onSaveAsDraft={onSaveAsDraft}
        onSaveProgress={() => onSaveProgress(currentStepIndex)}
        onContinue={onContinue}
        onPublish={onPublish}
        showGoBackButton
        canPublish={data?.offer?.publishStatus.canPublish}
        missingInformation={data?.offer?.publishStatus?.missingInformation}
      />
    </GridItem>
  );
};

export default CustomOffer;
