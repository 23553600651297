import React from "react";
import { Flex } from "@chakra-ui/react";

import useFilters from "hooks/useFilters";
import useSorting from "hooks/useSorting";
import { Filters } from "hooks/useFilters/types";
import { Container } from "components/Container";
import { OfferImportLog, useOfferImportLogsQuery } from "generated/graphql";
import StatusBullet from "components/StatusBullet";
import { usePagination } from "contexts/pagination";
import getBulletStatusList from "utils/getBulletStatusList";

import OfferImportLogsTable from "./OfferImportLogsTable";

const OfferImportLogs: React.FC = () => {
  const { renderFilters, dealerId } = useFilters([Filters.Dealer]);
  const { paginationLimit } = usePagination();
  const { field, order } = useSorting({ defaultField: "imported_at" });

  const { data, loading } = useOfferImportLogsQuery({
    variables: {
      pagination: {
        limit: paginationLimit,
        orderBy: (
          field
            ? [{
              field,
              order,
            }]
            : undefined
        ),
      },
      filters: {
        dealerId,
      },
    },
  });

  const logs = data?.offerImportLogs as OfferImportLog[] || [];

  const statusList = getBulletStatusList();

  return (
    <Container>
      {renderFilters}

      <OfferImportLogsTable
        logs={logs}
        loading={loading}
      />

      <Flex>
        {
          statusList.map((item) => (
            <StatusBullet
              id={item.id}
              key={item.id}
              label={item.label}
              status={item.status}
            />
          ))
        }
      </Flex>
    </Container>
  );
};

export default OfferImportLogs;
