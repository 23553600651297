import * as CSS from "csstype";

import { beforeCss } from "components/FormComponents/Radio/styles";

/**
 * @param isBlocked boolean e.g.: getBeforeStyles(isReadOnly || isDisabled)
 * @returns CSS Object e.g.: {color: isBlocked ? "gray.200" : "secondary.200"}
 */
export function getBeforeStyles(isBlocked: boolean): CSS.Properties {
  const selectedCircleStyles = isBlocked ? "gray.200" : "secondary.500";

  return {
    ...beforeCss,
    color: selectedCircleStyles,
  };
}
