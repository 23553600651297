import React, { useCallback } from "react";
import {
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";

import { WizardHeaderProps } from "./types";
import useWizardStore from "./store";

const WizardHeader: React.FC<WizardHeaderProps> = ({
  steps,
  onStepClick,
}) => {
  const {
    setStepIndex,
    stepIndex,
  } = useWizardStore(store => ({
    setStepIndex: store.setStepIndex,
    stepIndex: store.stepIndex,
  }));

  const handleStepClick = useCallback((index: number) => () => {
    onStepClick?.();
    setStepIndex(index);
  }, [
    onStepClick,
    setStepIndex,
  ]);

  return (
    <Flex
      bgColor="white"
      flexDir="row"
      w="full"
      mb={2}
    >
      <Flex
        maxWidth="container.xl"
        w="full"
        m="auto"
      >
        {
          steps.map((step, index) => (
            <Button
              borderBottomWidth={index === stepIndex ? 2 : 0}
              onClick={handleStepClick(index)}
              borderColor="secondary.500"
              borderRadius="none"
              variant="unstyled"
              key={step.title}
            >
              <Text
                color={index === stepIndex ? "secondary.500" : "black.500"}
                fontWeight="medium"
                textStyle="h3"
                px={4}
              >
                {step.title}
              </Text>
            </Button>
          ))
        }
      </Flex>
    </Flex>
  );
};

export default WizardHeader;
