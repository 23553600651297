import React, { useCallback } from "react";
import {
  Flex, Stack, Text, useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FetchResult } from "@apollo/client";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import duplicateOfferSchema, {
  DuplicateOfferSchema,
} from "settings/yup/schemas/duplicateOfferSchema";
import DealerSelect from "components/FormComponents/Inputs/Selects/DealerSelect";
import { DuplicateOfferMutation, useDuplicateOfferMutation } from "generated/graphql";
import { CUSTOM_OFFER_PAGE_LOCATION } from "routes/locations";

import { DuplicateOfferModalProps } from "./types";

const DuplicateOfferModal: React.FC<DuplicateOfferModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();

  const newToast = useToast();

  const { offer } = componentProps;

  const {
    formState,
    control,
    getValues,
  } = useForm<DuplicateOfferSchema>({
    resolver: yupResolver(duplicateOfferSchema),
    mode: "onChange",
  });

  const { dealer } = getValues();

  const handleCancel = useCallback(() => hideModal(), [hideModal]);

  const [duplicateOffer] = useDuplicateOfferMutation();

  const history = useHistory();

  const handlePublishOffer = useCallback(
    (offerId): Promise<FetchResult<DuplicateOfferMutation>> => duplicateOffer({
      awaitRefetchQueries: true,
      variables: {
        dealerId: dealer,
        offerId,
      },
    }), [duplicateOffer, dealer],
  );

  const handleSubmit = useCallback(() => {
    if (!offer?.id) return;

    handlePublishOffer(offer?.id)
      .then(({ data }) => {
        newToast({
          title: t("success.success"),
          description: t("success.duplicate_offer"),
          status: "success",
          duration: 6000,
          isClosable: true,
        });

        history.push(CUSTOM_OFFER_PAGE_LOCATION.toUrl({
          id: data?.duplicateOffer.id,
        }));
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
        });
      })
      .finally(() => {
        handleCancel();
      });
  },
  [handlePublishOffer, history, newToast, t, handleCancel, offer]);

  return (
    <>
      <Flex
        flexDir="column"
        p="6"
      >
        <Text
          lineHeight="24px"
          fontSize="20px"
          textStyle="h3"
        >
          {t("screens.offers.duplicate_offer")}
        </Text>
      </Flex>

      <Stack
        flexDir="column"
        w="full"
        p={6}
        spacing={6}
      >
        <Controller
          control={control}
          name="dealer"
          render={({
            field,
            fieldState,
          }) => (
            <DealerSelect
              {...field}
              {...fieldState}
              errors={formState.errors}
              title={t("components.selects.dealer")}
            />
          )}
        />
      </Stack>

      <Flex
        justifyContent="space-between"
        borderRadius="0 0 8px 8px"
        bg="gray.200"
        w="full"
        p={6}
      >
        <Button
          label={t("components.buttons.cancel")}
          onClick={handleCancel}
          variant="ghost"
          bgColor="white"
          boxShadow="md"
        />

        <Button
          label={t("components.buttons.duplicate")}
          boxShadow="md"
          variant="solid"
          colorScheme="primary"
          onClick={handleSubmit}
        />
      </Flex>
    </>
  );
};

export default DuplicateOfferModal;
