import yup from "settings/yup";
import i18n from "translations/i18n";

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(i18n.t("yup.mixed_required_field")),
});

export interface ForgotPasswordSchema {
  email: string;
}

export default forgotPasswordSchema;
