import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import i18n from "translations/i18n";
import {
  Vehicle,
  useUpdateOfferCalculatorInfoMutation,
  UpdateOfferCalculatorInfoParams,
} from "generated/graphql";
import { editVehicleSchema, EditVehicleOfferSchema } from "settings/yup/schemas/editVehicleOfferSchema";
import { normalizeNumber, parseDynamicLeasingRates } from "components/Modals/EditVehicleModal";
import useToast from "hooks/useToast";

import EditVehicleForm from "../Form";
import { EditVehicleModalContentProps } from "./types";

const replaceSemicolon = (string: string): string => string && string.replace(".", ",");

const EditVehicleModalContent: React.FC<EditVehicleModalContentProps> = ({
  hideModal,
  data,
}) => {
  const [showToast] = useToast();

  const [updateOfferCalculatorInfo, { loading }] = useUpdateOfferCalculatorInfoMutation();

  const vehicle = useMemo(() => ({
    brand: data?.vehicle?.brand,
    modelGroup: data?.vehicle?.modelGroup,
    model: data?.vehicle?.model,
    dynamicLeasingRates: data?.dynamicLeasingRates,
    brandDiscounts: data?.brandDiscounts,
  } as Vehicle), [
    data,
  ]);

  const resolver = yupResolver(editVehicleSchema);

  const {
    handleSubmit,
    formState,
    register,
    setValue,
    getValues,
    control,
  } = useForm<EditVehicleOfferSchema>({
    resolver,
    mode: "onChange",
    defaultValues: {
      listPrice: replaceSemicolon(data?.baseListPriceNet),
      manufacturerBonusNet: replaceSemicolon(data?.manufacturerBonusNet),
      configuratorLink: data?.configuratorLink,
      bankId: data?.bank?.id ? String(data?.bank.id) : "",
    },
  });

  const handleCancel = useCallback(() => {
    setValue("dynamicLeasingRates", vehicle?.dynamicLeasingRates || []);

    hideModal();
  }, [
    hideModal,
    setValue,
    vehicle,
  ]);

  const onUpdate = useCallback(
    (values: EditVehicleOfferSchema) => {
      const vehicleParams = {
        bankId: Number(values?.bankId),
        baseListPriceNet: normalizeNumber(values.listPrice),
        manufacturerBonusNet: normalizeNumber(values.manufacturerBonusNet),
        configuratorLink: values.configuratorLink || "",
        configurationNotes: values.configurationNotes || "",
        calculateWithFactors: values.calculateWithFactors === "true",
        dynamicLeasingRates: parseDynamicLeasingRates(values.dynamicLeasingRates),
        brandDiscount: {
          discount: normalizeNumber(values.discount),
          downPaymentDiscount: normalizeNumber(values.downPaymentDiscount),
          listPriceDiscountRate: Number(normalizeNumber(values?.brandDiscountRate)) / 100,
        },
      } as UpdateOfferCalculatorInfoParams;

      updateOfferCalculatorInfo({
        awaitRefetchQueries: true,
        variables: {
          id: data?.id,
          params: vehicleParams,
        },
      })
        .then(() => {
          showToast({
            title: i18n.t("success.success"),
            description: i18n.t("success.vehicle_updated"),
            status: "success",
            isClosable: true,
          });

          hideModal();
        })
        .catch((error: { message: string }) => {
          showToast({
            title: i18n.t("errors.something_went_wrong"),
            description: error.message,
            status: "error",
            isClosable: true,
          });
        });
    },
    [
      updateOfferCalculatorInfo,
      hideModal,
      showToast,
      data,
    ],
  );

  return (
    <EditVehicleForm
      submitState={!loading}
      isLoading={loading}
      defaultFormValues={data}
      handleSubmit={handleSubmit(onUpdate)}
      handleCancel={handleCancel}
      errors={formState.errors}
      formState={formState}
      getValues={getValues}
      register={register}
      setValue={setValue}
      control={control}
      vehicle={vehicle}
    />
  );
};

export default EditVehicleModalContent;
