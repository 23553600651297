import React, { useEffect } from "react";

import {
  CalculatorOffer, OfferStatusEnum, useCalculatorOffersLazyQuery,
} from "generated/graphql";
import { Container } from "components/Container";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";
import { usePagination } from "contexts/pagination";
import { useCurrentUser } from "contexts/currentUser";

import CalculatorOffersTable from "./CalculatorOffersTable";

const CalculatorOffers: React.FC = () => {
  const [, , { isAdmin }] = useCurrentUser();
  const { paginationLimit } = usePagination();

  const filters = [
    Filters.Dealer,
    Filters.Vehicle,
    Filters.VehicleModel,
    Filters.CustomerType,
    Filters.OnlyActive,
    Filters.HasPricingInformation,
    Filters.Search,
    ...(isAdmin ? [Filters.IsPossibleToEnableNewConfigurator] : []),
  ];

  const {
    renderFilters,
    brandId,
    dealerId,
    modelGroup,
    model,
    customerType,
    active,
    hasPricingInformation,
    search,
    isPossibleToEnableNewConfigurator,
  } = useFilters(
    filters,
    {
      Dealer: { onlyCalculatorDealers: true },
    },
  );

  const [
    listCalculatorOffers,
    { data, loading },
  ] = useCalculatorOffersLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    listCalculatorOffers({
      variables: {
        search,
        pagination: {
          limit: paginationLimit,
        },
        filters: {
          dealerId,
          brandId,
          modelGroup,
          model,
          customerType,
          offerStatus: active ? OfferStatusEnum.Live : undefined,
          hasPricingInformation,
          isPossibleToEnableNewConfigurator,
        },
      },
    });
  }, [
    isPossibleToEnableNewConfigurator,
    active,
    search,
    brandId,
    dealerId,
    modelGroup,
    model,
    customerType,
    hasPricingInformation,
    listCalculatorOffers,
    paginationLimit,
  ]);

  return (
    <Container
      alignCenter
    >
      {renderFilters}

      <CalculatorOffersTable
        data={data?.calculatorOffers as CalculatorOffer[]}
        loading={loading}
      />
    </Container>
  );
};

export default CalculatorOffers;
