import React from "react";
import { Grid } from "@chakra-ui/react";

import {
  GRID_GAP,
  GRID_COLUMNS,
  CONTAINER_PADDING,
} from "constants/layout";
import TitleBar from "components/TitleBar";
import Footer from "components/Footer";

import { LayoutProps } from "../types";

const Main: React.FC<LayoutProps> = ({
  children,
  title,
  Icon,
  FooterContent,
  footerVariant,
}) => (
  <>
    <TitleBar
      title={title || ""}
      Icon={Icon}
      hasContainer
      hasLogo
    />
    <Grid
      paddingY={CONTAINER_PADDING.MD}
      templateColumns={GRID_COLUMNS}
      gap={GRID_GAP.LG}
      maxWidth="container.xl"
      margin="auto"
    >
      {children}
    </Grid>

    {
      FooterContent && (
        <Footer
          FooterContent={FooterContent}
          footerVariant={footerVariant}
        />
      )
    }
  </>
);

export default Main;
