import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Flex,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useModal } from "contexts/modal";
import { useRequestLeadRejectionMutation } from "generated/graphql";
import requestLeadRejectionSchema, { RequestLeadRejectionSchema } from "settings/yup/schemas/requestRejectionSchema";
import Textarea from "components/FormComponents/Inputs/Textarea";
import CheckIcon from "components/Icons/CheckIcon";
import Button from "components/Button";

import { RequestLeadRejectionModalProps } from "./types";
import InReviewRequestLeadRejectionModal from "./InReviewRequestLeadRejectionModal";

const RequestLeadRejectionModal: React.FC<RequestLeadRejectionModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();

  const [showModal] = useModal();

  const newToast = useToast();

  const [requestRejection, { loading }] = useRequestLeadRejectionMutation({
    refetchQueries: componentProps?.refetchQueries ?? [],
  });

  const {
    handleSubmit,
    formState,
    register,
  } = useForm<RequestLeadRejectionSchema>({
    resolver: yupResolver(requestLeadRejectionSchema),
    mode: "onChange",
    defaultValues: {
      reason: "",
    },
  });

  const onSubmit = useCallback(async (values: RequestLeadRejectionSchema) => {
    const { leadId } = componentProps;

    await requestRejection({
      variables: {
        id: leadId,
        reason: values.reason,
      },
    })
      .then(() => {
        showModal({
          component: InReviewRequestLeadRejectionModal,
          size: "xl",
          isCloseable: false,
        });
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
        });
      });
  }, [
    t,
    newToast,
    showModal,
    componentProps,
    requestRejection,
  ]);

  const handleCancel = useCallback(() => {
    hideModal();
  }, [
    hideModal,
  ]);

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        h="full"
        w="full"
        p={6}
        spacing={6}
      >
        <Text
          lineHeight="28px"
          fontSize="24px"
          textStyle="h3"
          textAlign="left"
          w="100%"
          fontWeight="700"
        >
          {t("components.modals.request_lead_rejection.title")}
        </Text>

        <Flex
          mt={4}
          w="100%"
          direction="column"
        >
          <Textarea
            {...register("reason")}
            title={t("components.modals.request_lead_rejection.reason")}
            placeholder={t("components.modals.request_lead_rejection.reason_placeholder")}
          />

          <Flex
            alignItems="center"
          >
            {
              formState.isValid && (
                <CheckIcon
                  color="success.500"
                  mr="2"
                  boxSize={6}
                />
              )
            }
            <Text
              color={
                !formState.isValid && formState.isDirty
                  ? "error.500"
                  : "gray.500"
              }
            >
              {t("components.modals.request_lead_rejection.feedback_text")}
            </Text>
          </Flex>
        </Flex>
      </Stack>

      <Flex
        justifyContent="space-between"
        borderRadius="0 0 8px 8px"
        bg="gray.200"
        w="full"
        p={6}
      >
        <Button
          label={t("components.buttons.cancel")}
          onClick={handleCancel}
          variant="ghost"
          bgColor="white"
          boxShadow="md"
          isDisabled={loading}
        />

        <Button
          label={t("components.buttons.confirm_request_rejection")}
          onClick={handleSubmit(onSubmit)}
          isDisabled={!formState.isValid || loading}
          _disabled={{
            opacity: "30%",
          }}
          boxShadow="md"
          isLoading={loading}
        />
      </Flex>
    </>
  );
};

export default RequestLeadRejectionModal;
