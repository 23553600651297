import i18n from "translations/i18n";
import { formatWithParseDate, germanDateFormat, germanDateFormatWithoutDay } from "utils/dateFormats";

import { DateSelection } from "./types";

const buildDateSelectionString = (
  date: DateSelection,
  isStartDate = true,
  isMonthFilter: boolean,
): string => {
  if (!date) {
    return isStartDate
      ? i18n.t("components.table.filters.start_date")
      : i18n.t("components.table.filters.end_date");
  }

  if (isMonthFilter) {
    return formatWithParseDate(date, germanDateFormatWithoutDay);
  }

  return formatWithParseDate(date, germanDateFormat);
};

export const getFormattedDisplayValue = (
  startDate: DateSelection,
  endDate: DateSelection,
  isMonthFilter: boolean,
): string => (
  `${buildDateSelectionString(startDate, true, isMonthFilter)}/${buildDateSelectionString(endDate, false, isMonthFilter)}`
);
