import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const BankIcon: IconProps = createIcon({
  viewBox: "0 0 49.531 49.529",
  path: (
    <>
      <path
        fill="currentColor"
        d="M39.249,8.589,23.143.717a7.244,7.244,0,0,0-6.286,0L.751,8.589A1.379,1.379,0,0,0,.2,9.066a1.21,1.21,0,0,0-.2.665v1.558a1.241,1.241,0,0,0,.418.918,1.5,1.5,0,0,0,1.01.38H38.571a1.5,1.5,0,0,0,1.01-.38A1.241,1.241,0,0,0,40,11.289V9.731a1.21,1.21,0,0,0-.2-.665,1.379,1.379,0,0,0-.549-.477Z"
      />
      <path
        fill="currentColor"
        d="M2.6,0a2.6,2.6,0,0,1,2.6,2.6v6.49A2.6,2.6,0,0,1,0,9.087V2.6A2.6,2.6,0,0,1,2.6,0Z"
        transform="translate(17.403 18.109)"
      />
      <path
        fill="currentColor"
        d="M2.6,0a2.6,2.6,0,0,1,2.6,2.6v6.49A2.6,2.6,0,0,1,0,9.087V2.6A2.6,2.6,0,0,1,2.6,0Z"
        transform="translate(31.08 18.109)"
      />
      <path
        fill="currentColor"
        d="M2.6,0a2.6,2.6,0,0,1,2.6,2.6v6.49A2.6,2.6,0,0,1,0,9.087V2.6A2.6,2.6,0,0,1,2.6,0Z"
        transform="translate(3.724 18.109)"
      />
      <path
        fill="currentColor"
        d="M2.989,0H37.011A2.862,2.862,0,0,1,40,2.716h0a2.862,2.862,0,0,1-2.989,2.716H2.989A2.862,2.862,0,0,1,0,2.716H0A2.862,2.862,0,0,1,2.989,0Z"
        transform="translate(0 34.567)"
      />
    </>
  ),
});

export default BankIcon;
