import React from "react";

import EuroIcon from "components/Icons/EuroIcon";
import { formatNumberToGerman } from "utils/currencyFormat";
import isPositiveValue from "utils/isPositiveValue";

import Input from "../Input";
import { InputProps } from "../Input/types";

const MonetaryField = React.forwardRef<HTMLInputElement, InputProps>((
  {
    acceptEmptyValue = false,
    errors,
    title,
    defaultValue,
    ...props
  },
  ref,
) => {
  const monetaryValue = formatNumberToGerman(parseFloat(String(defaultValue || 0))) || 0;
  const monetaryDefaultValue = (acceptEmptyValue && (!isPositiveValue(defaultValue as number | string | undefined))) ? "" : monetaryValue;

  return (
    <Input
      errors={errors}
      title={title}
      type="text"
      ref={ref}
      rightElement={<EuroIcon />}
      rightElementProps={{
        color: "gray.400",
      }}
      {...props}
      defaultValue={monetaryDefaultValue}
    />
  );
});

export default MonetaryField;
