import { Grid, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Input from "components/FormComponents/Inputs/Input";
import { titleCss } from "views/CustomOffer/components/Steps/CustomOfferSteps/styles";
import { OfferFuelTypeEnum } from "generated/graphql";
import { germanNumberMask } from "constants/inputMasks";
import { CARBONIC_EMISSION } from "constants/units";

import { InnerSection } from "../../../../InnerSections";
import { FieldsCommonProps } from "..";

export interface CombinedCO2EmissionsProps extends FieldsCommonProps {
  fuelType: OfferFuelTypeEnum;
  isPluginHybrid?: boolean;
}

export const CombinedCO2Emissions = ({
  fuelType,
  isPluginHybrid,
  register,
  formState,
  setValue,
}: CombinedCO2EmissionsProps): JSX.Element => {
  const { t } = useTranslation();

  const isWeightedEmission = useMemo(() => (
    [OfferFuelTypeEnum.Hybrid, OfferFuelTypeEnum.HybridDiesel].includes(fuelType)
    && isPluginHybrid
  ), [fuelType, isPluginHybrid]);

  const isDisabled = useMemo(() => (
    [OfferFuelTypeEnum.Electric, OfferFuelTypeEnum.Hydrogen].includes(fuelType)
  ), [fuelType]);

  useEffect(() => {
    if (!isDisabled) {
      return;
    }

    setValue?.("wltpCarbonicEmissionCombined", "0", { shouldValidate: true, shouldTouch: true });
  }, [
    isDisabled,
    setValue,
  ]);

  const name = isWeightedEmission ? "wltpCarbonicEmissionCombinedWeighted" : "wltpCarbonicEmissionCombined";
  const title = isWeightedEmission
    ? t("custom_offer.steps.standard_data.fuel_card.weighted_combined_co2_emissions")
    : t("custom_offer.steps.standard_data.fuel_card.combined_co2_emissions");

  const placeholder = isWeightedEmission
    ? t("custom_offer.steps.standard_data.fuel_card.insert_weighted_combined_co2_emissions")
    : t("custom_offer.steps.standard_data.fuel_card.insert_combined_co2_emissions");

  return (
    <InnerSection label={t("custom_offer.steps.standard_data.fuel_card.combined_co2_emissions")}>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        <Input
          {...register?.(name)}
          name={name}
          title={title}
          placeholder={placeholder}
          titleCss={titleCss}
          disabled={isDisabled}
          rightAddonProps={{ color: "gray.400" }}
          rightAddon={<Text>{CARBONIC_EMISSION}</Text>}
          errors={formState?.errors}
          mask={germanNumberMask}
          isRequired
        />
      </Grid>
    </InnerSection>
  );
};
