import i18n from "translations/i18n";
import { SellerRoleEnum, UserRoleEnum } from "generated/graphql";

export const ONLY_ADMINS = [
  UserRoleEnum.Admin,
];

export const ALL_ROLES = [
  UserRoleEnum.Admin,
  UserRoleEnum.User,
];

export const roles = {
  [UserRoleEnum.Admin]: i18n.t("roles.admin"),
  [UserRoleEnum.User]: i18n.t("roles.user"),
};

export const ALL_SELLER_ROLES = [
  SellerRoleEnum.Default,
  SellerRoleEnum.Sales,
];
