import { OptionProps } from "components/FormComponents/Inputs/Selects/BaseSelect/types";
import { VehicleCategoryEnum } from "generated/graphql";
import i18n from "translations/i18n";

const getVehicleCategories = (): OptionProps[] | [] => {
  const categoryTypes = Object.values(VehicleCategoryEnum);

  const categoriesOptions = categoryTypes.map(value => ({
    value,
    label: i18n.t(`vehicle_categories.${value}`),
  }));

  return categoriesOptions ?? [];
};

export default getVehicleCategories;
