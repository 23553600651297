import { ErrorResponse } from "@apollo/client/link/error";
import { buildSentryErrorLink } from "apollo-sentry-helper";

import { ignoredNetworkAndGraphqlErrors } from "./ignoreErrors";

const shouldReportError = (error: ErrorResponse): boolean => {
  const { graphQLErrors, networkError } = error;

  if (graphQLErrors && graphQLErrors.length > 0) {
    return graphQLErrors.some(graphqlError => (
      !ignoredNetworkAndGraphqlErrors.some(
        ignoreError => graphqlError.message.includes(ignoreError),
      )
    ));
  }

  if (networkError) {
    return !ignoredNetworkAndGraphqlErrors.some(
      ignoreError => networkError.message.includes(ignoreError),
    );
  }

  return true;
};

export const sentryErrorLink = buildSentryErrorLink({
  filter: shouldReportError,
});
