import React from "react";

export const GetErrorMessageAsList = (errorMessage: string): React.ReactNode => {
  const fields = errorMessage.split(",");

  return (
    <ul>
      {
        fields.map((field) => (
          <li key={field}>{field}</li>
        ))
      }
    </ul>
  );
};
