import createNumberMask from "text-mask-addons/dist/createNumberMask";

export const germanNumberMask = createNumberMask({
  prefix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowDecimal: true,
  decimalSymbol: ",",
});

export const numberMask = createNumberMask({ prefix: "", includeThousandsSeparator: false });

/**
 * mask to be used in react number format
 * @param string formattedValue
 * e.g. : "1000" -> 10,00 €
 * e.g. : "1" -> 0,01 €
 */
export const maskWithEuroSymbol = (formattedValue: string): string => {
  if (!Number(formattedValue) && formattedValue !== "0") return "";

  const amount = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(parseFloat(formattedValue) / 100);
  return `${amount}`;
};

/**
 * mask to be used in react number format
 * @param string formattedValue
 * e.g. : "1000" -> 10,00
 * e.g. : "1" -> 0,01
 */
export const maskWithoutCurrencySymbol = (formattedValue: string): string => {
  if (!Number(formattedValue) && formattedValue !== "0") return "";

  const amount = (parseFloat(formattedValue) / 100).toLocaleString("de-DE", { minimumFractionDigits: 2 });
  return `${amount}`;
};
