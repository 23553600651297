/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from "react";
import CreatableSelect from "react-select/creatable";

import i18n from "translations/i18n";

import { BaseSelectProps } from "../BaseSelect/types";
import parseSelectValue from "../BaseSelect/parseSelectValue";
import { chakraStyles, chakraComponents } from "../BaseSelect/theme";

const BaseCreatableSelect = React.forwardRef<any, BaseSelectProps>(({
  onChange,
  isClearable,
  isSearchable,
  styles,
  ...props
}, ref) => {
  const {
    value,
    options,
  } = props;

  const selectValue = parseSelectValue(value, options);

  const handleSelect = useCallback((option, action) => {
    if (onChange) {
      onChange(option?.value ?? null, action);
    }
  }, [onChange]);

  return (
    <CreatableSelect
      {...props}
      ref={ref}
      components={chakraComponents}
      styles={{ ...chakraStyles, ...styles }}
      value={selectValue}
      options={options}
      onChange={handleSelect}
      isClearable={isClearable}
      isSearchable={isSearchable}
      menuPlacement="auto"
    />
  );
});

export default BaseCreatableSelect;

BaseCreatableSelect.defaultProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: `${i18n.t("actions.search")}...`,
};
