import { gql } from "@apollo/client";

export const GET_LEAD_DETAILS_REFETCH_QUERIES = [
  "GetLeadDetails",
];

export const getLeadDetailsQuery = gql`
  query GetLeadDetails (
    $id: String!
  ) {
    lead(id: $id) {
      id
      vehicleType
      title
      phone
      email
      status
      mileage
      company
      duration
      photoPath
      offerUrl
      lastName
      firstName
      salutation
      commission
      offerShortId
      dealerNotes
      dealerId
      notes
      createdDate
      deliveryTime
      deliveryDate
      vin
      internalNumber
      leasingRateNet
      leasingRateGross
      provisioningCostsNet
      provisioningCostsGross
      accountOwner
      bank
      iban
      bicOrSwift
      birthdate
      placeOfBirth
      nationality
      maritalStatus
      numberDependentChildren
      configurationListPriceGross
      configurationListPriceNet
      servicePackageIncluded
      deliveryMethod
      configurationCode
      configurationPdf {
        id
        file
      }
      assignee {
        id
        seller {
          id
          contactId
          name
        }
      }
      configurationDetails
      companyName
      companyLegalStructure
      companyFoundationDate
      companyNumber
      companyStreet
      companyCity
      companyZipCode
      companyRegistrationNumber
      taxId
      previousYearRevenue
      expectedRevenue
      fleetSize
      leasedBefore
      ceoFirstName
      ceoLastName
      ceoPhoneNumber
      residentSince
      monthlyIncome
      housingSituation
      houseCost
      rentalCost
      rentalCostApartment
      number
      street
      city
      zipCode
      employmentStatus
      employer
      employedSince
      jobTitle
      otherIncome
      debits
      rejectionStatus
      isCheckoutLead
  }
}
`;
