import React from "react";
import {
  VStack,
} from "@chakra-ui/react";

import { Container } from "components/Container";

import { SectionCardProps } from "./types";
import { defaultTitleCss } from "./styles";

const SectionCard: React.FC<SectionCardProps> = ({
  title = "",
  titleCss = defaultTitleCss,
  subTitle = "",
  subTitleCss = {},
  children,
}) => (
  <Container
    boxShadow="default"
    title={title}
    subTitle={subTitle}
    mt="0 !important"
    mb="32px !important"
    borderRadius={8}
    paddingTop={7}
    paddingX={6}
    titleCss={titleCss}
    subTitleCss={subTitleCss}
  >
    <VStack
      align="justify-content"
      shouldWrapChildren
      mt={8}
    >
      {children}
    </VStack>
  </Container>
);

export default SectionCard;
