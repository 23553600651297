import React from "react";
import {
  Box,
  Flex,
  GridItem,
} from "@chakra-ui/react";

import { FooterProps } from "./types";

const Footer: React.FC<FooterProps> = ({
  children,
  FooterContent,
  footerVariant,
}) => {
  const wrapperProps = {
    bg: "white",
    boxShadow: "default",
    paddingX: 6,
    paddingY: 4,
    borderRadius: "8px 0 0 0",
  };

  const footerChildren = (
    <>
      {
        FooterContent && (
          React.createElement(FooterContent)
        )
      }
      {children}
    </>
  );

  return (
    footerVariant === "container" ? (
      <GridItem
        colSpan={8}
        maxWidth="initial"
        width="100%"
        bottom="0"
        margin="auto"
        {...wrapperProps}
      >
        <Flex
          maxWidth="initial"
          marginLeft="0"
          as="footer"
          justifyContent="flex-end"
        >
          {footerChildren}
        </Flex>
      </GridItem>
    ) : (
      <Box
        colSpan={12}
        width="100%"
        top="auto"
        bottom="0"
        right="0"
        left="0"
        margin="auto"
        {...wrapperProps}
      >
        <Flex
          maxWidth="container.xl"
          margin="auto"
          as="footer"
          justifyContent="flex-end"
        >
          {footerChildren}
        </Flex>
      </Box>
    )
  );
};

export default Footer;
