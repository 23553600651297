import { ResidualRatesCustomerTypeEnum } from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

import csvFileTypeTest from "../tests/csvFileTypeTest";
import requiredFileTest from "../tests/requiredFileTest";

const importLeasingFactorsSchema = yup.object().shape({
  customerType: yup
    .string()
    .nullable()
    .required(i18n.t("yup.mixed_required_field")),
  file: yup
    .mixed<FileList>()
    .test(requiredFileTest())
    .test(csvFileTypeTest()),
});

export interface ImportLeasingFactorsSchema {
  customerType: ResidualRatesCustomerTypeEnum;
  file: FileList;
}

export default importLeasingFactorsSchema;
