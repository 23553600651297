import Location from "react-app-location";
import * as Yup from "yup";

import { CUSTOM_OFFER_PAGE_PATH } from "routes";

export const buildIdSchema = (
  idName = "id",
  required = true,
): Record<string, Yup.NumberSchema> => {
  let field = Yup
    .number()
    .integer()
    .positive();

  if (required) {
    field = field.required();
  }

  return {
    [idName]: field,
  };
};

export const CUSTOM_OFFER_PAGE_LOCATION = new Location(
  CUSTOM_OFFER_PAGE_PATH,
  buildIdSchema(),
);
