import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";

import useCustomOfferStore from "views/CustomOffer/store";

import DescriptionForm from "./Description";

const Description: React.FC = () => {
  const { offer, descriptionMarkdown } = useCustomOfferStore(state => ({
    offer: state.offer,
    descriptionMarkdown: state.store?.descriptionMarkdown?.descriptionMarkdown,
  }));

  if (!offer) {
    return (
      <Flex
        justifyContent="center"
        w="full"
        h="full"
      >
        <Spinner
          color="secondary.500"
          size="lg"
          thickness="2px"
        />
      </Flex>
    );
  }

  const defaultFormValues = descriptionMarkdown ? { ...offer, descriptionMarkdown } : offer;

  return <DescriptionForm key={offer?.id} defaultFormValues={defaultFormValues} />;
};

export default Description;
