import React, { FC, useCallback } from "react";
import { Tooltip, useToast } from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";
import { useTranslation } from "react-i18next";

import { useCurrentUser } from "contexts/currentUser";
import Button from "components/Button";
import { OfferCreationTypeEnum, useEnableNewConfiguratorForOffersMutation } from "generated/graphql";

import useOffersStore from "./store";
import { OFFERS_REFETCH_QUERIES } from "../graphql/offers";
import { parseSelectedOffers } from "./utils/parseSelectedOffers";
import { OfferRow } from "../types";

const getAlreadyMigratedOffersShortIds = (
  selectedOffers: OfferRow[],
): string[] | null => {
  const alreadyMigratedOffers = (
    selectedOffers.filter(offer => offer?.isNewConfiguratorEnabled) || []
  );

  const result = (
    alreadyMigratedOffers.length > 0 ? alreadyMigratedOffers.map(offer => offer.shortId) : null
  );

  return result;
};

export const MigrateOffersButton: FC<{ isDisabled?: boolean }> = ({
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const [, , { isAdmin }] = useCurrentUser();
  const newToast = useToast();

  const { selectedItems, clearSelectedItems } = useOffersStore();

  const isConfiguratorMigrationDisabled = selectedItems.some(
    (item) => item.creationType !== OfferCreationTypeEnum.Calculator,
  );

  const [enableNewConfiguratorForOffers] = useEnableNewConfiguratorForOffersMutation({
    awaitRefetchQueries: true,
    refetchQueries: OFFERS_REFETCH_QUERIES,
  });

  const onEnableNewConfiguratorForOffers = useCallback((): void => {
    if (!selectedItems) {
      return;
    }

    const validOffers = selectedItems.filter(item => !item.isNewConfiguratorEnabled);

    const alreadyMigratedOffers = getAlreadyMigratedOffersShortIds(selectedItems);
    if (alreadyMigratedOffers) {
      newToast({
        title: t("actions.warning"),
        description: t("actions.some_offers_has_already_been_migrated", {
          count: alreadyMigratedOffers.length,
          offersShortIds: alreadyMigratedOffers.join(", "),
        }),
        status: "warning",
        isClosable: true,
      });
    }

    if (validOffers.length === 0) {
      clearSelectedItems();

      return;
    }

    enableNewConfiguratorForOffers({
      variables: {
        offersIds: parseSelectedOffers(selectedItems),
      },
    })
      .then(() => {
        newToast({
          title: t("actions.success"),
          description: t("actions.offers_has_been_migrated_successfully", {
            count: selectedItems.length,
          }),
          status: "success",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  }, [
    enableNewConfiguratorForOffers,
    clearSelectedItems,
    selectedItems,
    newToast,
    t,
  ]);

  if (!isAdmin) {
    return null;
  }

  return (
    <ConditionalWrap
      condition={isConfiguratorMigrationDisabled}
      wrap={(content) => (
        <Tooltip
          label={t("actions.migrate_offers_tooltip.label")}
          hasArrow
          defaultIsOpen
          closeOnClick
        >
          <span>{content}</span>
        </Tooltip>
      )}
    >
      <Button
        label={t("actions.migrate_selected_offers_to_the_new_configurator")}
        disabled={isConfiguratorMigrationDisabled || isDisabled}
        onClick={onEnableNewConfiguratorForOffers}
        colorScheme="secondary"
        variant="solid"
      />
    </ConditionalWrap>
  );
};
