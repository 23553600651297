import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import { Column } from "components/Table/types";
import i18n from "translations/i18n";
import { formatDate, germanDateFormat } from "utils/dateFormats";
import { formatPercentage } from "utils/formatPercentage";
import Checkmark from "components/Checkmark";
import StatusComponent from "components/StatusComponent";
import { mapOfferStatus } from "constants/mapOffersStatus";
import Checkbox from "components/FormComponents/Checkbox";
import { CustomerTypeEnum } from "generated/graphql";
import { formatCurrency, numeralLocalized } from "utils/currencyFormat";
import ActionsButton from "components/ActionsButton";

import { CalculatorOfferActions, CalculatorOfferRow } from "../types";

const mapCustomerType = {
  [CustomerTypeEnum.Private]: i18n.t("enum.customer_type.private"),
  [CustomerTypeEnum.Corporate]: i18n.t("enum.customer_type.corporate"),
};

/**
 * Builds the table columns.
 */
export const makeTableColumns = ({
  selectedCalculatorOffers,
  toggleCalculatorOffer,
  toggleAllItems,
  isAllSelected,
  hasOffers,
  onEdit,
}: CalculatorOfferActions): Column<CalculatorOfferRow>[] => [
  {
    title: () => (
      <Checkbox
        name="checkbox"
        isChecked={isAllSelected}
        onChange={toggleAllItems}
        isDisabled={!hasOffers}
      />
    ),
    render: ({
      row,
    }) => (
      <Checkbox
        name="checkbox"
        isChecked={!!selectedCalculatorOffers.find(({ id }) => id === row.id)}
        onChange={toggleCalculatorOffer(row)}
      />
    ),
  },
  {
    title: i18n.t("components.table.heading.vehicle_id"),
    fieldResolver: (calculatorOffer) => (
      calculatorOffer?.vehicle?.id
        ? String(calculatorOffer.vehicle.id)
        : ""
    ),
  },
  {
    title: i18n.t("components.table.heading.dealer"),
    fieldResolver: (calculatorOffer) => calculatorOffer?.dealer?.companyName,
  },
  {
    title: i18n.t("components.table.heading.brand"),
    fieldResolver: (calculatorOffer) => calculatorOffer?.vehicle?.brand?.name,
  },
  {
    title: i18n.t("components.table.heading.model_group"),
    fieldResolver: (calculatorOffer) => calculatorOffer?.vehicle?.modelGroup,
  },
  {
    title: i18n.t("components.table.heading.model"),
    fieldResolver: (calculatorOffer) => calculatorOffer?.vehicle?.model,
  },
  {
    title: i18n.t("components.table.heading.last_updated"),
    fieldResolver: (calculatorOffer) => (formatDate(calculatorOffer?.vehicle?.updatedAt, germanDateFormat, "-")),
  },
  {
    title: i18n.t("components.table.heading.leasing"),
    render: ({ row }) => {
      const isPrivateCustomer = row?.customerType === CustomerTypeEnum.Private;

      const priceAppendLabel = i18n.t(`components.table.heading.${isPrivateCustomer ? "gross" : "net"}`);

      const price = isPrivateCustomer
        ? row?.minFinalLeasingRate?.priceGross
        : row?.minFinalLeasingRate?.priceNet;

      const formattedPrice = price
        ? formatCurrency({ amount: price })
        : "-";

      return (
        <Flex>
          <Text>
            {formattedPrice}
          </Text>

          {
            price && (
              <Text
                textDecor="italic"
                ml={1}
              >
                {priceAppendLabel}
              </Text>
            )
          }
        </Flex>
      );
    },
  },
  {
    title: i18n.t("components.table.heading.variant"),
    fieldResolver: (calculatorOffer) => {
      if (!calculatorOffer?.minFinalLeasingRate) {
        return undefined;
      }

      const {
        termMonths,
        mileageKm,
      } = calculatorOffer.minFinalLeasingRate;

      return `${termMonths}/${mileageKm / 1000}T`;
    },
  },
  {
    title: i18n.t("components.table.heading.leasing_factor"),
    fieldResolver: (calculatorOffer) => {
      const leasingFactor = calculatorOffer?.minFinalLeasingRate?.factor;

      return leasingFactor ? numeralLocalized(parseFloat(leasingFactor)) : "-";
    },
  },
  {
    title: i18n.t("components.table.heading.dealer_discount"),
    fieldResolver: (calculatorOffer) => (
      formatPercentage(calculatorOffer?.dealerDiscount?.listPriceDiscountRate)
    ),
  },
  {
    title: i18n.t("components.table.heading.customer_type"),
    fieldResolver: (row) => (mapCustomerType[row?.customerType]),
  },
  {
    title: i18n.t("components.table.heading.pricing"),
    cellProps: {
      textAlign: "center",
    },
    render: ({ row }) => (
      <Checkmark checked={row?.hasPricing} />
    ),
  },
  {
    title: i18n.t("components.table.heading.status"),
    cellProps: {
      textAlign: "center",
    },
    render: ({ row }) => {
      const status = mapOfferStatus[row?.offer?.status];

      return (
        <>
          <StatusComponent status={status} />

          <Text
            color="gray.500"
            fontSize="xs"
            textStyle="p"
          >
            {row?.offer?.shortId}
          </Text>
        </>
      );
    },
  },
  {
    render: ({
      row,
    }) => {
      const actions = [
        {
          onClick: () => onEdit(row),
          label: i18n.t("actions.edit"),
        },
      ];

      return (
        <ActionsButton
          actions={actions}
        />
      );
    },
  },
];
