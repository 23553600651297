import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useToast, Wrap } from "@chakra-ui/react";

import Button from "components/Button";
import {
  useDeactivateOffersMutation,
  useCreateOffersFromVehiclesMutation,
  OfferStatusEnum,
} from "generated/graphql";
import { GRID_GAP } from "constants/layout";

import useCalculatorOffersStore from "./store";
import { CalculatorOfferRow } from "../types";

const parseSelectedCalculatorOffers = (
  offers: CalculatorOfferRow[],
): number[] => offers.map((i => i?.offer?.id));

const CALCULATOR_OFFERS_REFETCH_QUERIES = ["CalculatorOffers", "Offers"];

const CalculatorOffersFooter: React.FC = () => {
  const { t } = useTranslation();
  const newToast = useToast();

  const {
    selectedItems,
    clearSelectedItems,
  } = useCalculatorOffersStore();

  const canDeactivate = (
    selectedItems.length > 0
    && !selectedItems.every(({ offer }) => offer === null)
    && selectedItems.every(({ offer }) => offer?.status === OfferStatusEnum.Live)
  );

  const canPublish = (
    selectedItems.length > 0
    && selectedItems.every(({ hasPricing }) => !!hasPricing)
    && selectedItems.every(({ offer }) => offer === null)
  );

  const [deactivateOffers] = useDeactivateOffersMutation({
    awaitRefetchQueries: true,
    refetchQueries: CALCULATOR_OFFERS_REFETCH_QUERIES,
  });

  const [publish, { loading }] = useCreateOffersFromVehiclesMutation({
    refetchQueries: CALCULATOR_OFFERS_REFETCH_QUERIES,
    awaitRefetchQueries: true,
  });

  const onDeactivateSelected = (): void => {
    if (!selectedItems) {
      return;
    }

    deactivateOffers({
      variables: {
        offersIds: parseSelectedCalculatorOffers(selectedItems),
      },
    })
      .then(() => {
        newToast({
          title: t("actions.success"),
          description: t("actions.offers_has_been_deactivated_successfully",
            { count: selectedItems.length }),
          status: "success",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  };

  const onPublishSelected = useCallback(() => {
    if (!canPublish) {
      return;
    }

    const params = selectedItems.map(({
      dealer,
      customerType,
      vehicle,
    }) => ({
      dealerId: dealer.id,
      vehicleId: vehicle?.id,
      customerType,
    }));

    publish({
      variables: {
        params,
      },
    })
      .then(() => {
        newToast({
          title: t("actions.success"),
          description: t("screens.calculator_offers.offers_successfully_published",
            { count: selectedItems.length }),
          status: "success",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  }, [
    t,
    publish,
    newToast,
    canPublish,
    selectedItems,
    clearSelectedItems,
  ]);

  return (
    <Wrap spacing={GRID_GAP.MD}>
      <Button
        disabled={!canDeactivate}
        variant="outline"
        label={t("actions.deactivate")}
        onClick={onDeactivateSelected}
      />

      <Button
        variant="solid"
        label={t("actions.publish")}
        disabled={!canPublish || loading}
        onClick={onPublishSelected}
      />
    </Wrap>
  );
};

export default CalculatorOffersFooter;
