import React from "react";
import { Text } from "@chakra-ui/react";

import { Column } from "components/Table/types";
import i18n from "translations/i18n";
import { Vehicle, VehicleSortFieldsEnum } from "generated/graphql";
import Checkbox from "components/FormComponents/Checkbox";

import { CreateNewOfferActions } from "../types";

/**
 * Builds the vehicles table columns.
 */
export const makeTableColumns = ({
  selectedCreateNewOffer,
  toggleCreateNewOffer,
}: CreateNewOfferActions):
  Column<Vehicle & {categoryDescription?: string; fuelTypeDescription?: string}>[] => [
  {
    render: ({ row }) => (
      <Checkbox
        name="checkbox"
        isChecked={selectedCreateNewOffer?.id === row?.id}
        onChange={toggleCreateNewOffer(row)}
      />
    ),
  },
  {
    title: i18n.t("components.table.heading.brand"),
    render: ({ row }) => (
      <Text fontWeight="bold">
        { row?.brand?.name}
      </Text>
    ),
    sortingColumn: VehicleSortFieldsEnum.BrandName,
  },
  {
    title: i18n.t("components.table.heading.model_group"),
    fieldPath: "modelGroup",
    sortingColumn: VehicleSortFieldsEnum.ModelGroup,
  },
  {
    title: i18n.t("components.table.heading.model"),
    fieldPath: "model",
    sortingColumn: VehicleSortFieldsEnum.Model,
  },
  {
    title: i18n.t("components.table.heading.category"),
    fieldPath: "categoryDescription",
    sortingColumn: VehicleSortFieldsEnum.Model,
  },
  {
    title: i18n.t("components.table.heading.fuel_type"),
    fieldPath: "fuelTypeDescription",
    sortingColumn: VehicleSortFieldsEnum.Model,
  },
  {
    title: i18n.t("components.table.heading.power"),
    fieldPath: "powerKw",
    sortingColumn: VehicleSortFieldsEnum.Model,
  },
];
