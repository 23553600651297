import i18n from "translations/i18n";

import { ConfigurationDetailsData, ParsedConfigurationDetails } from "./types";

export const parseConfigurationDetails = (
  data?: ConfigurationDetailsData,
): ParsedConfigurationDetails | undefined => {
  if (!data) {
    return undefined;
  }

  const parsedData = [];

  if (data.color) {
    parsedData.push({
      section: i18n.t("leads.details.form.color"),
      name: data.color.name,
      manufacturerCode: data.color.manufacturerCode,
      price: data.color.price,
      netPrice: data.color.netPrice,
    });
  }

  if (data.rims) {
    parsedData.push({
      section: i18n.t("leads.details.form.rims"),
      name: data.rims.name,
      manufacturerCode: data.rims.manufacturerCode,
      price: data.rims.price,
      netPrice: data.rims.netPrice,
    });
  }

  if (data.interior) {
    parsedData.push({
      section: i18n.t("leads.details.form.interior"),
      name: data.interior.name,
      manufacturerCode: data.interior.manufacturerCode,
      price: data.interior.price,
      netPrice: data.interior.netPrice,
    });
  }

  if (data.extras) {
    const extras = data.extras.map(extra => ({
      name: extra.name,
      manufacturerCode: extra.manufacturerCode,
      price: extra.price,
      netPrice: extra.netPrice,
    }));

    parsedData.push({
      section: i18n.t("leads.details.form.extras"),
      extras,
    });
  }

  if (data.deliveryOption) {
    parsedData.push({
      section: i18n.t("leads.details.form.delivery_option"),
      name: i18n.t(`leads.details.form.${data.deliveryOption.type}`),
      manufacturerCode: undefined,
      price: data.deliveryOption.price,
      netPrice: undefined,
    });
  }

  return parsedData;
};
