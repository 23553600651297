import { Flex, IconButton } from "@chakra-ui/react";
import React, { useCallback } from "react";

import ErrorIcon from "components/Icons/ErrorIcon";

import Image from "../Image";
import { DelectableImageProps } from "../types";
import HighlightLabel from "./HighlightLabel";

const DelectableImage: React.FC<DelectableImageProps> = ({
  src,
  label,
  callback,
  isHighlighted = true,
  ...props
}) => {
  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();

    callback?.();
  }, [
    callback,
  ]);

  const hightLightBorderColor = isHighlighted ? "secondary.500" : "transparent";

  return (
    <Flex
      textAlign="center"
      maxW="min-content"
      position="relative"
      borderRadius="10px"
      border="4px solid"
      borderColor={hightLightBorderColor}
    >
      <IconButton
        d="flex"
        top="8px"
        right="8px"
        zIndex="docked"
        onClick={onClick}
        variant="unstyled"
        position="absolute"
        alignItems="center"
        justifyContent="center"
        aria-label="close-button"
        __css={{
          boxSize: "20px",
        }}
        icon={
          React.createElement(
            ErrorIcon,
            {
              boxSize: 5,
              color: "error.500",
            },
          )
        }
      />

      {
        isHighlighted && (
          <HighlightLabel label={label} />
        )
      }

      <Image
        src={src}
        userSelect="none"
        {...props}
      />
    </Flex>
  );
};

export default DelectableImage;
