/**
 * This is used to capitalize the first letter of a string.
 * The rest of the string will be turned to lowercase.
 * @param string string to capitalize
 * @returns a string with the first character capitalized
 * @example capitalizeFirstCharacter('HELLO') // 'Hello'
 * @example capitalizeFirstCharacter('hello') // 'Hello'
 */
export const capitalize = (string: string): string => (
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
);
