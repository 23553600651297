import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import { OfferFuelTypeEnum } from "generated/graphql";
import Select from "components/FormComponents/Inputs/Selects/Select";
import { titleCss } from "views/CustomOffer/components/Steps/CustomOfferSteps/styles";

import { InnerSection } from "../../../../InnerSections";
import { FieldsCommonProps } from "..";

export interface EnergyCostProps extends FieldsCommonProps {
  fuelType: OfferFuelTypeEnum;
  isPluginHybrid: boolean;
}

const carbonicEmissionClasses = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
];

export const CO2Class = ({
  isPluginHybrid = false,
  fuelType,
  control,
}: EnergyCostProps): JSX.Element => {
  const { t } = useTranslation();
  const translationsPrefix = "custom_offer.steps.standard_data.fuel_card";

  const isHybrid = isPluginHybrid
    && [OfferFuelTypeEnum.HybridDiesel, OfferFuelTypeEnum.Hybrid].includes(fuelType);

  return (
    <InnerSection label={t(`${translationsPrefix}.co2_class`)}>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        {
          isHybrid ? (
            <>
              <Controller
                name="wltpCarbonicEmissionWeightedCombinedClass"
                control={control}
                render={({
                  field,
                  fieldState,
                  formState,
                }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    errors={formState?.errors}
                    options={carbonicEmissionClasses}
                    titleCss={titleCss}
                    isRequired
                    placeholder={t(`${translationsPrefix}.select_co2_class`)}
                    title={t(`${translationsPrefix}.weighted_combined_c02_emissions`)}
                  />
                )}
              />

              <Controller
                name="wltpCarbonicEmissionCombinedWithDischargedBatteryClass"
                control={control}
                render={({
                  field,
                  fieldState,
                  formState,
                }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    errors={formState?.errors}
                    options={carbonicEmissionClasses}
                    isRequired
                    placeholder={t(`${translationsPrefix}.select_co2_class`)}
                    title={t(`${translationsPrefix}.discharged_battery_c02_emissions`)}
                    titleCss={titleCss}
                  />
                )}
              />
            </>
          ) : (
            <Controller
              name="wltpCarbonicEmissionCombinedClass"
              control={control}
              render={({
                field,
                fieldState,
                formState,
              }) => (
                <Select
                  {...field}
                  {...fieldState}
                  errors={formState?.errors}
                  options={carbonicEmissionClasses}
                  isRequired
                  placeholder={t(`${translationsPrefix}.select_co2_class`)}
                  title={t(`${translationsPrefix}.combined_c02_emissions`)}
                  titleCss={titleCss}
                />
              )}
            />

          )
        }
      </Grid>
    </InnerSection>
  );
};
