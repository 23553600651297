const defaultSkeletonStyles = {
  w: "sm",
  mb: 4,
  h: 8,
};

export const skeletonStyles = {
  full: {
    ...defaultSkeletonStyles,
    w: "full",
  },
  sm: {
    ...defaultSkeletonStyles,
  },
};
