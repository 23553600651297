import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { ImageFallbackProps } from "../types";

const ImageFallback: React.FC<ImageFallbackProps> = ({
  src,
  ...props
}) => {
  const { t } = useTranslation();

  const isSrcValid = !!src;

  return (
    <Box
      p={3}
      d="flex"
      width="full"
      bgColor="gray.200"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Text
        width="full"
        fontSize="xxs"
        color="gray.500"
        textAlign="center"
      >
        {
          isSrcValid
            ? t("components.image_fallback.couldnt_load_this_image")
            : t("components.image_fallback.no_image_submitted")
        }
      </Text>
    </Box>
  );
};

export default ImageFallback;
