import React from "react";
import { IconButton, Stack } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import CalendarIcon from "components/Icons/CalendarIcon";
import Input from "components/FormComponents/Inputs/Input";

import { DateRangeDisplayProps } from "./types";
import { disabledStyles } from "./styles";
import { getContainerWidth } from "../getContainerWidth";

const DateRangeDisplay: React.FC<DateRangeDisplayProps> = ({
  isClearable = false,
  isDisabled,
  onClear,
  value,
  startDate,
}) => {
  const containerWidth = getContainerWidth(isClearable);

  return (
    <Stack
      justifyContent="space-between"
      _disabled={disabledStyles}
      backgroundColor={!startDate ? "white" : "secondary.50"}
      border="1px solid"
      borderColor={!startDate ? "gray" : "secondary.700"}
      disabled={isDisabled}
      alignItems="center"
      w={containerWidth}
      borderRadius="md"
      direction="row"
      display="flex"
      spacing={1}
      px={2.5}
      h={10}
    >
      <CalendarIcon
        disabled={isDisabled}
        color="secondary.700"
        mr={1.5}
      />

      <Input
        backgroundColor="transparent"
        showErrorMessage={false}
        justifyContent="center"
        color="secondary.700"
        cursor="pointer"
        disabled={isDisabled}
        alignItems="center"
        variant="unstyled"
        textAlign="center"
        name="date-range"
        fontWeight="500"
        display="flex"
        border="none"
        textStyle="p"
        value={value}
      />

      {
        isClearable && (
          <IconButton
            _disabled={{
              backgroundColor: disabledStyles.backgroundColor,
            }}
            _hover={{
              backgroundColor: "secondary.500",

              svg: {
                color: "white",
              },
            }}
            aria-label="clean-button"
            backgroundColor={!startDate ? "white" : "secondary.50"}
            disabled={isDisabled}
            onClick={onClear}
            size="xs"
            isRound
            p={0}
            m={0}
            icon={(
              <CloseIcon
                color="secondary.500"
                height="10px"
                width="10px"
              />
            )}
          />
        )
      }
    </Stack>
  );
};

export default DateRangeDisplay;
