import React from "react";

import CalendarIcon from "components/Icons/CalendarIcon";

import Input from "../../Input";
import { DateInputProps } from "./types";

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>((
  {
    isDisabled = false,
    name,
    ...props
  },
  ref,
) => (
  <Input
    {...props}
    rightElement={<CalendarIcon />}
    isDisabled={isDisabled}
    rightElementProps={{
      color: "gray.400",
    }}
    autoComplete="off"
    name={name}
    ref={ref}
    pr={10}
  />
));

export default DateInput;
