import {
  CustomerTypeEnum,
  OfferCustomerTypeEnum,
  UpdateDeliveryPriceParams,
} from "generated/graphql";

export const getDeliveryPrices = (
  deliveryPrices?: UpdateDeliveryPriceParams[],
  customerType?: OfferCustomerTypeEnum,
  dealerId?: string,
): UpdateDeliveryPriceParams[] | undefined => {
  if (!deliveryPrices || !dealerId || !deliveryPrices?.length) {
    return undefined;
  }

  const corporateId = deliveryPrices?.find(
    (price) => price?.type === CustomerTypeEnum.Corporate,
  )?.id;

  const privateId = deliveryPrices?.find(
    (price) => price.type === CustomerTypeEnum.Private,
  )?.id;

  if (customerType === OfferCustomerTypeEnum.Both) {
    return [
      {
        ...deliveryPrices?.[0],
        dealerId,
        type: CustomerTypeEnum.Private,
        id: corporateId && privateId,
      },
      {
        ...deliveryPrices?.[0],
        dealerId,
        type: CustomerTypeEnum.Corporate,
        id: privateId && corporateId,
      },
    ];
  }

  return [{
    ...deliveryPrices?.[0],
    dealerId,
    id: customerType === OfferCustomerTypeEnum.PrivateOnly
      ? privateId : corporateId,
    type: customerType === OfferCustomerTypeEnum.PrivateOnly
      ? CustomerTypeEnum.Private : CustomerTypeEnum.Corporate,
  }];
};
