import {
  StringParam,
  NumberParam,
  BooleanParam,
  withDefault,
  ArrayParam,
} from "use-query-params";

import {
  Filter,
  Filters,
  UseFiltersConfig,
  QueryFiltersConfig,
} from "./types";

export const FilterParams: UseFiltersConfig = {
  [Filters.Dealer]: {
    dealerId: StringParam,
  },
  [Filters.Vehicle]: {
    brandId: NumberParam,
    brandName: StringParam,
    modelGroup: StringParam,
    model: StringParam,
  },
  [Filters.VehicleCategory]: {
    vehicleCategory: StringParam,
  },
  [Filters.CustomerType]: {
    customerType: StringParam,
  },
  [Filters.VehicleType]: {
    vehicleType: StringParam,
  },
  [Filters.OfferCustomerType]: {
    offerCustomerType: StringParam,
  },
  [Filters.CreationType]: {
    creationType: StringParam,
  },
  [Filters.OnlyActive]: {
    active: withDefault(BooleanParam, false),
  },
  [Filters.TopOffer]: {
    topOffer: withDefault(BooleanParam, false),
  },
  [Filters.MarketingOffer]: {
    marketingOffer: withDefault(BooleanParam, false),
  },
  [Filters.HasPricingInformation]: {
    hasPricingInformation: withDefault(BooleanParam, false),
  },
  [Filters.Search]: {
    search: StringParam,
  },
  [Filters.HasResidualInformation]: {
    hasResidualInformation: withDefault(BooleanParam, false),
  },
  [Filters.Step]: {
    step: withDefault(NumberParam, 1),
  },
  [Filters.Period]: {
    period: ArrayParam,
  },
  [Filters.LeadStatus]: {
    leadStatus: ArrayParam,
  },
  [Filters.Salesperson]: {
    salesperson: StringParam,
  },
  [Filters.IsPossibleToEnableNewConfigurator]: {
    isPossibleToEnableNewConfigurator: withDefault(BooleanParam, false),
  },
  [Filters.IsNewConfiguratorEnabled]: {
    isNewConfiguratorEnabled: withDefault(BooleanParam, false),
  },
};

export const buildFilters = (filters: Filter[]): QueryFiltersConfig => (
  filters.reduce((acc, cur) => ({
    ...acc,
    ...FilterParams[cur],
  }), {})
);
