import React from "react";
import {
  Skeleton, Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ConditionalWrap from "conditional-wrap";
import i18next from "i18next";

import CurrencyInput from "components/FormComponents/Inputs/CurrencyInput";
import { SupportedLanguages } from "translations/i18n";

import { SpendingsBadgeProps } from "./types";

const formatTooltipLabel = (value: number): string => {
  const isGerman = i18next.language === SupportedLanguages.de;

  const numberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const localizedString = isGerman
    ? value.toLocaleString(SupportedLanguages.de, numberFormatOptions)
    : value.toLocaleString(SupportedLanguages.en, numberFormatOptions);

  return `${localizedString} €`;
};

const SpendingsBadge: React.FC<SpendingsBadgeProps> = ({
  isUnlimited = false,
  isLoading = false,
  budget,
  spent,
}) => {
  const { t } = useTranslation();

  const spentLabel = spent || "0 €";

  const hasTooltip = (!!budget && budget !== 0) && !isUnlimited;

  const remainingBudget = (
    hasTooltip && budget
      ? formatTooltipLabel(spent ? budget - spent : budget)
      : "€ 0"
  );

  const remainingBudgetTooltip = (
    hasTooltip
      ? t("budget_overview.remaining_budget", { amount: remainingBudget })
      : ""
  );

  return (
    <ConditionalWrap
      condition={hasTooltip}
      wrap={(content) => (
        <Tooltip
          label={remainingBudgetTooltip}
          hasArrow
        >
          <span>
            {content}
          </span>
        </Tooltip>
      )}
    >
      <Skeleton
        isLoaded={!isLoading}
        borderRadius="sm"
      >
        <CurrencyInput
          title={t("budget_overview.spendings")}
          showErrorMessage={false}
          isNumericString
          format={undefined}
          fixedDecimalScale
          disabled
          decimalScale={2}
          value={spentLabel}
          suffix=" €"
          w="full"
          h="29px"
        />
      </Skeleton>
    </ConditionalWrap>
  );
};

export default SpendingsBadge;
