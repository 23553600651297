import React from "react";

import { Lead } from "generated/graphql";
import { Column } from "components/Table/types";
import Checkbox from "components/FormComponents/Checkbox";
import { formatDate, germanDateFormat } from "utils/dateFormats";
import i18n from "translations/i18n";
import AssigneeSelector from "views/Leads/components/AssigneeSelector";

import { MakeLeadsTableColumnsProps } from "./types";
import leadSelectStyles from "./components/Select/styles";

const MemoizedAssigneeSelector = React.memo(AssigneeSelector);

export const makeDefaultLeadsTableColumns = ({
  selectedLeads,
  toggleLead,
  toggleAllLeads,
  isAllSelected = false,
  hasLeads,
  refetchQueries,
}: MakeLeadsTableColumnsProps): Column<Lead>[] => [
  {
    title: () => (
      <Checkbox
        onChange={toggleAllLeads}
        isDisabled={!hasLeads}
        isChecked={isAllSelected}
        name="header-checkbox"
      />
    ),
    render: ({
      row,
    }) => (
      <Checkbox
        isChecked={!!selectedLeads.find(({ id }) => id === row.id)}
        onChange={() => toggleLead(row)}
        name="checkbox"
      />
    ),
  },
  {
    title: i18n.t("components.table.heading.date"),
    fieldResolver: lead => formatDate(lead?.createdDate, germanDateFormat, "-"),
  },
  {
    title: i18n.t("components.table.heading.last_name"),
    fieldResolver: lead => lead?.lastName,
    fieldPath: "lastName",
    cellProps: {
      fontWeight: 700,
    },
  },
  {
    title: i18n.t("components.table.heading.first_name"),
    fieldPath: "firstName",
  },
  {
    title: i18n.t("components.table.heading.dealer"),
    fieldResolver: lead => lead?.dealerName,
  },
  {
    title: i18n.t("components.table.heading.offer_title"),
    fieldPath: "title",
  },
  {
    title: i18n.t("components.table.heading.vehicle_type"),
    fieldPath: "vehicleType",
  },
  {
    title: i18n.t("components.table.heading.salesperson"),
    render: ({ row }) => (
      <MemoizedAssigneeSelector
        defaultValue={row?.assignee?.seller?.contactId}
        value={row?.assignee?.seller?.contactId}
        refetchQueries={refetchQueries}
        dealerId={row?.dealerId}
        leadId={row?.id}
        styles={leadSelectStyles}
      />
    ),
    cellProps: {
      minW: "180px",
    },
  },
];
