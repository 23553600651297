import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useModal } from "contexts/modal";
import Table from "components/Table";
import EditVehicleModal from "components/Modals/EditVehicleModal";

import { makeTableColumns } from "./tableColumns";
import { VehicleTableProps } from "../types";

const VehiclesTable: React.FC<VehicleTableProps> = ({
  vehicles,
  loading,
}) => {
  const { t } = useTranslation();
  const [showModal] = useModal();

  const onEditVehicle = useCallback((row) => () => showModal({
    componentProps: {
      data: row,
    },
    size: "6xl",
    component: EditVehicleModal,
    isCloseable: false,
    isCentered: false,
  }), [
    showModal,
  ]);

  const columns = makeTableColumns({
    onEditVehicle,
  });

  return (
    <Table
      columns={columns}
      data={vehicles}
      emptyDataProps={{
        message: t("empty_data_messages.no_information_to_be_displayed"),
        isDataLoading: loading,
      }}
      isPageable
    />
  );
};

export default VehiclesTable;
