import React from "react";
import { Grid } from "@chakra-ui/react";
import { ReactDatePickerProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { titleCss } from "views/CustomOffer/components/Steps/CustomOfferSteps/styles";
import { EURO_MONTH, EURO_T, EURO_YEAR } from "constants/units";
import DatePicker from "components/FormComponents/Inputs/DatePicker";

import { InnerSection } from "../../../../InnerSections";
import { FieldsCommonProps } from "..";
import { InputField } from "../shared/InputField";

type Props = FieldsCommonProps

export const CO2Cost = ({
  register,
  formState,
  control,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const datePickerProps: Omit<ReactDatePickerProps, "onChange"> = {
    showYearPicker: true,
    dateFormat: "yyyy",
    yearItemNumber: 6,
    maxDate: new Date(2999, 0),
  };

  return (
    <InnerSection label={t("custom_offer.steps.standard_data.fuel_card.possible_co2_costs")}>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full" alignItems="flex-end">
        <InputField
          {...register?.("wltpCarbonicEmissionPriceAverage")}
          name="wltpCarbonicEmissionPriceAverage"
          title={t("custom_offer.steps.standard_data.fuel_card.average_co2_price")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_average_co2_price")}
          errors={formState?.errors}
          measurementUnit={EURO_T}
        />

        <InputField
          {...register?.("wltpCarbonicEmissionPriceLowAverage")}
          name="wltpCarbonicEmissionPriceLowAverage"
          title={t("custom_offer.steps.standard_data.fuel_card.low_average_co2_price")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_low_average_co2_price")}
          titleCss={titleCss}
          errors={formState?.errors}
          measurementUnit={EURO_T}
        />

        <InputField
          {...register?.("wltpCarbonicEmissionPriceHighAverage")}
          name="wltpCarbonicEmissionPriceHighAverage"
          title={t("custom_offer.steps.standard_data.fuel_card.high_average_co2_price")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_high_average_co2_price")}
          titleCss={titleCss}
          errors={formState?.errors}
          rightAddonProps={{ color: "gray.400" }}
          measurementUnit={EURO_T}
        />
      </Grid>

      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        <InputField
          {...register?.("wltpCarbonicEmissionPriceAverageAccumulated")}
          name="wltpCarbonicEmissionPriceAverageAccumulated"
          title={t("custom_offer.steps.standard_data.fuel_card.accumulated_average_co2_price")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_accumulated_average_co2_price")}
          titleCss={titleCss}
          errors={formState?.errors}
          measurementUnit={EURO_YEAR}
        />

        <InputField
          {...register?.("wltpCarbonicEmissionPriceLowAverageAccumulated")}
          name="wltpCarbonicEmissionPriceLowAverageAccumulated"
          title={t("custom_offer.steps.standard_data.fuel_card.accumulated_low_average_co2_price")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_accumulated_low_average_co2_price")}
          titleCss={titleCss}
          errors={formState?.errors}
          measurementUnit={EURO_YEAR}
        />

        <InputField
          {...register?.("wltpCarbonicEmissionPriceHighAverageAccumulated")}
          name="wltpCarbonicEmissionPriceHighAverageAccumulated"
          title={t("custom_offer.steps.standard_data.fuel_card.accumulated_high_average_co2_price")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_accumulated_high_average_co2_price")}
          titleCss={titleCss}
          errors={formState?.errors}
          measurementUnit={EURO_YEAR}
        />
      </Grid>

      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        <InputField
          {...register?.("vehicleTax")}
          name="vehicleTax"
          title={t("custom_offer.steps.standard_data.fuel_card.vehicle_tax")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_vehicle_tax")}
          titleCss={titleCss}
          errors={formState?.errors}
          measurementUnit={EURO_MONTH}
        />

        <InputField
          {...register?.("vehicleTaxPerYear")}
          name="vehicleTaxPerYear"
          title={t("custom_offer.steps.standard_data.fuel_card.vehicle_tax_per_year")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_vehicle_tax_per_year")}
          titleCss={titleCss}
          errors={formState?.errors}
          measurementUnit={EURO_YEAR}
        />

        <Controller
          name="wltpCarbonicEmissionPricePeriodFromYear"
          control={control}
          render={({
            field,
            fieldState,
          }) => (
            <DatePicker
              {...field}
              {...fieldState}
              title={t("custom_offer.steps.standard_data.fuel_card.timeframe_from")}
              titleCss={titleCss}
              placeholder={t("custom_offer.steps.standard_data.fuel_card.year")}
              errors={formState?.errors}
              datePickerProps={{
                ...datePickerProps,
                minDate: new Date(new Date().getFullYear(), 0),
              }}
            />
          )}
        />

        <Controller
          name="wltpCarbonicEmissionPricePeriodToYear"
          control={control}
          render={({
            field,
            fieldState,
          }) => (
            <DatePicker
              {...field}
              {...fieldState}
              title={t("custom_offer.steps.standard_data.fuel_card.timeframe_to")}
              titleCss={titleCss}
              placeholder={t("custom_offer.steps.standard_data.fuel_card.year")}
              errors={formState?.errors}
              datePickerProps={{
                ...datePickerProps,
                minDate: new Date(new Date().getFullYear() + 8, 0),
              }}
            />
          )}
        />
      </Grid>
    </InnerSection>
  );
};
