import React from "react";

import useCustomOfferStore from "views/CustomOffer/store";

import SpinnerContainer from "../../../SpinnerContainer";
import StandardDataForm from "./StandardDataForm";

const StandardData: React.FC = () => {
  const { offer, standardData } = useCustomOfferStore(state => ({
    offer: state.offer,
    standardData: state.store?.standardData,
  }));

  if (!offer) {
    return (<SpinnerContainer />);
  }

  const defaultFormValues = standardData
    ? { ...standardData, brand: offer?.brand }
    : offer;

  return <StandardDataForm key={offer?.id} defaultFormValues={defaultFormValues} />;
};

export default StandardData;
