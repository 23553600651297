import React, { useRef, useCallback, useState } from "react";
import { Flex } from "@chakra-ui/react";
import mergeRefs from "react-merge-refs";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Input from "components/FormComponents/Inputs/Input";
import PlusIcon from "components/Icons/PlusIcon";

import { UploadButtonProps } from "./types";
import { useLimitImageSize } from "../useLimitImageSize";

const UploadButton = React.forwardRef<HTMLElement, UploadButtonProps>((
  {
    onChange,
    errors,
    ...props
  },
  ref,
) => {
  const { t } = useTranslation();
  const [, setFiles] = useState<FileList | null>();
  const fileInputRef = useRef<HTMLInputElement>();

  const { handleValidateFiles } = useLimitImageSize();

  const onUploadClick = useCallback(() => {
    if (!fileInputRef.current) {
      return;
    }

    fileInputRef.current.click();
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event) {
      return;
    }

    const fileList = event.currentTarget.files;

    if (!fileList) {
      return;
    }

    const validFiles = handleValidateFiles(Array.from(fileList));

    if (validFiles.length > 0) {
      const dataTransfer = new DataTransfer();
      validFiles.forEach(file => dataTransfer.items.add(file));

      const newFileList = dataTransfer.files;

      onChange(event);

      setFiles(newFileList);
    }
  }, [
    handleValidateFiles,
    onChange,
    setFiles,
  ]);

  return (
    <Flex flexDir="column" marginTop="16px">
      <Button
        onClick={onUploadClick}
        colorScheme="secondary"
        label={t("custom_offer.steps.images.upload_button")}
        Icon={PlusIcon}
        boxShadow="sm"
        textStyle="p"
        px={6}
      />

      <Input
        ref={mergeRefs([fileInputRef, ref])}
        onChange={handleChange}
        visibility="hidden"
        variant="unstyled"
        errors={errors}
        display="none"
        type="file"
        multiple
        {...props}
      />
    </Flex>
  );
});

export default UploadButton;
