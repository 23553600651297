import { OfferFuelTypeEnum } from "generated/graphql";
import {
  ENERGY_CONSUMPTION, FUEL_CONSUMPTION, GASEOUS_CONSUMPTION,
} from "constants/units";
import i18n from "translations/i18n";

export const getChargingTimeAppend = (startAt?: number, endAt?: number): string | null => {
  if (!endAt) return null;

  const chargingTimePrefix = (!startAt || startAt === 0)
    ? i18n.t("custom_offer.steps.standard_data.fuel_card.up")
    : `${startAt}%`;

  return ` - (${chargingTimePrefix} to ${endAt}%)`;
};

const LiquidFuels = [
  OfferFuelTypeEnum.Diesel,
  OfferFuelTypeEnum.Ethanol,
  OfferFuelTypeEnum.Hybrid,
  OfferFuelTypeEnum.HybridDiesel,
  OfferFuelTypeEnum.Petrol,
];

const GaseousFuels = [
  OfferFuelTypeEnum.NaturalGas,
  OfferFuelTypeEnum.Lpg,
  OfferFuelTypeEnum.Hydrogen,
];

export const getMeasurementUnit = (fuelType?: OfferFuelTypeEnum): string => {
  if (!fuelType) {
    return "";
  }

  if (LiquidFuels.includes(fuelType)) {
    return FUEL_CONSUMPTION;
  }

  if (GaseousFuels.includes(fuelType)) {
    return GASEOUS_CONSUMPTION;
  }

  if (fuelType === OfferFuelTypeEnum.Electric) {
    return ENERGY_CONSUMPTION;
  }

  return "";
};
