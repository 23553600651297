import React from "react";
import { useTranslation } from "react-i18next";

import { Lead } from "generated/graphql";
import { LeadsTableProps } from "views/Leads/types";
import { useLeadStore } from "views/Leads/context/leadsSelection";
import Table from "components/Table";

import { makeTableColumns } from "./makeTableColumns";

const LeadsTable: React.FC<LeadsTableProps> = ({
  data = [] as Lead[],
  isLoading = true,
}) => {
  const { t } = useTranslation();

  const {
    selectedLeads,
    toggleLead,
    toggleAllLeads,
  } = useLeadStore();

  const hasLeads = data.length > 0;

  const isAllSelected = (
    hasLeads
    && selectedLeads?.length === data?.length
  );

  const handleToggleAllClassicLeads = React.useCallback(() => {
    toggleAllLeads(isAllSelected, data);
  }, [
    toggleAllLeads,
    isAllSelected,
    data,
  ]);

  const columns = React.useMemo(() => makeTableColumns({
    selectedLeads,
    toggleLead,
    toggleAllLeads: handleToggleAllClassicLeads,
    isAllSelected,
    hasLeads,
  }), [
    handleToggleAllClassicLeads,
    selectedLeads,
    toggleLead,
    hasLeads,
    isAllSelected,
  ]);

  return (
    <Table
      columns={columns}
      data={data}
      emptyDataProps={{
        message: t("empty_data_messages.no_classic_leads_were_found"),
        isDataLoading: isLoading,
      }}
      isPageable
    />
  );
};

export default LeadsTable;
