import React from "react";
import { HStack, StackProps } from "@chakra-ui/react";

const ModalRow: React.FC<StackProps> = ({ children, ...props }) => (
  <HStack
    alignItems="flex-start"
    mt="8"
    spacing="6"
    {...props}
  >
    {children}
  </HStack>
);

export default ModalRow;
