import { LeadStatusEnum } from "generated/graphql";
import i18n from "translations/i18n";
import theme from "settings/theme";
import { availableLeadStatusType } from "components/FormComponents/Inputs/Selects/LeadStatusMultiSelect/types";

const colors = {
  red: theme?.colors?.error[900],
  yellow: theme?.colors?.warning[500],
  blue: theme?.colors?.secondary[500],
  green: theme?.colors?.success[500],
};

export const availableLeadsStatus = [
  {
    value: LeadStatusEnum.NoResponse,
    label: i18n.t(`status.${LeadStatusEnum.NoResponse}`),
    styles: {
      color: colors.red,
    },
  },
  {
    value: LeadStatusEnum.CarUnavailable,
    label: i18n.t(`status.${LeadStatusEnum.CarUnavailable}`),
    styles: {
      color: colors.red,
    },
  },
  {
    value: LeadStatusEnum.Cancelled,
    label: i18n.t(`status.${LeadStatusEnum.Cancelled}`),
    styles: {
      color: colors.red,
    },
  },
  {
    value: LeadStatusEnum.CustomerCancelled,
    label: i18n.t(`status.${LeadStatusEnum.CustomerCancelled}`),
    styles: {
      color: colors.red,
    },
  },
  {
    value: LeadStatusEnum.ContractRejected,
    label: i18n.t(`status.${LeadStatusEnum.ContractRejected}`),
    styles: {
      color: colors.red,
    },
  },
  {
    value: LeadStatusEnum.OfferSent,
    label: i18n.t(`status.${LeadStatusEnum.OfferSent}`),
    styles: {
      color: colors.yellow,
    },
  },
  {
    value: LeadStatusEnum.Unprocessed,
    label: i18n.t(`status.${LeadStatusEnum.Unprocessed}`),
    styles: {
      color: colors.yellow,
    },
  },
  {
    value: LeadStatusEnum.Contacted,
    label: i18n.t(`status.${LeadStatusEnum.Contacted}`),
    styles: {
      color: colors.yellow,
    },
  },
  {
    value: LeadStatusEnum.ContractSent,
    label: i18n.t(`status.${LeadStatusEnum.ContractSent}`),
    styles: {
      color: colors.blue,
    },
  },
  {
    value: LeadStatusEnum.StartedCheckout,
    label: i18n.t(`status.${LeadStatusEnum.StartedCheckout}`),
    styles: {
      color: colors.blue,
    },
  },
  {
    value: LeadStatusEnum.BankRequest,
    label: i18n.t(`status.${LeadStatusEnum.BankRequest}`),
    styles: {
      color: colors.blue,
    },
  },
  {
    value: LeadStatusEnum.ContractClosed,
    label: i18n.t(`status.${LeadStatusEnum.ContractClosed}`),
    styles: {
      color: colors.green,
    },
  },
  {
    value: LeadStatusEnum.ContractSigned,
    label: i18n.t(`status.${LeadStatusEnum.ContractSigned}`),
    styles: {
      color: colors.blue,
    },
  },
  {
    value: LeadStatusEnum.FinishedCheckout,
    label: i18n.t(`status.${LeadStatusEnum.FinishedCheckout}`),
    styles: {
      color: colors.blue,
    },
  },
];

const classicStatusLeadList = [
  LeadStatusEnum.NoResponse,
  LeadStatusEnum.CarUnavailable,
  LeadStatusEnum.Cancelled,
  LeadStatusEnum.CustomerCancelled,
  LeadStatusEnum.ContractRejected,
  LeadStatusEnum.OfferSent,
  LeadStatusEnum.Unprocessed,
  LeadStatusEnum.Contacted,
  LeadStatusEnum.ContractSent,
  LeadStatusEnum.ContractSigned,
  LeadStatusEnum.ContractClosed,
];

const checkoutStatusLeadList = [
  LeadStatusEnum.NoResponse,
  LeadStatusEnum.CarUnavailable,
  LeadStatusEnum.Cancelled,
  LeadStatusEnum.CustomerCancelled,
  LeadStatusEnum.ContractRejected,
  LeadStatusEnum.OfferSent,
  LeadStatusEnum.Unprocessed,
  LeadStatusEnum.Contacted,
  LeadStatusEnum.StartedCheckout,
  LeadStatusEnum.FinishedCheckout,
  LeadStatusEnum.ContractSent,
  LeadStatusEnum.BankRequest,
  LeadStatusEnum.ContractSigned,
  LeadStatusEnum.ContractClosed,
];

const getStatusListByEnum = (
  list: LeadStatusEnum[],
): (
  availableLeadStatusType | undefined
)[] => list
  .map((item) => availableLeadsStatus
    .find((option) => option.value === item));

export const availableCheckoutLeadStatus = getStatusListByEnum(checkoutStatusLeadList);

export const availableClassicLeadStatus = getStatusListByEnum(classicStatusLeadList);
