import React, { useCallback } from "react";
import {
  Flex, Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { PreviewPublishModalProps } from "./types";

const PreviewPublishModal: React.FC<PreviewPublishModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    componentProps?.onConfirm?.();
    hideModal();
  }, [
    componentProps,
    hideModal,
  ]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Text
        fontWeight="bold"
        fontSize="lg"
        p={5}
      >
        {t("components.modals.publish_offer.title")}
      </Text>

      <div className="content-container">
        <Text
          fontSize="md"
          p={5}
        >
          {t("components.modals.publish_offer.description")}
        </Text>
      </div>

      <Flex
        justifyContent="space-between"
        borderRadius="0 0 8px 8px"
        bg="gray.200"
        w="full"
        p={6}
      >
        <Button
          label={t("components.buttons.cancel")}
          onClick={() => hideModal()}
          variant="ghost"
          bgColor="white"
          boxShadow="md"
        />

        <Button
          label={t("components.buttons.publish_offer")}
          onClick={() => handleConfirm()}
          boxShadow="md"
        />
      </Flex>
    </Flex>
  );
};

export default PreviewPublishModal;
