import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";

import { iconsMap } from "constants/icons";

import { LeadDetailsCardProps } from "./types";

const LeadDetailsCard: React.FC<LeadDetailsCardProps> = ({
  children,
  title,
  hideHeader,
  isExpandable = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const toggleIsOpen = (): void => setIsOpen(prevIsOpen => !prevIsOpen);

  const shouldRenderChildren = (!isExpandable || (isExpandable && isOpen));

  const UpOrDownIcon = (): JSX.Element => {
    if (isOpen) {
      return <iconsMap.UpChevron cursor="pointer" onClick={toggleIsOpen} />;
    }

    return <iconsMap.DownChevron cursor="pointer" onClick={toggleIsOpen} />;
  };

  return (
    <Box
      bgColor="white"
      boxShadow="md"
      rounded="lg"
      p={6}
      h="100%"
    >
      {
        !hideHeader && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb={30}
          >
            <Text
              fontSize={24}
              fontWeight="700"
            >
              {title}
            </Text>

            {
              isExpandable
            && <UpOrDownIcon />
            }
          </Flex>
        )
      }

      {
        shouldRenderChildren && <Box>{children}</Box>
      }
    </Box>
  );
};

export default LeadDetailsCard;
