import i18n from "translations/i18n";
import { VehicleTypeEnum } from "generated/graphql";

export default [
  {
    value: VehicleTypeEnum.CurrentYear,
    label: i18n.t("enum.vehicle_type.current_year"),
  },
  {
    value: VehicleTypeEnum.Demonstration,
    label: i18n.t("enum.vehicle_type.demonstration"),
  },
  {
    value: VehicleTypeEnum.New,
    label: i18n.t("enum.vehicle_type.new"),
  },
];
