import React from "react";
import { ApolloClient, ApolloLink, ApolloProvider } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { createLink } from "apollo-v3-absinthe-upload-link";
import { onError } from "@apollo/client/link/error";

import { errorCodes, ErrorCodesEnum } from "constants/authErrorCodes";
import { sentryErrorLink } from "settings/sentry/sentryErrorLink";

import uri from "../graphQLUri";
import { GraphQLErrorWithCode, MemoizedApolloProviderProps } from "./types";

const MemoizedApolloProvider: React.FC<MemoizedApolloProviderProps> = ({
  showAuthError,
  children,
}) => {
  const httpLink = createLink({
    uri,
    credentials: "include",
  });

  const apolloClient = React.useMemo(() => (
    new ApolloClient({
      link: ApolloLink.from([
        sentryErrorLink,
        onError(({ graphQLErrors }) => {
          const errors = graphQLErrors as GraphQLErrorWithCode[];

          if (graphQLErrors) {
            const hasAuthError = errors.some((error: GraphQLErrorWithCode) => (
              error?.code
                ? errorCodes.includes(error?.code as ErrorCodesEnum)
                : false
            ));

            if (hasAuthError) {
              showAuthError();
            }
          }
        }),
        httpLink,
      ]),
      cache: new InMemoryCache({
      // CalculatorOffer schema doesn't have a ID
      // so this generates an unique ID for CalculatorOffer
        typePolicies: {
          CalculatorOffer: {
            keyFields: ["vehicle", ["id"], "customerType", "dealer", ["id"]],
          },
          // LeasingFactor schema has duplicated IDs so this disables field
          // normalization for LeasingFactor
          LeasingFactor: {
            keyFields: false,
          },
        },
      }),
    })), [
    showAuthError,
    httpLink,
  ]);

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default MemoizedApolloProvider;
