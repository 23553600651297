import { EmploymentStatusEnum } from "generated/graphql";
import i18n from "translations/i18n";

const getEmploymentStatusValue = (employmentStatus: EmploymentStatusEnum): string => {
  const EMPLOYMENT_STATUS = {
    [EmploymentStatusEnum.Employed]: i18n.t("employment_status.employed"),
    [EmploymentStatusEnum.Freelancer]: i18n.t("employment_status.freelancer"),
    [EmploymentStatusEnum.Retired]: i18n.t("employment_status.retired"),
    [EmploymentStatusEnum.SelfEmployed]: i18n.t("employment_status.self_employed"),
  };

  return EMPLOYMENT_STATUS[employmentStatus] ?? "";
};

export default getEmploymentStatusValue;
