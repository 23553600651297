import { gql } from "@apollo/client";

export const getCheckoutLeadDocumentsQuery = gql`
  query GetCheckoutLeadDocuments (
    $id: String!
  ) {
    lead(id: $id) {
      id
      selfAssessment
      handlerContracts {
        id
        file
        insertedAt
      }
      clientContracts {
        id
        file
        insertedAt
      }
      financialEvidences {
        id
        file
        insertedAt
      }
      personalDocuments {
        id
        file
        insertedAt
      }
      businessRegistrations {
        id
        file
        insertedAt
      }
  }
}
`;
