import React from "react";
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Card } from "components/Card";

import { LoginForm } from "./LoginForm";
import { LoginComponentProps } from "./types";

export function LoginComponent({
  handleSubmit,
  isLoading,
  register,
  errors,
}: LoginComponentProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Card px={6}>
      <Heading
        as="h1"
        lineHeight={1.1}
        fontSize="28px"
        textAlign="center"
        mb={8}
      >
        {t("sign_in.sign_in_to_your_account")}
      </Heading>

      <LoginForm
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        register={register}
        errors={errors}
      />
    </Card>
  );
}
