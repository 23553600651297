import React from "react";
import { Text } from "@chakra-ui/react";

import i18n from "translations/i18n";
import { CustomerTypeEnum, Vehicle, VehicleSortFieldsEnum } from "generated/graphql";
import { formatDate, germanDateFormatShortYear } from "utils/dateFormats";
import { formatCurrency } from "utils/currencyFormat";
import Button from "components/Button";
import { Column } from "components/Table/types";

import { VehicleActions } from "../types";

/**
 * Builds the base vehicles table columns.
 */
export const makeTableColumns = ({ onEditVehicle }: VehicleActions):
  Column<Vehicle & {categoryDescription?: string; fuelTypeDescription?: string}>[] => [
  {
    title: i18n.t("components.table.heading.id"),
    fieldPath: "id",
    sortingColumn: VehicleSortFieldsEnum.Id,
  },
  {
    title: i18n.t("components.table.heading.brand"),
    render: ({ row }) => (
      <Text fontWeight="bold">
        { row?.brand?.name}
      </Text>
    ),
    sortingColumn: VehicleSortFieldsEnum.BrandName,
  },
  {
    title: i18n.t("components.table.heading.model_group"),
    fieldPath: "modelGroup",
    sortingColumn: VehicleSortFieldsEnum.ModelGroup,
  },
  {
    title: i18n.t("components.table.heading.model"),
    fieldPath: "model",
    sortingColumn: VehicleSortFieldsEnum.Model,
  },
  {
    title: i18n.t("components.table.heading.category"),
    fieldPath: "categoryDescription",
    sortingColumn: VehicleSortFieldsEnum.Category,
  },
  {
    title: i18n.t("components.table.heading.fuel_type"),
    fieldPath: "fuelTypeDescription",
    sortingColumn: VehicleSortFieldsEnum.FuelType,
  },
  {
    title: i18n.t("components.table.heading.power"),
    fieldPath: "powerKw",
    sortingColumn: VehicleSortFieldsEnum.PowerKw,
  },
  {
    title: i18n.t("components.table.heading.list_price"),
    fieldResolver: vehicle => formatCurrency({ amount: vehicle.baseListPriceNet }),
    sortingColumn: VehicleSortFieldsEnum.BaseListPriceNet,
  },
  {
    title: i18n.t("components.table.heading.corporate_brand_discount", {
      customerType: CustomerTypeEnum.Corporate,
    }),
    fieldResolver: vehicle => {
      const foundDiscount = vehicle?.brandDiscounts?.find(discount => (
        discount?.type === CustomerTypeEnum.Corporate
      ))?.discount;

      return foundDiscount ? formatCurrency(foundDiscount) : "-";
    },
  },
  {
    title: i18n.t("components.table.heading.private_brand_discount", {
      customerType: CustomerTypeEnum.Private,
    }),
    fieldResolver: vehicle => {
      const foundDiscount = vehicle?.brandDiscounts?.find(discount => (
        discount?.type === CustomerTypeEnum.Private
      ))?.discount;

      return foundDiscount ? formatCurrency(foundDiscount) : "-";
    },
  },
  {
    title: i18n.t("components.table.heading.leasing_factors"),
    fieldResolver: vehicle => (vehicle.dynamicLeasingRates.length !== 0
      ? i18n.t("screens.base_vehicles.table.yes")
      : i18n.t("screens.base_vehicles.table.no")),
    sortingColumn: VehicleSortFieldsEnum.HasDynamicLeasingRates,
  },
  {
    title: i18n.t("components.table.heading.factors"),
    fieldResolver: vehicle => (vehicle.calculateWithFactors
      ? i18n.t("screens.base_vehicles.table.factor")
      : i18n.t("screens.base_vehicles.table.interest")),
    sortingColumn: VehicleSortFieldsEnum.CalculateWithFactors,
  },
  {
    title: i18n.t("components.table.heading.last_update_at"),
    fieldResolver: vehicle => formatDate(
      new Date(vehicle.updatedAt),
      germanDateFormatShortYear,
    ),
    sortingColumn: VehicleSortFieldsEnum.UpdatedAt,
  },
  {
    title: i18n.t("components.table.heading.options"),
    render: ({ row }) => (
      <Button
        label={i18n.t("components.buttons.edit")}
        onClick={onEditVehicle(row)}
        colorScheme="secondary"
        textStyle="p"
      />
    ),
  },
];
