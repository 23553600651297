import React, {
  useCallback, useMemo, useState,
} from "react";
import {
  Fade,
  Modal,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";

import {
  ModalContextPayload,
  ModalContainerProps,
  OnChakraModalClose,
  ShowModalOptions,
  ModalState,
} from "./types";
import { ModalProvider } from ".";

/**
 * In order to properly render a component with this context we suggest that you
 * extends the ModalComponentProps type
 * e.g.: type Component = ModalComponentProps<{ componentProps }>
 */
function ModalContainer<T = Record<string, unknown>>({
  children,
}: ModalContainerProps<T>): React.ReactElement {
  const defaultState = useMemo<ModalState<T>>(() => ({
    title: "",
    size: "lg",
    onClose: undefined,
    component: undefined,
    isCloseable: true,
    isCentered: true,
    colorScheme: "secondary",
    closeOnOverlayClick: true,
    componentProps: {
    },
    modalHeaderProps: {
      padding: "0px",
    },
    modalContentProps: {
      padding: "0px",
      maxHeight: "90vh",
      overflow: "hidden",
    },
    modalContentContainerProps: {
      overflow: "hidden",
    },
    modalCloseButtonProps: {
      color: "gray.400",
      mt: "5px",
      css: {
        svg: {
          width: "20px",
          height: "20px",
        },
      },
    },
  }), []);

  const [modalState, setModalState] = useState<ModalState<T>>(defaultState);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showModal = useCallback((options: ShowModalOptions<T> | unknown) => {
    const modalOptions = options as ShowModalOptions<T>;

    const newState = {
      ...defaultState,
      ...modalOptions,
      isCloseable: modalOptions?.isCloseable ?? defaultState.isCloseable,
    };

    setModalState(newState);
    onOpen();
  }, [
    defaultState,
    onOpen,
  ]);

  const hideModal = useCallback((payload) => {
    modalState.onClose?.(payload ?? {});

    setModalState(defaultState);
    onClose();
  }, [
    defaultState,
    modalState,
    onClose,
  ]);

  const payload = useMemo<ModalContextPayload<T>>(() => [
    showModal,
    hideModal,
    {
      isOpen,
    },
  ], [
    showModal,
    hideModal,
    isOpen,
  ]);

  const Component = modalState?.component;
  const componentProps = (modalState?.componentProps || {}) as T;

  return (
    <ModalProvider value={payload}>
      {children}

      <Fade in={isOpen}>
        <Modal
          closeOnOverlayClick={modalState?.isCloseable && modalState?.closeOnOverlayClick}
          onClose={hideModal as OnChakraModalClose}
          closeOnEsc={modalState?.isCloseable}
          isCentered={modalState?.isCentered}
          size={modalState?.size as string}
          isOpen={isOpen}
          trapFocus={false}
        >
          <ModalOverlay zIndex="modal">
            <ModalContent
              containerProps={modalState?.modalContentContainerProps}
              {...(modalState?.modalContentProps ?? {})}
            >
              {
                modalState?.title && (
                  <>
                    <ModalHeader {...(modalState?.modalHeaderProps ?? {})}>
                      <Text
                        textAlign="start"
                        color="gray.500"
                        fontSize="20px"
                        textStyle="p"
                      >
                        {modalState?.title}
                      </Text>
                    </ModalHeader>
                  </>
                )
              }

              {
                modalState?.isCloseable && (
                  <ModalCloseButton
                    {...(modalState?.modalCloseButtonProps ?? {})}
                  />
                )
              }

              {
                Component && (
                  <Component
                    size={modalState?.size as string}
                    componentProps={componentProps}
                    hideModal={hideModal}
                  />
                )
              }
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </Fade>
    </ModalProvider>
  );
}

export default ModalContainer;
