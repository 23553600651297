import React from "react";
import ReactSelect from "react-select";
import ConditionalWrap from "conditional-wrap";

import getErrorMessage from "utils/getErrorMessage";

import { SelectProps } from "./types";
import HelperTextContainer from "../../HelperTextContainer";
import BaseSelect from "../BaseSelect";
import BaseCreatableSelect from "../BaseCreatableSelect";

const Select = React.forwardRef<ReactSelect, SelectProps>((
  {
    validationMessage,
    options = [],
    isInvalid,
    onChange,
    errors,
    title,
    name,
    titleCss,
    isCreatable,
    isRequired,
    formControlCss,
    ...props
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const hasHelperText = !!title || !!errors;

  const SelectComponent = isCreatable ? BaseCreatableSelect : BaseSelect;

  return (
    <ConditionalWrap
      condition={hasHelperText}
      wrap={(content) => (
        <HelperTextContainer
          validationMessage={validationMessage}
          title={isRequired ? `${title} *` : title}
          titleCss={titleCss}
          error={error}
          name={name}
          formControlCss={formControlCss}
        >
          {content}
        </HelperTextContainer>
      )}
    >
      <SelectComponent
        onChange={onChange}
        isInvalid={!!error || isInvalid}
        options={options}
        name={name}
        ref={ref}
        {...props}
      />
    </ConditionalWrap>
  );
});

export default Select;
