import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const HistoricIcon: IconProps = createIcon({
  viewBox: "0 0 49.252 32.834",
  path: (
    <path
      fill="currentColor"
      d="M29.571,3A21.466,21.466,0,0,0,8.143,24.5H1l9.262,9.293.167.334L20.048,24.5H12.9A16.667,16.667,0,1,1,29.571,41.222,16.505,16.505,0,0,1,17.81,36.3l-3.381,3.392A21.4,21.4,0,0,0,51,24.5,21.466,21.466,0,0,0,29.571,3ZM27.19,14.944V26.889l10.119,6.02,1.833-3.058-8.381-4.993V14.944Z"
      transform="scale(0.95) translate(0 -8)"
    />
  ),
});

export default HistoricIcon;
