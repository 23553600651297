import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@chakra-ui/react";

import { OfferFuelTypeEnum } from "generated/graphql";
import { germanNumberMask } from "constants/inputMasks";

import { InnerSection } from "../../../../InnerSections";
import { getMeasurementUnit } from "../../utils";
import { InputField } from "../shared/InputField";
import { FieldsCommonProps } from "..";

export interface ConsumptionProps extends FieldsCommonProps {
  fuelType: OfferFuelTypeEnum;
  isPluginHybrid: boolean;
}

interface ConsumptionInputsProps extends FieldsCommonProps {
  isPluginHybrid: boolean;
  fuelType: OfferFuelTypeEnum;
  translationsPrefix: string;
  measurementUnit: string;
}
const ConsumptionInputs = React.memo(({
  isPluginHybrid,
  fuelType,
  measurementUnit,
  translationsPrefix,
  register,
  formState,
}: ConsumptionInputsProps): JSX.Element => {
  const { t } = useTranslation();

  if (
    isPluginHybrid
    && [OfferFuelTypeEnum.HybridDiesel, OfferFuelTypeEnum.Hybrid].includes(fuelType)
  ) {
    return (
      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        <InputField
          {...register?.("wltpConsumptionCombinedWeighted")}
          name="wltpConsumptionCombinedWeighted"
          title={t(`${translationsPrefix}.weighted_combined_consumption`)}
          placeholder={t(`${translationsPrefix}.insert_weighted_combined_consumption`)}
          measurementUnit={measurementUnit}
          errors={formState?.errors}
          mask={germanNumberMask}
          isRequired
        />
      </Grid>
    );
  }

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        <InputField
          {...register?.("wltpConsumptionCombined")}
          name="wltpConsumptionCombined"
          title={t(`${translationsPrefix}.combined_consumption`)}
          placeholder={t(`${translationsPrefix}.insert_combined_consumption`)}
          measurementUnit={measurementUnit}
          errors={formState?.errors}
          isRequired
        />

        <InputField
          {...register?.("wltpConsumptionCity")}
          name="wltpConsumptionCity"
          title={t(`${translationsPrefix}.city_consumption`)}
          placeholder={t(`${translationsPrefix}.insert_city_consumption`)}
          measurementUnit={measurementUnit}
          errors={formState?.errors}
        />

        <InputField
          {...register?.("wltpConsumptionSuburban")}
          name="wltpConsumptionSuburban"
          title={t(`${translationsPrefix}.suburban_consumption`)}
          placeholder={t(`${translationsPrefix}.insert_suburban_consumption`)}
          measurementUnit={measurementUnit}
          errors={formState?.errors}
        />
      </Grid>

      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        <InputField
          {...register?.("wltpConsumptionRural")}
          name="wltpConsumptionRural"
          title={t(`${translationsPrefix}.rural_consumption`)}
          placeholder={t(`${translationsPrefix}.insert_rural_consumption`)}
          measurementUnit={measurementUnit}
          errors={formState?.errors}
        />

        <InputField
          {...register?.("wltpConsumptionHighway")}
          name="wltpConsumptionHighway"
          title={t(`${translationsPrefix}.highway_consumption`)}
          placeholder={t(`${translationsPrefix}.insert_highway_consumption`)}
          measurementUnit={measurementUnit}
          errors={formState?.errors}
        />
      </Grid>
    </>
  );
});

export const Consumption = ({
  isPluginHybrid = false,
  fuelType,
  formState,
  register,
  setValue,
}: ConsumptionProps): JSX.Element => {
  const { t } = useTranslation();
  const measurementUnit = getMeasurementUnit(fuelType);
  const translationsPrefix = "custom_offer.steps.standard_data.fuel_card";

  return (
    <InnerSection label={t(`${translationsPrefix}.consumption`)}>
      <ConsumptionInputs
        translationsPrefix={translationsPrefix}
        isPluginHybrid={isPluginHybrid}
        measurementUnit={measurementUnit}
        fuelType={fuelType}
        formState={formState}
        register={register}
        setValue={setValue}
      />
    </InnerSection>
  );
};
