import { CSSObject } from "@emotion/react";

/**
  * Receives a boolean and returns a CSS Object
  * @param isBlocked current component state, e.g.: (error, readOnly, etc)
  * @returns e.g.: { transition: "all 0.2s ease", borderColor: "secondary.500" }
  */
export const getHoverStyles = (isBlocked?: boolean): CSSObject => (
  !isBlocked
    ? {
      transition: "all 0.2s ease",
      borderColor: "secondary.500",
    }
    : {
      borderColor: "gray.200",
    }
);
