import React, { FC } from "react";
import {
  Table, Thead, Tr, Tbody,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { TableDataCell } from "components/TableDataCell";
import { TableHeaderCell } from "components/TableHeaderCell";
import { TABLE_BORDER_COLOR } from "settings/theme/colors";
import { formatCurrency } from "utils/currencyFormat";

import { ConfigurationDetailsTableProps } from "./types";

const getFormattedPrice = (price?: number): string => {
  if (!price) {
    return "-";
  }

  return formatCurrency({ amount: Number(price), symbolPosition: "before" });
};

export const ConfigurationDetailsTable: FC<ConfigurationDetailsTableProps> = ({
  sectionName,
  item,
}) => {
  const [t] = useTranslation();
  const normalizedItem = Array.isArray(item) ? item : [item];

  return (
    <Table
      textTransform="lowercase"
      variant="unstyled"
      size="sm"
      w="full"
      mb={8}
    >
      <Thead w="full" mb="none">
        <Tr>
          <TableHeaderCell
            textTransform="capitalize"
            pl={0}
            pt={0}
          >
            {sectionName}
          </TableHeaderCell>

          <TableHeaderCell textAlign="left">{t("leads.details.form.manufacturer_code")}</TableHeaderCell>

          <TableHeaderCell textAlign="right">{t("leads.details.form.net_price")}</TableHeaderCell>

          <TableHeaderCell textAlign="right">{t("leads.details.form.gross_price")}</TableHeaderCell>
        </Tr>
      </Thead>

      <Tbody>
        {
          normalizedItem?.map((configurationDetail) => (
            <Tr
              _hover={{ backgroundColor: "gray.100" }}
              borderBottom="1px solid"
              borderColor={TABLE_BORDER_COLOR}
              w="full"
              h={15}
            >
              <TableDataCell
                maxW="60%"
                w="full"
                pl={0}
              >
                {configurationDetail?.name}
              </TableDataCell>

              <TableDataCell
                textAlign="left"
              >
                {configurationDetail?.manufacturerCode ?? "-"}
              </TableDataCell>

              <TableDataCell
                textAlign="right"
              >
                {getFormattedPrice(configurationDetail?.netPrice)}
              </TableDataCell>

              <TableDataCell
                textAlign="right"
              >
                {getFormattedPrice(configurationDetail?.price)}
              </TableDataCell>
            </Tr>
          ))
        }
      </Tbody>
    </Table>
  );
};
