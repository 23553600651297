/* eslint-disable react/no-children-prop */
import React from "react";
import MaskedInput from "react-text-mask";
import mergeRefs from "react-merge-refs";
import { CSSObject } from "@emotion/react";

import getErrorMessage from "utils/getErrorMessage";

import maskPlaceholderChar from "./maskPlaceholderChar";
import { InputProps } from "./types";
import InputContainer from "../InputContainer";
import {
  isRightElementCss,
  isLeftElementCss,
  bothElementsCss,
  defaultCss,
} from "./styles";
import BaseInput from "../BaseInput";

const getBorderStyles = (
  isRightAddon: boolean,
  isLeftAddon: boolean,
): CSSObject => {
  if (isRightAddon && isLeftAddon) {
    return bothElementsCss;
  }

  if (isRightAddon) {
    return isRightElementCss;
  }

  if (isLeftAddon) {
    return isLeftElementCss;
  }

  return defaultCss;
};

/** This component should be used as a base to other inputs implementations or as
 * an input itself, what makes it different from the `BaseInput` is that this component
 * also handles the Input Container logic, so we can render error messages,
 * titles, addons, and input inner elements, etc.
 * */
const Input = React.forwardRef<HTMLElement, InputProps>((
  {
    customCss,
    validationMessage,
    rightElementProps,
    showMask = false,
    showErrorMessage,
    leftElementProps,
    rightAddonProps,
    leftAddonProps,
    rightElement,
    mask = false,
    leftElement,
    rightAddon,
    leftAddon,
    isInvalid,
    onChange,
    onBlur,
    errors,
    title,
    titleCss,
    name,
    borderRadius,
    formControlCss,
    hasCustomErrorCss,
    isRequired,
    ...rest
  },
  ref,
) => {
  const borderRadiusCss = borderRadius
    ? { borderRadius }
    : getBorderStyles(!!rightAddon, !!leftAddon);
  const error = getErrorMessage(name, errors);

  return (
    <InputContainer
      validationMessage={validationMessage}
      rightElementProps={rightElementProps}
      leftElementProps={leftElementProps}
      showErrorMessage={showErrorMessage}
      rightAddonProps={rightAddonProps}
      leftAddonProps={leftAddonProps}
      rightElement={rightElement}
      leftElement={leftElement}
      rightAddon={rightAddon}
      leftAddon={leftAddon}
      error={error}
      title={isRequired ? `${title} *` : title}
      titleCss={titleCss}
      formControlCss={formControlCss}
      name={name}
      hasCustomErrorCss={hasCustomErrorCss}
    >
      <MaskedInput
        placeholderChar={maskPlaceholderChar}
        showMask={showMask}
        onChange={onChange}
        onBlur={onBlur}
        mask={mask}
        render={(maskRef, props) => (
          <BaseInput
            {...props}
            {...rest}
            ref={mergeRefs([maskRef, ref])}
            css={{ ...customCss, ...borderRadiusCss }}
            isInvalid={!!error || isInvalid}
            name={name}
          />
        )}
      />
    </InputContainer>
  );
});

export default Input;
