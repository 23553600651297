import { ImportMethodEnum } from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

import urlTest from "../tests/urlTest";

const createBrandSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t("yup.mixed_required_field")),
  desktopVideoUrl: yup
    .string()
    .required(i18n.t("yup.mixed_required_field"))
    .test(urlTest()),
  mobileVideoUrl: yup
    .string()
    .required(i18n.t("yup.mixed_required_field"))
    .test(urlTest()),
  importMethod: yup
    .mixed<ImportMethodEnum>()
    .required(i18n.t("yup.mixed_required_field")),
  priceNotes: yup
    .string()
    .nullable(),
  privateCustomerBankId: yup
    .string()
    .nullable(),
  corporateCustomerBankId: yup
    .string()
    .nullable(),
});

export interface CreateUpdateBrandSchema {
  id?: string;
  name: string;
  desktopVideoUrl: string;
  mobileVideoUrl: string;
  importMethod: ImportMethodEnum;
  priceNotes?: string;
  privateCustomerBankId?: string;
  corporateCustomerBankId?: string;
}

export default createBrandSchema;
