import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const InfoIcon: IconProps = createIcon({
  viewBox: "0 0 42.895 42.895",
  path: (
    <path
      fill="currentColor"
      d="M21.447-1.105A21.455,21.455,0,0,1,0-22.553,21.455,21.455,0,0,1,21.447-44,21.455,21.455,0,0,1,42.895-22.553,21.455,21.455,0,0,1,21.447-1.105Zm2.145-32.171H19.3v4.289h4.289Zm0,8.579H19.3v12.868h4.289Z"
      transform="translate(0 44)"
    />
  ),
});

export default InfoIcon;
