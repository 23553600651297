import { gql } from "@apollo/client";

export const UPDATE_OFFERS_MUTATION_REFETCH_QUERIES = [
  "Offers",
];

export default gql`
  mutation UpdateOffer (
    $id: Int!,
    $params: UpdateOfferParams!
  ) {
    updateOffer(
      id: $id,
      params: $params
    ){
        id
    }
  }
`;
