import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const TagIcon: IconProps = createIcon({
  viewBox: "0 0 20 21",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2049 3.49714L18.6275 11.6703C18.929 11.9992 18.918 12.5072 18.6025 12.8227L12.2559 19.1692C11.9305 19.4946 11.4028 19.4946 11.0774 19.1692L2.86612 10.9579C2.6317 10.7235 2.5 10.4055 2.5 10.074V3.92513C2.5 3.46489 2.8731 3.0918 3.33333 3.0918H10.2835C10.6338 3.0918 10.9681 3.23885 11.2049 3.49714ZM5.83333 7.67513C6.52369 7.67513 7.08333 7.11549 7.08333 6.42513C7.08333 5.73477 6.52369 5.17513 5.83333 5.17513C5.14298 5.17513 4.58333 5.73477 4.58333 6.42513C4.58333 7.11549 5.14298 7.67513 5.83333 7.67513Z"
      fill="currentColor"
    />
  ),
});

export default TagIcon;
