import React from "react";
import { Flex } from "@chakra-ui/react";

import i18n from "translations/i18n";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import { formatDate, germanDateFormat } from "utils/dateFormats";
import getLeasedBeforeLabel from "utils/getLeasedBeforeLabel";
import getLegalStructureLabel from "utils/getLegalStructureLabel";

import { LeftSectionProps } from "./types";

const LeftSection: React.FC<LeftSectionProps> = ({ lead }) => (
  <Flex flex={1} direction="column">
    <Flex mb={30}>
      <FakeTextField
        value={lead?.companyName}
        label={i18n.t("leads.details.form.company_name")}
        w="50%"
      />

      <FakeTextField
        value={getLegalStructureLabel(lead?.companyLegalStructure)}
        label={i18n.t("leads.details.form.legal_structure")}
        w="25%"
      />

      <FakeTextField
        value={formatDate(lead?.companyFoundationDate, germanDateFormat, "-")}
        label={i18n.t("leads.details.form.foundation_date")}
        w="25%"
      />
    </Flex>

    <Flex mb={30}>
      <FakeTextField
        value={`${lead?.companyStreet ?? ""} ${lead?.companyNumber ?? ""}`}
        label={i18n.t("leads.details.form.street")}
        w="50%"
      />

      <FakeTextField
        value={lead?.companyZipCode}
        label={i18n.t("leads.details.form.postcode")}
        w="25%"
      />

      <FakeTextField
        value={lead?.companyCity}
        label={i18n.t("leads.details.form.city")}
        w="25%"
      />
    </Flex>

    <Flex mb={30}>
      <FakeTextField
        value={getLeasedBeforeLabel(lead?.leasedBefore)}
        label={i18n.t("leads.details.form.contract_notes")}
      />
    </Flex>
  </Flex>
);

export default LeftSection;
