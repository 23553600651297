import React from "react";
import { matchPath, useHistory } from "react-router-dom";

import appRoutes from "routes/appRoutes";
import { useCurrentUser } from "contexts/currentUser";
import { ROOT_PAGE_PATH } from "routes";
import { UserRoleEnum } from "generated/graphql";
import { useSession } from "contexts/session";

const RouteSessionValidator: React.FC = ({
  children,
}) => {
  const history = useHistory();
  const { endSession } = useSession();

  const [
    currentUser,
    {
      loading: loadingCurrentUser,
    },
  ] = useCurrentUser();

  const pathname = history?.location?.pathname ?? "";

  const appRoute = appRoutes.find((route) => matchPath(pathname, route));

  const isInvalidRoute = (
    appRoute?.requireAuthentication
      && !currentUser
      && !loadingCurrentUser
  );

  const hasUserRequiredRole = !appRoute?.requireAuthentication || (
    currentUser
    && (appRoute?.userRequiredRoles || []).includes(currentUser.role as UserRoleEnum)
  );

  const hasSellerRequiredRole = !appRoute?.requireAuthentication || (
    currentUser
    && (appRoute?.sellerRequiredRoles || []).includes(currentUser?.seller.role)
  );

  if (appRoute?.requireAuthentication && loadingCurrentUser) {
    return null;
  }

  if (isInvalidRoute) {
    history.push(ROOT_PAGE_PATH);

    return (
      <>
        {
          children
        }
      </>
    );
  }

  if (!hasUserRequiredRole || !hasSellerRequiredRole || currentUser?.seller === null) {
    return (
      /**
       * TODO: Replace with an unauthorized screen.
       */
      <div>
        <p>
          You are not allowed to see this page.
        </p>

        <button onClick={() => endSession()} type="button">
          Click here to log out
        </button>
      </div>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default RouteSessionValidator;
