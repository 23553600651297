import React from "react";
import {
  FormControl,
  Checkbox as ChakraCheckbox,
} from "@chakra-ui/react";

import getErrorMessage from "utils/getErrorMessage";
import { getHoverStyles } from "utils/getHoverStyles";
import TextConditionalWrap from "components/TextConditionalWrap";

import { CheckboxProps } from "./types";
import FieldErrorMessage from "../FieldErrorMessage";

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((
  {
    renderChildrenAsText = true,
    showErrorMessage = true,
    isReadOnly,
    children,
    errors,
    name,
    padding = 1,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const hoverStyles = getHoverStyles(!!error && isReadOnly);

  return (
    <FormControl p={padding}>
      <ChakraCheckbox
        {...rest}
        ref={ref}
        name={name}
        spacing={2}
        isInvalid={!!error}
        isReadOnly={isReadOnly}
        cursor={isReadOnly ? "default" : "pointer"}
        _hover={hoverStyles}
      >
        <TextConditionalWrap renderChildrenAsText={renderChildrenAsText}>
          {children}
        </TextConditionalWrap>
      </ChakraCheckbox>

      {
        showErrorMessage && (
          <FieldErrorMessage error={error} />
        )
      }
    </FormControl>
  );
});

export default Checkbox;
