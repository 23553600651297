import React, { useCallback } from "react";
import {
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import CheckIcon from "components/Icons/CheckIcon";

import { InReviewRequestLeadRejectionModalProps } from "./types";

const InReviewRequestLeadRejectionModal: React.FC<InReviewRequestLeadRejectionModalProps> = ({
  hideModal,
}) => {
  const { t } = useTranslation();

  const handleCloseWindow = useCallback(() => {
    hideModal();
  }, [
    hideModal,
  ]);

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        h="full"
        w="full"
        p={6}
        spacing={6}
      >
        <Flex
          alignItems="center"
          w="100%"
        >
          <CheckIcon
            color="success.500"
            mr="2"
            boxSize={6}
          />

          <Text
            lineHeight="28px"
            fontSize="24px"
            textStyle="h3"
            textAlign="left"
            w="100%"
            fontWeight="700"
          >
            {t("components.modals.in_review_request_lead_rejection.title")}
          </Text>
        </Flex>

        <Flex
          mt={4}
          w="100%"
          direction="column"
        >
          <Text>
            {t("components.modals.in_review_request_lead_rejection.description")}
          </Text>
        </Flex>
      </Stack>

      <Flex
        justifyContent="space-between"
        borderRadius="0 0 8px 8px"
        bg="gray.200"
        w="full"
        p={6}
      >
        <Button
          label={t("components.buttons.close_window")}
          onClick={handleCloseWindow}
          boxShadow="md"
          ml="auto"
          colorScheme="secondary"
        />
      </Flex>
    </>
  );
};

export default InReviewRequestLeadRejectionModal;
