import React, { useCallback, useEffect } from "react";
import ReactSelect, { OptionsType, OptionTypeBase } from "react-select";
import { Flex } from "@chakra-ui/react";

import {
  GetVehiclesQuery, VehicleSortFieldsEnum, SortingOrderEnum, useGetVehiclesLazyQuery,
} from "generated/graphql";
import i18n from "translations/i18n";

import { ModelSelectProps } from "./types";
import Select from "../../Select";

function getModelsOptions(data: GetVehiclesQuery | undefined): OptionsType<OptionTypeBase> {
  if (!data) {
    return [];
  }

  return data.vehicles?.map(model => ({
    value: model.model,
    label: model.model,
  }));
}

const ModelSelect = React.forwardRef<ReactSelect, ModelSelectProps>((
  {
    placeholder = i18n.t("components.selects.model"),
    modelSelectContainerProps,
    selectedBrand,
    selectedModelGroup,
    onChange,
    ...props
  },
  ref,
) => {
  const [
    getModels,
    {
      data,
      error,
      loading,
    },
  ] = useGetVehiclesLazyQuery({
    variables: {
      filters: {
        brandId: selectedBrand,
        modelGroup: selectedModelGroup,
      },
      pagination: {
        orderBy: [{ field: VehicleSortFieldsEnum.Model, order: SortingOrderEnum.Asc }],
      },
    },
  });

  useEffect(() => {
    if (!selectedBrand || !selectedModelGroup) {
      return;
    }

    getModels({
      variables: {
        filters: {
          brandId: selectedBrand,
          modelGroup: selectedModelGroup,
        },
      },
    });
  }, [
    selectedBrand,
    selectedModelGroup,
    getModels,
  ]);

  const handleChange = useCallback((value) => {
    if (!selectedBrand && !selectedModelGroup) {
      return;
    }

    onChange(value);
  }, [
    selectedBrand,
    selectedModelGroup,
    onChange,
  ]);

  const options = selectedBrand && selectedModelGroup ? getModelsOptions(data) : [];

  const isDisabled = !!error || !selectedBrand || !selectedModelGroup;

  return (
    <Flex
      w="full"
      {...modelSelectContainerProps}
    >
      <Select
        {...props}
        isDisabled={isDisabled}
        isLoading={loading}
        options={options}
        placeholder={placeholder}
        isSearchable
        ref={ref}
        onChange={handleChange}
      />
    </Flex>
  );
});

export default ModelSelect;
