import React from "react";
import {
  Box,
  Flex,
  Stack,
  Button,
  Text,
} from "@chakra-ui/react";
import { useQueryParams } from "use-query-params";

import {
  CONTAINER_PADDING,
} from "constants/layout";
import { PageTitle } from "components/TitleBar/PageTitle";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";
import { DefaultLayoutContainer } from "layouts/components/DefaultLayoutContainer";
import { LayoutProps } from "layouts/types";
import { tabsQueryParams } from "views/Settings/tabs";

const getTextColor = (
  queryString: string,
  currentTab?: string | null,
): string => (
  queryString === currentTab ? "secondary.500" : "black.500"
);

const getBorderColor = (
  queryString: string,
  currentTab?: string | null,
): string => (
  queryString === currentTab ? "secondary.500" : "transparent"
);

const Settings: React.FC<LayoutProps> = ({
  settingsHeaderTabs,
  children,
  title,
  Icon,
}) => {
  const [queryParam, setQueryParam] = useQueryParams(tabsQueryParams);

  const { renderFilters } = useFilters([Filters.Dealer]);

  return (
    <DefaultLayoutContainer>
      <Flex
        direction="column"
        basis="auto"
        shrink={0}
        grow={1}
        w="full"
      >
        <Flex
          boxShadow="default"
          bgColor="white"
          w="full"
          padding="16px 16px 0 16px"
        >
          <Stack
            spacing={5}
            w="full"
          >
            <Flex>
              <PageTitle
                title={title || ""}
                textStyle="h1"
                Icon={Icon}
              />

              <Flex ml={8}>
                {renderFilters}
              </Flex>
            </Flex>

            <Flex>
              {
                settingsHeaderTabs?.map((tab) => (
                  <Button
                    borderColor={getBorderColor(tab.queryString, queryParam.tab)}
                    onClick={() => setQueryParam({ tab: tab.queryString })}
                    borderBottomWidth={2}
                    borderRadius="none"
                    variant="unstyled"
                    w="fit-content"
                    key={tab.label}
                    _focus={{
                      boxShadow: "none",
                    }}
                  >
                    <Text
                      color={getTextColor(tab.queryString, queryParam?.tab)}
                      fontWeight="bold"
                      textStyle="h3"
                      px={4}
                    >
                      {tab.label}
                    </Text>
                  </Button>
                ))
              }
            </Flex>
          </Stack>
        </Flex>

        <Box
          py={CONTAINER_PADDING.XS}
          pr={CONTAINER_PADDING.XS}
        >
          {children}
        </Box>
      </Flex>
    </DefaultLayoutContainer>
  );
};

export default Settings;
