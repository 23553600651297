import React, {
  useCallback, useEffect, useRef, useState,
} from "react";
import ReactSelect from "react-select";
import { Stack } from "@chakra-ui/react";

import { GRID_GAP } from "constants/layout";

import { VehicleSelectProps, SelectedVehicleProps } from "./types";
import BrandSelect from "./BrandSelect";
import ModelGroupSelect from "./ModelGroupSelect";
import ModelSelect from "./ModelSelect";

/**
 * This component renders two dependant dropdowns selects that renders the
 * brands list and the models list.
 * In order to access the value from these two fields, you should
 * pass an callback through the onChange property.
 */
const VehicleSelect: React.FC<VehicleSelectProps> = ({
  brandSelectContainerProps,
  modelGroupSelectContainerProps,
  modelSelectContainerProps,
  isModelActive,
  variant = "row",
  containerProps,
  onChange,
  value,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState<SelectedVehicleProps>({
    brandId: value?.brandId,
    brandName: value?.brandName,
    modelGroup: value?.modelGroup,
    model: value?.model,
  });
  const modelGroupSelectRef = useRef<ReactSelect>(null);
  const modelSelectRef = useRef<ReactSelect>(null);

  const handleChange = useCallback((state: SelectedVehicleProps) => {
    setSelectedVehicle((prev) => {
      const newState = {
        ...prev,
        ...state,
      };

      onChange(newState);

      return newState;
    });
  }, [
    setSelectedVehicle,
    onChange,
  ]);

  const handleModelGroupSearch = useCallback((modelGroup) => {
    handleChange({
      modelGroup: modelGroup === null ? undefined : modelGroup,
    });
  }, [
    handleChange,
  ]);

  const handleModelSearch = useCallback((model) => {
    handleChange({
      model: model === null ? undefined : model,
    });
  }, [
    handleChange,
  ]);

  const handleBrandSearch = useCallback((brand) => {
    if (!brand?.value) {
      handleChange({
        brandId: undefined,
        brandName: undefined,
        modelGroup: undefined,
        model: undefined,
      });

      return;
    }

    handleChange({
      brandId: brand.value,
      brandName: brand.label,
      modelGroup: undefined,
      model: undefined,
    });
  }, [
    handleChange,
  ]);

  useEffect(() => {
    if (!selectedVehicle.brandId && !selectedVehicle.brandName) {
      modelSelectRef?.current?.select?.clearValue();
    }
  }, [
    selectedVehicle.brandId,
    selectedVehicle.brandName,
  ]);

  return (
    <Stack
      spacing={GRID_GAP.LG}
      direction={variant}
      w="md"
      {...containerProps}
    >
      <BrandSelect
        brandSelectContainerProps={brandSelectContainerProps}
        value={selectedVehicle?.brandId || selectedVehicle.brandName}
        onChange={handleBrandSearch}
        valueAsObject
      />

      <ModelGroupSelect
        modelGroupSelectContainerProps={modelGroupSelectContainerProps}
        selectedBrand={selectedVehicle?.brandId as number}
        value={selectedVehicle?.modelGroup}
        onChange={handleModelGroupSearch}
        ref={modelGroupSelectRef}
      />

      {isModelActive && (
        <ModelSelect
          modelSelectContainerProps={modelSelectContainerProps}
          selectedBrand={selectedVehicle?.brandId as number}
          selectedModelGroup={selectedVehicle?.modelGroup}
          value={selectedVehicle?.model}
          onChange={handleModelSearch}
          ref={modelSelectRef}
        />
      )}
    </Stack>
  );
};

export default VehicleSelect;
