import React from "react";
import { Box } from "@chakra-ui/react";

import { LeadDetailsCardDividerProps } from "./types";

const LeadDetailsCardDivider: React.FC<LeadDetailsCardDividerProps> = ({ ...props }) => (
  <Box
    width="1px"
    bg="gray.200"
    mx={12}
    {...props}
  />
);

export default LeadDetailsCardDivider;
