import React, { useCallback } from "react";
import {
  Flex, Stack, Text, useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import importLeasingFactorsSchema, { ImportLeasingFactorsSchema } from "settings/yup/schemas/importLeasingFactorsSchema";
import Button from "components/Button";
import Select from "components/FormComponents/Inputs/Selects/Select";
import { useImportLeasingFactorsFromCsvMutation } from "generated/graphql";

import { ImportLeasingFactorsModalProps } from "./types";
import UploadButton from "../ImportCalculatorDataModal/UploadButton";
import { IMPORT_LEASING_FACTORS_REFETCH_QUERIES } from "./graphql/importLeasingFactorsFromCsvMutation";
import { makeCustomerTypeOptions } from "./makeCustomerTypeOptions";

const ImportLeasingFactorsModal: React.FC<ImportLeasingFactorsModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();
  const newToast = useToast();

  const {
    handleSubmit,
    formState,
    register,
    control,
  } = useForm<ImportLeasingFactorsSchema>({
    resolver: yupResolver(importLeasingFactorsSchema),
    mode: "onChange",
  });

  const [importLeasingFactor] = useImportLeasingFactorsFromCsvMutation({
    awaitRefetchQueries: true,
    refetchQueries: IMPORT_LEASING_FACTORS_REFETCH_QUERIES,
  });

  const handleCancel = useCallback(() => {
    hideModal();
  }, [
    hideModal,
  ]);

  const onSubmit = useCallback((values: ImportLeasingFactorsSchema) => {
    if (!values.file) {
      return;
    }

    componentProps?.callback?.(values);

    importLeasingFactor({
      variables: {
        csvFile: values.file?.[0],
        residualRatesCustomerType: values?.customerType,
      },
    })
      .then(() => {
        newToast({
          title: t("components.modals.import_leasing_factor.leasing_factors_has_been_imported_successfully"),
          status: "success",
        });
      })
      .catch(error => (
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
        })
      ))
      .finally(() => {
        hideModal();
      });
  }, [
    importLeasingFactor,
    componentProps,
    hideModal,
    newToast,
    t,
  ]);

  const options = makeCustomerTypeOptions();

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        h="full"
        w="full"
        p={6}
        spacing={6}
      >
        <Text
          lineHeight="24px"
          fontSize="20px"
          textStyle="h3"
          mb={6}
        >
          {t("components.modals.import_leasing_factor.title")}
        </Text>

        <Controller
          name="customerType"
          control={control}
          render={({
            field,
            fieldState,
          }) => (
            <Select
              {...field}
              {...fieldState}
              title={t("components.selects.customer_type")}
              errors={formState.errors}
              options={options}
            />
          )}
        />

        <UploadButton
          {...register("file")}
        />
      </Stack>

      <Flex
        justifyContent="space-between"
        borderRadius="0 0 8px 8px"
        bg="gray.200"
        w="full"
        p={6}
      >
        <Button
          label={t("components.buttons.cancel")}
          onClick={handleCancel}
          variant="ghost"
          bgColor="white"
          boxShadow="md"
        />

        <Button
          label={t("components.buttons.import")}
          onClick={handleSubmit(onSubmit)}
          isDisabled={!formState.isValid}
          boxShadow="md"
        />
      </Flex>
    </>
  );
};

export default ImportLeasingFactorsModal;
