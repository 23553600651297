import React, { useCallback, useState, useEffect } from "react";
import {
  Flex, Stack, Text, Tooltip, useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ConditionalWrap from "conditional-wrap";
import { Props as ReactSelectProps } from "react-select/src/Select";

import DealerUsersSelect from "components/FormComponents/Inputs/Selects/DealerUsersSelect";
import LeadStatusSelect from "components/FormComponents/Inputs/Selects/LeadStatusSelect";
import Button from "components/Button";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";
import { UpdateLeadsParams, useUpdateLeadsMutation } from "generated/graphql";
import { purgeUndefined } from "utils/purgeUndefined";
import { useCrmChannel } from "hooks/useCrmChannel";

import { useLeadStore } from "../context/leadsSelection";
import { FooterProps } from "./types";

const Footer: React.FC<FooterProps> = ({
  refetchQueries,
  isCheckout,
}) => {
  const [selectedSalesperson, setSelectedSalesperson] = useState<string | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();

  const { getCrmChannel, removeCrmChannelSearchParam } = useCrmChannel();

  const { dealerId } = useFilters([
    Filters.Dealer,
  ]);

  const { selectedLeads, clearSelectedLeads } = useLeadStore();
  const { t } = useTranslation();
  const newToast = useToast();

  const handleSalespersonChange = (salesperson: string): void => {
    setSelectedSalesperson(salesperson);
  };

  const handleStatusChange = (salesperson: string): void => {
    setSelectedStatus(salesperson);
  };

  const [
    updateLeads, {
      loading,
    },
  ] = useUpdateLeadsMutation({
    refetchQueries,
    onCompleted: removeCrmChannelSearchParam,
  });

  const handleUpdateLeads = useCallback(() => {
    const params = selectedLeads?.map(lead => (
      purgeUndefined({
        id: lead.id,
        status: selectedStatus,
        assignee: selectedSalesperson,
        dealerCrmChannelSource: getCrmChannel(),
      }) as UpdateLeadsParams));

    newToast({
      title: t("leads.footer.updating", { count: selectedLeads?.length }),
      status: "info",
    });

    updateLeads({
      variables: {
        params,
      },
    })
      .then(() => {
        newToast({
          title: t("leads.footer.updated_successfully", { count: params?.length }),
          status: "success",
        });

        clearSelectedLeads();
      })
      .catch(error => {
        newToast({
          title: error.message,
          status: "error",
        });
      });
  }, [
    selectedSalesperson,
    clearSelectedLeads,
    selectedStatus,
    selectedLeads,
    updateLeads,
    getCrmChannel,
    newToast,
    t,
  ]);

  useEffect(() => {
    if (dealerId) {
      return;
    }

    setSelectedSalesperson(undefined);
  }, [
    dealerId,
  ]);

  const hasLeadsSelected = selectedLeads?.length > 0;

  const isSubmissionDisabled = (
    !hasLeadsSelected
    || loading
  );

  const opacity = hasLeadsSelected ? 1 : 0.5;

  return (
    <Flex justifyContent="space-between" alignItems="center" w="full">
      <Text
        color="secondary.500"
        fontWeight="700"
        fontSize="20px"
        lineHeight={1}
      >
        {t("leads.footer.selected", { count: selectedLeads?.length })}
      </Text>

      <Stack alignItems="flex-end" direction="row" minW="600px" spacing={2}>
        <Stack opacity={opacity} w="full">
          <ConditionalWrap
            condition={!dealerId}
            wrap={(content) => (
              <Tooltip
                label={t("leads.footer.please_select_a_dealer")}
                isDisabled={!hasLeadsSelected}
                color="secondary.500"
                bg="gray.100"
                hasArrow
              >
                <span>{content}</span>
              </Tooltip>
            )}
          >
            <DealerUsersSelect
              onChange={handleSalespersonChange}
              isDisabled={!hasLeadsSelected}
              dealerId={dealerId}
              placeholder={t("leads.footer.salespersonPlaceholder")}
              styles={{
                placeholder: (base: ReactSelectProps) => ({
                  ...base,
                  fontSize: "14px",
                }),
              }}
            />
          </ConditionalWrap>
        </Stack>

        <Stack opacity={opacity} w="full">
          <LeadStatusSelect
            isDisabled={!hasLeadsSelected}
            onChange={handleStatusChange}
            value={selectedStatus}
            isCheckout={isCheckout}
            isClearable
            placeholder={t("leads.footer.statusPlaceholder")}
            styles={{
              placeholder: (base: ReactSelectProps) => ({
                ...base,
                fontSize: "14px",
              }),
            }}
          />
        </Stack>

        <Button
          label={t("components.buttons.save")}
          isDisabled={isSubmissionDisabled}
          onClick={handleUpdateLeads}
          lineHeight="1"
          minW="150px"
        />
      </Stack>
    </Flex>
  );
};

export default Footer;
