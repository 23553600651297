import React, { useMemo } from "react";
import { Grid } from "@chakra-ui/react";

import {
  GRID_COLUMNS,
} from "constants/layout";
import TitleBar from "components/TitleBar";
import useCustomOfferStore from "views/CustomOffer/store";

import { LayoutProps } from "../types";
import { childrenContainerStyles } from "./styles";

const Wizard: React.FC<LayoutProps> = ({
  children,
  title,
  Icon,
}) => {
  const offer = useCustomOfferStore(store => store.offer);

  const offerTitle = useMemo((): string => {
    if (!offer) {
      return "";
    }

    return `
      ${offer.vehicle?.brand?.name || ""}
      ${offer.vehicle?.modelGroup || ""}
      ${offer.vehicle?.model || ""}
    `;
  }, [
    offer,
  ]);

  return (
    <>
      <TitleBar
        title={offerTitle || title}
        hasContainer
        Icon={Icon}
        hasLogo
      />

      <Grid
        templateColumns={GRID_COLUMNS}
        margin="auto"
        css={childrenContainerStyles}
      >
        {children}
      </Grid>
    </>
  );
};

export default Wizard;
