import React from "react";
import NumberFormat from "react-number-format";

import Input from "components/FormComponents/Inputs/Input";
import useLocalizedNumberSeparators from "hooks/useLocalizedNumberSeparators";

import { CurrencyInputType } from "./types";
import { borderCss } from "./styles";

const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputType>((
  {
    customInput = (Input),
    focusCss,
    placeholder,
    border = borderCss,
    textAlign,
    disabled,
    formControlCss,
    hasCustomErrorCss,
    ...props
  },
  ref,
): JSX.Element => {
  const {
    localizedThousandSeparator,
    localizedDecimalSeparator,
  } = useLocalizedNumberSeparators();

  return (
    <NumberFormat
      getInputRef={ref}
      allowNegative={false}
      focusCss={focusCss}
      customInput={customInput}
      placeholder={placeholder}
      border={border}
      textAlign={textAlign}
      disabled={disabled}
      formControlCss={formControlCss}
      hasCustomErrorCss={hasCustomErrorCss}
      thousandSeparator={localizedThousandSeparator}
      decimalSeparator={localizedDecimalSeparator}
      allowedDecimalSeparators={[localizedDecimalSeparator]}
      {...props}
    />
  );
});

export default CurrencyInput;
