import React, { ChangeEvent, useState } from "react";
import { Stack } from "@chakra-ui/react";

import Checkbox from "components/FormComponents/Checkbox";
import i18n from "translations/i18n";
import EuroIcon from "components/Icons/EuroIcon";
import CurrencyInput from "components/FormComponents/Inputs/CurrencyInput";
import { GROSS_TAX } from "constants/leasing";
import { formatCurrencyToDecimal, formatCurrency } from "utils/currencyFormat";

import { centCss, rightElementCss } from "../styles";
import { CustomFieldProps } from "../types";

const formatCurrencyHelper = (amount: number): string => formatCurrency({
  amount,
  addSymbol: false,
});

const CustomMonetaryField: React.FC<CustomFieldProps> = (
  {
    setValue: formSetValue,
    includedCheckbox = false,
    isCent = false,
    rightElement = isCent ? "¢" : (<EuroIcon />),
    netTitle,
    grossTitle,
    name,
    titleCss,
    defaultValue,
    width,
    css,
    isDisabled,
  },
) => {
  const decimalScale = 2;

  const defaultValueToFloat = parseFloat(defaultValue);

  const initialStateValue = defaultValueToFloat >= 0
    ? formatCurrencyHelper(defaultValueToFloat)
    : "";

  const isInitiallyDisabled = includedCheckbox && defaultValue?.toString() === "0";

  const [isInternallyDisabled, setIsInternallyDisabled] = useState(isInitiallyDisabled);

  const [netPrice, setNetPrice] = useState<string>(initialStateValue);

  const priceWithTax = defaultValueToFloat >= 0
    ? formatCurrencyHelper(defaultValueToFloat * GROSS_TAX)
    : "";

  const [grossPrice, setGrossPrice] = useState(priceWithTax);

  const rightElementStyles = isCent ? centCss : rightElementCss;

  const handleOnChange = React.useCallback((value: string, isGross = false): void => {
    const isValidInput = typeof value === "string" && value.length > 0;

    if (isValidInput) {
      const valueToDecimal = formatCurrencyToDecimal(value);
      const netValue = isGross ? (valueToDecimal / GROSS_TAX) : valueToDecimal;
      const netPriceToString = formatCurrencyHelper(netValue);
      const grossPriceToString = formatCurrencyHelper(
        isGross
          ? valueToDecimal
          : parseFloat(
            (netValue * GROSS_TAX).toFixed(decimalScale),
          ),
      );

      setNetPrice(netPriceToString);
      setGrossPrice(grossPriceToString);
      return formSetValue?.(name, netValue);
    }

    setGrossPrice("");
    setNetPrice("");
    return formSetValue?.(name, null);
  }, [
    setGrossPrice,
    formSetValue,
    setNetPrice,
    name,
  ]);

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = isInternallyDisabled ? "" : "0";

    setNetPrice(value);
    setGrossPrice(value);
    setIsInternallyDisabled(event.target?.checked);
    return handleOnChange(value);
  };

  const customMonetaryProps = {
    name,
    format: undefined,
    decimalSeparator: ",",
    thousandSeparator: ".",
    fixedDecimalScale: true,
    decimalScale,
    titleCss,
    placeholder: "-",
    rightElement,
    rightElementProps: rightElementStyles,
  };

  React.useEffect(() => {
    if (isDisabled) {
      handleOnChange("");
    }
  }, [
    handleOnChange,
    defaultValue,
    isDisabled,
  ]);

  return (
    <>
      <Stack
        key={`${name}-${defaultValue}`}
        width={width}
        css={css}
      >
        <CurrencyInput
          {...customMonetaryProps}
          value={isDisabled ? "" : netPrice}
          title={netTitle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e.target.value)}
          disabled={isDisabled || isInternallyDisabled}
        />
        {
          includedCheckbox && (
            <Checkbox
              defaultIsChecked={isInitiallyDisabled}
              name="checkbox"
              onChange={handleChangeCheckbox}
            >
              {i18n.t("actions.included")}
            </Checkbox>
          )
        }
      </Stack>

      {
        grossTitle && (
          <Stack width={width} css={css}>
            <CurrencyInput
              {...customMonetaryProps}
              title={grossTitle}
              value={isDisabled ? "" : grossPrice}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(
                e.target.value, true,
              )}
              disabled={isDisabled || isInternallyDisabled}
            />
          </Stack>
        )
      }
    </>
  );
};

export default CustomMonetaryField;
