import React from "react";
import { Flex } from "@chakra-ui/react";

import i18n from "translations/i18n";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import { formatDate, germanDateFormat } from "utils/dateFormats";
import { formatCurrency } from "utils/currencyFormat";
import getMaritalStatusLabel from "utils/getMaritalStatusLabel";

import { RightSectionProps } from "./types";

const RighSection: React.FC<RightSectionProps> = ({ lead }) => (
  <Flex flex={1} direction="column">
    <Flex mb={30}>
      <FakeTextField
        value={formatDate(lead?.birthdate, germanDateFormat, "-")}
        label={i18n.t("leads.details.form.birthdate")}
        w="25%"
      />

      <FakeTextField
        value={lead?.placeOfBirth}
        label={i18n.t("leads.details.form.place_of_birth")}
        w="75%"
      />
    </Flex>

    <Flex mb={30}>
      <FakeTextField
        value={lead?.nationality}
        label={i18n.t("leads.details.form.nationality")}
        w="50%"
      />

      <FakeTextField
        value={lead?.numberDependentChildren}
        label={i18n.t("leads.details.form.dependent_children")}
        w="50%"
      />
    </Flex>

    <Flex mb={30}>
      <FakeTextField
        value={getMaritalStatusLabel(lead?.maritalStatus)}
        label={i18n.t("leads.details.form.marital_status")}
        w="50%"
      />

      <FakeTextField
        value={lead?.monthlyIncome && formatCurrency({
          amount: lead?.monthlyIncome,
          symbolPosition: "before",
        })}
        label={i18n.t("leads.details.form.monthly_income")}
        w="50%"
      />
    </Flex>
  </Flex>
);

export default RighSection;
