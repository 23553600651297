import React from "react";
import {
  Container as ChakraContainer,
  Text,
} from "@chakra-ui/react";

import {
  CONTAINER_PADDING_RESPONSIVE,
  CONTAINER_PADDING,
} from "constants/layout";

import { ContainerProps } from "./types";
import { defaultSubTitleCss } from "./styles";

const Container: React.FC<ContainerProps> = ({
  boxShadow = "default",
  children,
  textStyle,
  title,
  titleCss,
  subTitle,
  subTitleCss,
  alignCenter,
  ...props
}) => (
  <ChakraContainer
    bg="white"
    boxShadow={boxShadow}
    maxW="initial"
    paddingTop={CONTAINER_PADDING_RESPONSIVE}
    paddingX={CONTAINER_PADDING_RESPONSIVE}
    paddingBottom={CONTAINER_PADDING.MD}
    margin={alignCenter ? "0 auto" : "0"}
    borderRadius="8px"
    {...props}
  >
    {
      title && (
        <Text
          as="h3"
          fontWeight="600"
          wordBreak="break-all"
          mb="4"
          textStyle={textStyle || "h2"}
          {...titleCss}
        >
          {title}
        </Text>
      )
    }
    {
      subTitle && (
        <Text
          as="h4"
          fontWeight="400"
          wordBreak="break-all"
          textStyle={textStyle || "h4"}
          {...defaultSubTitleCss}
          {...subTitleCss}
        >
          {subTitle}
        </Text>
      )
    }
    {children}
  </ChakraContainer>
);

export { Container };
