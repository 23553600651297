import i18n from "translations/i18n";
import {
  ROOT_PAGE_PATH,
  OFFERS_PAGE_PATH,
  CALCULATOR_OFFERS_PAGE_PATH,
  CREATE_NEW_OFFER_PAGE_PATH,
  MANAGE_CONDITIONS_PAGE_PATH,
  BASE_VEHICLES_PAGE_PATH,
  BRANDS_PAGE_PATH,
  BANKS_PAGE_PATH,
  HISTORIC_PAGE_PATH,
  CUSTOM_OFFER_PAGE_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  OFFER_IMPORT_LOGS_PAGE_PATH,
  OFFER_IMPORT_LOGS_DETAILS_PAGE_PATH,
  CHECKOUT_LEADS_OVERVIEW_PAGE_PATH,
  CLASSIC_LEADS_OVERVIEW_PAGE_PATH,
  LEAD_DETAILS_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  SET_PASSWORD_PATH,
  BILLING_LEADS_OVERVIEW_PAGE_PATH,
} from "routes";
import { ALL_ROLES, ALL_SELLER_ROLES, ONLY_ADMINS } from "constants/roleConfig";
import {
  RouteProps, DefaultRouteProps, SidebarSectionsEnum,
} from "contexts/route/types";
import Main from "layouts/Main";
import Wizard from "layouts/Wizard";
import Dashboard from "layouts/Dashboard";
import AuthLayout from "layouts/Auth";
import Offers from "views/Offers";
import OffersFooter from "views/Offers/OffersFooter";
import SignIn from "views/SignIn";
import ForgotPassword from "views/ForgotPassword";
import ResetPassowrd from "views/ResetPassword";
import Banks from "views/Banks";
import BanksModal from "components/Modals/BanksModal";
import CreateNewOffers from "views/CreateNewOffer";
import CreateNewOfferFooter from "views/CreateNewOffer/CreateNewOfferFooter";
import CalculatorOffers from "views/CalculatorOffers";
import ImportCalculatorDataModal from "components/Modals/ImportCalculatorDataModal";
import CustomOffer from "views/CustomOffer";
import CalculatorOffersFooter from "views/CalculatorOffers/CalculatorOffersFooter";
import ManageConditions from "views/ManageConditions";
import BaseVehicles from "views/BaseVehicles";
import ImportLeasingFactorsModal from "components/Modals/ImportLeasingFactorsModal";
import Historic from "views/Historic";
import Brands from "views/Brands";
import BrandsModal from "components/Modals/BrandsModal";
import { ManageConditionsFooter } from "views/ManageConditions/Footer";
import BankIcon from "components/Icons/BankIcon";
import CalculatorIcon from "components/Icons/CalculatorIcon";
import DatabaseIcon from "components/Icons/DatabaseIcon";
import CarIcon from "components/Icons/CarIcon";
import GearIcon from "components/Icons/GearIcon";
import HistoricIcon from "components/Icons/HistoricIcon";
import OfferImportLogs from "views/OfferImportLogs";
import OfferImportLogsDetails from "views/OfferImportLogsDetails";
import PersonIcon from "components/Icons/PersonIcon";
import ClassicLeadsOverview from "views/Leads/Classic/Overview";
import CheckoutLeadsOverview from "views/Leads/Checkout/Overview";
import BillingLeadsOverview from "views/Leads/Billing/Overview";
import Footer from "views/Leads/Footer";
import Leads from "layouts/Leads";
import { GET_CHECKOUT_LEADS_REFETCH_QUERIES, GET_CLASSIC_LEADS_REFETCH_QUERIES } from "views/Leads/graphql/updateLeadsMutation";
import Settings from "layouts/Settings";
import { SettingsContainer } from "views/Settings/components/SettingsContainer";
import { settingsHeaderTabs } from "views/Settings/tabs";
import DocumentIcon from "components/Icons/DocumentIcon";
import TagIcon from "components/Icons/TagIcon";
import BillingIcon from "components/Icons/BillingIcon";
import Details from "views/Leads/Details";
import SetPassword from "views/SetPassword";
import { SellerRoleEnum } from "generated/graphql";

const layouts = {
  main: {
    layout: Main,
    footerVariant: "fluid",
    showInSidebar: false,
  },
  auth: {
    layout: AuthLayout,
    showInSidebar: false,
  },
  dashboard: {
    layout: Dashboard,
    footerVariant: "container",
    showInSidebar: true,
  },
  leads: {
    layout: Leads,
    footerVariant: "container",
    showInSidebar: true,
  },
  wizard: {
    layout: Wizard,
  },
  settings: {
    layout: Settings,
  },
};

const defaultRouteProps: DefaultRouteProps = {
  ...layouts.main,
  isEnabled: false,
  exact: true,
  strict: false,
  requireAuthentication: true,
  showHeader: true,
  showFooter: true,
  showInSidebar: false,
  componentProps: {},
  userRequiredRoles: ONLY_ADMINS,
  sellerRequiredRoles: ALL_SELLER_ROLES,
};

const appRoutes: RouteProps[] = [
  {
    ...defaultRouteProps,
    ...layouts.auth,
    isEnabled: true,
    requireAuthentication: false,
    component: SignIn,
    path: ROOT_PAGE_PATH,
  },
  {
    ...defaultRouteProps,
    ...layouts.auth,
    isEnabled: true,
    requireAuthentication: false,
    component: ForgotPassword,
    path: FORGOT_PASSWORD_PATH,
  },
  {
    ...defaultRouteProps,
    ...layouts.auth,
    isEnabled: true,
    requireAuthentication: false,
    component: ResetPassowrd,
    path: RESET_PASSWORD_PATH,
  },
  {
    ...defaultRouteProps,
    ...layouts.auth,
    isEnabled: true,
    requireAuthentication: false,
    component: SetPassword,
    path: SET_PASSWORD_PATH,
  },
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    isEnabled: true,
    showInSidebar: false,
    userRequiredRoles: ALL_ROLES,
    requireAuthentication: true,
    path: LEAD_DETAILS_PAGE_PATH,
    component: Details,
    label: i18n.t("components.sidebar.lead_details"),
  },
  {
    ...defaultRouteProps,
    ...layouts.main,
    isEnabled: true,
    path: CREATE_NEW_OFFER_PAGE_PATH,
    userRequiredRoles: ALL_ROLES,
    requireAuthentication: true,
    component: CreateNewOffers,
    FooterContent: CreateNewOfferFooter,
    label: i18n.t("components.sidebar.create_new_offer"),
    Icon: CarIcon,
  },
  {
    ...defaultRouteProps,
    isEnabled: true,
    layout: Wizard,
    Icon: CarIcon,
    path: CUSTOM_OFFER_PAGE_PATH,
    userRequiredRoles: ALL_ROLES,
    requireAuthentication: true,
    label: i18n.t("custom_offer.title"),
    component: CustomOffer,
  },
  {
    ...defaultRouteProps,
    ...layouts.main,
    requireAuthentication: true,
    path: MANAGE_CONDITIONS_PAGE_PATH,
    FooterContent: ManageConditionsFooter,
    component: ManageConditions,
    label: i18n.t("components.sidebar.manage_conditions"),
    Icon: GearIcon,
  },
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    isEnabled: true,
    showInSidebar: false,
    requireAuthentication: true,
    component: OfferImportLogsDetails,
    userRequiredRoles: ONLY_ADMINS,
    path: OFFER_IMPORT_LOGS_DETAILS_PAGE_PATH,
    label: i18n.t("components.sidebar.offer_import_logs_details"),
    Icon: DatabaseIcon,
  },
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    requireAuthentication: true,
    path: HISTORIC_PAGE_PATH,
    component: Historic,
    label: i18n.t("components.sidebar.historic"),
    Icon: HistoricIcon,
  },
  /**
   * Routes that will be shown in the sidebar
   */

  /**
   * Offers section
   */
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    isEnabled: true,
    path: OFFERS_PAGE_PATH,
    userRequiredRoles: ALL_ROLES,
    sellerRequiredRoles: [SellerRoleEnum.Default],
    requireAuthentication: true,
    component: Offers,
    FooterContent: OffersFooter,
    label: i18n.t("components.sidebar.offers"),
    Icon: CarIcon,
    titleBarButton: {
      navigatePath: CREATE_NEW_OFFER_PAGE_PATH,
      info: {
        label: i18n.t("screens.offers.new_offer"),
      },
    },
    sidebarSection: SidebarSectionsEnum.Offers,
  },
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    isEnabled: true,
    path: CALCULATOR_OFFERS_PAGE_PATH,
    requireAuthentication: true,
    component: CalculatorOffers,
    FooterContent: CalculatorOffersFooter,
    label: i18n.t("components.sidebar.calculator_offers"),
    Icon: CalculatorIcon,
    titleBarButton: {
      actionModal: {
        component: ImportCalculatorDataModal,
        size: "lg",
      },
      info: {
        label: i18n.t("screens.calculator_offers.button_import_calculator"),
      },
    },
    sidebarSection: SidebarSectionsEnum.Offers,
  },
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    isEnabled: true,
    requireAuthentication: true,
    component: OfferImportLogs,
    userRequiredRoles: ONLY_ADMINS,
    path: OFFER_IMPORT_LOGS_PAGE_PATH,
    label: i18n.t("components.sidebar.offer_import_logs"),
    Icon: DatabaseIcon,
    sidebarSection: SidebarSectionsEnum.Offers,
  },
  /**
   * Configurations section
   */
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    requireAuthentication: true,
    path: BASE_VEHICLES_PAGE_PATH,
    component: BaseVehicles,
    label: i18n.t("components.sidebar.base_vehicles"),
    Icon: CarIcon,
    isEnabled: true,
    titleBarButton: {
      actionModal: {
        component: ImportLeasingFactorsModal,
        size: "xl",
      },
      info: {
        label: i18n.t("screens.base_vehicles.import_residual_values_factors"),
      },
    },
    sidebarSection: SidebarSectionsEnum.Configurations,
  },
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    requireAuthentication: true,
    path: BRANDS_PAGE_PATH,
    component: Brands,
    label: i18n.t("components.sidebar.brands"),
    Icon: TagIcon,
    isEnabled: true,
    titleBarButton: {
      actionModal: {
        component: BrandsModal,
        size: "xl",
      },
      info: {
        label: i18n.t("screens.brands.add"),
      },
    },
    sidebarSection: SidebarSectionsEnum.Configurations,
  },
  {
    ...defaultRouteProps,
    ...layouts.dashboard,
    isEnabled: true,
    requireAuthentication: true,
    component: Banks,
    path: BANKS_PAGE_PATH,
    label: i18n.t("components.sidebar.banks"),
    Icon: BankIcon,
    titleBarButton: {
      actionModal: {
        component: BanksModal,
        size: "xl",
      },
      info: {
        label: i18n.t("screens.banks.add"),
      },
    },
    sidebarSection: SidebarSectionsEnum.Configurations,
  },
  /**
   * Leads section
   */
  {
    ...defaultRouteProps,
    ...layouts.leads,
    isEnabled: true,
    showInSidebar: true,
    userRequiredRoles: ALL_ROLES,
    requireAuthentication: true,
    path: CLASSIC_LEADS_OVERVIEW_PAGE_PATH,
    component: ClassicLeadsOverview,
    FooterContent: () => Footer({
      refetchQueries: GET_CLASSIC_LEADS_REFETCH_QUERIES,
      isCheckout: false,
    }),
    label: i18n.t("components.sidebar.classic_leads"),
    Icon: PersonIcon,
    sidebarSection: SidebarSectionsEnum.Leads,
  },
  {
    ...defaultRouteProps,
    ...layouts.leads,
    isEnabled: true,
    requireAuthentication: true,
    userRequiredRoles: ALL_ROLES,
    path: CHECKOUT_LEADS_OVERVIEW_PAGE_PATH,
    component: CheckoutLeadsOverview,
    FooterContent: () => Footer({
      refetchQueries: GET_CHECKOUT_LEADS_REFETCH_QUERIES,
      isCheckout: true,
    }),
    label: i18n.t("components.sidebar.checkout_leads"),
    Icon: DocumentIcon,
    sidebarSection: SidebarSectionsEnum.Leads,
  },
  {
    ...defaultRouteProps,
    ...layouts.leads,
    isEnabled: true,
    requireAuthentication: true,
    userRequiredRoles: ALL_ROLES,
    path: BILLING_LEADS_OVERVIEW_PAGE_PATH,
    component: BillingLeadsOverview,
    label: i18n.t("components.sidebar.billing"),
    Icon: BillingIcon,
    sidebarSection: SidebarSectionsEnum.Leads,
    dealerSelector: true,
  },
  /**
   * Settings section
   */
  {
    ...defaultRouteProps,
    ...layouts.settings,
    label: i18n.t("components.sidebar.settings"),
    path: SETTINGS_PAGE_PATH,
    userRequiredRoles: ALL_ROLES,
    component: SettingsContainer,
    showInSidebar: true,
    Icon: GearIcon,
    showFooter: false,
    isEnabled: true,
    settingsHeaderTabs,
    sidebarSection: SidebarSectionsEnum.Settings,
  },
];

/**
 * Only enabled paths should be available to be accessed.
 */
const enabledPaths: RouteProps[] = appRoutes.filter((route) => route.isEnabled);

/**
 * Public paths that can be accessed without a session.
 */
export const publicPaths = enabledPaths
  .filter((route) => !route?.requireAuthentication)
  .map((route) => route.path);

export default enabledPaths;
