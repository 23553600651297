import React from "react";
import { useTranslation } from "react-i18next";
import ReactSelect, { MultiValueProps, SingleValueProps, StylesConfig } from "react-select";

import { LeadStatusSelectProps } from "./types";
import { OptionProps } from "../BaseSelect/types";
import Select from "../Select";

const LeadStatusMultiSelect = React.forwardRef<ReactSelect, LeadStatusSelectProps>((
  {
    placeholder,
    isDisabled,
    isLoading,
    onChange,
    defaultValue,
    initialValues = [],
    availableLeadStatus = [],
    ...props
  },
  ref,
) => {
  const getColors = (
    provided: React.CSSProperties,
    properties: SingleValueProps<OptionProps>,
  ): React.CSSProperties => {
    const styles = availableLeadStatus.find(
      statusObject => statusObject.value === properties.data.value,
    )?.styles;

    return {
      ...provided,
      ...styles,
    };
  };

  const getMultiValueStyles = (
    styles: React.CSSProperties,
    properties: MultiValueProps<OptionProps>,
  ): React.CSSProperties => ({
    ...styles,
    color: "#fff",
    backgroundColor: properties.data.styles?.color,
  });

  const styles: StylesConfig<OptionProps, false> = {
    option: getColors,
    multiValue: getMultiValueStyles,
    multiValueLabel: getMultiValueStyles,
    valueContainer: (provided: React.CSSProperties): React.CSSProperties => ({
      ...provided,
      flexWrap: "nowrap",
    }),
  };

  const { t } = useTranslation();

  const availableOptions = availableLeadStatus.filter(status => ({
    value: status.value,
    label: t(`status.${status.value}`),
  }));

  const handleChange = (list: Array<OptionProps>): void => {
    const formattedValues = (list || []).map((item) => item.value);

    return onChange(formattedValues);
  };

  const selectedDefaultValues = (defaultValue || initialValues)
    .map((item: string) => availableOptions
      .find((option) => option.value === item));

  return (
    <Select
      {...props}
      options={availableOptions}
      defaultValue={selectedDefaultValues}
      placeholder={placeholder}
      isDisabled={isDisabled}
      onChange={handleChange}
      isLoading={isLoading}
      styles={styles}
      valueAsObject
      ref={ref}
      isMulti
    />
  );
});

export default LeadStatusMultiSelect;
