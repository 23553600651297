import React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Wrap,
  Avatar,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Code,
  IconButton,
  Stack,
  SkeletonCircle,
  SkeletonText,
  Skeleton,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import Tabs from "components/Tabs";
import LogsAsCode from "components/LogsAsCode";
import { Container } from "components/Container";
import { OfferImportLog, useOfferImportLogQuery } from "generated/graphql";
import { formatFullDate } from "utils/dateFormats";
import { EmptyDataMessage } from "components/EmptyDataMessage";
import i18n from "translations/i18n";

import { OfferImportLogsDetailsPageParams } from "./types";

const OfferImportLogsDetails: React.FC = () => {
  const { id } = useParams<OfferImportLogsDetailsPageParams>();

  const history = useHistory();

  const { data, loading } = useOfferImportLogQuery({
    variables: {
      id: Number(id),
    },
  });

  const log = data?.offerImportLog as OfferImportLog;

  if (loading) {
    return (
      <Container>
        <Box mt={8} padding="6" bg="white">
          <SkeletonCircle size="10" mb={4} />
          <Flex>
            <Skeleton width={150} height={16} mr={6} />
            <Skeleton width={150} height={16} mr={6} />
            <Skeleton width={150} height={16} mr={6} />
            <Skeleton width={150} height={16} mr={6} />
          </Flex>
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        </Box>
      </Container>
    );
  }

  const logsFailed = log.offersFailed.length === 0 ? (
    <EmptyDataMessage />
  ) : (
    <Accordion allowToggle>
      {
        log.offersFailed.map((item) => (
          <AccordionItem key={item?.row_number}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {i18n.t("logs.accordion.action")}
                  {" "}
                  {item?.row_number}
                  {" "}
                  {item?.params?.internal_number && `(${item?.params?.internal_number})`}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <pre>
                <Code>
                  {JSON.stringify(item, null, "\t")}
                </Code>
              </pre>
            </AccordionPanel>
          </AccordionItem>
        ))
      }
    </Accordion>
  );

  return (
    <Container>
      <Stack direction="row" mb={8}>
        <IconButton
          icon={<ArrowBackIcon />}
          fontSize="28px"
          variant="unstyled"
          color="gray.500"
          aria-label="Go back"
          onClick={() => history.goBack()}
        />
      </Stack>
      <Wrap mb={6}>
        <Flex justify="space-between" flex={1}>
          <Flex align="center">
            <Avatar name={log?.dealer?.companyName} mr={2} />
            <div>
              <Text fontSize="md" color="gray.500" fontWeight={600}>
                {log?.dealer?.companyName}
              </Text>
            </div>
          </Flex>

          <Flex direction="column" align="flex-end">
            <Text fontSize="md" color="gray.500" fontWeight={600}>
              {log?.dealer?.id}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {formatFullDate(log.importedAt)}
            </Text>
          </Flex>
        </Flex>
      </Wrap>

      <Tabs
        isLazy
        tabs={[
          {
            label: `${i18n.t("logs.details.created")} (${log?.offersCreated.length})`,
            content: <LogsAsCode logs={log?.offersCreated} />,
          },
          {
            label: `${i18n.t("logs.details.deleted")} (${log?.offersDeleted.length})`,
            content: <LogsAsCode logs={log?.offersDeleted} />,
          },
          {
            label: `${i18n.t("logs.details.failed")} (${log?.offersFailed.length})`,
            content: logsFailed,
          },
          {
            label: `${i18n.t("logs.details.skipped")} (${log?.offersSkipped.length})`,
            content: <LogsAsCode logs={log?.offersSkipped} />,
          },
          {
            label: `${i18n.t("logs.details.updated")} (${log?.offersUpdated.length})`,
            content: <LogsAsCode logs={log?.offersUpdated} />,
          },
          {
            label: `${i18n.t("logs.details.published")} (${log?.offersPublished.length})`,
            content: <LogsAsCode logs={log?.offersPublished} />,
          },
        ]}
      />
    </Container>
  );
};

export default OfferImportLogsDetails;
