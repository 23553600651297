import React from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

import { availableCheckoutLeadStatus, availableClassicLeadStatus } from "constants/availableLeadsStatus";

import Select from "../Select";
import { LeadStatusSelectProps } from "./types";

const getAvailableStatuses = (
  isCheckout: boolean,
): typeof availableCheckoutLeadStatus | typeof availableClassicLeadStatus => (
  isCheckout
    ? availableCheckoutLeadStatus
    : availableClassicLeadStatus
);

const LeadStatusSelect = React.forwardRef<ReactSelect, LeadStatusSelectProps>((
  {
    defaultValue,
    placeholder,
    isDisabled,
    isLoading,
    isClearable = false,
    isCheckout,
    ...props
  },
  ref,
) => {
  const { t } = useTranslation();

  const statuses = getAvailableStatuses(isCheckout);

  const options = statuses.filter(status => ({
    value: status?.value,
    label: t(`status.${status?.value}`),
  }));

  const selectDefaultValue = options?.find(item => item?.value === defaultValue);

  return (
    <Select
      {...props}
      defaultValue={selectDefaultValue}
      isClearable={isClearable}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isLoading={isLoading}
      options={options}
      ref={ref}
    />
  );
});

export default LeadStatusSelect;
