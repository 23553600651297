import React from "react";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import {
  LeadStatusEnum,
  useUpdateLeadBySellerMutation,
} from "generated/graphql";
import LeadStatusSelect from "components/FormComponents/Inputs/Selects/LeadStatusSelect";
import { useCrmChannel } from "hooks/useCrmChannel";

import { StatusSelectorProps } from "./types";

const StatusSelector: React.FC<StatusSelectorProps> = ({
  refetchQueries,
  defaultValue,
  leadId,
  ...props
}) => {
  const { t } = useTranslation();
  const newToast = useToast();
  const { getCrmChannel, removeCrmChannelSearchParam } = useCrmChannel();

  const [updateLead, { loading }] = useUpdateLeadBySellerMutation({
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: removeCrmChannelSearchParam,
  });

  const onAssigneeUpdate = React.useCallback((id: string, status: LeadStatusEnum) => {
    updateLead({
      variables: {
        id,
        params: {
          status,
          dealerCrmChannelSource: getCrmChannel(),
        },
      },
    })
      .then(() => (
        newToast({
          title: t("success.success"),
          description: t("success.lead_updated"),
          status: "success",
        })
      ))
      .catch(() => (
        newToast({
          title: t("errors.error"),
          description: t("errors.something_went_wrong"),
          status: "error",
        })
      ));
  }, [
    getCrmChannel,
    updateLead,
    newToast,
    t,
  ]);

  return (
    <LeadStatusSelect
      {...props}
      onChange={(status: LeadStatusEnum) => onAssigneeUpdate(leadId, status)}
      defaultValue={defaultValue}
      isDisabled={loading}
      isLoading={loading}
    />
  );
};

export default StatusSelector;
