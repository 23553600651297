import React from "react";
import { Button } from "@chakra-ui/react";
import { Link as ReactRouterDomLink } from "react-router-dom";

import { ButtonAsLinkProps } from "./types";

export const ButtonAsLink: React.FC<ButtonAsLinkProps> = ({
  path,
  label,
  ...props
}) => (
  <Button
    as={ReactRouterDomLink}
    justifyContent="start"
    to={path}
    {...props}
  >
    {label}
  </Button>
);

