import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const CarIcon: IconProps = createIcon({
  viewBox: "0 0 20 21",
  path: (
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9459 8.09924C16.991 8.09432 17.0368 8.0918 17.0832 8.0918C17.7735 8.0918 18.3332 8.65144 18.3332 9.3418C18.3332 9.88605 17.9853 10.3491 17.4998 10.5207V16.8418C17.4998 17.5322 16.9402 18.0918 16.2498 18.0918H14.9998C14.3095 18.0918 13.7498 17.5322 13.7498 16.8418V15.5918H6.24984V16.8418C6.24984 17.5322 5.69019 18.0918 4.99984 18.0918H3.74984C3.05948 18.0918 2.49984 17.5322 2.49984 16.8418V10.5207C2.01434 10.3491 1.6665 9.88605 1.6665 9.3418C1.6665 8.65144 2.22615 8.0918 2.9165 8.0918C2.96288 8.0918 3.00867 8.09432 3.05374 8.09924L3.80397 4.72319C4.01579 3.76999 4.86124 3.0918 5.83769 3.0918H14.162C15.1384 3.0918 15.9839 3.76999 16.1957 4.72319L16.9459 8.09924ZM15.4221 8.92513L14.5687 5.08474C14.5264 4.8941 14.3573 4.75846 14.162 4.75846H5.83769C5.6424 4.75846 5.47331 4.8941 5.43095 5.08474L4.57753 8.92513H15.4221ZM5.4165 13.9251C6.10686 13.9251 6.6665 13.3655 6.6665 12.6751C6.6665 11.9848 6.10686 11.4251 5.4165 11.4251C4.72615 11.4251 4.1665 11.9848 4.1665 12.6751C4.1665 13.3655 4.72615 13.9251 5.4165 13.9251ZM14.5832 13.9251C15.2735 13.9251 15.8332 13.3655 15.8332 12.6751C15.8332 11.9848 15.2735 11.4251 14.5832 11.4251C13.8928 11.4251 13.3332 11.9848 13.3332 12.6751C13.3332 13.3655 13.8928 13.9251 14.5832 13.9251Z"
      fill="currentColor"
    />
  ),
});

export default CarIcon;
