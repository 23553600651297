import { TestConfig } from "yup";

import i18n from "translations/i18n";
import { CSV_EXTENSION } from "constants/fileExtensions";

const csvFileTypeTest = (): TestConfig<FileList | undefined> => ({
  name: "csvFileType",
  message: i18n.t("yup.mixed_unsupported_file_extension"),
  test: (value) => (value ? CSV_EXTENSION.includes(value?.[0]?.type) : false),
});

export default csvFileTypeTest;
