import React, { useEffect, useMemo } from "react";
import { Stack, Flex } from "@chakra-ui/react";

import useWizardStore, { INITIAL_STORE_STEP_INDEX } from "./store";
import WizardHeader from "./WizardHeader";
import { WizardProps } from "./types";

const Wizard: React.FC<WizardProps> = ({
  initialStepIndex = 0,
  steps,
  onStepClick,
}) => {
  const stepIndex = useWizardStore((store) => store.stepIndex);

  const step = useMemo(() => steps[stepIndex], [
    stepIndex,
    steps,
  ]);

  useEffect(() => {
    if (stepIndex !== INITIAL_STORE_STEP_INDEX) {
      return;
    }

    useWizardStore.setState({
      stepIndex: initialStepIndex,
      steps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!step?.id) {
    return null;
  }

  const Component = step.component;

  return (
    <Stack
      spacing={8}
      h="full"
      mb={6}
    >
      <WizardHeader
        steps={steps}
        onStepClick={onStepClick}
      />

      <Flex
        maxW="container.xl"
        alignSelf="center"
        w="full"
      >
        <Component />
      </Flex>
    </Stack>
  );
};

export default Wizard;
