import React from "react";
import { Textarea as ChakraTextArea } from "@chakra-ui/react";

import getErrorMessage from "utils/getErrorMessage";

import HelperTextContainer from "../HelperTextContainer";
import { TextareaProps } from "./types";
import {
  activeFieldCss,
  disabledFieldCss,
  invalidCss,
  placeholderCss,
  generalCss,
} from "./styles";

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((
  {
    validationMessage,
    showErrorMessage = true,
    errors,
    title,
    name,
    titleProps,
    ...props
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  return (
    <HelperTextContainer
      validationMessage={validationMessage}
      showErrorMessage={showErrorMessage}
      titleProps={titleProps}
      error={error}
      title={title}
      name={name}
    >
      <ChakraTextArea
        _hover={activeFieldCss}
        _focus={activeFieldCss}
        _disabled={disabledFieldCss}
        borderColor="gray.400"
        _placeholder={placeholderCss}
        _invalid={invalidCss}
        fontFamily="regular"
        isInvalid={!!error}
        padding="16px 12px"
        placeholder="aaaa"
        lineHeight="18px"
        css={generalCss}
        fontSize="14px"
        name={name}
        ref={ref}
        rows={2}
        {...props}
      />
    </HelperTextContainer>
  );
});

export default Textarea;
