import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const CardIcon: IconProps = createIcon({
  viewBox: "0 0 55.034 44.028",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M48.155,44.027H6.879a6.939,6.939,0,0,1-4.863-1.985A6.724,6.724,0,0,1,0,37.255V6.773A6.724,6.724,0,0,1,2.016,1.985,6.938,6.938,0,0,1,6.879,0H48.155a6.938,6.938,0,0,1,4.863,1.985,6.724,6.724,0,0,1,2.016,4.788V37.255a6.724,6.724,0,0,1-2.016,4.788A6.938,6.938,0,0,1,48.155,44.027ZM11.494,7.648h7.151a3.467,3.467,0,0,1,2.432.992,3.36,3.36,0,0,1,1.007,2.395v10.98a3.36,3.36,0,0,1-1.007,2.395,3.467,3.467,0,0,1-2.432.992H11.494a3.467,3.467,0,0,1-2.432-.992,3.36,3.36,0,0,1-1.007-2.395V11.049A3.36,3.36,0,0,1,9.062,8.655a3.467,3.467,0,0,1,2.432-.992Zm-.448,23.186H43.084a2.949,2.949,0,0,1,2.068.843,2.848,2.848,0,0,1,0,4.072,2.949,2.949,0,0,1-2.068.843H11.046a2.949,2.949,0,0,1-2.068-.843,2.848,2.848,0,0,1,0-4.072A2.949,2.949,0,0,1,11.046,30.834Zm32.073-17.5H32.187a2.914,2.914,0,0,1-1.977-.87,2.817,2.817,0,0,1,0-3.953,2.914,2.914,0,0,1,1.977-.87H43.119a2.935,2.935,0,0,1,1.139.186,2.9,2.9,0,0,1,.975.61,2.847,2.847,0,0,1,.655.937,2.807,2.807,0,0,1,0,2.229,2.847,2.847,0,0,1-.655.937,2.9,2.9,0,0,1-.975.61A2.934,2.934,0,0,1,43.119,13.329Zm0,11.533H32.187a2.916,2.916,0,0,1-2.046-.834,2.817,2.817,0,0,1,0-4.028,2.916,2.916,0,0,1,2.046-.834H43.119A2.917,2.917,0,0,1,45.165,20a2.818,2.818,0,0,1,0,4.028A2.917,2.917,0,0,1,43.119,24.862Z"
    />
  ),
});

export default CardIcon;
