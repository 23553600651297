import { FileTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";

const SELF_ASSESSMENT = "SELF_ASSESSMENT";

export type FileType = {
  type: FileTypeEnum | typeof SELF_ASSESSMENT;
  label: string;
  field: string;
  isVersionable: boolean;
  canUpload: boolean;
  canDelete: boolean;
}

/**
 * Defines possible document types for leads.
 */
const fileTypes: FileType[] = [
  {
    type: FileTypeEnum.HandlerContract,
    label: i18n.t("document_types.leasing_contract_dealer"),
    field: "handlerContracts",
    isVersionable: true,
    canUpload: true,
    canDelete: true,
  },
  {
    type: FileTypeEnum.ClientContract,
    label: i18n.t("document_types.leasing_contract_customer"),
    field: "clientContracts",
    isVersionable: true,
    canUpload: false,
    canDelete: true,
  },
  {
    type: FileTypeEnum.FinancialEvidence,
    label: i18n.t("document_types.self_salary_statements"),
    field: "financialEvidences",
    isVersionable: true,
    canUpload: false,
    canDelete: true,
  },
  {
    type: FileTypeEnum.PersonalDocument,
    label: i18n.t("document_types.passport"),
    field: "personalDocuments",
    isVersionable: true,
    canUpload: false,
    canDelete: true,
  },
  {
    type: FileTypeEnum.BusinessRegistration,
    label: i18n.t("document_types.business_registration"),
    field: "businessRegistrations",
    isVersionable: true,
    canUpload: false,
    canDelete: false,
  },
  {
    type: SELF_ASSESSMENT,
    label: i18n.t("document_types.self_declaration"),
    field: "selfAssessment",
    isVersionable: false,
    canUpload: false,
    canDelete: false,
  },
];

export default fileTypes;
