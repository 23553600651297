import React from "react";
import { Flex } from "@chakra-ui/react";

import { iconsMap } from "constants/icons";

import { CheckmarkProps } from "./types";

const Checkmark: React.FC<CheckmarkProps> = ({
  checked = false,
}) => (
  <Flex
    w="full"
    align="center"
    justify="center"
  >
    {
      checked
        ? (
          React.createElement(
            iconsMap.CheckIcon,
            {
              color: "secondary.500",
              fontSize: "lg",
            },
          )
        )
        : (
          <span>
            -
          </span>
        )
    }
  </Flex>
);

export default Checkmark;
