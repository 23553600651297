import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import {
  Checkbox,
  CheckboxGroup,
  VStack,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";

import { AccessoriesProps } from "./types";

const Accessories: React.FC<AccessoriesProps> = ({
  availableAccessories,
  pickedAccessories,
  control,
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="selectedAccessories"
      control={control}
      defaultValue={pickedAccessories}
      render={({
        field,
        fieldState,
      }) => (
        <CheckboxGroup
          {...field}
          {...fieldState}
        >
          <Text
            wordBreak="break-all"
            fontFamily="regular"
            fontWeight="600"
            fontSize="24px"
            color="black.500"
            pl={6}
            pb={6}
          >
            {t("custom_offer.steps.equipment.title")}
          </Text>

          <HStack
            alignItems="start"
            spacing="auto"
            flexDir="row"
          >
            {
              availableAccessories.map((accessoryCategory) => (
                <Box
                  boxShadow="default"
                  borderRadius={8}
                  bgColor="white"
                  paddingTop={7}
                  paddingX={6}
                  w="max"
                  key={accessoryCategory[0].category}
                >
                  <VStack w="max">
                    <Text
                      textTransform="uppercase"
                      textAlign="left"
                      textStyle="h3"
                      w="full"
                      mb={4}
                    >
                      {t(`custom_offer.steps.equipment.${accessoryCategory[0].category}`)}
                    </Text>
                    {
                      accessoryCategory.map(({ label, value }) => (
                        <Checkbox
                          key={value}
                          name={label}
                          value={value}
                          width="240px"
                          height="50px"
                          marginLeft="0px !important"
                          alignItems="baseline"
                        >
                          {label}
                        </Checkbox>
                      ))
                    }
                  </VStack>
                </Box>
              ))
            }
          </HStack>
        </CheckboxGroup>
      )}
    />
  );
};

export default Accessories;
