import { format } from "date-fns";

import getDateFnsLocale from "translations/dateFns";

/**
 * Time mask.
 * E.g. 12:00:00
 */
export const timeMask = "HH:mm:ss";

/**
 * Hours and minutes.
 * E.g. 12:00
 */
export const hoursAndMinutes = "HH:mm";

/**
 * Date mask.
 * E.g. 01 April 2020
 */
export const dateMask = "d MMMM yyyy";

/**
 * Full date mask.
 * E.g. 1 April 2020 - 12:00:00
 */
export const fullDateMask = `${dateMask} - ${timeMask}`;

/**
 * Mask with year, month, day, for backend usage.
 * E.g. 2020-12-25
 */
export const yearMonthDay = "yyyy-MM-dd";

/**
 * Mask for date pickers.
 * E.g. 25/12/2020
 */
export const datePickerDateMask = "dd/MM/yyyy";

/**
 * Format to display date in DatePicker input with time.
 * E.g. 09/08/2020 - 03:39 PM
 */
export const dateTimePickerFormat = "MM/dd/yyyy - p";

/**
 * Format to display date in DatePicker input without time.
 * E.g. 09/08/2020M
 */
export const datePickerFormat = "MM/dd/yyyy";

/**
 * Mask for short month name, day and hour.
 * E.g. May 3th - 08:00 PM
 */
export const monthNameDayHour = "MMM do - p";

/**
 * Mask for short month name and full year.
 * E.g. May 2023
 */
export const monthNameDay = "MMM yyyy";

/**
 * Mask for german date format
 * E.g. 15.03.2021
 */
export const germanDateFormat = "dd.MM.yyyy";

/**
 * Mask for german date format without day of the month
 * E.g. 03.2021
 */
export const germanDateFormatWithoutDay = "MM.yyyy";

/**
 * Mask for german date format but with short year
 * E.g. 15.03.21
 */
export const germanDateFormatShortYear = "dd.MM.yy";

/**
 * Format to display date in DatePicker input with time with german pattern.
 * E.g. 09.08.2020 - 03:39 PM
 */
export const germanDateTimePickerFormat = "dd.MM.yyyy - HH:mm";

/**
 * Mask for detailed time.
 * E.g. 08:00 PM
 */
export const detailedTime = "p";

/**
 * @param date the date.
 * Remove GMT hour to get a currect day.
 */
export const parseDateTime = (date: Date): Date => {
  const GET_GTM_IN_HOUR = date.getTimezoneOffset() / 60;

  const dateParsed = date.setHours(date.getHours() + GET_GTM_IN_HOUR);

  return new Date(dateParsed);
};

/**
 * Safely formats a date with a mask.
 * @param date the date.
 * @param mask the mask.
 * @param fallback fallback return value if date is invalid.
 */
export const formatDate = (
  date: Date | string,
  mask = yearMonthDay,
  fallback = "",
): string => {
  if (!date) {
    return fallback;
  }

  return format(new Date(date), mask, {
    locale: getDateFnsLocale(),
  });
};

/**
 * Safely formats a date with a mask.
 * @param date the date of the month formatted from the second day to fix a Date API issue
 * @param mask the mask.
 * @param fallback fallback return value if date is invalid.
 */
export const formatWithParseDate = (
  date: Date | string,
  mask = yearMonthDay,
  fallback = "",
): string => {
  if (!date) {
    return fallback;
  }

  const dateFormater = new Date(date);

  return format(
    parseDateTime(dateFormater),
    mask,
    {
      locale: getDateFnsLocale(),
    },
  );
};

/**
 * Safely formats a date to fullDate mask.
 * @param date the date.
 */
export const formatFullDate = (
  date: Date | string,
): string => formatDate(date, fullDateMask);

/**
 * Safely formats a date to a time mask.
 * @param date the date.
 */
export const formatTime = (
  date: Date | string,
): string => formatDate(date, timeMask);
