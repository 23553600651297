import { OfferImportLog } from "generated/graphql";

export interface OfferImportLogsTableProps {
  logs: OfferImportLog[];
  loading?: boolean;
}

export enum ImportLogsSortFieldsEnum {
  ImportedAt = "imported_at"
}
