import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import configureSentry from "settings/sentry";
import configureGTM from "settings/gtm";

import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/app.scss";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

import "translations/i18n";

import App from "./App";

configureSentry();
configureGTM();

ReactDOM.render(<App />, document.getElementById("root"));
