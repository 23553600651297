import React from "react";
import { Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import {
  KILOMETERS,
} from "constants/units";
import { germanNumberMask } from "constants/inputMasks";
import { OfferFuelTypeEnum } from "generated/graphql";

import { InnerSection } from "../../../../InnerSections";
import { FieldsCommonProps } from "..";
import { InputField } from "../shared/InputField";

interface Props extends FieldsCommonProps {
  fuelType?: OfferFuelTypeEnum;
}

export const ElectricRange = ({
  register,
  formState,
  fuelType,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const translationKeySuffix = fuelType === OfferFuelTypeEnum.Electric ? "" : "_eaer";

  return (
    <InnerSection label={t("custom_offer.steps.standard_data.fuel_card.electric_range")}>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        <InputField
          {...register?.("rangeKm")}
          name="rangeKm"
          title={t(`custom_offer.steps.standard_data.fuel_card.electric_range${translationKeySuffix}`)}
          placeholder={t(`custom_offer.steps.standard_data.fuel_card.insert_electric_range${translationKeySuffix}`)}
          measurementUnit={KILOMETERS}
          errors={formState?.errors}
          mask={germanNumberMask}
        />
      </Grid>
    </InnerSection>
  );
};
