import React from "react";
import { IconButton } from "@chakra-ui/react";

import { CarouselArrowProps } from "components/Images/types";
import RightArrowIcon from "components/Icons/RightArrowIcon";
import LeftArrowIcon from "components/Icons/LeftArrowIcon";

const CarouselArrow: React.FC<CarouselArrowProps> = ({
  variant = "next",
  ...props
}) => {
  const icon = variant === "next" ? RightArrowIcon : LeftArrowIcon;

  return (
    <IconButton
      icon={React.createElement(icon, { color: "white" })}
      className={`swipe-button-${variant}`}
      aria-label={`${variant}-button`}
      colorScheme="secondary"
      borderRadius="40px"
      zIndex="docked"
      {...props}
    />
  );
};

export default CarouselArrow;
