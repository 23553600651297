import React from "react";
import {
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import { numeralLocalized } from "utils/currencyFormat";

import { NoticeAlertProps } from "./types";
import { Rates } from "../types";

const NoticeAlert: React.FC<NoticeAlertProps> = ({
  data,
  isOpen,
  onClose,
  handleSubmit,
  textButton,
}) => {
  const { t } = useTranslation();

  const cancelRef = React.useRef(null);

  const printNotice = data.map((item) => (
    <Text>
      {`${numeralLocalized(item.mileageKm || 0, "0,0")} Km - ${item.termMonths}
      ${t("components.modals.edit_leasing_rates_modal.months")}: < ${Rates.Limit}€`}
    </Text>
  ));

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("warnings.warning")}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t("components.modals.edit_leasing_rates_modal.warning_notice")}
            {printNotice}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" label={t("components.buttons.cancel")} onClick={onClose} />
            <Button
              ml={3}
              onClick={handleSubmit}
              label={t(textButton)}
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default NoticeAlert;
