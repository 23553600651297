import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@chakra-ui/react";

import { OfferFuelTypeEnum } from "generated/graphql";
import { POWER_CONSUMPTION_COMBINED } from "constants/units";

import { InnerSection } from "../../../../InnerSections";
import { InputField } from "../shared/InputField";
import { FieldsCommonProps } from "..";

interface Props extends FieldsCommonProps {
  fuelType: OfferFuelTypeEnum;
  isPluginHybrid: boolean;
}

interface ElectricityConsumptionInputsProps extends FieldsCommonProps {
  translationsPrefix: string;
  measurementUnit: string;
  isPluginHybrid: boolean;
  fuelType: OfferFuelTypeEnum;
}

const ElectricityConsumptionInputs = React.memo(({
  measurementUnit,
  translationsPrefix,
  isPluginHybrid,
  fuelType,
  register,
  formState,
}: ElectricityConsumptionInputsProps): JSX.Element => {
  const { t } = useTranslation();

  const shouldRenderWeightedConsumptionField = isPluginHybrid
    && [OfferFuelTypeEnum.HybridDiesel, OfferFuelTypeEnum.Hybrid].includes(fuelType);

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
      {
        shouldRenderWeightedConsumptionField && (
          <InputField
            {...register?.("wltpPowerConsumptionCombinedWeighted")}
            name="wltpPowerConsumptionCombinedWeighted"
            errors={formState?.errors}
            title={t(`${translationsPrefix}.weighted_combined_electricity_consumption`)}
            placeholder={t(`${translationsPrefix}.insert_weighted_combined_electricity_consumption`)}
            measurementUnit={measurementUnit}
            isRequired
          />
        )
      }

      <InputField
        {...register?.("wltpPowerConsumptionCombined")}
        name="wltpPowerConsumptionCombined"
        errors={formState?.errors}
        title={t(`${translationsPrefix}.combined_electricity_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_combined_electricity_consumption`)}
        measurementUnit={measurementUnit}
        isRequired={!shouldRenderWeightedConsumptionField}
      />

      <InputField
        {...register?.("wltpPowerConsumptionCity")}
        name="wltpPowerConsumptionCity"
        errors={formState?.errors}
        title={t(`${translationsPrefix}.city_electricity_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_city_electricity_consumption`)}
        measurementUnit={measurementUnit}
      />

      <InputField
        {...register?.("wltpPowerConsumptionSuburban")}
        name="wltpPowerConsumptionSuburban"
        errors={formState?.errors}
        title={t(`${translationsPrefix}.suburban_electricity_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_suburban_electricity_consumption`)}
        measurementUnit={measurementUnit}
      />

      <InputField
        {...register?.("wltpPowerConsumptionRural")}
        name="wltpPowerConsumptionRural"
        errors={formState?.errors}
        title={t(`${translationsPrefix}.rural_electricity_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_rural_electricity_consumption`)}
        measurementUnit={measurementUnit}
      />

      <InputField
        {...register?.("wltpPowerConsumptionHighway")}
        name="wltpPowerConsumptionHighway"
        errors={formState?.errors}
        title={t(`${translationsPrefix}.highway_electricity_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_highway_electricity_consumption`)}
        measurementUnit={measurementUnit}
      />
    </Grid>
  );
});

export const ElectricityConsumption = ({
  isPluginHybrid,
  fuelType,
  formState,
  register,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const translationsPrefix = "custom_offer.steps.standard_data.fuel_card";

  return (
    <InnerSection label={t(`${translationsPrefix}.electricity_consumption`)}>
      <ElectricityConsumptionInputs
        isPluginHybrid={isPluginHybrid}
        fuelType={fuelType}
        translationsPrefix={translationsPrefix}
        measurementUnit={POWER_CONSUMPTION_COMBINED}
        formState={formState}
        register={register}
      />
    </InnerSection>
  );
};
