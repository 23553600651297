import { OptionProps } from "components/FormComponents/Inputs/Selects/BaseSelect/types";
import { CustomerTypeEnum } from "generated/graphql";

const makeCustomerTypeOptions = (): OptionProps[] | [] => (
  Object.keys(CustomerTypeEnum).map(key => ({
    label: key,
    value: CustomerTypeEnum[key],
  })));

export default makeCustomerTypeOptions;
