import React from "react";
import { Tooltip } from "@chakra-ui/react";

import RoundedExclamationMarkIcon from "components/Icons/RoundedExclamationMarkIcon";
import ClockIcon from "components/Icons/ClockIcon";
import ErrorIcon from "components/Icons/ErrorIcon";
import i18n from "translations/i18n";
import { LeadRejectionStatusEnum } from "generated/graphql";

import { LeadRejectionStatusTagProps } from "./types";

const REJECTION_STATUS_COLORS_MAPPING = {
  [LeadRejectionStatusEnum.Approved]: {
    color: "error.500",
    Icon: ErrorIcon,
    label: i18n.t("rejection_status.approved"),
  },
  [LeadRejectionStatusEnum.NotApproved]: {
    color: "gray.500",
    Icon: RoundedExclamationMarkIcon,
    label: i18n.t("rejection_status.not_approved"),
  },
  [LeadRejectionStatusEnum.Requested]: {
    color: "gray.500",
    Icon: ClockIcon,
    label: i18n.t("rejection_status.requested"),
  },
};

const LeadRejectionStatusTag: React.FC<LeadRejectionStatusTagProps> = ({
  rejectionStatus,
}) => {
  const {
    Icon,
    label,
    color,
  } = REJECTION_STATUS_COLORS_MAPPING[rejectionStatus];

  return (
    <Tooltip
      borderRadius="8px"
      bgColor="gray.500"
      fontWeight="700"
      placement="top"
      fontSize="14px"
      label={label}
      color="white"
      p={2}
    >
      <Icon
        color={color}
        w={5}
        h={5}
      />
    </Tooltip>
  );
};

export default LeadRejectionStatusTag;
