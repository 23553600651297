/**
 * Downloads an url and opens it in another tab.
 *
 * @param {string} href A url string (ex: http://www.domain.com/a_file_here.ext)
 */
export const downloadLink = (href: string): void => {
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = href;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
