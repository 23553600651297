import React from "react";
import { Flex, useToast } from "@chakra-ui/react";

import {
  Lead,
  LeadStatusEnum,
  useGetCheckoutLeadsLazyQuery,
  GetCheckoutLeadsQueryVariables,
} from "generated/graphql";
import { availableCheckoutLeadStatus } from "constants/availableLeadsStatus";
import { usePagination } from "contexts/pagination";
import { Container } from "components/Container";
import { Filters } from "hooks/useFilters/types";
import useFilters from "hooks/useFilters";
import ExportLeadsButton from "views/Leads/components/ExportLeadsButton";

import LeadsTable from "./LeadsTable";

const CheckoutLeads: React.FC = () => {
  const newToast = useToast();

  const { paginationLimit } = usePagination();

  const [variables, setVariables] = React.useState<GetCheckoutLeadsQueryVariables>({});

  const filters = [
    Filters.Dealer,
    Filters.VehicleType,
    Filters.Vehicle,
    Filters.Search,
    Filters.Period,
    Filters.LeadStatus,
    Filters.Salesperson,
  ];

  const initialLeadStatusValues = React.useMemo(() => [
    LeadStatusEnum.OfferSent,
    LeadStatusEnum.Unprocessed,
    LeadStatusEnum.Contacted,
    LeadStatusEnum.StartedCheckout,
    LeadStatusEnum.FinishedCheckout,
    LeadStatusEnum.ContractSent,
    LeadStatusEnum.BankRequest,
    LeadStatusEnum.ContractSigned,
  ], []);

  const {
    renderFilters,
    dealerId,
    brandName,
    modelGroup,
    vehicleType,
    search,
    period,
    leadStatus,
    salesperson,
    dirtyFields,
  } = useFilters(
    filters,
    {
      LeadStatus: {
        initialValues: initialLeadStatusValues,
        availableLeadStatus: availableCheckoutLeadStatus,
      },
    },
  );

  React.useEffect(() => {
    const isValidPeriod = period && !!period[1];

    setVariables({
      search,
      dealerId,
      period: isValidPeriod ? {
        startDate: isValidPeriod && period?.[0],
        endDate: isValidPeriod && period?.[1],
      } : undefined,
      where: {
        modelGroup,
        status: (dirtyFields.leadStatus || leadStatus?.length)
          ? leadStatus
          : initialLeadStatusValues,
        vehicleType: vehicleType && [vehicleType],
        assignee: salesperson,
        brand: brandName,
        isCheckoutLead: true,
      },
    });
  }, [
    paginationLimit,
    dealerId,
    search,
    modelGroup,
    brandName,
    vehicleType,
    period,
    salesperson,
    initialLeadStatusValues,
    dirtyFields,
    leadStatus,
  ]);

  const [listCheckoutLeads, { loading, data }] = useGetCheckoutLeadsLazyQuery({
    variables: {
      ...variables,
      limit: paginationLimit,
    },
  });

  React.useEffect(() => {
    listCheckoutLeads()
      .catch((error) => {
        if (error) {
          newToast({
            title: error?.name,
            description: error?.message,
            status: "error",
          });
        }
      });
  }, [
    newToast,
    variables,
    paginationLimit,
    listCheckoutLeads,
  ]);

  return (
    <Container
      paddingBottom="0"
      paddingTop="0"
      paddingX="0"
      alignCenter
      padding="0"
    >

      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        padding="6"
      >
        {renderFilters}

        <ExportLeadsButton
          variables={variables}
        />
      </Flex>

      <LeadsTable
        data={data?.leads as Lead[] || []}
        isLoading={loading}
      />
    </Container>
  );
};

export default CheckoutLeads;
