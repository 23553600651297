import TagManager from "react-gtm-module";

/**
 * Google Tag Manager ID.
 */
const gtmId = process.env.REACT_APP_GTM_ID as string;

/**
 * Configures Google Tag Manager in the application.
 */
const configureGTM = (): void => {
  if (process.env.NODE_ENV === "production") {
    TagManager.initialize({
      gtmId,
    });
  }
};

export default configureGTM;
