import React, { FC } from "react";
import { Td, TableCellProps } from "@chakra-ui/react";

export const TableDataCell: FC<TableCellProps> = ({
  children,
  ...props
}) => (
  <Td
    textTransform="capitalize"
    fontFamily="regular"
    color="black.500"
    fontWeight="normal"
    lineHeight="1.6"
    fontSize="md"
    py="10px"
    {...props}
  >
    {children}
  </Td>
);
