import { gql } from "@apollo/client";

export const CHECKOUT_LEAD_DETAILS_REFETCH_QUERIES = [
  "GetCheckoutLeadDocuments",
];

export const LEAD_REFETCH_QUERIES = [
  "GetLeadDetails",
];

export const GET_CHECKOUT_LEADS_REFETCH_QUERIES = [
  "GetCheckoutLeads",
];

export const GET_CLASSIC_LEADS_REFETCH_QUERIES = [
  "GetClassicLeads",
];

const updateLeadBySellerMutation = gql`
  mutation UpdateLeadBySeller (
    $id: String!,
    $params: UpdateLeadBySellerParams!
  ) {
    updateLeadBySeller(id: $id, params: $params) {
      id
    }
  }
`;

export default updateLeadBySellerMutation;
