import React, { useCallback } from "react";
import {
  Flex, HStack, Stack, Text, useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "components/Button";
import Select from "components/FormComponents/Inputs/Selects/Select";
import Checkbox from "components/FormComponents/Checkbox";
import importCalculatorDataSchema, {
  ImportCalculatorDataSchema,
} from "settings/yup/schemas/importCalculatorDataSchema";
import {
  useImportDiscountsMutation,
} from "generated/graphql";
import DealerSelect from "components/FormComponents/Inputs/Selects/DealerSelect";

import { ImportCalculatorDataModalProps } from "./types";
import UploadButton from "./UploadButton";
import makeCustomerTypeOptions from "./makeCustomerTypeOptions";
import { IMPORT_DISCOUNTS_MUTATION_REFETCH_QUERIES } from "./graphql/importDiscountsFromCsvMutation";

const ImportCalculatorDataModal: React.FC<ImportCalculatorDataModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();
  const newToast = useToast();

  const {
    handleSubmit,
    formState,
    register,
    control,
    trigger,
  } = useForm<ImportCalculatorDataSchema>({
    resolver: yupResolver(importCalculatorDataSchema),
    mode: "onChange",
  });

  const [importDiscounts, { loading }] = useImportDiscountsMutation({
    awaitRefetchQueries: true,
    refetchQueries: IMPORT_DISCOUNTS_MUTATION_REFETCH_QUERIES,
  });

  const handleCancel = useCallback(() => {
    hideModal();
  }, [
    hideModal,
  ]);

  const onSubmit = useCallback((values: ImportCalculatorDataSchema) => {
    if (!values.file) {
      return;
    }

    componentProps?.callback?.(values);

    importDiscounts({
      variables: {
        csvFile: values.file?.[0],
        customerType: values.customerType,
        dealerId: values.dealer,
        shouldCreateBrandDiscounts: values.brandDiscounts,
        shouldCreateDealerDiscounts: values.dealerDiscounts,
      },
    })
      .then(() => {
        newToast({
          title: t("components.modals.import_calculator_data_modal.discounts_has_been_imported_successfully"),
          status: "success",
        });
      })
      .catch(error => (
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
        })
      ))
      .finally(() => {
        hideModal();
      });
  }, [
    importDiscounts,
    componentProps,
    hideModal,
    newToast,
    t,
  ]);

  const customerTypes = makeCustomerTypeOptions();

  const dealerDiscounts = register("dealerDiscounts");

  const handleDealerDiscountsChange = useCallback((event) => {
    dealerDiscounts.onChange(event);

    trigger("dealer");
  }, [
    dealerDiscounts,
    trigger,
  ]);

  return (
    <Flex
      flexDir="column"
      p="6"
    >
      <Text
        lineHeight="24px"
        fontSize="20px"
        textStyle="h3"
        mb={6}
      >
        {t("components.modals.import_calculator_data_modal.title")}
      </Text>

      <Stack spacing="40px">
        <Controller
          control={control}
          name="dealer"
          render={({
            field,
            fieldState,
          }) => (
            <DealerSelect
              {...field}
              {...fieldState}
              onlyCalculatorDealers
              errors={formState.errors}
              title={t("components.selects.dealer")}
            />
          )}
        />

        <Controller
          name="customerType"
          control={control}
          render={({
            field,
            fieldState,
          }) => (
            <Select
              {...field}
              {...fieldState}
              errors={formState.errors}
              options={customerTypes}
              title={t("components.selects.customer_type")}
            />
          )}
        />

        <HStack
          w="fit-content"
          spacing={6}
        >
          <Checkbox
            {...dealerDiscounts}
            onChange={handleDealerDiscountsChange}
          >
            {t("components.modals.import_calculator_data_modal.set_dealer_discounts")}
          </Checkbox>

          <Checkbox {...register("brandDiscounts")}>
            {t("components.modals.import_calculator_data_modal.set_brand_discounts_global")}
          </Checkbox>
        </HStack>

        <UploadButton
          {...register("file")}
          errors={formState.errors}
        />

        <Flex justifyContent="space-between">
          <Button
            label={t("components.buttons.cancel")}
            onClick={handleCancel}
            variant="outline"
          />

          <Button
            label={t("components.modals.import_calculator_data_modal.start_upload")}
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
          />
        </Flex>
      </Stack>
    </Flex>
  );
};

export default ImportCalculatorDataModal;
