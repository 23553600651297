import { CSSProperties } from "react";

export const swiperCss: CSSProperties = {
  padding: "0px 50px",
  position: "relative",
  width: "100%",
};

export const slideCss: CSSProperties = {
  justifyContent: "center",
  display: "flex",
};
