import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  HStack,
  Stack,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import useNavigate from "hooks/useNavigate";
import { OFFERS_PAGE_PATH } from "routes";
import { useModal } from "contexts/modal";
import { UpdateFixedLeasingRateParams } from "generated/graphql";
import useCustomOfferStore from "views/CustomOffer/store";
import Button from "components/Button";
import NoticeAlert from "components/Modals/EditLeasingRatesModal/NoticeAlert";
import useWizardStore from "components/Wizard/store";
import RightArrowIcon from "components/Icons/RightArrowIcon";
import LeftArrowIcon from "components/Icons/LeftArrowIcon";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import PreviewPublishModal from "components/Modals/PreviewPublishModal";
import { Rates } from "components/Modals/EditLeasingRatesModal/types";
import { validateRates } from "components/Modals/EditLeasingRatesModal/LeasingRatesTable";

import { CustomOfferFooterProps } from "./types";

const FormWizardFooter: React.FC<CustomOfferFooterProps> = ({
  missingInformation,
  showGoBackButton,
  onSaveAsDraft,
  onSaveProgress,
  onContinue,
  onPublish,
  isLoading = false,
  canPublish,
}) => {
  const [loading, setLoading] = useState<boolean>(isLoading);
  const [isSavingDraft, setIsSavingDraft] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [showModal] = useModal();

  const [invalidTax, setInvalidTax] = useState<UpdateFixedLeasingRateParams[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [shouldPublish, setShouldPublish] = useState(false);

  const newToast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const history = useHistory();

  const {
    navigateToPreviousStep,
    navigateToNextStep,
    getIsFirstStep,
    getIsLastStep,
    getIsLoaded,
  } = useWizardStore((store) => ({
    navigateToPreviousStep: store.navigateToPreviousStep,
    navigateToNextStep: store.navigateToNextStep,
    getIsFirstStep: store.getIsFirstStep,
    getIsLastStep: store.getIsLastStep,
    getIsLoaded: store.getIsLoaded,
    stepIndex: store.stepIndex,
  }));

  const isFirstStep = getIsFirstStep();
  const isLastStep = getIsLastStep();
  const isLoaded = getIsLoaded();

  const {
    trigger,
    resetStore,
    getValues,
    wizardStepStore,
  } = useCustomOfferStore((store) => ({
    getValues: store.wizardStepStore?.getValues,
    trigger: store.wizardStepStore?.trigger,
    wizardStepStore: store.wizardStepStore,
    resetStore: store.resetStore,
  }));

  const handleGoToNextStep = useCallback(async () => {
    setLoading(true);
    await onSaveProgress?.();
    await onContinue?.();

    if (isLastStep) {
      return;
    }
    setLoading(false);
    navigateToNextStep();
  }, [
    onSaveProgress,
    navigateToNextStep,
    onContinue,
    isLastStep,
  ]);

  const handleValidate = useCallback((callback?: () => void) => {
    if (!trigger) {
      callback?.();
      return;
    }

    trigger()
      .then((isValid) => {
        if (!isValid) {
          newToast({
            title: t("errors.please_fill_all_fields"),
            isClosable: true,
            status: "error",
          });

          return;
        }

        callback?.();
      })
      .catch((error) => {
        newToast({
          title: t("errors.please_fill_all_fields"),
          description: error.message,
          isClosable: true,
          status: "error",
        });
      });
  }, [
    newToast,
    trigger,
    t,
  ]);

  const handleOnContinue = useCallback(() => {
    handleValidate(handleGoToNextStep);
    onClose();
  }, [
    handleGoToNextStep,
    handleValidate,
    onClose,
  ]);

  const handleGoBack = useCallback(async () => {
    await onSaveProgress?.();
    navigateToPreviousStep();
  }, [
    onSaveProgress,
    navigateToPreviousStep,
  ]);

  const handleSaveAsDraft = useCallback(() => {
    setIsSavingDraft(true);

    onSaveAsDraft?.()
      .then(() => {
        history.push(OFFERS_PAGE_PATH);
        resetStore();
      })
      .finally(() => {
        setIsSavingDraft(false);
      });
  }, [
    onSaveAsDraft,
    resetStore,
    history,
  ]);

  const handleConfirmPublish = useCallback(() => {
    setIsPublishing(true);

    onPublish?.()
      .then(() => {
        history.push(OFFERS_PAGE_PATH);
        resetStore();
      })
      .finally(() => {
        setIsPublishing(false);
      });
  }, [
    resetStore,
    onPublish,
    history,
  ]);

  const handlePublish = useCallback(async () => {
    onClose();

    handleValidate(async () => {
      await onContinue?.();

      showModal({
        componentProps: {
          onConfirm: handleConfirmPublish,
        },
        closeOnOverlayClick: false,
        component: PreviewPublishModal,
        size: "2xl",
      });
    });
  }, [
    handleConfirmPublish,
    handleValidate,
    onContinue,
    showModal,
    onClose,
  ]);

  const handleCancel = useCallback(() => {
    showModal({
      componentProps: {
        description: t("components.modals.cancel_custom_offer_editing.description"),
        title: t("components.modals.cancel_custom_offer_editing.title"),
        onConfirm: () => {
          navigate(OFFERS_PAGE_PATH)();

          resetStore();
        },
      },
      closeOnOverlayClick: false,
      component: ConfirmationModal,
      size: "2xl",
    });
  }, [
    resetStore,
    showModal,
    navigate,
    t,
  ]);

  const isContinueButtonDisabled = (isLoaded && isLastStep);

  const shouldShowGoBackButton = (
    showGoBackButton
    && isLoaded
    && !isFirstStep
  );

  const getInvalidRates = useCallback(() => {
    if (!getValues) return [];

    const leasingRates = getValues("leasingRates") ?? [];

    const { invalidRates } = validateRates({
      data: leasingRates,
      validationType: Rates.Limit,
    });

    return invalidRates;
  }, [getValues]);

  const handleValidateForm = useCallback((callback, publish = false) => {
    const invalidValues = getInvalidRates();

    if (!invalidValues.length) return callback();

    setInvalidTax(invalidValues);

    setShouldPublish(publish);

    return onOpen();
  }, [getInvalidRates, setInvalidTax, onOpen]);

  const renderPublishButton = (): JSX.Element => (
    <Tooltip
      label={missingInformation?.join(", ")}
      placement="top"
      shouldWrapChildren
    >
      <Button
        label={t("components.buttons.publish_offer")}
        onClick={() => handleValidateForm(handlePublish, true)}
        isLoading={isPublishing}
        colorScheme="success"
        iconPosition="right"
        fontSize="sm"
        isDisabled={!canPublish || !wizardStepStore?.isValid}
        size="md"
      />
    </Tooltip>
  );

  const renderContinueButton = (): JSX.Element => (
    <Button
      label={t("components.buttons.proceed")}
      onClick={() => handleValidateForm(handleOnContinue)}
      Icon={RightArrowIcon}
      isLoading={loading}
      iconPosition="right"
      fontSize="sm"
      size="sm"
    />
  );

  return (
    <Flex
      flexDir="column"
      w="full"
    >
      <Stack
        justifyContent="flex-end"
        alignItems="center"
        maxW="container.xl"
        isInline
        m="auto"
        w="full"
        pb={6}
        spacing={6}
      >
        {
          shouldShowGoBackButton && (
            <Button
              label={t("components.buttons.go_back")}
              isDisabled={isLoading}
              onClick={handleGoBack}
              Icon={LeftArrowIcon}
              variant="outline"
              fontSize="sm"
              size="sm"
            />
          )
        }

        {
          isContinueButtonDisabled
            ? renderPublishButton()
            : renderContinueButton()
        }

      </Stack>

      <Flex bgColor="white">
        <HStack
          justifyContent="space-between"
          maxW="container.xl"
          bgColor="white"
          isInline
          m="auto"
          w="full"
          py={4}
        >
          <Button
            label={t("components.buttons.cancel")}
            onClick={handleCancel}
            isDisabled={isLoading}
            variant="contained"
            fontSize="sm"
            color="primary.600"
            size="md"
            boxShadow="md"
          />

          <HStack spacing={4}>
            <Button
              label={t("components.buttons.save_draft_and_close")}
              onClick={handleSaveAsDraft}
              isLoading={isSavingDraft}
              isDisabled={!wizardStepStore?.isValid}
              colorScheme="gray"
              variant="contained"
              color="black.500"
              fontSize="sm"
              boxShadow="md"
              border={isSavingDraft ? "1px" : "none"}
              px={3}
            />

            {!isContinueButtonDisabled && renderPublishButton()}
          </HStack>
        </HStack>
      </Flex>

      <NoticeAlert
        onClose={onClose}
        handleSubmit={shouldPublish ? handlePublish : handleOnContinue}
        isOpen={isOpen}
        data={invalidTax}
        textButton={shouldPublish ? "components.buttons.publish_offer" : "components.buttons.proceed"}
      />
    </Flex>
  );
};

export default FormWizardFooter;
