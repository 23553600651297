import React from "react";
import { FormControl } from "@chakra-ui/react";

import FieldErrorMessage from "../FieldErrorMessage";
import { HelperTextContainerProps } from "./types";
import FormLabel from "./FormLabel";

const HelperTextContainer: React.FC<HelperTextContainerProps> = ({
  validationMessage,
  showErrorMessage = true,
  titleProps,
  children,
  error,
  title,
  titleCss,
  name,
  formControlCss,
  hasCustomErrorCss,
}) => (
  <FormControl css={formControlCss}>
    {
      title && (
        <FormLabel
          {...titleProps}
          htmlFor={name}
          title={title}
          titleCss={titleCss}
        />
      )
    }

    {children}

    {
      showErrorMessage && (
        <FieldErrorMessage
          validationMessage={validationMessage}
          error={error}
          hasCustomErrorCss={hasCustomErrorCss}
          mt="8px"
        />
      )
    }
  </FormControl>
);

export default HelperTextContainer;
