import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
  UseFormReset,
  FormState,
} from "react-hook-form";

import { ModalComponentProps } from "contexts/modal/types";
import { OfferRow } from "views/Offers/types";
import { EditLeasingRatesSchema } from "settings/yup/schemas/editLeasingRatesSchema";
import {
  FixedLeasingRate,
  FixedLeasingRatesPriceTypeEnum,
  SpecialCondition,
  OfferSpecialCondition,
  UpdateFixedLeasingRateParams,
  CustomerTypeEnum,
} from "generated/graphql";
import { FinanceAndCostsProps } from "views/CustomOffer/components/Steps/CustomOfferSteps/FinanceAndCosts/types";
import { EditCalculatorOfferSchema } from "settings/yup/schemas/editCalculatorOfferSchema";
import { DeliveryPricesDefaultValues } from "utils/getDeliveryPricesDefaultValues";

import { LeasingColumn } from "../EditVehicleModal/types";

export interface RegisterProps {
  register: UseFormRegister<EditLeasingRatesSchema>;
}

export type EditLeasingRatesModalProps = ModalComponentProps<{
  callback?: () => void;
  data: OfferRow;
  specialConditionsData: SpecialCondition[];
}>

export interface EditLeasingRateFormProps extends RegisterProps {
  handleSubmit: () => void;
  handleCancel?: () => void;
  submitState: boolean;
  setSubmitState: React.Dispatch<React.SetStateAction<boolean>>;
  errors: FieldErrors;
  offer?: OfferRow;
  specialConditions?: SpecialCondition[];
  setValue: UseFormSetValue<EditLeasingRatesSchema>;
  getValues: UseFormGetValues<EditLeasingRatesSchema>;
  reset: UseFormReset<EditLeasingRatesSchema>;
  formState: FormState<EditLeasingRatesSchema>;
}

export interface LeasingRateTable {
  fixedLeasingRatesPriceType: FixedLeasingRatesPriceTypeEnum;
  fixedLeasingRates: FixedLeasingRate[];
}

export interface LeasingRatesTableProps {
  title?: string;
  data: LeasingRateTable;
  loading?: boolean;
  setValue: UseFormSetValue<EditLeasingRatesSchema>;
  getValues: UseFormGetValues<EditLeasingRatesSchema>;
  reset: UseFormReset<EditLeasingRatesSchema>;
  defaultLeasingRates?: FixedLeasingRate[];
  formState: FormState<EditLeasingRatesSchema>;
  register: UseFormRegister<EditLeasingRatesSchema>;
  handleChange?: (values: UpdateFixedLeasingRateParams[]) => void;
}

export interface FinancingDataProps {
  setSubmitState?: React.Dispatch<React.SetStateAction<boolean>>;
  offer?: FinanceAndCostsProps["defaultFormValues"];
  specialConditions?: SpecialCondition[];
  setValue: UseFormSetValue<EditLeasingRatesSchema>;
}

export interface DeliveryPricesProps {
  setSubmitState?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultValues?: DeliveryPricesDefaultValues[];
  setValue?: UseFormSetValue<EditLeasingRatesSchema> | UseFormSetValue<EditCalculatorOfferSchema>;
}

export interface MileagePricesProps {
  setSubmitState?: React.Dispatch<React.SetStateAction<boolean>>;
  offer?: FinanceAndCostsProps["defaultFormValues"];
  setValue: UseFormSetValue<EditLeasingRatesSchema>;
}

export type LeasingColumns = LeasingColumn[];

export enum SpecialConditionsEnum {
  bafaEnvironmentalBonus = "bafa_environmental_bonus"
}
export interface UpdateSpecialConditionsPayload {
  specialConditionId?: number;
  description?: string;
}
export interface OfferSpecialConditions {
  specialCondition: OfferSpecialCondition["specialCondition"];
  displayedDescription: OfferSpecialCondition["displayedDescription"];
}

export interface LeasingRateTableFieldProperties {
  id: string | null;
  cellName: string;
  termMonths: string;
  mileageKm: string;
}

export type Leasing = {
  id?: number;
  type?: CustomerTypeEnum;
  termMonths?: number;
  mileageKm?: number;
}

export type FixedLeasingRates = Leasing[];

export enum Rates {
  Limit = 50,
  Invalid = 0
}
