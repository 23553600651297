export const toggleButtonCss = {
  display: "flex",
  maxHeight: "34px",
  border: "solid 1px",
  padding: "8px 36px",
  borderRadius: "36px",
  fontWeight: "bold",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    filter: "brightness(0.9)",
  },
};

export const isSelectedCss = {
  backgroundColor: "secondary.500",
  color: "white",
};

export const isNotSelectedCss = {
  backgroundColor: "white",
  color: "secondary.500",
};
