import React from "react";
import {
  Button as ChakraButton,
  Text,
} from "@chakra-ui/react";

import { ButtonProps } from "./types";

const Button: React.FC<ButtonProps> = ({
  label,
  Icon,
  iconPosition,
  textStyle,
  ...props
}) => {
  let buttonOptions = {};

  if (Icon) {
    const ButtonIcon = React.createElement(Icon);

    buttonOptions = iconPosition === "right"
      ? { rightIcon: ButtonIcon }
      : { leftIcon: ButtonIcon };
  }

  return (
    <ChakraButton
      {...buttonOptions}
      {...props}
    >
      <Text as="span" textStyle={textStyle}>
        {label}
      </Text>
    </ChakraButton>
  );
};

Button.defaultProps = {
  label: "Button",
  colorScheme: "primary",
  variant: "solid",
  size: "md",
  isDisabled: false,
};

export default Button;
