import React from "react";
import {
  Flex,
  Stack,
  Button as CKButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Input from "components/FormComponents/Inputs/Input";
import Button from "components/Button";
import { PasswordField } from "components/FormComponents/Inputs/PasswordField";
import { handleOnKeyPress } from "utils/handleOnKeyPress";
import { FORGOT_PASSWORD_PATH } from "routes";

import { LoginFormProps } from "./types";

export function LoginForm({
  isLoading = false,
  handleSubmit,
  register,
  errors,
}: LoginFormProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Stack spacing="4" mb={1}>
        <Input
          onKeyPress={handleOnKeyPress(handleSubmit)}
          title={t("forms.email")}
          {...register("email")}
          autoComplete="email"
          errors={errors}
          type="email"
        />

        <PasswordField
          onKeyPress={handleOnKeyPress(handleSubmit)}
          title={t("forms.password")}
          {...register("password")}
          errors={errors}
        />
      </Stack>

      <CKButton
        colorScheme="blue"
        variant="link"
        onClick={() => history.push(FORGOT_PASSWORD_PATH)}
      >
        {t("forms.forgot_password")}
      </CKButton>

      <Flex w="full" justifyContent="center" mt={8}>
        <Button
          label={t("forms.sign_in")}
          onClick={handleSubmit}
          isLoading={isLoading}
          colorScheme="primary"
          fontFamily="regular"
          type="submit"
          fontSize="md"
          size="lg"
          w="full"
        />
      </Flex>
    </>
  );
}
