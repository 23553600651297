import React from "react";
import { useTranslation } from "react-i18next";

import SectionCard from "components/SectionCard";

import { SectionDefaultProps } from "../..";
import { Fields } from "./Fields";

export const NEFZ = ({
  watch,
  control,
  formState,
  register,
}: SectionDefaultProps): JSX.Element => {
  const { t } = useTranslation();

  const fuelType = watch("fuelType");

  return (
    <SectionCard
      title={t("custom_offer.steps.standard_data.fuel_card.nefz.title")}
    >
      <Fields
        formState={formState}
        register={register}
        fuelType={fuelType}
        control={control}
      />
    </SectionCard>
  );
};
