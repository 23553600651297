import React from "react";
import { Box } from "@chakra-ui/react";

import useSorting from "hooks/useSorting";
import { SortingOrderEnum } from "generated/graphql";
import { iconsMap } from "constants/icons";

import { SortableHeaderProps } from "../types";

const iconProps = {
  fontSize: "xxs",
};

const SortableHeader: React.FC<SortableHeaderProps> = ({
  sortingColumn,
  children,
}) => {
  const {
    field,
    order,
  } = useSorting();

  const icon = order === SortingOrderEnum.Desc
    ? React.createElement(iconsMap.DownChevron, iconProps)
    : React.createElement(iconsMap.DownChevron, { ...iconProps, transform: "rotate(180deg)" });

  return (
    <Box
      d="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
    >
      {children}

      <Box ml="2" w="2">
        {
          field === sortingColumn && (
            icon
          )
        }
      </Box>
    </Box>
  );
};

export default SortableHeader;
