import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const DocumentIcon: IconProps = createIcon({
  viewBox: "0 0 20 21",
  path: (
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 6.4248V18.0915C17.5 19.0119 16.7538 19.7581 15.8333 19.7581H4.16667C3.24619 19.7581 2.5 19.0119 2.5 18.0915V3.09147C2.5 2.171 3.24619 1.4248 4.16667 1.4248H12.5V6.4248H17.5ZM17.5 5.59147H13.3333V1.4248L17.5 5.59147ZM5 15.5915V16.8415H15V15.5915H5ZM5 12.2581V13.5081H15V12.2581H5ZM5 8.9248V10.1748H15V8.9248H5Z"
      fill="currentColor"
    />
  ),
});

export default DocumentIcon;
