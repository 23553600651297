import React from "react";
import {
  Flex,
  Skeleton,
  Stack,
} from "@chakra-ui/react";

import i18n from "translations/i18n";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import LeadDetailsCardDivider from "views/Leads/components/LeadDetailsCard/LeadDetailsCardDivider";

import { ManagingDirectorInformationProps } from "./types";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import { skeletonStyles } from "../styles";

const ManagingDirectorInformation: React.FC<ManagingDirectorInformationProps> = ({
  isLoading,
  lead,
}) => (
  <Stack mb={4}>
    <Flex w="full" mr={4} flexDir="column">
      <LeadDetailsCard
        title={i18n.t("leads.details.form.managing_director")}
        isExpandable
      >
        {
          isLoading
            ? (
              <Flex flexDir="column" w="full">
                <Skeleton {...skeletonStyles.sm} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
              </Flex>
            ) : (
              <Flex>
                <LeftSection lead={lead} />

                <LeadDetailsCardDivider />

                <RightSection lead={lead} />
              </Flex>
            )
        }
      </LeadDetailsCard>
    </Flex>
  </Stack>
);

export default ManagingDirectorInformation;
