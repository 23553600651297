import { StringParam } from "use-query-params";

import i18n from "translations/i18n";

import { Tab } from "./types";
import { BudgetSettings } from "./BudgetSettings";

export const tabsQueryParams = {
  tab: StringParam,
  dealerId: StringParam,
};

const settingsTabsList = [
  {
    Component: BudgetSettings,
    queryString: "budget",
    isDefault: true,
  },
];

export const tabs: Tab[] = settingsTabsList.map(tab => ({
  componentProps: {
    title: i18n.t(`screens.settings.tabs.${tab.queryString}.title`),
    description: i18n.t(`screens.settings.tabs.${tab.queryString}.description`),
  },
  label: i18n.t(`screens.settings.tabs.${tab.queryString}.label`),
  ...tab,
}));

export const settingsHeaderTabs = tabs.map(tab => ({
  label: tab.label,
  queryString: tab.queryString,
}));
