import React from "react";

import { Container } from "components/Container";
import { Filters } from "hooks/useFilters/types";
import useFilters from "hooks/useFilters";
import { Bank, useGetBanksQuery } from "generated/graphql";
import { usePagination } from "contexts/pagination";
import useSorting from "hooks/useSorting";

import BanksTable from "./BanksTable";

const Banks: React.FC = () => {
  const { paginationLimit } = usePagination();

  const {
    renderFilters,
    search,
  } = useFilters([
    Filters.Search,
  ]);

  const {
    field,
    order,
  } = useSorting({ defaultField: "name" });

  const { data, loading } = useGetBanksQuery({
    variables: {
      search,
      pagination: {
        limit: paginationLimit,
        orderBy: (
          field
            ? [{
              field,
              order,
            }]
            : undefined
        ),
      },
    },
  });

  const banks = data?.banks as Bank[] || [];

  return (
    <Container>
      {renderFilters}

      <BanksTable
        banks={banks}
        loading={loading}
      />
    </Container>
  );
};

export default Banks;
