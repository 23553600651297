import React, { useEffect } from "react";
import {
  Flex,
  Skeleton,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import fileTypes from "constants/fileTypes";
import { Lead, useGetCheckoutLeadDocumentsQuery } from "generated/graphql";

import { DocumentsProps } from "./types";
import DocumentAccordion from "./DocumentAccordion";
import { skeletonStyles } from "../styles";

const Documents: React.FC<DocumentsProps> = ({
  leadId,
}) => {
  const { t } = useTranslation();
  const newToast = useToast();

  const {
    data,
    loading,
    error: checkoutLeadDocumentsError,
  } = useGetCheckoutLeadDocumentsQuery({
    variables: {
      id: leadId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (checkoutLeadDocumentsError) {
      newToast({
        title: t("errors.something_went_wrong"),
        description: checkoutLeadDocumentsError.message,
        status: "error",
      });
    }
  }, [
    checkoutLeadDocumentsError,
    newToast,
    t,
  ]);

  return (
    <Stack mb={4}>
      <Flex w="full" mr={4} flexDir="column">
        <LeadDetailsCard
          title={t("leads.details.form.documents")}
          isExpandable
        >
          {
            loading ? (
              <Flex flexDir="column" w="full">
                <Skeleton {...skeletonStyles.sm} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
                <Skeleton {...skeletonStyles.full} />
              </Flex>
            ) : (
              <>
                {
                  fileTypes.map((fileType, index) => (
                    <DocumentAccordion
                    // eslint-disable-next-line react/no-array-index-key
                      key={`${fileType} ${index}`}
                      fileType={fileType}
                      documents={data?.lead?.[fileType.field]}
                      lead={data?.lead as Lead}
                    />
                  ))
                }
              </>
            )
          }
        </LeadDetailsCard>
      </Flex>
    </Stack>
  );
};

export default Documents;
