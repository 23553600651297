import React from "react";

import { Container } from "components/Container";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";
import {
  Brand,
  SortingOrderEnum,
  useGetBrandsQuery,
} from "generated/graphql";
import { usePagination } from "contexts/pagination";

import BrandsTable from "./BrandsTable";

const Brands: React.FC = () => {
  const { paginationLimit } = usePagination();

  const {
    renderFilters,
    search,
  } = useFilters([
    Filters.Search,
  ]);

  const { data, loading } = useGetBrandsQuery({
    variables: {
      search,
      pagination: {
        limit: paginationLimit,
        orderBy: [{
          field: "updated_at",
          order: SortingOrderEnum.Desc,
        }],
      },
    },
  });

  const brands = data?.brands as Brand[] || [];

  return (
    <Container>
      {renderFilters}

      <BrandsTable
        brands={brands}
        loading={loading}
      />
    </Container>
  );
};

export default Brands;
