import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMount } from "react-use";

import useCustomOfferStore from "../../../../store";
import { UseFormStepOptions } from "./types";

/**
 * Handles the Form logic form a Form Step. Initializes the useForm hook, updates the values within
 * the form store and exposes the form properties according to the schema.
 * @param schema The schema.
 */
function useFormStep<Schema>({
  formOptions,
  schema,
}: UseFormStepOptions<Schema>): UseFormReturn<Schema> {
  const form = useForm<Schema>({
    resolver: (
      schema
        ? yupResolver(schema)
        : undefined
    ),
    mode: "onChange",
    ...(formOptions ?? {}),
  });

  useMount(() => {
    const {
      getValues,
      trigger,
      formState,
      watch,
    } = form;

    useCustomOfferStore.setState(state => ({
      wizardStepStore: {
        ...state.wizardStepStore,
        getValues,
        trigger,
        errors: formState.errors,
        watch,
      },
    }));
  });

  useEffect(() => {
    useCustomOfferStore.setState(state => ({
      wizardStepStore: {
        ...state.wizardStepStore,
        isValid: form.formState.isValid,
        isDirty: form.formState.isDirty,
      },
    }));
  }, [
    form.formState.isValid,
    form.formState.isDirty,
  ]);

  return form;
}

export default useFormStep;
