import yup from "settings/yup";

export const offerDetailsSchema = yup.object().shape({
  formSpecialConditions: yup.object().shape({
    disabilityDiscount: yup.object().shape({
      isChecked: yup.boolean(),
      description: yup.string().nullable(),
    }),
    brandChangeBonus: yup.object().shape({
      isChecked: yup.boolean(),
      description: yup.string().nullable(),
    }),
    drivingSchoolsOnly: yup.object().shape({
      isChecked: yup.boolean(),
      description: yup.string().nullable(),
    }),
    tradeIn: yup.object().shape({
      isChecked: yup.boolean(),
      description: yup.string().nullable(),
    }),
    conquestBonus: yup.object().shape({
      isChecked: yup.boolean(),
      description: yup.string().nullable(),
    }),
    environmentalBonus: yup.object().shape({
      isChecked: yup.boolean(),
      description: yup.string().nullable(),
    }),
  }),
  topOffer: yup
    .boolean(),
  marketingOffer: yup
    .boolean(),
  preventOverwrite: yup
    .boolean(),
  availableUntil: yup
    .date()
    .nullable(),
  isCheckout: yup
    .boolean(),
  isNewConfiguratorEnabled: yup
    .boolean(),
  offerOfTheDay: yup
    .boolean(),
  hideInSearch: yup
    .boolean(),
});

export interface SpecialCondition {
  isChecked?: boolean;
  description?: string;
  slug?: string;
}

export interface SpecialConditionsSchema {
  disabilityDiscount?: SpecialCondition;
  brandChangeBonus?: SpecialCondition;
  drivingSchoolsOnly?: SpecialCondition;
  tradeIn?: SpecialCondition;
  conquestBonus?: SpecialCondition;
  environmentalBonus?: SpecialCondition;
}

export interface OfferDetailsSchema {
  formSpecialConditions?: SpecialConditionsSchema;
  topOffer: boolean;
  marketingOffer: boolean;
  preventOverwrite: boolean;
  availableUntil: Date | null;
  isCheckout: boolean;
  isNewConfiguratorEnabled: boolean;
  offerOfTheDay: boolean;
  hideInSearch: boolean;
}
