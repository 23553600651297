import React from "react";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { ROOT_PAGE_PATH } from "routes";
import UpdatePasswordForm from "components/UpdatePasswordForm";

const TOKEN_QUERY_KEY = "reset_password_token";

const ResetPassword: React.FC = () => {
  const newToast = useToast();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const query = new URLSearchParams(location.search);
  const token = query.get(TOKEN_QUERY_KEY);

  if (!token) {
    newToast({
      title: t("update_password_form.invalid_token"),
      status: "error",
      duration: 4000,
      isClosable: true,
    });

    history.replace(ROOT_PAGE_PATH);

    return null;
  }

  return (
    <UpdatePasswordForm
      token={token}
      title={t("reset_password.title")}
      submitButtonText={t("reset_password.submit_button")}
    />
  );
};

export default ResetPassword;
