import React from "react";
import { Text } from "@chakra-ui/react";

import { Column } from "components/Table/types";
import { Bank } from "generated/graphql";
import i18n from "translations/i18n";
import Button from "components/Button";

import { BanksActions, BankSortFieldsEnum } from "../types";

/**
 * Builds the banks table columns.
 */
export const makeTableColumns = ({ onEditBank }: BanksActions):
  Column<Bank>[] => [
  {
    title: i18n.t("components.table.heading.name"),
    render: ({ row }) => (
      <Text fontWeight="bold">
        { row?.name}
      </Text>
    ),
    sortingColumn: BankSortFieldsEnum.name,
  },
  {
    title: i18n.t("components.table.heading.private_template"),
    fieldPath: "privateTemplateId",
  },
  {
    title: i18n.t("components.table.heading.corporate_template"),
    fieldPath: "corporateTemplateId",
  },
  {
    title: i18n.t("components.table.heading.aliases"),
    fieldResolver: (bank) => bank.aliases?.join(", "),
  },
  {
    title: i18n.t("components.table.heading.options"),
    render: ({ row }) => (
      <Button
        label={i18n.t("components.buttons.edit")}
        onClick={onEditBank(row)}
        colorScheme="secondary"
        textStyle="p"
      />
    ),
  },
];
