import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const DownArrowIcon: IconProps = createIcon({
  viewBox: "0 0 15 16",
  d: "M6 0.500001L6 12.67L0.410001 7.08L-0.999999 8.5L7 16.5L15 8.5L13.59 7.09L8 12.67L8 0.500001L6 0.500001Z",
});

export default DownArrowIcon;
