import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const ErrorIcon: IconProps = createIcon({
  viewBox: "0 0 20 20",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM6.45711 5.04289C6.06658 4.65237 5.43342 4.65237 5.04289 5.04289C4.65237 5.43342 4.65237 6.06658 5.04289 6.45711L8.58579 10L5.04289 13.5429C4.65237 13.9334 4.65237 14.5666 5.04289 14.9571C5.43342 15.3476 6.06658 15.3476 6.45711 14.9571L10 11.4142L13.5429 14.9571C13.9334 15.3476 14.5666 15.3476 14.9571 14.9571C15.3476 14.5666 15.3476 13.9334 14.9571 13.5429L11.4142 10L14.9571 6.45711C15.3476 6.06658 15.3476 5.43342 14.9571 5.04289C14.5666 4.65237 13.9334 4.65237 13.5429 5.04289L10 8.58579L6.45711 5.04289Z"
      fill="currentColor"
    />
  ),
});

export default ErrorIcon;
