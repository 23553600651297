/**
 * Formats a decimal value to a percentage string.
 * @param value a decimal value in the interval 0-1.
 * @param fallback a fallback value which will be
 */
export const formatPercentage = (
  value: number | undefined,
  fallback?: "-",
): string | undefined => {
  if (Number.isNaN(Number(value)) || value === undefined) {
    return fallback;
  }

  return `${(value * 100).toFixed(2)} %`;
};
