import React, { useState } from "react";
import {
  FormState,
  UseFormRegister,
} from "react-hook-form";
import { NumberFormatValues } from "react-number-format";

import { UpdateFixedLeasingRateParams } from "generated/graphql";
import i18n from "translations/i18n";
import { Column, ColumnRenderOptions } from "components/Table/types";
import { LeasingColumns } from "components/Modals/EditVehicleModal/types";
import { TERM_MONTHS, MILEAGES, CUSTOM_FIELD_REPRESENTATION } from "constants/leasing";
import CurrencyInput from "components/FormComponents/Inputs/CurrencyInput";
import { FinancingAndCostsSchema } from "settings/yup/schemas/financingAndCostsSchema";

import addCustomElements from "../../EditVehicleModal/LeasingFactorsTable/addCustomElements";
import { CustomHeaderProps, CustomTitleEnum } from "./types";
import {
  focusCss,
  customMileageCss,
  customMonthCss,
  formControlCss,
} from "./styles";

const MONTH = i18n.t("components.modals.edit_leasing_rates_modal.months")?.toUpperCase();
const customMileageTitle = `${i18n.t("components.modals.edit_leasing_rates_modal.custom")}   `;
const withCustomMileages = addCustomElements(MILEAGES);
const withCustomTermMonths = addCustomElements(TERM_MONTHS);

const CustomHeader = ({
  value,
  onHandleChange,
  isMileage,
  formState,
  register,
}: CustomHeaderProps): JSX.Element => (
  <CurrencyInput
    {...register(isMileage
      ? CustomTitleEnum.customMileage
      : CustomTitleEnum.customMonth, {
      setValueAs: val => val?.replace(".", "").match(/^\d+/g)?.[0] || "",
    })}
    isNumericString
    decimalSeparator=","
    thousandSeparator="."
    defaultValue={value}
    placeholder={customMileageTitle}
    border={0}
    textAlign="center"
    suffix={` ${isMileage ? "km" : MONTH}`}
    customCss={isMileage ? customMileageCss : customMonthCss}
    focusCss={focusCss}
    formControlCss={formControlCss}
    onValueChange={(e: NumberFormatValues) => onHandleChange(e.value)}
    errors={formState.errors}
    hasCustomErrorCss
  />
);

interface MakeLeasingRatesColumnsProps {
  leasingRates: UpdateFixedLeasingRateParams[];
  formState: FormState<FinancingAndCostsSchema>;
  register: UseFormRegister<FinancingAndCostsSchema>;
}

export const MakeLeasingRatesColumns = ({
  leasingRates = [],
  formState,
  register,
}: MakeLeasingRatesColumnsProps)
: Column<LeasingColumns>[] => {
  const customMileageInitialValue = leasingRates.find(
    leasingRate => !withCustomMileages.includes(Number(leasingRate.mileageKm)),
  )?.mileageKm?.toString();
  const customMonthInitialValue = leasingRates.find(
    leasingRate => !withCustomTermMonths.includes(Number(leasingRate.termMonths)),
  )?.termMonths?.toString();

  const [customMileage, setCustomMileage] = useState(customMileageInitialValue || "");
  const [customMonth, setCustomMonth] = useState(customMonthInitialValue || "");

  const mileages = {
    render: ((data: { currentIndex: string | number }) => {
      const isDefaultMileages = MILEAGES[data.currentIndex];
      if (isDefaultMileages) return (`${MILEAGES[data.currentIndex]?.toLocaleString("de-DE")} km`);
      return (
        <CustomHeader
          value={customMileage}
          onHandleChange={setCustomMileage}
          formState={formState}
          register={register}
          isMileage
        />
      );
    }),
  };

  const months = withCustomTermMonths.map((month, monthIdx) => {
    const title = month
      ? `${month} ${MONTH}`
      : () => (
        <CustomHeader
          value={customMonth}
          onHandleChange={setCustomMonth}
          isMileage={false}
          formState={formState}
          register={register}
        />
      );
    return ({
      title,
      cellProps: {
        textAlign: "center" as const,
        textTransform: "uppercase" as const,
        fontSize: "xs" as const,
        fontWeight: "800" as const,
        bgColor: monthIdx % 2 === 0 ? "white" : "gray.200",
        minWidth: "100px",
      },
      render: ({ row, currentIndex } : ColumnRenderOptions<LeasingColumns>) => {
        const { price } = row[month] || {};
        const mileage = MILEAGES[currentIndex];
        const customFieldsEnabled = !!((mileage || customMileage) && (month || customMonth));
        const actualPrice = ((typeof price === "number" && price >= 0) && customFieldsEnabled) ? price : "";

        return (
          <CurrencyInput
            disabled={!customFieldsEnabled}
            isNumericString
            value={actualPrice}
            format={undefined}
            decimalSeparator=","
            thousandSeparator="."
            fixedDecimalScale
            decimalScale={2}
            id={`${month}${(MILEAGES[currentIndex] || CUSTOM_FIELD_REPRESENTATION)}`}
            name={`${(month || customMonth)}-${(MILEAGES[currentIndex] || customMileage)}`}
            focusCss={focusCss}
            formControlCss={formControlCss}
            placeholder="-"
            border={0}
            textAlign="center"
            suffix=" €"
            bgColor="inherit"
          />
        );
      },
    });
  });

  return [
    {
      cellProps: {
        textAlign: "right" as const,
        fontSize: "xs" as const,
        width: "50px",
      },
      ...mileages,
    },
    ...months,
  ];
};
