import i18n from "translations/i18n";

export const defaultOptionLabels = [
  {
    label: i18n.t("components.toggle_button.default_option_labels.off"),
    value: i18n.t("components.toggle_button.default_option_labels.off")
      .toLowerCase(),
  },
  {
    label: i18n.t("components.toggle_button.default_option_labels.on"),
    value: i18n.t("components.toggle_button.default_option_labels.on")
      .toLowerCase(),
  },
];
