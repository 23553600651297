import { TestConfig } from "yup";

import i18n from "translations/i18n";

const passwordCaseTest = (): TestConfig<string | undefined> => ({
  name: "caseTest",
  message: i18n.t("yup.password_strength_must_have"),
  test: (value): boolean => (
    !value || (
      !!value
        && /(?=.*[a-z])/.test(value)
        && /(?=.*[A-Z])/.test(value)
    )
  ),
});

export default passwordCaseTest;
