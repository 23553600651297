import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const UploadIcon: IconProps = createIcon({
  viewBox: "0 0 49.252 32.834",
  path: (
    <path
      fill="currentColor"
      d="M36.281,16.231C35.006,9.245,29.325,4,22.5,4a13.97,13.97,0,0,0-12.469,8.181C4.388,12.829,0,17.993,0,24.25c0,6.7,5.044,12.15,11.25,12.15H35.625C40.8,36.4,45,31.864,45,26.275,45,20.929,41.156,16.6,36.281,16.231ZM26.25,22.225v8.1h-7.5v-8.1H13.125L22.5,12.1l9.375,10.125Z"
    />
  ),
});

export default UploadIcon;
