import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
} from "@chakra-ui/react";

import i18n from "translations/i18n";
import { Column, ColumnRenderOptions } from "components/Table/types";
import CopyIcon from "components/Icons/CopyIcon";
import { TableFactorsEnum } from "components/Modals/EditVehicleModal/constants";
import { MILEAGES, TERM_MONTHS } from "constants/leasing";

import { LeasingColumns, EditableComponentType } from "../types";
import { tableCellStyles } from "./styles";

const MONTH = i18n.t("components.modals.edit_vehicle_modal.months");

const EditableComponent = ({
  row,
  month,
  factor,
}: EditableComponentType): JSX.Element => {
  const factorValue = row[month][factor] as string || "";
  const [editableText, setEditableText] = useState<string>(factorValue);

  useEffect(() => {
    setEditableText(factorValue);
  }, [factorValue]);

  return (
    <Editable
      defaultValue={editableText}
      value={editableText}
      placeholder="-"
      onChange={value => setEditableText(value)}
      bgColor="inherit"
      minH="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="5px"
      _focus={{
        borderRadius: "5px",
      }}
    >
      <EditablePreview
        width="100%"
      />
      <EditableInput
        css={tableCellStyles}
        month={month}
      />
    </Editable>
  );
};

/**
 * Builds the edit vehicle modal table.
 */
export const MakeLeasingFactorColumns = (
  factor: TableFactorsEnum,
  copyColumns: (mileage: number) => void,
): Column<LeasingColumns>[] => {
  const mileages = {
    render: ((data: { currentIndex: string | number }) => (`${MILEAGES[data.currentIndex]?.toLocaleString()} km`)),
  };

  const copyColumn = {
    render: ({ currentIndex } : ColumnRenderOptions<LeasingColumns>) => (
      <Tooltip
        label={i18n.t("components.tooltips.copy_values_line")}
        bg="gray.100"
        color="secondary.800"
        hasArrow
      >
        <Button
          variant="ghost"
          onClick={() => copyColumns(MILEAGES[currentIndex])}
        >
          <CopyIcon />
        </Button>
      </Tooltip>
    ),
  };

  const months = TERM_MONTHS.map((month, monthIdx) => ({
    title: `${month.toString()} ${MONTH}`,
    cellProps: {
      textAlign: "center",
      textTransform: "uppercase",
      fontSize: "xs",
      bgColor: monthIdx % 2 === 0 ? "white" : "gray.200",
    },
    render: ({ row } : ColumnRenderOptions<LeasingColumns>) => (
      <EditableComponent
        row={row}
        month={month}
        factor={factor}
      />
    ),
  }));

  return [
    {
      cellProps: {
        textAlign: "right",
        fontSize: "xs",
      },
      ...mileages,
    },
    ...months,
    copyColumn,
  ];
};
