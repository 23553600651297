import { Vehicle } from "generated/graphql";
import yup from "settings/yup";

export const imagesSchema = yup.object().shape({
  images: yup
    .array().of(yup.string())
    .nullable(),
  vehicle: yup.object().shape({
    jatoSuggestedImages: yup
      .array().of(yup.string())
      .nullable(),
    suggestedImages: yup
      .array().of(yup.string())
      .nullable(),
    nlImagePrefix: yup
      .string()
      .nullable(),
  })
    .nullable(),
  files: yup
    .mixed<File>()
    .nullable(),
});

export interface ImagesDataSchema {
  images: (string | File)[];
  vehicle?: Partial<Pick<Vehicle, "jatoSuggestedImages" | "suggestedImages" | "nlImagePrefix">>;
  files: File[];
}
