import {
  AccessoryEnum,
  OfferInteriorFabricEnum,
  OfferAccessory,
  Vehicle,
  VehicleBaseColorEnum,
} from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

export const equipmentSchema = yup.object().shape({
  accessories: yup
    .array(yup.string())
    .nullable(),
  exteriorColorName: yup
    .string()
    .required(i18n.t("yup.mixed_required_field"))
    .nullable(),
  exteriorBaseColor: yup
    .mixed<VehicleBaseColorEnum>()
    .oneOf(Object.values(VehicleBaseColorEnum), i18n.t("yup.mixed_required_field"))
    .required(i18n.t("yup.mixed_required_field"))
    .nullable(),
  interiorColorName: yup
    .string()
    .required(i18n.t("yup.mixed_required_field"))
    .nullable(),
  interiorBaseColor: yup
    .mixed<VehicleBaseColorEnum>()
    .oneOf(Object.values(VehicleBaseColorEnum), i18n.t("yup.mixed_required_field"))
    .required(i18n.t("yup.mixed_required_field"))
    .nullable(),
  interiorFabric: yup
    .string()
    .required(i18n.t("yup.mixed_required_field"))
    .nullable(),
});

export interface EquipmentDataSchema {
  exteriorBaseColor?: VehicleBaseColorEnum;
  exteriorColorName?: string;
  interiorBaseColor?: VehicleBaseColorEnum;
  interiorColorName?: string;
  interiorFabric: OfferInteriorFabricEnum;
  offerAccessories: Pick<OfferAccessory, "id" | "accessory">[];
  selectedAccessories: AccessoryEnum[];
  vehicle?: Partial<Pick<Vehicle, "accessories" | "colors">>;
}
