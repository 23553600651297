import { Box, useRadio } from "@chakra-ui/react";
import React from "react";

import { toggleButtonCss, isSelectedCss, isNotSelectedCss } from "./styles";
import { RadioCardProps } from "./types";

const RadioCard: React.FC<RadioCardProps> = (props: RadioCardProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const isSelectedStyles = props.isChecked ? isSelectedCss : isNotSelectedCss;

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        variant="unstyled"
        cursor="pointer"
        _checked={{
          bg: "secondary.500",
          color: "white",
          borderColor: "secondary.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        textStyle="p"
        __css={{
          ...toggleButtonCss,
          ...isSelectedStyles,
        }}
      >
        {props?.children}
      </Box>
    </Box>
  );
};

export default RadioCard;
