import {
  FieldErrors,
  UseFormGetValues,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import { GetOfferQuery } from "generated/graphql";
import { StandardDataSchema } from "settings/yup/schemas/standardDataSchema";
import { EquipmentDataSchema } from "settings/yup/schemas/equipmentSchema";
import { DescriptionDataSchema } from "settings/yup/schemas/descriptionSchema";
import { ImagesDataSchema } from "settings/yup/schemas/imagesSchema";
import { FinancingAndCostsSchema } from "settings/yup/schemas/financingAndCostsSchema";
import { OfferDetailsSchema } from "settings/yup/schemas/offerDetailsSchema";
import { EditLeasingRatesSchema } from "settings/yup/schemas/editLeasingRatesSchema";

export type WizardStepStore = {
  isValid?: boolean;
  isDirty?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues?: UseFormGetValues<any>;
  errors?: FieldErrors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch?: UseFormWatch<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger?: UseFormTrigger<any>;

  /**
   * Defines if the getForm query is loading.
   */
  isFormLoading?: boolean;
}

export enum CustomOfferSteps {
  StandardData = 1,
  Equipment = 2,
  Description = 3,
  Images = 4,
  FinancingAndCosts = 5,
  OfferDetails = 6,
}

export interface CustomOfferStoreState {
  offer?: GetOfferQuery["offer"];
  setOffer: (offer: GetOfferQuery["offer"]) => void;
  setWizardStepStore: (isValid: boolean) => void;
  resetOffer: () => void;
  resetStore: () => void;
  wizardStepStore?: WizardStepStore;
  getValues?: UseFormGetValues<EditLeasingRatesSchema>;
  store?: {
    standardData?: StandardDataSchema;
    equipment?: EquipmentDataSchema;
    descriptionMarkdown?: DescriptionDataSchema;
    images?: ImagesDataSchema;
    financingAndCosts?: FinancingAndCostsSchema;
    offerDetails?: OfferDetailsSchema;
  };
}
