import React from "react";
import {
  Box,
  Flex,
} from "@chakra-ui/react";

import {
  CONTAINER_PADDING,
} from "constants/layout";
import TitleBar from "components/TitleBar";
import Footer from "components/Footer";
import { LayoutProps } from "layouts/types";
import { DefaultLayoutContainer } from "layouts/components/DefaultLayoutContainer";

const Dashboard: React.FC<LayoutProps> = ({
  children,
  title,
  Icon,
  FooterContent,
  footerVariant,
  titleBarButton,
}) => (
  <DefaultLayoutContainer>
    <Flex
      grow={1}
      shrink={0}
      basis="auto"
      direction="column"
    >
      <TitleBar
        button={titleBarButton}
        title={title || ""}
        Icon={Icon}
      />

      <Box
        py={CONTAINER_PADDING.XS}
        pr={CONTAINER_PADDING.XS}
      >
        {children}
      </Box>
    </Flex>

    {
      FooterContent && (
        <Flex shrink={0}>
          <Footer
            FooterContent={FooterContent}
            footerVariant={footerVariant}
          />
        </Flex>
      )
    }
  </DefaultLayoutContainer>
);

export default Dashboard;
