import { ValueType } from "react-select";

import { OptionProps } from "./types";

/**
 * Parses a select initial value.
 * @param {*} value Select value
 * @param {*} options Select options
 */
const parseSelectValue = (
  value: unknown,
  options?: unknown,
): ValueType<OptionProps, false> => {
  if (!value) {
    return undefined;
  }

  const selectValue = value as OptionProps;
  const stringValue = value as string | number;

  if (selectValue?.value && selectValue?.label) {
    return selectValue;
  }

  const selectOptions = (
    Array.isArray(options)
      ? options
      : []
  );

  return selectOptions
    .find((option) => option?.value === stringValue || option?.label === stringValue);
};

export default parseSelectValue;
