import React from "react";

import { MakeLeadsTableColumnsProps } from "views/Leads/types";
import { makeDefaultLeadsTableColumns } from "views/Leads/makeDefaultLeadsTableColumns";
import { Lead, LeadRejectionStatusEnum } from "generated/graphql";
import { Column } from "components/Table/types";
import i18n from "translations/i18n";
import StatusSelector from "views/Leads/components/StatusSelector";
import { GET_CLASSIC_LEADS_REFETCH_QUERIES } from "views/Leads/graphql/updateLeadBySellerMutation";
import LeadRejectionStatusTag from "components/Tags/LeadRejectionStatusTag";
import { ButtonAsLink } from "components/ButtonAsLink";
import { LEAD_DETAILS_PAGE_PATH } from "routes";
import leadSelectStyles from "views/Leads/components/Select/styles";

const MemoizedStatusSelector = React.memo(StatusSelector);

export const makeTableColumns = ({
  selectedLeads,
  toggleLead,
  toggleAllLeads,
  isAllSelected = false,
  hasLeads,
}: MakeLeadsTableColumnsProps): Column<Lead>[] => {
  const makeColumnsProps = {
    selectedLeads,
    toggleLead,
    toggleAllLeads,
    isAllSelected,
    hasLeads,
    refetchQueries: GET_CLASSIC_LEADS_REFETCH_QUERIES,
  };

  return [
    ...makeDefaultLeadsTableColumns(makeColumnsProps),
    {
      title: i18n.t("components.table.heading.status"),
      fieldResolver: lead => i18n.t([`status.${lead?.status}`, "-"]),
      render: ({ row }) => (
        <MemoizedStatusSelector
          refetchQueries={GET_CLASSIC_LEADS_REFETCH_QUERIES}
          defaultValue={row?.status}
          isCheckout={false}
          value={row?.status}
          leadId={row?.id}
          styles={leadSelectStyles}
        />
      ),
      cellProps: {
        minW: "180px",
      },
    },
    {
      render: ({ row }) => (
        <ButtonAsLink
          path={LEAD_DETAILS_PAGE_PATH.replace(":id", String(row.id))}
          label={i18n.t("components.buttons.details")}
          colorScheme="secondary"
          textStyle="p"
          variant="ghost"
          lineHeight={1}
        />
      ),
      cellProps: {
        textAlign: "right",
      },
    },
    {
      title: "",
      render: ({ row }) => (
        row?.rejectionStatus
            && (row?.rejectionStatus !== LeadRejectionStatusEnum.NotRequested)
          ? (
            <LeadRejectionStatusTag rejectionStatus={row?.rejectionStatus} />
          ) : (
            ""
          )
      ),
    },
  ];
};
