import { Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import SectionCard from "components/SectionCard";
import { FinancingDataProps } from "components/Modals/EditLeasingRatesModal/types";

import CustomPercentageField from "../Fields/CustomPercentageField";
import { titleCss } from "../../styles";

const FinancingData: React.FC<FinancingDataProps> = ({
  offer,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <SectionCard
      title={t("components.modals.edit_leasing_rates_modal.financing_data")}
    >
      <Stack
        justifyContent="space-between"
        direction="row"
      >
        <CustomPercentageField
          allowNegative
          netTitle={t("components.modals.edit_leasing_rates_modal.effective_annual_interest")}
          name="effectiveAnnualInterestRate"
          titleCss={titleCss}
          defaultValue={offer?.effectiveAnnualInterestRate === null ? "0" : offer?.effectiveAnnualInterestRate}
          width="md"
          setValue={setValue}
        />

        <CustomPercentageField
          allowNegative
          netTitle={t("components.modals.edit_leasing_rates_modal.nominal_interest_rate")}
          name="nominalInterestRate"
          titleCss={titleCss}
          defaultValue={offer?.nominalInterestRate === null ? "0" : offer?.nominalInterestRate}
          width="md"
          setValue={setValue}
        />
      </Stack>
    </SectionCard>
  );
};

export default FinancingData;
