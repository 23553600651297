import React from "react";
import { VStack } from "@chakra-ui/react";

import NullLeasingMobileDeLogo from "components/Logo/NullLeasingMobileDeLogo";

const LoadingProgress: React.FC = () => (
  <VStack
    width="full"
    bgColor="gray.100"
    height="100vh"
    justifyContent="center"
  >
    <NullLeasingMobileDeLogo
      w={245}
      h={65}
    />
  </VStack>
);

export default LoadingProgress;
