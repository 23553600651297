import React, { FC } from "react";

import CheckoutLeadsDetails from "./Checkout/Details";
import ClassicLeadsDetails from "./Classic/Details";
import { SpecificSectionsProps } from "./types";

const SpecificSections: FC<SpecificSectionsProps> = ({
  isLoading = true,
  isCheckout,
  lead,
}) => (
  <div>
    {
      isCheckout
        ? (
          <CheckoutLeadsDetails
            isLoading={isLoading}
            lead={lead}
          />
        )
        : (
          <ClassicLeadsDetails
            isLoading={isLoading}
            lead={lead}
          />
        )
    }
  </div>
);

export default SpecificSections;
