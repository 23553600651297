import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const SpinnerContainer = (): JSX.Element => (
  <Flex
    justifyContent="center"
    w="full"
    h="400"
  >
    <Spinner
      color="secondary.500"
      size="lg"
      thickness="2px"
      alignSelf="center"
    />
  </Flex>
);

export default SpinnerContainer;
