import React from "react";
import type { StoreApi } from "zustand";
import createContext from "zustand/context";

import createStore from "./storeState";
import { LeadsStoreStateProps } from "./types";

const { Provider, useStore: useLeadStore } = createContext<StoreApi<LeadsStoreStateProps>>();

const LeadsStoreProvider: React.FC = ({ children }) => (
  <Provider createStore={createStore}>
    {children}
  </Provider>
);

export { LeadsStoreProvider, useLeadStore };
