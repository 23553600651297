import fonts from "settings/theme/fonts";

export const focusCss = {
  border: "2px solid",
  borderColor: "secondary.500",
};

export const customMileageCss = {
  width: "78px",
  fontSize: "12px",
  fontFamily: fonts.body,
  padding: 0,
  textAlign: "right",
};

export const customMonthCss = {
  maxWidth: "100%",
  height: "41px",
  fontSize: "12px",
  fontWeight: 700,
  fontFamily: fonts.body,
  padding: 0,
  textAlign: "center",
};

export const formControlCss = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  height: "48px",
  maxWidth: "fit-content",
};

export const tableCss = {
  overflow: "hidden",
};

export const buttonCss = {
  backgroundColor: "error.500",
  _hover: {
    backgroundColor: "error.800",
  },
};

export const collapseButtonCss = {
  color: "black.500",
  backgroundColor: "transparent",
  _hover: {
    filter: "brightness(0.9)",
    backgroundColor: "gray.100",
  },
  _active: {
    filter: "brightness(0.9)",
    backgroundColor: "gray.100",
  },
};
