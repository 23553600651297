import React, { useCallback, useState } from "react";
import { Flex, Stack, useDisclosure } from "@chakra-ui/react";

import i18n from "translations/i18n";
import Button from "components/Button";
import CustomerTypeSelector from "components/CustomerTypeSelector";
import { OfferCustomerTypeEnum, UpdateFixedLeasingRateParams } from "generated/graphql";
import { getDeliveryPricesDefaultValues } from "utils/getDeliveryPricesDefaultValues";

import FinancingData from "./FinancingData";
import { EditLeasingRateFormProps, LeasingRateTable, Rates } from "../types";
import MileagePrices from "./MileagePrices";
import DeliveryPrices from "./DeliveryPrices";
import LeasingRatesTable, { validateRates } from "../LeasingRatesTable";
import NoticeAlert from "../NoticeAlert";

const Form: React.FC<EditLeasingRateFormProps> = ({
  setSubmitState,
  handleSubmit,
  handleCancel,
  offer,
  setValue,
  getValues,
  reset,
  register,
  formState,
}) => {
  const [disabledButton, setDisabledButton] = useState(false);

  const [invalidTax, setInvalidTax] = useState<UpdateFixedLeasingRateParams[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChangeLeasingRates = useCallback((currentRates) => {
    const { isInvalidRates } = validateRates({
      data: currentRates,
      validationType: Rates.Invalid,
    });

    return setDisabledButton(isInvalidRates);
  }, []);

  const OfferLeasingRates = {
    fixedLeasingRatesPriceType: offer?.fixedLeasingRatesPriceType,
    fixedLeasingRates: offer?.fixedLeasingRates || [],
  } as LeasingRateTable;

  const onSubmit = useCallback(() => {
    const { invalidRates, isInvalidRates } = validateRates({
      data: getValues("fixedLeasingRates"),
      validationType: Rates.Limit,
    });

    if (!isInvalidRates) return handleSubmit();

    setInvalidTax(invalidRates);

    return onOpen();
  }, [onOpen, getValues, handleSubmit]);

  const deliveryPricesDefaultValues = getDeliveryPricesDefaultValues(offer?.deliveryPrices);

  return (
    <form
      onChange={() => setSubmitState(true)}
      style={{
        flexDirection: "column",
        overflow: "hidden",
        display: "flex",
      }}
    >
      <Flex direction="column" overflow="hidden">
        <Flex direction="column" overflow="auto">
          <Stack
            mr={3}
            flexDirection="column"
          >
            <CustomerTypeSelector
              onChange={(customerType) => setValue("customerType", customerType)}
              defaultCustomerType={offer?.customerType as OfferCustomerTypeEnum}
            />

            <LeasingRatesTable
              title={offer?.title}
              data={OfferLeasingRates}
              setValue={setValue}
              getValues={getValues}
              reset={reset}
              register={register}
              formState={formState}
              handleChange={handleChangeLeasingRates}
            />

            <FinancingData
              offer={offer}
              setValue={setValue}
            />

            <MileagePrices
              offer={offer}
              setValue={setValue}
            />

            <DeliveryPrices
              defaultValues={deliveryPricesDefaultValues}
              setValue={setValue}
            />

          </Stack>
        </Flex>

        <Stack spacing="40px" mt="6">
          <Flex justifyContent="space-between">
            <Button
              label={i18n.t("components.buttons.cancel")}
              onClick={handleCancel}
              variant="contained"
              fontSize="sm"
              color="primary.600"
              size="md"
              boxShadow="md"
            />

            <Button
              onClick={onSubmit}
              label={i18n.t("components.buttons.update_and_publish")}
              disabled={disabledButton}
            />
          </Flex>
        </Stack>
      </Flex>

      <NoticeAlert
        onClose={onClose}
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        data={invalidTax}
        textButton="components.buttons.update_and_publish"
      />
    </form>
  );
};

export default Form;
