export const purgeUndefined = (obj: Record<string, unknown>): Record<string, unknown> => {
  const newObj = { ...obj };

  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === undefined) {
      delete newObj[key];
    }
  });

  return newObj;
};
