import create from "zustand";

import { CustomOfferStoreState } from "./types";

/**
 * Exposes the Custom Offer view store, containing the current offer data
 */
const useCustomOfferStore = create<CustomOfferStoreState>((set) => ({
  offer: undefined,
  setOffer: (offer) => set(() => ({
    offer,
  })),
  resetOffer: () => set(() => ({
    offer: undefined,
  })),
  resetStore: () => set(() => ({
    store: undefined,
    offer: undefined,
    wizardStepStore: undefined,
  })),
  wizardStepStore: {
    isValid: true,
    isDirty: false,
    isFormLoading: true,
    form: undefined,
    getValues: undefined,
    errors: undefined,
    watch: undefined,
    trigger: undefined,
  },
  setWizardStepStore: (isValid) => set(state => ({
    wizardStepStore: {
      ...state.wizardStepStore,
      isValid,
    },
  })),
  store: {
    standardData: undefined,
    equipment: undefined,
    descriptionMarkdown: undefined,
    images: undefined,
    financingAndCosts: undefined,
    offerDetails: undefined,
  },
}));

export default useCustomOfferStore;
