import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Flex,
  Icon,
} from "@chakra-ui/react";

import PlusIcon from "components/Icons/PlusIcon";
import { ButtonAsLink } from "components/ButtonAsLink";

import { SidebarActionProps } from "./types";
import { highlightedStyle, baseStyle } from "./styles";

const SidebarAction: React.FC<SidebarActionProps> = ({
  action,
}) => {
  const {
    pathname: currentPath,
  } = useLocation();

  const actionProps = useMemo((): Record<string, unknown> => {
    if (action.path === currentPath) {
      return highlightedStyle;
    }

    return baseStyle;
  }, [
    action.path,
    currentPath,
  ]);

  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      key={action.label}
      minH="48px"
      w="full"
      {...actionProps}
    >
      <ButtonAsLink
        label={action.label}
        leftIcon={(
          <Icon
            as={action.Icon}
            style={{ fontSize: 21 }}
          />
        )}
        fontWeight="normal"
        wordBreak="break-word"
        whiteSpace="break-spaces"
        textAlign="left"
        textStyle="h3"
        lineHeight={1}
        borderRadius="0"
        path={action.path}
        paddingInline={2}
        w="full"
        {...actionProps}
      />

      {
        action.extraAction && (
          <Button
            onClick={action.extraAction}
            {...actionProps}
          >
            <PlusIcon />
          </Button>
        )
      }
    </Flex>
  );
};

export default SidebarAction;
