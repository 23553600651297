import React from "react";
import {
  Tabs as ChakraTabs,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import Tab from "./Tab";
import { TabsProps } from "./types";

const Tabs: React.FC<TabsProps> = ({
  tabs,
  boxShadow,
  isLazy,
}) => (
  <ChakraTabs
    w="full"
    variant="unstyled"
    isLazy={isLazy}
  >
    <TabList>
      {
        tabs.map(tab => (
          <Tab
            key={tab.label}
            isDisabled={tab.isDisabled}
          >
            {tab.label}
          </Tab>
        ))
      }
    </TabList>

    <TabPanels
      pl={1}
      boxShadow={
        boxShadow || "none"
      }
    >
      {
        tabs.map(tab => (
          <TabPanel
            p={5}
            borderRadius="0px 4px 4px 4px"
            bgColor="white"
            fontFamily="regular"
            key={tab.label}
          >
            {tab.content}
          </TabPanel>
        ))
      }
    </TabPanels>
  </ChakraTabs>
);

export default Tabs;
