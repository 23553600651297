import React from "react";
import {
  OptionsType,
  OptionTypeBase,
} from "react-select";
import { Flex } from "@chakra-ui/react";

import { GetBrandsQueryResult, SortingOrderEnum, useGetBrandsQuery } from "generated/graphql";
import i18n from "translations/i18n";

import Select from "../../Select";
import { BrandSelectProps } from "./types";

function getBrandOptions(data: GetBrandsQueryResult["data"]): OptionsType<OptionTypeBase> {
  if (!data) {
    return [];
  }

  return data.brands?.map(brand => ({
    value: brand.id,
    label: brand.name,
  }));
}

export function BrandSelect(
  {
    placeholder = i18n.t("components.selects.brand"),
    brandSelectContainerProps,
    ...props
  }: BrandSelectProps,
): JSX.Element {
  const {
    loading,
    error,
    data,
  } = useGetBrandsQuery({
    variables: {
      pagination: {
        orderBy: [{
          field: "name",
          order: SortingOrderEnum.Asc,
        }],
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const options = getBrandOptions(data);

  return (
    <Flex
      w="full"
      {...brandSelectContainerProps}
    >
      <Select
        isDisabled={!!error}
        isLoading={loading}
        options={options}
        placeholder={placeholder}
        isSearchable
        {...props}
      />
    </Flex>
  );
}

export default BrandSelect;
