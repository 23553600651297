import { GetSpecialConditionsQuery } from "generated/graphql";
import { OfferDetailsSchema } from "settings/yup/schemas/offerDetailsSchema";
import { snakeToCamelCase } from "utils/snakeToCamelCase";
import { OfferSpecialCondition, OfferDetailsFormProps } from "views/CustomOffer/components/Steps/CustomOfferSteps/OfferDetails/types";

import { GetOfferDetailsDefaultFormValues } from "./types";

export const getOfferDetailsDefaultFormValues = ({
  offer,
  offerDetails,
  specialConditions,
  financingSpecialConditions,
}: GetOfferDetailsDefaultFormValues): OfferDetailsFormProps["defaultFormValues"] => {
  const selectedSpecialConditions = Object.values({
    ...offerDetails?.formSpecialConditions,
    ...financingSpecialConditions,
  } || {});

  return (offerDetails
    ? {
      ...offerDetails,
      creationType: offer?.creationType,
      dealer: offer?.dealer,
      shortId: offer?.shortId,
      offerSpecialConditions: specialConditions?.reduce((acc: OfferSpecialCondition[], curr) => {
        const foundCondition = selectedSpecialConditions.find(
          selectedCondition => selectedCondition.slug === curr.slug && selectedCondition.isChecked,
        );

        if (foundCondition) {
          acc.push({
            displayedDescription: foundCondition?.description || "",
            specialCondition: curr,
          });
        }

        return acc;
      }, []) || [],
    } : offer);
};

export const getOfferDetailsDefaultValues = (
  defaultValues?: OfferDetailsFormProps["defaultFormValues"],
  specialConditions?: GetSpecialConditionsQuery["specialConditions"],
): OfferDetailsSchema => {
  const parsedSpecialConditions = specialConditions?.map(
    item => ({
      id: item.id,
      slug: item.slug,
      isChecked: defaultValues?.offerSpecialConditions
        && defaultValues?.offerSpecialConditions.some(
          condition => condition.specialCondition.id === item.id,
        ),
      description: (defaultValues?.offerSpecialConditions
        && defaultValues?.offerSpecialConditions.find(
          condition => condition.specialCondition.id === item.id,
        )?.displayedDescription) || item.description,
    }),
  );

  const formSpecialConditions = parsedSpecialConditions?.reduce((obj, item) => {
    const { slug, description, isChecked } = item;

    // eslint-disable-next-line no-param-reassign
    obj[snakeToCamelCase(item.slug)] = { slug, description, isChecked };
    return obj;
  }, {});

  return {
    availableUntil: defaultValues?.availableUntil || null,
    preventOverwrite: !!defaultValues?.preventOverwrite,
    topOffer: !!defaultValues?.topOffer,
    marketingOffer: !!defaultValues?.marketingOffer,
    formSpecialConditions,
    isCheckout: !!defaultValues?.isCheckout,
    offerOfTheDay: !!defaultValues?.offerOfTheDay,
    isNewConfiguratorEnabled: !!defaultValues?.isNewConfiguratorEnabled,
    hideInSearch: !!defaultValues?.hideInSearch,
  };
};
