import { gql } from "@apollo/client";

export const IMPORT_LEASING_FACTORS_REFETCH_QUERIES = [
  "GetBaseVehicles",
];

export default gql`
  mutation ImportLeasingFactorsFromCsv (
    $csvFile: Upload!,
    $residualRatesCustomerType: ResidualRatesCustomerTypeEnum!
  ) {
    importDynamicLeasingRatesFromCsv(
      csvFile: $csvFile,
      residualRatesCustomerType: $residualRatesCustomerType
    )
  }
`;
