import React from "react";
import { Skeleton, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import GearIcon from "components/Icons/GearIcon";
import { formatCurrency } from "utils/currencyFormat";
import Button from "components/Button";

import { BudgetButtonProps } from "./types";

export const BudgetButton: React.FC<BudgetButtonProps> = ({
  isUnlimited = false,
  isLoading = false,
  onClick,
  budget,
}) => {
  const { t } = useTranslation();

  const formattedBudget = !!budget && budget !== 0
    ? formatCurrency({
      symbolPosition: "before",
      addSymbol: true,
      amount: budget,
    })
    : "€ 0";

  const buttonLabel = isUnlimited
    ? t("budget_overview.unlimited")
    : formattedBudget;

  return (
    <Stack
      direction="column"
      spacing={1}
    >
      <Text
        fontWeight="bold"
        textStyle="th"
      >
        {t("budget_overview.budget")}
      </Text>

      <Skeleton
        isLoaded={!isLoading}
        borderRadius="sm"
      >
        <Button
          label={buttonLabel}
          rightIcon={<GearIcon />}
          colorScheme="secondary"
          justifyContent="space-between"
          variant="outline"
          onClick={onClick}
          h="fit-content"
          textStyle="th"
          borderRadius={4}
          minW={100}
          p={1}
        />
      </Skeleton>
    </Stack>
  );
};
