import React, { useCallback } from "react";
import { Flex } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import { useModal } from "contexts/modal";
import { ButtonAsLink } from "components/ButtonAsLink";
import NullLeasingMobileDeLogo from "components/Logo/NullLeasingMobileDeLogo";
import { ROOT_PAGE_PATH } from "routes";

import { PageTitle } from "./PageTitle";
import { TitleBarProps } from "./types";

const TitleBar: React.FC<TitleBarProps> = ({
  title,
  textStyle,
  Icon,
  button,
  hasContainer,
  hasLogo,
}) => {
  const [showModal] = useModal();
  const router = useHistory();

  let wrapperProps = {};

  if (hasContainer) {
    wrapperProps = {
      maxWidth: "container.xl",
      margin: "0 auto",
    };
  } else if (button?.info || hasLogo) {
    wrapperProps = {
      justifyContent: "space-between",
    };
  }

  const handleClick = useCallback(() => showModal({
    ...button?.actionModal,
    closeOnOverlayClick: false,
  }), [
    showModal,
    button?.actionModal,
  ]);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      boxShadow="default"
      bgColor="white"
      h="fit-content"
      paddingX="6"
      paddingY="4"
      borderRadius="0 0 0 8px"
    >
      <Flex
        width="100%"
        {...wrapperProps}
      >
        <Flex width="full" h="fit-content">
          <PageTitle
            title={title || ""}
            Icon={Icon}
            textStyle={textStyle || "h1"}
          />
        </Flex>

        <Flex
          justifyContent="flex-end"
          minWidth="360px"
          width="50%"
        >
          {
            hasLogo && (
              <NullLeasingMobileDeLogo
                w={150}
                h="auto"
                onClick={() => router.push({
                  pathname: ROOT_PAGE_PATH,
                })}
                cursor="pointer"
              />
            )
          }

          { button
            && (
              <Flex>
                {
                  button?.navigatePath
                    ? (
                      <ButtonAsLink
                        path={button?.navigatePath}
                        colorScheme="primary"
                        label={button.info.label}
                      />
                    )
                    : (
                      <Button
                        onClick={handleClick}
                        label={button.info.label}
                      />
                    )
                }
              </Flex>
            )}
        </Flex>

      </Flex>
    </Flex>
  );
};

export default TitleBar;
