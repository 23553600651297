import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const AttachmentIcon: IconProps = createIcon({
  viewBox: "0 0 36.784 39.851",
  path: (
    <path
      fill="currentColor"
      d="M39.266,21.424a2.662,2.662,0,0,0-3.542,0L23.779,32.418a11.647,11.647,0,0,1-15.514.014A9.6,9.6,0,0,1,8.2,18.112L20.986,6.364a6.929,6.929,0,0,1,9.208,0,5.645,5.645,0,0,1,.1,8.365L17.41,26.568a2.15,2.15,0,0,1-2.842,0,1.733,1.733,0,0,1,0-2.572L26.516,12.985a2.191,2.191,0,0,0,0-3.266,2.5,2.5,0,0,0-.812-.507,2.722,2.722,0,0,0-1.917,0,2.5,2.5,0,0,0-.809.5L11.042,20.693a6.458,6.458,0,0,0-1.519,2.1,6.008,6.008,0,0,0,0,4.949,6.458,6.458,0,0,0,1.519,2.1,7.071,7.071,0,0,0,2.273,1.4,7.547,7.547,0,0,0,5.362,0,7.071,7.071,0,0,0,2.273-1.4l12.79-11.751a10.565,10.565,0,0,0,2.5-3.437,9.825,9.825,0,0,0,0-8.118,10.565,10.565,0,0,0-2.5-3.437,12.239,12.239,0,0,0-16.27,0L4.67,14.852A14.42,14.42,0,0,0,.3,22.4a13.685,13.685,0,0,0,.917,8.516,15.062,15.062,0,0,0,5.891,6.609A16.928,16.928,0,0,0,15.989,40a17.149,17.149,0,0,0,6.126-1.113,16.137,16.137,0,0,0,5.2-3.194L39.263,24.705a2.2,2.2,0,0,0,0-3.281Z"
    />
  ),
});

export default AttachmentIcon;
