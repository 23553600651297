import i18n from "translations/i18n";
import { CustomerTypeEnum } from "generated/graphql";

export default [
  {
    value: CustomerTypeEnum.Private,
    label: i18n.t("enum.customer_type.private"),
  },
  {
    value: CustomerTypeEnum.Corporate,
    label: i18n.t("enum.customer_type.corporate"),
  },
];
