import React from "react";
import { Button } from "@chakra-ui/react";

import {
  DealerInvoice,
} from "generated/graphql";
import { Column } from "components/Table/types";
import i18n from "translations/i18n";
import { formatCurrency } from "utils/currencyFormat";
import { formatWithParseDate, monthNameDay } from "utils/dateFormats";

export const makeTableColumns = (): Column<DealerInvoice>[] => [
  {
    title: i18n.t("components.table.heading.billing_range"),
    fieldResolver: dealerLeadsInvoices => formatWithParseDate(
      dealerLeadsInvoices.month,
      monthNameDay,
    ),
    cellProps: {
      width: "100px",
    },
  },
  {
    title: i18n.t("components.table.heading.billing_invoiced"),
    fieldResolver: dealerLeadsInvoices => formatCurrency({
      amount: dealerLeadsInvoices.totalCommission ?? 0,
      symbolPosition: "before",
    }),
    cellProps: {
      width: "100px",
    },
  },
  {
    title: i18n.t("components.table.heading.billing_leads"),
    render: ({ row }) => (
      <Button
        as="a"
        background="white"
        border="1px solid"
        borderColor="gray.300"
        color="secondary.500"
        height="33px"
        href={row.leadsDetailsExportUrl ?? ""}
        fontSize="sm"
        textDecoration="none"
        target="_blank"
        rel="noreferrer"
        _hover={{ textDecoration: "none" }}
        _focus={{ outline: "none" }}
      >
        {i18n.t("components.table.heading.billing_export_xls")}
      </Button>
    ),
  },
];
