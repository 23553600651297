import { CustomerTypeEnum, UpdateBrandDiscountParams } from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

const requiredErrorMessage = i18n.t("yup.mixed_required_field");

export const editVehicleSchema = yup.object().shape({
  listPrice: yup.string(),
  manufacturerBonusNet: yup.string(),
  configuratorLink: yup.string(),
  configurationNotes: yup.string(),
  bankId: yup.string().nullable(),
  calculateWithFactors: yup
    .string()
    .typeError(requiredErrorMessage)
    .required(requiredErrorMessage),
});

export type Leasing = {
  id?: number;
  internalId?: string | number;
  type?: CustomerTypeEnum;
  termMonths?: number;
  mileageKm?: number;
  residualRate?: number | string;
  residualFactor?: number | string;
  salesFactor?: number | string;
  nominalInterestRate?: number | string;
}

export type DynamicLeasingRates = Leasing[];

export type BrandDiscount = {
  private?: UpdateBrandDiscountParams;
  corporate?: UpdateBrandDiscountParams;
}

export interface EditVehicleSchema {
  customerType: CustomerTypeEnum;
  listPrice: string;
  manufacturerBonusNet: string;
  configuratorLink: string;
  configurationNotes: string;
  bankId: string;
  calculateWithFactors: string;
  brandDiscounts: BrandDiscount;
  dynamicLeasingRates: DynamicLeasingRates;
}
