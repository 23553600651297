import React from "react";
import {
  Flex, StackDivider, Text, VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import i18n from "translations/i18n";
import { CLASSIC_LEADS_OVERVIEW_PAGE_PATH, ROOT_PAGE_PATH } from "routes";
import appRoutes from "routes/appRoutes";
import { SidebarRouteProps, SidebarSectionsEnum } from "contexts/route/types";
import { useCurrentUser } from "contexts/currentUser";
import { UserType } from "contexts/currentUser/types";
import { roles } from "constants/roleConfig";
import { useSession } from "contexts/session";
import MobileDeLogo from "components/Logo/MobileDeLogo";
import NullLeasingLogo from "components/Logo/NullLeasingLogo";

import SidebarUserInfo from "./SidebarUserInfo";
import SidebarAction from "./SidebarAction";
import { SidebarActionItemsProps } from "./types";

const getActionsByRole = (
  routes: SidebarRouteProps[],
  user?: UserType | null,
): SidebarRouteProps[] => {
  if (!user) {
    return routes;
  }

  return routes.filter(route => route.userRequiredRoles.includes(user.role));
};

/**
 * Each section of the sidebar will be populated with the routes that have the
 * same section property based on the order of the appRoutes array.
 */
const Sidebar: React.FC = () => {
  const { endSession } = useSession();
  const router = useHistory();

  const [currentUser, , { isSalesUser }] = useCurrentUser();

  const { t } = useTranslation();

  const role = (
    currentUser
      ? roles[String(currentUser.role)]
      : ""
  );

  const visibleRoutes: SidebarRouteProps[] = getActionsByRole(
    appRoutes as SidebarRouteProps[], currentUser,
  )
    .filter((route) => route.showInSidebar)
    .filter((route) => {
      if (isSalesUser) {
        return route.sidebarSection === "LEADS" && route.label !== "Billing";
      }

      return route;
    });

  const items: SidebarActionItemsProps[] = visibleRoutes
    .map((route) => ({
      label: route.label,
      path: route.path,
      Icon: route.Icon,
      extraAction: route.extraAction,
      section: route.sidebarSection as SidebarSectionsEnum,
    }));

  const sections: SidebarActionItemsProps[][] = items.reduce((acc, item) => {
    const section = acc.find(accItem => accItem[0].section === item.section);

    if (section) {
      section.push(item);
    } else {
      acc.push([item]);
    }

    return acc;
  }, [] as SidebarActionItemsProps[][]);

  return (
    <Flex
      bgColor="white"
      w="300px"
      px="18px"
      py={7}
      maxH="100vh"
    >
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        alignItems="start-flex"
        flexDir="column"
        spacing={5}
        w="full"
      >
        <Flex
          alignItems="center"
          w="full"
        >
          <NullLeasingLogo
            cursor="pointer"
            w={264}
            h={45}
            onClick={() => router.push({
              pathname: isSalesUser ? CLASSIC_LEADS_OVERVIEW_PAGE_PATH : ROOT_PAGE_PATH,
            })}
          />
        </Flex>
        <SidebarUserInfo
          userName={String(currentUser?.seller?.name || currentUser?.email)}
          userRole={role}
          logoutAction={{
            onClick: endSession,
            label: i18n.t("actions.log_out"),
          }}
        />

        <Flex
          flexDir="column"
          overflowY="auto"
          w="full"
        >
          <VStack
            divider={<StackDivider borderColor="gray.200" />}
            alignItems="start-flex"
            flexDir="column"
            spacing={5}
            w="full"
          >
            {
              sections.map((section) => (
                <Flex
                  key={section[0].section}
                  flexDir="column"
                  w="full"
                >
                  <Text
                    textStyle="p"
                    fontSize={12}
                    mb={2}
                  >
                    {
                      t(`components.sidebar.sections.${section[0].section}`)
                    }
                  </Text>
                  {
                    section.map((item: SidebarActionItemsProps) => (
                      <SidebarAction
                        key={item.label}
                        action={item}
                      />
                    ))
                  }
                </Flex>
              ))
            }
          </VStack>
        </Flex>

        <Flex
          alignItems="center"
          w="full"
        >
          <MobileDeLogo
            w={130}
            h={54}
            onClick={() => router.push({
              pathname: isSalesUser ? CLASSIC_LEADS_OVERVIEW_PAGE_PATH : ROOT_PAGE_PATH,
            })}
            cursor="pointer"
          />
        </Flex>
      </VStack>
    </Flex>
  );
};

export default Sidebar;
