import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/react";

import SectionCard from "components/SectionCard";
import Input from "components/FormComponents/Inputs/Input";

import { titleCss } from "../../styles";
import { StandardDataFormProps } from "../types";

const OfferTitleCard: React.FC<StandardDataFormProps> = ({
  defaultFormValues,
  register,
  formState,
}) => {
  const { t } = useTranslation();

  return (
    <SectionCard
      title={t("custom_offer.steps.standard_data.offer_title_card.offer")}
    >
      <Stack spacing={6}>
        <Input
          {...register("title")}
          errors={formState.errors}
          title={t("custom_offer.steps.standard_data.offer_title_card.title")}
          titleCss={titleCss}
          placeholder={t("custom_offer.steps.standard_data.offer_title_card.insert_title")}
          defaultValue={defaultFormValues?.title}
          leftAddon={(<span>{defaultFormValues?.brand?.name}</span>)}
        />
      </Stack>
    </SectionCard>
  );
};
export default OfferTitleCard;
