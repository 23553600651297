import create from "zustand";

import { CalculatorOfferRow } from "../types";
import { CalculatorOffersStore } from "./types";

const useCalculatorOffersStore = create<CalculatorOffersStore>((set, get) => ({
  selectedItems: get()?.selectedItems || [],
  clearSelectedItems: () => {
    set({ selectedItems: [] });
  },
  toggleItem: (item: CalculatorOfferRow) => {
    set(({ selectedItems }) => {
      const selectedRow = selectedItems.find(({ id }) => id === item.id);

      if (!selectedRow) {
        return {
          selectedItems: [
            ...selectedItems,
            item,
          ],
        };
      }

      return {
        selectedItems: selectedItems.filter(({ id }) => id !== item.id),
      };
    });
  },
  toggleAllItems: (isAllSelected: boolean, items: CalculatorOfferRow[]) => {
    set(() => {
      if (isAllSelected) {
        return {
          selectedItems: [],
        };
      }

      return {
        selectedItems: items,
      };
    });
  },
}));

export default useCalculatorOffersStore;
