import React, { useMemo } from "react";
import { Stack, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { GRID_GAP } from "constants/layout";
import theme from "settings/theme";

import { DocumentsAvailableProps } from "./types";

const DocumentsAvailable: React.FC<DocumentsAvailableProps> = ({
  isHandlerContractPresent,
  isClientContractPresent,
  isPersonalDocumentPresent,
  isSelfAssessmentPresent,
  isFinancialEvidencePresent,
  isBusinessRegistrationPresent,
}) => {
  const { t } = useTranslation();

  const documents = useMemo(() => [
    {
      abbreviation: t("document_types.passport_abbreviation"),
      highlight: isPersonalDocumentPresent,
    },
    {
      abbreviation: t("document_types.business_registration_abbreviation"),
      highlight: isBusinessRegistrationPresent,
    },
    {
      abbreviation: t("document_types.self_declaration_abbreviation"),
      highlight: isSelfAssessmentPresent,
    },
    {
      abbreviation: t("document_types.self_salary_statements_abbreviation"),
      highlight: isFinancialEvidencePresent,
    },
    {
      abbreviation: t("document_types.leasing_contract_dealer_abbreviation"),
      highlight: isHandlerContractPresent,
    },
    {
      abbreviation: t("document_types.leasing_contract_customer_abbreviation"),
      highlight: isClientContractPresent,
    },
  ], [
    t,
    isHandlerContractPresent,
    isClientContractPresent,
    isPersonalDocumentPresent,
    isSelfAssessmentPresent,
    isFinancialEvidencePresent,
    isBusinessRegistrationPresent,
  ]);

  return (
    <Stack
      direction="row"
      spacing={GRID_GAP.XS}
      align="center"
    >
      {
        documents.map(document => (
          <Box
            key={document.abbreviation}
            bg={document.highlight ? theme.colors?.success?.[500] : theme.colors?.gray?.[200]}
            color={document.highlight ? theme.colors?.white : theme.colors?.gray?.[400]}
            opacity={document.highlight ? 1 : 0.6}
            borderRadius="4px"
            borderWidth="1px"
            borderColor="gray.400"
            paddingX={2}
            paddingY={1}
            fontFamily="regular"
          >
            {document.abbreviation}
          </Box>
        ))
      }
    </Stack>
  );
};

export default DocumentsAvailable;
