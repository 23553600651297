import { Dealer } from "generated/graphql";

import { OptionProps } from "../BaseSelect/types";
/**
 * Parses a dealer object into a select option.
 */
export const parseDealer = ({ id, companyName }: Pick<Dealer, "id" | "companyName">): OptionProps => ({
  label: companyName,
  value: id,
});
