import numeral from "numeral";
import "numeral/locales/de";

/**
 * localized numeral to german format
 * @param amount EUR amount
 * @param format numeral format
 * e.g.: 1.0000 and "0.0,0000" -> "1,0000"
 * e.g.: 422233.8637694973 and "0.0,0000" -> "422.233,863769"
 */
export const numeralLocalized = (amount = 0, format = "0,0.0000"): string => {
  const localizedAmount = amount.toString().replace(".", ",");
  numeral.locale("de");
  numeral.localeData("de").delimiters.thousands = ".";

  return numeral(localizedAmount).format(format);
};

const CURRENCY_FORMAT = "0,0.00";
const ABBREVIATION_FORMAT = "0,[0]a";

type FormatCurrency = {
  amount: number;
  useAbbreviation?: boolean;
  addSymbol?: boolean;
  symbolPosition?: "before" | "after";
}

/**
 * Receives a number and returns it formatted as currency
 * @param amount EUR amount
 * @param useAbbreviation boolean to that adds thousands formatting
 * e.g.: 1K €, 2.5K €
 */
export const formatCurrency = ({
  amount = 0,
  addSymbol = true,
  useAbbreviation = false,
  symbolPosition = "after",
}: FormatCurrency): string => {
  const formattedCurrency = numeralLocalized(
    amount,
    useAbbreviation ? ABBREVIATION_FORMAT : CURRENCY_FORMAT,
  ).toUpperCase();

  if (!addSymbol) {
    return formattedCurrency;
  }

  const formattedCurrencyWithSymbol = [
    symbolPosition === "before" ? "€ " : "",
    formattedCurrency,
    symbolPosition === "after" ? " €" : "",
  ].join("");

  return formattedCurrencyWithSymbol;
};

/**
 *
 * @param string EUR amount
 * e.g. : "10.000,12" -> 10000.12
 */
export const formatCurrencyToDecimal = (string = "0"): number => parseFloat(string.replace(/[^0-9,]/g, "").replace(",", "."));

/**
 *
 * @param string EUR amount
 * e.g. : "10000" -> "10.000,12"
 */
export const formatNumberToGerman = (number?: number): string | undefined => {
  if (!number) {
    return undefined;
  }

  return new Intl.NumberFormat("de-DE", { currency: "EUR" }).format(number);
};
