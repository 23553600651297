import { CustomerTypeEnum, Lead } from "generated/graphql";

import checkIsCorporateLead from "./checkIsCorporateLead";

/**
 * Returns the customer type for one lead.
 * @param {*} lead The lead
 */
const getLeadCustomerType = (lead: Lead): CustomerTypeEnum => {
  const isCorporateLead = checkIsCorporateLead(lead);

  return (
    isCorporateLead
      ? CustomerTypeEnum.Corporate
      : CustomerTypeEnum.Private
  );
};

export default getLeadCustomerType;
