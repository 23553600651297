import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@chakra-ui/react";

import { FUEL_CONSUMPTION } from "constants/units";

import { InnerSection } from "../../../../InnerSections";
import { InputField } from "../shared/InputField";
import { FieldsCommonProps } from "..";

interface DischargedConsumptionInputsProps extends FieldsCommonProps {
  translationsPrefix: string;
  measurementUnit: string;
}

const DischargedBatteryInputs = React.memo(({
  measurementUnit,
  translationsPrefix,
  register,
  formState,
}: DischargedConsumptionInputsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
      <InputField
        {...register?.("wltpConsumptionCombinedWithDischargedBattery")}
        name="wltpConsumptionCombinedWithDischargedBattery"
        title={t(`${translationsPrefix}.combined_discharged_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_combined_discharged_consumption`)}
        isRequired
        errors={formState?.errors}
        measurementUnit={measurementUnit}
      />

      <InputField
        {...register?.("wltpConsumptionCityWithDischargedBattery")}
        name="wltpConsumptionCityWithDischargedBattery"
        title={t(`${translationsPrefix}.city_discharged_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_city_discharged_consumption`)}
        errors={formState?.errors}
        measurementUnit={measurementUnit}
      />

      <InputField
        {...register?.("wltpConsumptionSuburbanWithDischargedBattery")}
        name="wltpConsumptionSuburbanWithDischargedBattery"
        title={t(`${translationsPrefix}.suburban_discharged_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_suburban_discharged_consumption`)}
        errors={formState?.errors}
        measurementUnit={measurementUnit}
      />

      <InputField
        {...register?.("wltpConsumptionRuralWithDischargedBattery")}
        name="wltpConsumptionRuralWithDischargedBattery"
        title={t(`${translationsPrefix}.rural_discharged_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_rural_discharged_consumption`)}
        measurementUnit={measurementUnit}
      />

      <InputField
        {...register?.("wltpConsumptionHighwayWithDischargedBattery")}
        name="wltpConsumptionHighwayWithDischargedBattery"
        title={t(`${translationsPrefix}.highway_discharged_consumption`)}
        placeholder={t(`${translationsPrefix}.insert_highway_discharged_consumption`)}
        errors={formState?.errors}
        measurementUnit={measurementUnit}
      />
    </Grid>
  );
});

export const ConsumptionWithDischargedBattery = ({
  register,
  formState,
}: FieldsCommonProps): JSX.Element => {
  const { t } = useTranslation();
  const translationsPrefix = "custom_offer.steps.standard_data.fuel_card";

  return (
    <InnerSection label={t(`${translationsPrefix}.consumption_with_discharged_battery`)}>
      <DischargedBatteryInputs
        translationsPrefix={translationsPrefix}
        measurementUnit={FUEL_CONSUMPTION}
        register={register}
        formState={formState}
      />
    </InnerSection>
  );
};
