import {
  useMemo, useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { addSeconds } from "date-fns";

import crmSearchParams from "constants/crmSearchParams";

import { UseCrmChannelPayload } from "./types";

const ONE_HOUR_IN_SECONDS = 3600;

/**
 * This hook exposes methods for handling and reading dealer_crm_channel cookie
 */
export const useCrmChannel = (): UseCrmChannelPayload => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => (
    new URLSearchParams(location.search)
  ), [
    location,
  ]);

  const getCrmChannel = useCallback(() => (
    Cookies.get(crmSearchParams.crmChannel)
  ), []);

  const setCrmChannelCookie = useCallback((crmChannelValue: string) => {
    Cookies.set(
      crmSearchParams.crmChannel,
      crmChannelValue, {
        expires: addSeconds(new Date(), ONE_HOUR_IN_SECONDS),
        path: "/",
      },
    );
  }, []);

  const removeCrmChannelSearchParam = useCallback((): void => {
    if (!queryParams.has(crmSearchParams.crmChannel)) {
      return;
    }

    queryParams.delete(crmSearchParams.crmChannel);

    history.replace({
      search: queryParams.toString(),
    });
  }, [
    queryParams,
    history,
  ]);

  const payload = useMemo(() => ({
    getCrmChannel,
    removeCrmChannelSearchParam,
    setCrmChannelCookie,
  }), [
    removeCrmChannelSearchParam,
    setCrmChannelCookie,
    getCrmChannel,
  ]);

  return payload;
};
