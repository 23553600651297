import React from "react";
import {
  Control, Controller, FormState, UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@chakra-ui/react";

import Select from "components/FormComponents/Inputs/Selects/Select";
import Input from "components/FormComponents/Inputs/Input";
import { StandardDataSchema } from "settings/yup/schemas/standardDataSchema";
import { titleCss } from "views/CustomOffer/components/Steps/CustomOfferSteps/styles";
import { germanNumberMask } from "constants/inputMasks";
import { CARBONIC_EMISSION, POWER_CONSUMPTION_COMBINED } from "constants/units";
import { OfferFuelTypeEnum } from "generated/graphql";

import { makeEnergyEfficiencyClassOptions, makePollutantClassOptions } from "../../../../../makeOptions";
import { getMeasurementUnit } from "../../utils";

interface Props {
  control: Control<StandardDataSchema>;
  formState: FormState<StandardDataSchema>;
  register: UseFormRegister<StandardDataSchema>;
  fuelType: OfferFuelTypeEnum;
}

export const Fields = ({
  control,
  formState,
  register,
  fuelType,
}: Props):JSX.Element => {
  const { t } = useTranslation();

  const measurementUnit = getMeasurementUnit(fuelType);

  const isElectric = fuelType === OfferFuelTypeEnum.Electric;

  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      w="full"
      gap={4}
    >
      {
        isElectric && (
          <Input
            {...register("powerConsumptionCombined")}
            placeholder={t("custom_offer.steps.standard_data.fuel_card.power_consumption_combined_placeholder")}
            title={t("custom_offer.steps.standard_data.fuel_card.power_consumption_combined")}
            rightAddon={(<span>{POWER_CONSUMPTION_COMBINED}</span>)}
            errors={formState.errors}
            titleCss={titleCss}
            mask={germanNumberMask}
            rightAddonProps={{
              color: "gray.400",
            }}
          />
        )
      }

      {
        !isElectric && (
          <>
            <Input
              {...register("fuelConsumptionCombined")}
              placeholder={t("custom_offer.steps.standard_data.fuel_card.fuel_consumption_combined_placeholder")}
              title={t("custom_offer.steps.standard_data.fuel_card.fuel_consumption_combined")}
              rightAddon={(<span>{measurementUnit}</span>)}
              errors={formState.errors}
              titleCss={titleCss}
              mask={germanNumberMask}
              rightAddonProps={{
                color: "gray.400",
              }}
            />

            <Input
              {...register("fuelConsumptionCity")}
              placeholder={t("custom_offer.steps.standard_data.fuel_card.fuel_consumption_city_placeholder")}
              title={t("custom_offer.steps.standard_data.fuel_card.fuel_consumption_city")}
              rightAddon={(<span>{measurementUnit}</span>)}
              errors={formState.errors}
              titleCss={titleCss}
              mask={germanNumberMask}
              rightAddonProps={{
                color: "gray.400",
              }}
            />

            <Input
              {...register("fuelConsumptionHighway")}
              placeholder={t("custom_offer.steps.standard_data.fuel_card.fuel_consumption_highway_placeholder")}
              title={t("custom_offer.steps.standard_data.fuel_card.fuel_consumption_highway")}
              rightAddon={(<span>{measurementUnit}</span>)}
              errors={formState.errors}
              titleCss={titleCss}
              mask={germanNumberMask}
              rightAddonProps={{
                color: "gray.400",
              }}
            />

            <Controller
              name="pollutantClass"
              control={control}
              render={({
                field,
                fieldState,
              }) => (
                <Select
                  {...field}
                  {...fieldState}
                  placeholder={t("custom_offer.steps.standard_data.fuel_card.pollutant_class_placeholder")}
                  title={t("custom_offer.steps.standard_data.fuel_card.pollutant_class")}
                  options={makePollutantClassOptions()}
                  errors={formState.errors}
                  titleCss={titleCss}
                />
              )}
            />

            <Input
              {...register("pollutantClassDetailed")}
              placeholder={t("custom_offer.steps.standard_data.fuel_card.pollutant_class_detailed_placeholder")}
              title={t("custom_offer.steps.standard_data.fuel_card.pollutant_class_detailed")}
              errors={formState.errors}
              titleCss={titleCss}
            />
          </>
        )
      }

      <Input
        {...register("carbonicEmission")}
        placeholder={t("custom_offer.steps.standard_data.fuel_card.carbonic_emission_placeholder")}
        title={t("custom_offer.steps.standard_data.fuel_card.carbonic_emission")}
        rightAddon={(<span>{CARBONIC_EMISSION}</span>)}
        errors={formState.errors}
        titleCss={titleCss}
        mask={germanNumberMask}
        rightAddonProps={{
          color: "gray.400",
        }}
      />

      <Controller
        name="energyEfficiencyClass"
        control={control}
        render={({
          field,
          fieldState,
        }) => (
          <Select
            {...field}
            {...fieldState}
            placeholder={t("custom_offer.steps.standard_data.fuel_card.energy_efficiency_class_placeholder")}
            title={t("custom_offer.steps.standard_data.fuel_card.energy_efficiency_class")}
            options={makeEnergyEfficiencyClassOptions()}
            errors={formState.errors}
            titleCss={titleCss}
          />
        )}
      />
    </Grid>
  );
};
