import { Bank } from "generated/graphql";

export interface BanksTableProp {
  banks: Bank[];
  loading?: boolean;
}

export interface BanksActions {
  onEditBank: (row: Bank) => () => void;
}

export enum BankSortFieldsEnum {
  name = "name",
}
