import React from "react";
import { Input as ChakraInput } from "@chakra-ui/react";

import {
  defaultFocusCss,
  disabledCss,
  hoverCss,
  invalidCss,
  placeholderStyles,
} from "../styles";
import { BaseInputProps } from "./types";

/**
 * This component is supposed to be used, as the name suggests, as a base for other
 * inputs implementations. This component handles all the generic stylization logic as
 * `disabled`, `hover`, `focus`, `typography`, etc.
 */
const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>((
  {
    focusCss = defaultFocusCss,
    name,
    ...props
  },
  ref,
) => (
  <ChakraInput
    _placeholder={placeholderStyles}
    _disabled={disabledCss}
    backgroundColor="white"
    borderColor="gray.400"
    _invalid={invalidCss}
    border="1px solid"
    _hover={hoverCss}
    _focus={focusCss}
    variant="filled"
    fontSize="14px"
    textStyle="p"
    name={name}
    ref={ref}
    {...props}
  />
));

export default BaseInput;
