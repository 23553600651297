/* eslint-disable dot-notation */
/* eslint-disable no-console */
import { GAEvent } from "constants/gaEvents";

/**
 * Attempts to track event via Google Analytics.
 * @param {*} category The event as described in gaEvents
 */
const trackGAEvent = (event: GAEvent): void => {
  if (!window?.["ga"] || !event) {
    return;
  }

  try {
    const trackerName = window["ga"]?.getAll()?.[0].get("name");

    window["ga"](
      `${trackerName}.send`,
      "event",
      event.category,
      event.action,
      event.label,
    );
  } catch {
    console.warn("Failed to track google analytics event");
  }
};

export default trackGAEvent;
