import React, { FC } from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";

import { AlertBoxProps } from "./types";

export const AlertBox: FC<AlertBoxProps> = ({
  Icon = <AlertIcon />,
  label,
  ...props
}) => (
  <Alert
    status="warning"
    {...props}
  >
    {
      Icon
    }
    {
      label
    }
  </Alert>
);
