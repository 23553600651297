import { isValidDate } from "./isValidDate";

export const parseYearToDate = (year?: number | Date | null): undefined | Date => {
  if (isValidDate(year)) {
    return year as Date;
  }

  if (typeof year !== "number") {
    return undefined;
  }

  return new Date(year, 0);
};
