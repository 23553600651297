import colors from "settings/theme/colors";

export const tableCellStyles = {
  minHeight: "40px",
  maxWidth: "fit-content",
  borderRadius: "5px",
  "&:focus": {
    boxShadow: "none",
    border: `2px solid ${colors.secondary["500"]}`,
  },
};
