import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

import { name, version } from "../../../package.json";
import ignoreErrors from "./ignoreErrors";
import denyUrls from "./denyUrls";

/**
 * Applies configuration for Sentry.
 */
const SENTRY_DSN = "https://bf545499000d4a7f8740f7919baf6af6@o987713.ingest.sentry.io/6119912";

const configureSentry = (): void => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: process.env.NODE_ENV,
      release: `${name}@${version}`,
      ignoreErrors,
      denyUrls,
      integrations: [
        new Integrations.BrowserTracing(),
      ],
      tracesSampleRate: 1.0,
    });

    Sentry.configureScope(scope => {
      scope.setTag("app", "frontend");
    });
  }
};

export default configureSentry;
