import React from "react";
import { Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import camelCase from "lodash.camelcase";

import SectionCard from "components/SectionCard";
import Textarea from "components/FormComponents/Inputs/Textarea";
import Checkbox from "components/FormComponents/Checkbox";
import { OfferDetailsSchema } from "settings/yup/schemas/offerDetailsSchema";

import { textAreaTitleStyles } from "./styles";
import { SpecialConditionsCardProps } from "./types";

const getFieldName = (text: string): string => `formSpecialConditions.${camelCase(text)}`;

const SpecialConditionsCard: React.FC<SpecialConditionsCardProps> = ({
  specialConditions,
  formState,
  register,
  watch,
}) => {
  const { t } = useTranslation();

  return (
    <SectionCard
      title={t("custom_offer.steps.offer_details.special_conditions")}
    >
      <Grid
        templateColumns="repeat(4,1fr)"
        gap={3}
        mb={6}
      >
        {
          specialConditions?.map(condition => (
            <Checkbox
              {...register(`${getFieldName(condition.slug)}.isChecked` as keyof OfferDetailsSchema)}
              key={condition.slug}
            >
              {condition.name}
            </Checkbox>
          ))
        }
      </Grid>

      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={6}
      >
        {
          specialConditions?.map(condition => (
            <Textarea
              title={`${t("custom_offer.steps.offer_details.note_text")} (${condition.name})`}
              placeholder={t("custom_offer.steps.offer_details.enter_condition_description")}
              {...register(`${getFieldName(condition.slug)}.description` as keyof OfferDetailsSchema)}
              isDisabled={!watch(`${getFieldName(condition.slug)}.isChecked` as keyof OfferDetailsSchema)}
              titleProps={textAreaTitleStyles}
              errors={formState.errors}
              key={condition.name}
              borderStyle="dashed"
              borderRightWidth="0"
              borderLeftWidth="0"
              borderTopWidth="0"
              borderRadius="0"
              rows={4}
            />
          ))
        }
      </Grid>
    </SectionCard>
  );
};

export default SpecialConditionsCard;
