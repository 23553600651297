import React from "react";
import ConditionalWrap from "conditional-wrap";
import { Text } from "@chakra-ui/react";

import { TextConditionalWrapProps } from "./types";

const TextConditionalWrap: React.FC<TextConditionalWrapProps> = ({
  renderChildrenAsText = true,
  children,
  titleCss,
  ...props
}) => (
  <ConditionalWrap
    condition={renderChildrenAsText}
    wrap={(content) => (
      <Text
        whiteSpace="nowrap"
        textStyle="p"
        {...titleCss}
        {...props}
      >
        {content}
      </Text>
    )}
  >
    <>
      {children}
    </>
  </ConditionalWrap>
);

export default TextConditionalWrap;
