import React, { useCallback } from "react";
import {
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { ROOT_PAGE_PATH } from "routes";
import { IS_AUTHENTICATED_COOKIE_NAME } from "constants/isAuthenticatedKey";

import { ExpiredSessionErrorNotifierProps } from "./types";

export const ExpiredSessionErrorNotifier: React.FC<ExpiredSessionErrorNotifierProps> = ({
  children,
}) => {
  const [, , removeCookie] = useCookies([IS_AUTHENTICATED_COOKIE_NAME]);

  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast();
  const TIMEOUT_IN_MILLISECONDS = 1000;

  const handleUnauthenticated = useCallback(() => {
    removeCookie(IS_AUTHENTICATED_COOKIE_NAME);

    history.push(ROOT_PAGE_PATH);
  }, [
    removeCookie,
    history,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showAuthError = useCallback(useDebouncedCallback(() => {
    handleUnauthenticated();

    toast({
      title: t("info.session_expired"),
      description: t("info.please_sign_in_again"),
      status: "info",
    });
  }, TIMEOUT_IN_MILLISECONDS), [
    handleUnauthenticated,
    toast,
    t,
  ]);

  return <>{children?.({ showAuthError })}</>;
};
