import React from "react";

import i18n from "translations/i18n";
import getVehicleCategories from "utils/getVehicleCategories";

import { CategorySelectProps } from "./types";
import Select from "../Select";

const CategorySelect: React.FC<CategorySelectProps> = (props) => {
  const {
    value,
    isDisabled,
  } = props;

  const options = getVehicleCategories();

  const defaultValue = options.length === 1
    ? options[0]
    : undefined;

  return (
    <Select
      {...props}
      options={options}
      isDisabled={isDisabled}
      value={value || defaultValue}
    />
  );
};

export default CategorySelect;

CategorySelect.defaultProps = {
  placeholder: i18n.t("components.selects.vehicle_category"),
  isSearchable: true,
};
