import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const CalculatorIcon: IconProps = createIcon({
  viewBox: "0 0 20 21",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83317 2.25879H14.1665C15.087 2.25879 15.8332 3.00498 15.8332 3.92546V17.2588C15.8332 18.1793 15.087 18.9255 14.1665 18.9255H5.83317C4.9127 18.9255 4.1665 18.1793 4.1665 17.2588V3.92546C4.1665 3.00498 4.9127 2.25879 5.83317 2.25879ZM5.83317 15.5921V17.2588H7.49984V15.5921H5.83317ZM5.83317 12.2588V13.9255H7.49984V12.2588H5.83317ZM5.83317 8.92545V10.5921H7.49984V8.92545H5.83317ZM6.24984 3.92546C6.01972 3.92546 5.83317 4.112 5.83317 4.34212V7.25879H14.1665V4.34212C14.1665 4.112 13.98 3.92546 13.7498 3.92546H6.24984ZM9.1665 15.5921V17.2588H10.8332V15.5921H9.1665ZM9.1665 12.2588V13.9255H10.8332V12.2588H9.1665ZM9.1665 8.92545V10.5921H10.8332V8.92545H9.1665ZM12.4998 15.5921V17.2588H14.1665V15.5921H12.4998ZM12.4998 12.2588V13.9255H14.1665V12.2588H12.4998ZM12.4998 8.92545V10.5921H14.1665V8.92545H12.4998Z"
      fill="currentColor"
    />
  ),
});

export default CalculatorIcon;
