import { TestConfig } from "yup";

import i18n from "translations/i18n";

const passwordNumberTest = (): TestConfig<string | undefined> => ({
  name: "numberTest",
  message: i18n.t("yup.password_strength_must_have"),
  test: (value): boolean => (
    !value || (
      !!value
        && /(?=.*[0-9])/.test(value)
    )
  ),
});

export default passwordNumberTest;
