import { Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import SectionCard from "components/SectionCard";

import { DeliveryPricesProps } from "../../types";
import CustomMonetaryField from "../Fields/CustomMonetaryField";
import { titleCss } from "../../styles";

const DeliveryPrices: React.FC<DeliveryPricesProps> = ({
  defaultValues,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <SectionCard
      title={t("components.modals.edit_leasing_rates_modal.delivery_prices")}
    >
      <Flex justifyContent="space-between" mb={10}>
        <Stack w="md">
          <CustomMonetaryField
            netTitle={t("components.modals.edit_leasing_rates_modal.dealer_pickup_price_net")}
            grossTitle={t("components.modals.edit_leasing_rates_modal.dealer_pickup_price_gross")}
            titleCss={titleCss}
            name="deliveryPrices.0.dealerPickupPrice"
            defaultValue={defaultValues?.[0]?.dealerPickupPrice}
            setValue={setValue}
            includedCheckbox
          />
        </Stack>

        <Stack w="md" ml="10" mr="10">
          <CustomMonetaryField
            netTitle={t("components.modals.edit_leasing_rates_modal.house_delivery_price_net")}
            grossTitle={t("components.modals.edit_leasing_rates_modal.house_delivery_price_gross")}
            titleCss={titleCss}
            name="deliveryPrices.0.houseDeliveryPrice"
            defaultValue={defaultValues?.[0]?.houseDeliveryPrice}
            setValue={setValue}
            includedCheckbox
          />
        </Stack>

        <Stack w="md">
          <CustomMonetaryField
            netTitle={t("components.modals.edit_leasing_rates_modal.factory_pickup_price_net")}
            grossTitle={t("components.modals.edit_leasing_rates_modal.factory_pickup_price_gross")}
            titleCss={titleCss}
            name="deliveryPrices.0.factoryPickupPrice"
            defaultValue={defaultValues?.[0]?.factoryPickupPrice}
            setValue={setValue}
            includedCheckbox
          />
        </Stack>
      </Flex>
    </SectionCard>
  );
};

export default DeliveryPrices;
