import React, { useCallback } from "react";
import { Stack, Heading, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Card } from "components/Card";
import { PasswordField } from "components/FormComponents/Inputs/PasswordField";
import Button from "components/Button";
import updatePasswordSchema, {
  UpdatePasswordSchema,
} from "settings/yup/schemas/updatePasswordSchema";
import { useUpdatePasswordMutation } from "generated/graphql";
import { ROOT_PAGE_PATH } from "routes";

import { UpdatePasswordFormProps } from "./types";

const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = ({
  title,
  token,
  submitButtonText,
}) => {
  const newToast = useToast();
  const history = useHistory();

  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<UpdatePasswordSchema>({
    resolver: yupResolver(updatePasswordSchema),
    mode: "onChange",
  });

  const [updatePassword, { loading }] = useUpdatePasswordMutation();

  const onSubmit = useCallback((data: UpdatePasswordSchema) => {
    if (token) {
      updatePassword({
        variables: {
          token,
          password: data.password,
        },
      })
        .then(() => {
          newToast({
            description: t("update_password_form.success_msg"),
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          history.push(ROOT_PAGE_PATH);
        })
        .catch(() => {
          newToast({
            title: t("update_password_form.update_password_error"),
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });
    }
  }, [
    newToast,
    history,
    t,
    token,
    updatePassword,
  ]);

  return (
    <Card p={6}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <Stack>
            <Heading
              as="h1"
              lineHeight={1.1}
              fontSize="28px"
              textAlign="center"
            >
              {title}
            </Heading>
          </Stack>

          <PasswordField
            title={t("update_password_form.new_password_input")}
            {...register("password")}
            errors={errors}
            data-testid="password"
          />

          <PasswordField
            title={t("update_password_form.confirm_new_password_input")}
            {...register("confirmPassword")}
            errors={errors}
            data-testid="confirmPassword"
          />

          <Button
            label={submitButtonText}
            isLoading={loading}
            colorScheme="primary"
            fontFamily="regular"
            type="submit"
            fontSize="md"
            size="lg"
            w="full"
          />
        </Stack>
      </form>
    </Card>
  );
};

export default UpdatePasswordForm;
