import {
  FormState,
} from "react-hook-form";
import {
  UseFormSetValue,
  Control,
  UseFormWatch,
  UseFormRegister,
  UseFormClearErrors,
  UseFormTrigger,
} from "react-hook-form/dist/types";
import React from "react";

import { GetOfferQuery } from "generated/graphql";
import { StandardDataSchema } from "settings/yup/schemas/standardDataSchema";

export interface RegisterProps {
  register: UseFormRegister<StandardDataSchema>;
  control: Control<StandardDataSchema>;
  formState: FormState<StandardDataSchema>;
  watch: UseFormWatch<StandardDataSchema>;
  clearErrors: UseFormClearErrors<StandardDataSchema>;
  trigger: UseFormTrigger<StandardDataSchema>;
}

export interface StandardDataFormProps extends RegisterProps {
  defaultFormValues?: StandardDataProps["defaultFormValues"];
  setValue: UseFormSetValue<StandardDataSchema>;
}

export interface StandardDefaultProps extends StandardDataSchema {
  brand?: {
    name: string;
  };
}

export interface StandardDataProps {
  defaultFormValues?: GetOfferQuery["offer"] | StandardDefaultProps;
}

export interface GetFieldsReturn {
  Component: React.FC<StandardDataFormProps>;
  titleAppend: string;
}

export enum EnergyEfficiencyClassEnum {
  A_PLUS_3 = "A+++",
  A_PLUS_2 = "A++",
  A_PLUS = "A+",
  A = "A",
  B_PLUS = "B+",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G"
}
