import React from "react";
import {
  Box,
  Flex,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import i18n from "translations/i18n";
import { formatFullDate } from "utils/dateFormats";
import DealerUsersSelect from "components/FormComponents/Inputs/Selects/DealerUsersSelect";
import LeadStatusSelect from "components/FormComponents/Inputs/Selects/LeadStatusSelect";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";

import { LeadInformationSectionFormProps } from "./types";

const LeadInformation: React.FC<LeadInformationSectionFormProps> = ({
  isCheckout,
  formState,
  control,
  lead,
  loading,
}) => (
  <Stack mb={4}>
    <LeadDetailsCard title={i18n.t("leads.details.form.lead_information")}>
      {
        loading ? (
          <Box w="full">
            <Skeleton w="md" h={8} mb={4} />
            <Skeleton w="full" h={8} />
          </Box>
        )
          : (
            <Flex w="full">
              <FakeTextField
                value={formatFullDate(new Date(lead?.createdDate ?? ""))}
                label={i18n.t("leads.details.form.date_of_receipt")}
                mr={8}
              />

              <FakeTextField
                value={lead?.id}
                label={i18n.t("leads.details.form.lead_ID")}
                mr={8}
              />

              <Box w={48} mr={8}>
                <Controller
                  name="salesperson"
                  control={control}
                  render={({
                    field,
                    fieldState,
                  }) => (
                    <DealerUsersSelect
                      {...field}
                      {...fieldState}
                      placeholder={i18n.t("leads.details.form.salesperson")}
                      title={i18n.t("leads.details.form.salesperson")}
                      dealerId={lead?.dealerId}
                      isDisabled={loading}
                      isLoading={loading}
                    />
                  )}
                />
              </Box>

              <Box w={48}>
                <Controller
                  name="status"
                  control={control}
                  defaultValue={lead?.status}
                  render={({
                    field,
                    fieldState,
                  }) => (
                    <LeadStatusSelect
                      {...field}
                      {...fieldState}
                      placeholder={i18n.t("leads.details.form.status")}
                      title={i18n.t("leads.details.form.status")}
                      isDisabled={loading}
                      isLoading={loading}
                      defaultValue={lead?.status}
                      errors={formState.errors}
                      isCheckout={isCheckout}
                    />
                  )}
                />
              </Box>
            </Flex>
          )
      }
    </LeadDetailsCard>
  </Stack>
);

export default LeadInformation;
