import { gql } from "@apollo/client";

export const UPDATE_BRAND_REFETCH_QUERIES = [
  "GetBrands",
];

export default gql`
  mutation UpdateBrand ($id: Int!, $params: UpdateBrandParams!) {
    updateBrand(id: $id, params: $params) {
      id
    }
  }
`;
