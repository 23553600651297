import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import i18n from "translations/i18n";

import { HighlightLabelProps } from "../types";

const HighlightLabel: React.FC<HighlightLabelProps> = ({
  label = i18n.t("components.highlight_label.insert_picture"),
}) => (
  <Flex
    transform="translateY(-60%)"
    bgColor="secondary.500"
    position="absolute"
    borderRadius="4px"
    padding="2px 5px"
    textStyle="p"
    color="white"
    left="10px"
    top="0"
    zIndex="docked"
  >
    <Text>
      {label}
    </Text>
  </Flex>
);

export default HighlightLabel;
