import { gql } from "@apollo/client";

export const GET_CLASSIC_LEADS_REFETCH_QUERIES = [
  "GetClassicLeads",
];

export const GET_CHECKOUT_LEADS_REFETCH_QUERIES = [
  "GetCheckoutLeads",
];

const updateLeadsMutation = gql`
  mutation UpdateLeads ($params: [UpdateLeadsParams]!) {
    updateLeads(params: $params) {
      id
    }
  }
`;

export default updateLeadsMutation;
