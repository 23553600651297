import React, {
  ChangeEvent, useState, useEffect,
} from "react";
import {
  Text,
} from "@chakra-ui/react";

import i18n from "translations/i18n";
import { CustomerTabContentProps } from "components/Modals/EditVehicleModal/types";
import {
  MAP_FACTORS, MAP_TAB_TITLE, TableFactorsEnum,
} from "components/Modals/EditVehicleModal/constants";
import Input from "components/FormComponents/Inputs/Input";
import ModalRow from "components/Modals/EditVehicleModal/ModalRow";
import Tabs from "components/Tabs";
import { CustomerTypeEnum, UpdateBrandDiscountParams } from "generated/graphql";
import MonetaryField from "components/FormComponents/Inputs/MonetaryField";
import isPositiveValue from "utils/isPositiveValue";

import LeasingFactorsTable from "../LeasingFactorsTable";

export const parseDiscountValues = (price?: string)
: number | undefined => {
  const floatValue = price ? parseFloat(price) : undefined;
  return (isPositiveValue(floatValue)
    ? floatValue
    : undefined
  );
};

const CustomerTabContent: React.FC<CustomerTabContentProps> = ({
  type,
  vehicle,
  setValue,
  getValues,
}) => {
  const [brandDiscount, setBrandDiscount] = useState<UpdateBrandDiscountParams>(
    vehicle.brandDiscounts?.find(v => v.type === type) || {},
  );

  useEffect(() => {
    const vehicleBrand = { ...vehicle.brandDiscounts?.find(v => v.type === type) };

    // eslint-disable-next-line no-underscore-dangle
    delete vehicleBrand?.__typename;

    setBrandDiscount(vehicleBrand);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === CustomerTypeEnum.Private) {
      setValue("brandDiscounts.private", brandDiscount);
      return;
    }

    setValue("brandDiscounts.corporate", brandDiscount);
  }, [
    brandDiscount,
    setValue,
    type,
  ]);

  const defaultDiscount = parseDiscountValues(brandDiscount?.discount);
  const defaultDownPaymentDiscount = parseDiscountValues(brandDiscount?.downPaymentDiscount);
  const defaultDiscountRate = parseDiscountValues(brandDiscount?.listPriceDiscountRate);

  return (
    <>
      <Text
        fontSize="18px"
        textStyle="h3"
        mb={8}
        mt={4}
      >
        {MAP_TAB_TITLE[type]}
      </Text>

      <ModalRow>
        <MonetaryField
          name="brandDiscount"
          title={i18n.t("components.modals.edit_vehicle_modal.discount")}
          defaultValue={defaultDiscount}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setBrandDiscount(
            {
              ...brandDiscount,
              type,
              discount: parseDiscountValues(e.target?.value),
            },
          )}
          showErrorMessage
          mb={6}
          acceptEmptyValue
        />

        <MonetaryField
          name="downPaymentDiscount"
          title={i18n.t("components.modals.edit_vehicle_modal.down_payment")}
          defaultValue={defaultDownPaymentDiscount}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setBrandDiscount(
            {
              ...brandDiscount,
              type,
              downPaymentDiscount: parseDiscountValues(e.target?.value),
            },
          )}
          placeholder="1.000"
          showErrorMessage
          mb={6}
          acceptEmptyValue
        />

        <Input
          name="brandDiscountRate"
          type="number"
          title={i18n.t("components.modals.edit_vehicle_modal.discount_rate")}
          defaultValue={defaultDiscountRate
            ? (defaultDiscountRate * 100)
            : defaultDiscountRate}
          rightElement="%"
          rightElementProps={{
            wordBreak: "break-all",
            overflow: "hidden",
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const parsedDiscount = parseDiscountValues(e.target?.value);
            return setBrandDiscount(
              {
                ...brandDiscount,
                type,
                listPriceDiscountRate: typeof parsedDiscount === "number"
                  ? (parsedDiscount / 100)
                  : parsedDiscount,
              },
            );
          }}
          showErrorMessage
          pr={10}
          mb={6}
        />
      </ModalRow>

      <ModalRow pb={8}>
        <Tabs
          boxShadow="lg"
          tabs={[
            {
              label: MAP_FACTORS.residualRate.translationKey,
              content: (
                <LeasingFactorsTable
                  disclaimer={i18n.t("components.modals.edit_vehicle_modal.disclaimer")}
                  data={vehicle?.dynamicLeasingRates}
                  factor={TableFactorsEnum.residualRate}
                  type={type}
                  setValue={setValue}
                  getValues={getValues}
                />
              ),
            },
            {
              label: MAP_FACTORS.residualFactor.translationKey,
              content: (
                <LeasingFactorsTable
                  disclaimer={i18n.t("components.modals.edit_vehicle_modal.disclaimer")}
                  data={vehicle?.dynamicLeasingRates}
                  factor={TableFactorsEnum.residualFactor}
                  type={type}
                  setValue={setValue}
                  getValues={getValues}
                />
              ),
            },
            {
              label: MAP_FACTORS.salesFactor.translationKey,
              content: (
                <LeasingFactorsTable
                  disclaimer={i18n.t("components.modals.edit_vehicle_modal.disclaimer")}
                  data={vehicle?.dynamicLeasingRates}
                  factor={TableFactorsEnum.salesFactor}
                  type={type}
                  setValue={setValue}
                  getValues={getValues}
                />
              ),
            },
            {
              label: MAP_FACTORS.nominalInterestRate.translationKey,
              content: (
                <LeasingFactorsTable
                  disclaimer={i18n.t("components.modals.edit_vehicle_modal.disclaimer")}
                  data={vehicle?.dynamicLeasingRates}
                  factor={TableFactorsEnum.nominalInterestRate}
                  type={type}
                  setValue={setValue}
                  getValues={getValues}
                />
              ),
            },
          ]}
        />
      </ModalRow>
    </>
  );
};

export default CustomerTabContent;
