import React, {
  useCallback,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

import { OFFERS_PAGE_PATH, ROOT_PAGE_PATH } from "routes";
import { useSignOutUserMutation } from "generated/graphql";
import { IS_AUTHENTICATED_COOKIE_NAME } from "constants/isAuthenticatedKey";

import { SessionContextPayload } from "./types";
import { SessionProvider } from ".";

const SessionContainer: React.FC = ({
  children,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies([IS_AUTHENTICATED_COOKIE_NAME]);
  const history = useHistory();

  const [signOut, { loading }] = useSignOutUserMutation();

  const createSession = useCallback((redirectTo = OFFERS_PAGE_PATH) => {
    setCookie(IS_AUTHENTICATED_COOKIE_NAME, true);

    history.push(redirectTo);
  }, [
    setCookie,
    history,
  ]);

  const endSession = useCallback(() => {
    if (loading) {
      return;
    }

    try {
      signOut();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      removeCookie(IS_AUTHENTICATED_COOKIE_NAME);

      history.push(ROOT_PAGE_PATH);
    }
  }, [
    removeCookie,
    signOut,
    history,
    loading,
  ]);

  const isAuthenticated = useMemo(() => (
    cookies[IS_AUTHENTICATED_COOKIE_NAME] === "true"
  ), [
    cookies,
  ]);

  const contextValue = useMemo<SessionContextPayload>(() => ({
    isAuthenticated,
    createSession,
    endSession,
  }), [
    isAuthenticated,
    createSession,
    endSession,
  ]);

  return (
    <SessionProvider value={contextValue}>
      {children}
    </SessionProvider>
  );
};

export default SessionContainer;
