import React, { useCallback } from "react";
import {
  Flex,
  Skeleton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import Button from "components/Button";
import { useModal } from "contexts/modal";
import RequestLeadRejectionModal from "components/Modals/RequestLeadRejectionModal";
import { LeadRejectionStatusEnum } from "generated/graphql";
import { GET_LEAD_DETAILS_REFETCH_QUERIES } from "views/Leads/Details/graphql/getLeadDetails";

import { RequestLeadRejectionProps } from "./types";

const RequestLeadRejection: React.FC<RequestLeadRejectionProps> = ({
  lead,
  isLoading,
  isExpandable = false,
}) => {
  const { t } = useTranslation();
  const [showModal] = useModal();

  const openRequestLeadRejectionModal = useCallback(() => {
    showModal({
      componentProps: {
        leadId: lead.id,
        refetchQueries: GET_LEAD_DETAILS_REFETCH_QUERIES,
      },
      component: RequestLeadRejectionModal,
      closeOnOverlayClick: false,
      size: "xl",
      isCloseable: false,
    });
  }, [
    showModal,
    lead?.id,
  ]);

  const buttonLabel = useCallback((): string => {
    const LABELS = {
      [LeadRejectionStatusEnum.NotRequested]: t("components.buttons.request_rejection"),
      [LeadRejectionStatusEnum.Approved]: t("rejection_status.approved"),
      [LeadRejectionStatusEnum.NotApproved]: t("rejection_status.not_approved"),
      [LeadRejectionStatusEnum.Requested]: t("rejection_status.requested"),
    };

    return LABELS[lead?.rejectionStatus] ?? "";
  }, [
    lead?.rejectionStatus,
    t,
  ]);

  const disabled = lead?.rejectionStatus !== LeadRejectionStatusEnum.NotRequested;

  return (
    <Flex w="full" flexDir="column">
      <LeadDetailsCard
        isExpandable={isExpandable}
        hideHeader
      >
        <Skeleton
          w="full"
          isLoaded={!isLoading}
        >
          <Button
            color="error.500"
            bg="gray.200"
            _hover={{
              bg: "gray.400",
              color: "white",
            }}
            label={buttonLabel()}
            disabled={disabled}
            onClick={openRequestLeadRejectionModal}
          />
        </Skeleton>
      </LeadDetailsCard>
    </Flex>
  );
};

export default RequestLeadRejection;
