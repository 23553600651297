import { GetOfferQuery, OfferFuelTypeEnum } from "generated/graphql";
import { FuelConsumptionSchema, StandardDataSchema } from "settings/yup/schemas/standardDataSchema";
import { formatNumberToGerman } from "utils/currencyFormat";

export const getFuelConsumptionDefaultValues = (
  defaultFormValues?: GetOfferQuery["offer"] | StandardDataSchema,
): FuelConsumptionSchema => {
  if (!defaultFormValues) {
    return {};
  }

  const isElectricOrHydrogen = [OfferFuelTypeEnum.Electric, OfferFuelTypeEnum.Hydrogen].includes(
    defaultFormValues.fuelType,
  );

  return {
    wltpConsumptionCombined: isElectricOrHydrogen
      ? "0"
      : formatNumberToGerman(
        defaultFormValues?.wltpConsumptionCombined,
      ),
    wltpConsumptionCombinedWeighted: formatNumberToGerman(
      defaultFormValues?.wltpConsumptionCombinedWeighted,
    ),
    wltpConsumptionCity: formatNumberToGerman(defaultFormValues?.wltpConsumptionCity),
    wltpConsumptionSuburban: formatNumberToGerman(defaultFormValues?.wltpConsumptionSuburban),
    wltpConsumptionRural: formatNumberToGerman(defaultFormValues?.wltpConsumptionRural),
    wltpConsumptionHighway: formatNumberToGerman(defaultFormValues?.wltpConsumptionHighway),
    fuelConsumptionCombined: formatNumberToGerman(defaultFormValues?.fuelConsumptionCombined),
    fuelConsumptionCity: formatNumberToGerman(defaultFormValues?.fuelConsumptionCity),
    fuelConsumptionHighway: formatNumberToGerman(defaultFormValues?.fuelConsumptionHighway),
    energyEfficiencyClass: defaultFormValues?.energyEfficiencyClass,
    powerConsumptionCombined: formatNumberToGerman(
      defaultFormValues?.powerConsumptionCombined,
    ),
    wltpPowerConsumptionCombined: formatNumberToGerman(
      defaultFormValues?.wltpPowerConsumptionCombined,
    ),
    ...(defaultFormValues?.battery && {
      rangeKm: formatNumberToGerman(defaultFormValues?.battery?.rangeKm),
      battery: {
        type: defaultFormValues?.battery?.type,
        capacityKwh: formatNumberToGerman(defaultFormValues?.battery?.capacityKwh),
        chargeTimeHour: formatNumberToGerman(defaultFormValues?.battery?.chargeTimeHour),
        chargeTimeFastHour: formatNumberToGerman(defaultFormValues?.battery?.chargeTimeFastHour),
        chargeTimeFastAcHour: formatNumberToGerman(
          defaultFormValues?.battery?.chargeTimeFastAcHour,
        ),
      },
    }),
  };
};
