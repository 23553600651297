import { LegalStructureEnum } from "generated/graphql";
import i18n from "translations/i18n";

const getLegalStructureLabel = (legalStructure: LegalStructureEnum): string => {
  const MARITAL_STATUSES = {
    [LegalStructureEnum.Ag]: i18n.t("legal_structures.ag"),
    [LegalStructureEnum.ChamberProfession]: i18n.t("legal_structures.chamber_profession"),
    [LegalStructureEnum.Freelancer]: i18n.t("legal_structures.freelancer"),
    [LegalStructureEnum.Gbr]: i18n.t("legal_structures.gbr"),
    [LegalStructureEnum.Gmbh]: i18n.t("legal_structures.gmbh"),
    [LegalStructureEnum.OhgKg]: i18n.t("legal_structures.ohg_kg"),
    [LegalStructureEnum.PartnershipOfProfessionals]: i18n.t("legal_structures.partnership_of_professionals"),
    [LegalStructureEnum.SideBusiness]: i18n.t("legal_structures.side_business"),
    [LegalStructureEnum.SoleProprietor]: i18n.t("legal_structures.sole_proprietor"),
    [LegalStructureEnum.SoleTrader]: i18n.t("legal_structures.sole_trader"),
    [LegalStructureEnum.Ug]: i18n.t("legal_structures.ug"),
  };

  return MARITAL_STATUSES[legalStructure] ?? "";
};

export default getLegalStructureLabel;
