import { MaritalStatusEnum } from "generated/graphql";
import i18n from "translations/i18n";

const getMaritalStatusLabel = (maritalStatus: MaritalStatusEnum): string => {
  const MARITAL_STATUSES = {
    [MaritalStatusEnum.CivilPartnership]: i18n.t("marital_statuses.civil_partnership"),
    [MaritalStatusEnum.Single]: i18n.t("marital_statuses.single"),
    [MaritalStatusEnum.Married]: i18n.t("marital_statuses.married"),
    [MaritalStatusEnum.Widowed]: i18n.t("marital_statuses.widowed"),
    [MaritalStatusEnum.Divorced]: i18n.t("marital_statuses.divorced"),
  };

  return MARITAL_STATUSES[maritalStatus] ?? "";
};

export default getMaritalStatusLabel;
