export const calculatedWithFactorsOptions = [
  {
    label: "R/S Factors",
    value: "true",
  },
  {
    label: "Interest Rate",
    value: "false",
  },
];
