import {
  Avatar, Button, Flex, Text, SkeletonText,
} from "@chakra-ui/react";
import React from "react";

import ActionsButton from "components/ActionsButton";

import { logOutButtonStyle } from "./styles";
import { SidebarUserInfoProps } from "./types";

const SidebarUserInfo: React.FC<SidebarUserInfoProps> = ({
  logoutAction,
  extraActions,
  userName,
  userRole,
}) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    w="full"
  >
    <Flex>
      <Avatar
        bgColor="secondary.500"
        name={userName}
        color="white"
        mr={2}
      />

      <Flex flexDir="column">
        <SkeletonText
          height={5}
          isLoaded={!!userName}
          noOfLines={1}
        >
          <Text textStyle="h3">
            {userName}
          </Text>
        </SkeletonText>
        <SkeletonText
          height={4}
          isLoaded={!!userRole}
          noOfLines={1}
        >
          <Text textStyle="p">
            {userRole}
          </Text>
        </SkeletonText>

        <Button
          onClick={logoutAction?.onClick}
          css={logOutButtonStyle}
          textDecor="underline"
          fontWeight="normal"
          variant="unstyled"
          h="fit-content"
          w="fit-content"
          fontSize="12px"
          textStyle="p"
          mt={1}
        >
          {logoutAction?.label}
        </Button>
      </Flex>
    </Flex>

    {
      extraActions && (
        <ActionsButton
          actions={extraActions}
          boxShadow="none"
          mr="-8px"
        />
      )
    }
  </Flex>
);

export default SidebarUserInfo;
