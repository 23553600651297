import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import { numeralLocalized, formatCurrency } from "utils/currencyFormat";
import {
  OfferCustomerTypeEnum,
  OfferCreationTypeEnum,
  OfferSortFieldsEnum,
  OfferStatusEnum,
} from "generated/graphql";
import i18n from "translations/i18n";
import { formatDate, germanDateFormat } from "utils/dateFormats";
import ActionsButton from "components/ActionsButton";
import StatusComponent from "components/StatusComponent";
import { Column } from "components/Table/types";
import Checkbox from "components/FormComponents/Checkbox";
import { mapOfferStatus } from "constants/mapOffersStatus";

import { OfferRow, OffersActions } from "../types";

const mapCustomerType = {
  [OfferCustomerTypeEnum.PrivateOnly]: i18n.t("enum.offer_customer_type.private_only"),
  [OfferCustomerTypeEnum.CorporateOnly]: i18n.t("enum.offer_customer_type.corporate_only"),
  [OfferCustomerTypeEnum.Both]: i18n.t("enum.offer_customer_type.both"),
};

const mapCreationType = {
  [OfferCreationTypeEnum.Importer]: i18n.t("enum.creation_type.importer"),
  [OfferCreationTypeEnum.Manual]: i18n.t("enum.creation_type.manual"),
  [OfferCreationTypeEnum.Calculator]: i18n.t("enum.creation_type.calculator"),
};

/**
 * Builds the table columns.
 */
export const makeTableColumns = ({
  onEditOffer,
  onEditLeasingRates,
  onActivate,
  onDeactivate,
  showOnWebsite,
  onDeleteOffer,
  onDuplicate,
  selectedOffers,
  toggleOffer,
  toggleAllItems,
  isAllSelected,
  hasOffers,
}: OffersActions): Column<OfferRow>[] => [
  {
    title: () => (
      <Checkbox
        name="checkbox"
        isChecked={isAllSelected}
        onChange={toggleAllItems}
        isDisabled={!hasOffers}
      />
    ),
    render: ({
      row,
    }) => (
      <Checkbox
        name="checkbox"
        isChecked={!!selectedOffers.find(({ id }) => id === row.id)}
        onChange={toggleOffer(row)}
      />
    ),
  },
  {
    title: i18n.t("components.table.heading.brand"),
    fieldResolver: (offer) => offer?.brand?.name,
    sortingColumn: OfferSortFieldsEnum.BrandName,
  },
  {
    title: i18n.t("components.table.heading.offer_title"),
    fieldPath: "title",
    sortingColumn: OfferSortFieldsEnum.Title,
  },
  {
    title: i18n.t("components.table.heading.dealer"),
    fieldResolver: (offer) => offer?.dealer?.companyName,
    sortingColumn: OfferSortFieldsEnum.DealerId,
  },
  {
    title: i18n.t("components.table.heading.short_id"),
    fieldPath: "shortId",
    sortingColumn: OfferSortFieldsEnum.ShortId,
  },
  {
    title: i18n.t("components.table.heading.internal_number"),
    fieldPath: "internalNumber",
    sortingColumn: OfferSortFieldsEnum.InternalNumber,
  },
  {
    title: i18n.t("components.table.heading.vin"),
    fieldPath: "vin",
    sortingColumn: OfferSortFieldsEnum.Vin,
  },
  {
    title: i18n.t("components.table.heading.customer_type"),
    fieldResolver: (offer) => (mapCustomerType[offer?.customerType]),
  },
  {
    title: i18n.t("components.table.heading.creation_type"),
    fieldResolver: (offer) => (mapCreationType[offer?.creationType]),
  },
  {
    title: i18n.t("components.table.heading.created_at"),
    fieldResolver: (offer) => (formatDate(offer?.insertedAt, germanDateFormat, "-")),
    sortingColumn: OfferSortFieldsEnum.InsertedAt,
  },
  {
    title: i18n.t("components.table.heading.last_updated"),
    fieldResolver: (offer) => (formatDate(offer?.updatedAt, germanDateFormat, "-")),
    sortingColumn: OfferSortFieldsEnum.UpdatedAt,
  },
  {
    title: i18n.t("components.table.heading.leasing_rate"),
    render: ({ row }) => {
      const {
        minFinalLeasingRate,
        customerType,
      } = row;

      const isPrivateCustomer = customerType === OfferCustomerTypeEnum.PrivateOnly;

      const priceAppendLabel = i18n.t(`components.table.heading.${isPrivateCustomer ? "gross" : "net"}`);

      const price = isPrivateCustomer
        ? minFinalLeasingRate?.priceGross
        : minFinalLeasingRate?.priceNet;

      const formattedPrice = price
        ? formatCurrency({ amount: price })
        : "-";

      return (
        <Flex>
          <Text>
            {formattedPrice}
          </Text>

          {
            price && (
              <Text
                textDecor="italic"
                ml={1}
              >
                {priceAppendLabel}
              </Text>
            )
          }
        </Flex>
      );
    },
    sortingColumn: OfferSortFieldsEnum.MinLeasingRateNet,
  },
  {
    title: i18n.t("components.table.heading.leasing_factor"),
    fieldResolver: (offer) => {
      const leasingFactor = offer?.minFinalLeasingRate?.factor;

      return leasingFactor
        ? numeralLocalized(parseFloat(leasingFactor))
        : "-";
    },
    sortingColumn: OfferSortFieldsEnum.MinLeasingRateFactor,
  },
  {
    title: i18n.t("components.table.heading.status"),
    cellProps: {
      textAlign: "center",
    },
    sortingColumn: OfferSortFieldsEnum.Status,
    render: ({
      row,
    }) => {
      const status = mapOfferStatus[row.status];

      return (
        <StatusComponent status={status} />
      );
    },
  },
  {
    render: ({
      row,
    }) => {
      const isLive = row.status === OfferStatusEnum.Live;
      const isDeactivationButtonDisabled = row.topOffer || row.marketingOffer;

      const duplicate = row.creationType !== OfferCreationTypeEnum.Calculator;

      const actions = [
        {
          onClick: onEditOffer(row),
          label: i18n.t("actions.edit_offer"),
        },
        {
          onClick: onEditLeasingRates(row),
          label: i18n.t("actions.edit_leasing_rates"),
        },
        isLive
          ? {
            onClick: onDeactivate(row),
            label: i18n.t("actions.deactivate"),
            isDisabled: isDeactivationButtonDisabled,
          }
          : {
            onClick: onActivate(row),
            label: i18n.t("actions.activate"),
          },
        {
          onClick: onDeleteOffer(row),
          label: i18n.t("actions.delete"),
        },
      ];

      if (isLive) {
        actions.splice((actions.length - 1), 0, {
          onClick: showOnWebsite(row),
          label: i18n.t("actions.show_on_website"),
        });
      }

      if (duplicate) {
        actions.splice(2, 0, {
          onClick: onDuplicate(row),
          label: i18n.t("actions.duplicate"),
        });
      }

      return (
        <ActionsButton
          actions={actions}
        />
      );
    },

  },
];
