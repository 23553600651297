import React from "react";

import { SessionContextPayload } from "./types";

const SessionContext = React.createContext({} as SessionContextPayload);

export const SessionProvider = SessionContext.Provider;

/**
 * Exposes session data and methods.
 */
export const useSession = (): SessionContextPayload => {
  const context = React.useContext(SessionContext);

  if (!context) {
    throw new Error("useSession should be within SessionProvider");
  }

  return context;
};
