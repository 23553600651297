import React from "react";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import DealerUsersSelect from "components/FormComponents/Inputs/Selects/DealerUsersSelect";
import {
  useUpdateLeadBySellerMutation,
} from "generated/graphql";

import { AssigneeSelectorProps } from "./types";

const AssigneeSelector: React.FC<AssigneeSelectorProps> = ({
  refetchQueries,
  defaultValue,
  dealerId,
  leadId,
  ...props
}) => {
  const { t } = useTranslation();
  const newToast = useToast();

  const [updateLead, { loading }] = useUpdateLeadBySellerMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const onAssigneeUpdate = React.useCallback((id: string, assignee: string) => {
    updateLead({
      variables: {
        id,
        params: {
          assignee,
        },
      },
    })
      .then(() => (
        newToast({
          title: t("success.success"),
          description: t("success.lead_updated"),
          status: "success",
        })
      ))
      .catch(() => (
        newToast({
          title: t("errors.error"),
          description: t("errors.something_went_wrong"),
          status: "error",
        })
      ));
  }, [
    updateLead,
    newToast,
    t,
  ]);

  return (
    <DealerUsersSelect
      {...props}
      onChange={(assignee: string) => onAssigneeUpdate(leadId, assignee)}
      defaultValue={defaultValue}
      dealerId={dealerId}
      isDisabled={loading}
      isLoading={loading}
    />
  );
};

export default AssigneeSelector;
