import { TestConfig } from "yup";

import i18n from "translations/i18n";

const urlTest = (): TestConfig<string | undefined> => ({
  name: "urlTest",
  message: i18n.t("yup.invalid_url"),
  test: (value) => !!value && new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/g).test(value),
});

export default urlTest;
