import i18next from "i18next";
import { useMemo } from "react";

import { SupportedLanguages } from "translations/i18n";

import {
  UseLocalizedNumberSeparatorsResult,
} from "./types";

const useLocalizedNumberSeparators = (): UseLocalizedNumberSeparatorsResult => {
  const isGerman = i18next.language === SupportedLanguages.de;

  const payload = useMemo<UseLocalizedNumberSeparatorsResult>(
    () => ({
      localizedThousandSeparator: isGerman ? "." : ",",
      localizedDecimalSeparator: isGerman ? "," : ".",
    }),
    [
      isGerman,
    ],
  );

  return payload;
};

export default useLocalizedNumberSeparators;
