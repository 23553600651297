import React, { forwardRef } from "react";

import Input from "components/FormComponents/Inputs/Input";
import { titleCss } from "views/CustomOffer/components/Steps/CustomOfferSteps/styles";
import { InputProps } from "components/FormComponents/Inputs/Input/types";
import { germanNumberMask } from "constants/inputMasks";

interface InputFieldProps extends Omit<InputProps, "rightAddon"> {
  measurementUnit: string;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({
  measurementUnit,
  mask = germanNumberMask,
  ...props
}, ref) => (
  <Input
    titleCss={titleCss}
    rightAddonProps={{ color: "gray.400" }}
    rightAddon={<span>{measurementUnit}</span>}
    mask={mask}
    ref={ref}
    {...props}
  />
));
