import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import {
  Flex,
  Stack,
  HStack,
  Text,
} from "@chakra-ui/react";

import { equipmentSchema, EquipmentDataSchema } from "settings/yup/schemas/equipmentSchema";
import Select from "components/FormComponents/Inputs/Selects/Select";
import SectionCard from "components/SectionCard";
import { Color, ColorTypeEnum, VehicleBaseColorEnum } from "generated/graphql";
import { OptionProps } from "components/FormComponents/Inputs/Selects/BaseSelect/types";

import { EquipmentFormProps } from "./types";
import useFormStep from "../../hooks/useFormStep";
import Accessories from "./Accessories/Accessories";
import {
  makeTypeOptions,
  makeColorOptions,
  makeVehicleBaseColorOptions,
} from "./makeOptions";
import { titleCss } from "../styles";

const EquipmentForm: React.FC<EquipmentFormProps> = ({
  defaultFormValues,
  availableAccessories,
}) => {
  const { t } = useTranslation();
  const firstRender = useRef(true);

  const defaultExteriorColorName = defaultFormValues?.exteriorColorName;
  const defaultInteriorColorName = defaultFormValues?.interiorColorName;
  const defaultInteriorBaseColor = defaultFormValues?.interiorBaseColor;
  const defaultExteriorBaseColor = defaultFormValues?.exteriorBaseColor;

  const {
    formState,
    control,
    watch,
    setValue,
    trigger,
  } = useFormStep<EquipmentDataSchema>({
    schema: equipmentSchema,
    formOptions: {
      defaultValues: {
        interiorColorName: defaultInteriorColorName,
        exteriorColorName: defaultExteriorColorName,
        interiorFabric: defaultFormValues?.interiorFabric,
        selectedAccessories: defaultFormValues?.accessories
          ?.map(({ accessory }) => accessory) || [],
        offerAccessories: defaultFormValues?.accessories,
        vehicle: defaultFormValues?.vehicle || {},
      },
    },
  });

  const vehicleColors = defaultFormValues?.vehicle?.colors;

  const [exteriorColorOptions, setExteriorColorOptions] = useState(makeColorOptions(
    vehicleColors as Color[],
    ColorTypeEnum.Exterior,
  ));
  const [interiorColorOptions, setInteriorColorOptions] = useState(makeColorOptions(
    vehicleColors as Color[],
    ColorTypeEnum.Interior,
  ));

  const selectedExteriorColor = watch("exteriorColorName");
  const selectedInteriorColor = watch("interiorColorName");
  const selectedAccessories = watch("selectedAccessories");

  const typeOptions = makeTypeOptions();
  const baseVehicleColorOptions = makeVehicleBaseColorOptions();

  const checkOptionsNotIncludedValue = (options: OptionProps[], value?: string): boolean => (
    !options.some((option) => option.value === value)
  );

  const findVehicleBaseColor = useCallback((name?: string): VehicleBaseColorEnum | undefined => (
    vehicleColors?.find((vehicleColor) => vehicleColor.name === name)?.base
  ), [vehicleColors]);

  const onChangeExteriorColor = useCallback((label) => {
    const vehicleBaseColor = findVehicleBaseColor(selectedExteriorColor);

    setValue(
      "exteriorBaseColor",
      label === "initial"
        ? defaultExteriorBaseColor
        : vehicleBaseColor,
    );

    return trigger("exteriorBaseColor");
  }, [
    selectedExteriorColor,
    defaultExteriorBaseColor,
    setValue,
    findVehicleBaseColor,
    trigger,
  ]);

  useEffect(() => {
    if (firstRender.current) {
      onChangeExteriorColor("initial");

      firstRender.current = false;
      return;
    }

    onChangeExteriorColor("changed");
  }, [
    selectedExteriorColor,
    onChangeExteriorColor,
  ]);

  const onChangeInteriorColor = useCallback((label) => {
    const vehicleBaseColor = findVehicleBaseColor(selectedInteriorColor);

    setValue(
      "interiorBaseColor",
      label === "initial"
        ? defaultInteriorBaseColor
        : vehicleBaseColor,
    );

    return trigger("interiorBaseColor");
  }, [
    selectedInteriorColor,
    defaultInteriorBaseColor,
    setValue,
    findVehicleBaseColor,
    trigger,
  ]);

  useEffect(() => {
    if (firstRender.current) {
      onChangeInteriorColor("initial");

      firstRender.current = false;
      return;
    }

    onChangeInteriorColor("changed");
  }, [
    selectedInteriorColor,
    onChangeInteriorColor,
  ]);

  useEffect(() => ((
    defaultExteriorColorName
    && checkOptionsNotIncludedValue(exteriorColorOptions, defaultExteriorColorName)
  )
    ? setExteriorColorOptions([
      { label: defaultExteriorColorName, value: defaultExteriorColorName },
      ...exteriorColorOptions,
    ])
    : setExteriorColorOptions(exteriorColorOptions)
  ), [
    defaultExteriorColorName,
    exteriorColorOptions,
    setExteriorColorOptions,
  ]);

  useEffect(() => ((
    defaultInteriorColorName
    && checkOptionsNotIncludedValue(interiorColorOptions, defaultInteriorColorName)
  )
    ? setInteriorColorOptions([
      { label: defaultInteriorColorName, value: defaultInteriorColorName },
      ...interiorColorOptions,
    ])
    : setInteriorColorOptions(interiorColorOptions)
  ), [
    defaultInteriorColorName,
    interiorColorOptions,
    setInteriorColorOptions,
  ]);

  return (
    <Flex
      w="full"
      h="full"
      flexDir="column"
    >
      <SectionCard
        title={t("custom_offer.steps.equipment.colors")}
      >
        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Stack
            width="50%"
            marginRight="32px"
          >
            <Text
              fontWeight="bold"
              mb={3}
            >
              {t("custom_offer.steps.equipment.exterior")}
            </Text>
            <HStack
              marginTop="16px"
              spacing={3}
              alignItems="flex-start"
            >
              <Controller
                name="exteriorColorName"
                control={control}
                render={({
                  field,
                  fieldState,
                }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    isCreatable
                    isRequired
                    createOptionPosition="first"
                    titleCss={titleCss}
                    errors={formState.errors}
                    options={exteriorColorOptions}
                    title={t("custom_offer.steps.equipment.manufacturer_color_name")}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      onChangeExteriorColor("changed");
                      field.onChange(e);
                    }}
                  />
                )}
              />

              <Controller
                name="exteriorBaseColor"
                control={control}
                render={({
                  field,
                  fieldState,
                }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    isRequired
                    titleCss={titleCss}
                    errors={formState.errors}
                    options={baseVehicleColorOptions}
                    title={t("custom_offer.steps.equipment.color")}
                  />
                )}
              />
            </HStack>
          </Stack>

          <Stack
            width="50%"
          >
            <Text
              fontWeight="bold"
              mb={3}
            >
              {t("custom_offer.steps.equipment.interior")}
            </Text>
            <HStack
              marginTop="16px"
              spacing={3}
              alignItems="flex-start"
            >
              <Controller
                name="interiorColorName"
                control={control}
                render={({
                  field,
                  fieldState,
                }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    isCreatable
                    isRequired
                    createOptionPosition="first"
                    titleCss={titleCss}
                    errors={formState.errors}
                    options={interiorColorOptions}
                    title={t("custom_offer.steps.equipment.manufacturer_color_name")}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      onChangeInteriorColor("changed");
                      field.onChange(e);
                    }}
                  />
                )}
              />

              <Controller
                name="interiorBaseColor"
                control={control}
                render={({
                  field,
                  fieldState,
                }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    isRequired
                    titleCss={titleCss}
                    errors={formState.errors}
                    options={baseVehicleColorOptions}
                    title={t("custom_offer.steps.equipment.color")}
                  />
                )}
              />
            </HStack>
            <Stack
              w="50%"
            >
              <Controller
                name="interiorFabric"
                control={control}
                defaultValue={defaultFormValues?.interiorFabric}
                render={({
                  field,
                  fieldState,
                }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    isRequired
                    titleCss={titleCss}
                    errors={formState.errors}
                    options={typeOptions}
                    title={t("custom_offer.steps.equipment.type")}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Flex>
      </SectionCard>

      {
        !!availableAccessories?.length && (
          <Accessories
            availableAccessories={availableAccessories}
            pickedAccessories={selectedAccessories}
            control={control}
          />
        )
      }
    </Flex>
  );
};

export default EquipmentForm;
