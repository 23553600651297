import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";

import { useCurrentUser } from "contexts/currentUser";
import LoadingProgress from "components/LoadingProgress";

import { LoadingContextProps } from "./types";
import { LoadingProvider } from ".";

const TIMEOUT_IN_MILLISECONDS = 1000;

const LoadingContainer: React.FC = ({
  children,
}) => {
  const [isOn, setOn] = useState(false);
  const [currentUser] = useCurrentUser();

  const timeout = (): ReturnType<typeof setTimeout> => (
    setTimeout(() => setOn(false), TIMEOUT_IN_MILLISECONDS)
  );

  const done = useCallback((): ReturnType<typeof setTimeout> => timeout(), []);

  const start = useCallback((): void => setOn(true), [setOn]);

  useEffect(() => {
    if (!currentUser) {
      setOn(true);
    }

    timeout();
  }, [currentUser]);

  const contextValue = useMemo<LoadingContextProps>(() => ({
    start,
    done,
  }), [
    start,
    done,
  ]);

  return (
    <LoadingProvider value={contextValue}>
      {
        isOn
          ? <LoadingProgress />
          : children
      }
    </LoadingProvider>
  );
};

export default LoadingContainer;
