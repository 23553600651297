import React from "react";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useExportLeadsToXlsxLazyQuery } from "generated/graphql";
import Button from "components/Button";

import { ExportLeadsButtonProps } from "./types";

const DATA_APPLICATION_XLSX = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const ExportLeadsButton: React.FC<ExportLeadsButtonProps> = ({
  variables,
}) => {
  const { t } = useTranslation();

  const newToast = useToast();

  const [exportLeadsToXlsx, { loading }] = useExportLeadsToXlsxLazyQuery();

  const handleExportLeads = React.useCallback((): void => {
    exportLeadsToXlsx({ variables })
      .then((response) => {
        window.location.href = `${DATA_APPLICATION_XLSX};base64,${response.data?.exportLeadsToXlsx.base64}`;

        newToast({
          title: t("leads.overview.export_leads"),
          description: t("leads.overview.successfully_exported"),
          status: "success",
        });
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
        });
      });
  }, [
    t,
    newToast,
    variables,
    exportLeadsToXlsx,
  ]);

  return (
    <Button
      isDisabled={loading}
      isLoading={loading}
      onClick={handleExportLeads}
      label={t("leads.overview.export_leads")}
      colorScheme="primary"
      textStyle="p"
      minW={140}
      marginBottom={{ md: "55px", "2xl": 0 }}
    />
  );
};

export default ExportLeadsButton;
