import React, { useCallback, useEffect } from "react";
import ReactSelect, { OptionsType, OptionTypeBase } from "react-select";
import { Flex } from "@chakra-ui/react";

import { GetModelsQueryResult, SortingOrderEnum, useGetModelsLazyQuery } from "generated/graphql";
import i18n from "translations/i18n";

import { ModelGroupSelectProps } from "./types";
import Select from "../../Select";

function getModelsGroupOptions(data: GetModelsQueryResult["data"]): OptionsType<OptionTypeBase> {
  if (!data) {
    return [];
  }

  return data.modelGroups?.map(model => ({
    value: model,
    label: model,
  }));
}

const ModelGroupSelect = React.forwardRef<ReactSelect, ModelGroupSelectProps>((
  {
    placeholder = i18n.t("components.selects.model_group"),
    modelGroupSelectContainerProps,
    selectedBrand,
    onChange,
    ...props
  },
  ref,
) => {
  const [
    getModelGroups,
    {
      loading,
      error,
      data,
    },
  ] = useGetModelsLazyQuery({
    variables: {
      pagination: {
        order: SortingOrderEnum.Asc,
      },
    },
  });

  useEffect(() => {
    if (!selectedBrand) {
      return;
    }

    getModelGroups({
      variables: {
        brandId: selectedBrand,
      },
    });
  }, [
    selectedBrand,
    getModelGroups,
  ]);

  const handleChange = useCallback((value) => {
    if (!selectedBrand) {
      return;
    }

    onChange(value);
  }, [
    selectedBrand,
    onChange,
  ]);

  const options = selectedBrand ? getModelsGroupOptions(data) : [];

  return (
    <Flex
      w="full"
      {...modelGroupSelectContainerProps}
    >
      <Select
        {...props}
        isDisabled={!!error || !selectedBrand}
        isLoading={loading}
        options={options}
        placeholder={placeholder}
        isSearchable
        ref={ref}
        onChange={handleChange}
      />
    </Flex>
  );
});

export default ModelGroupSelect;
