import React from "react";
import {
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import mergeRefs from "react-merge-refs";
import { useTranslation } from "react-i18next";

import SlashEyeIcon from "components/Icons/SlashEyeIcon";
import EyeIcon from "components/Icons/EyeIcon";

import Input from "../Input";
import { InputProps } from "../Input/types";

export const PasswordField = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onClickReveal = (): void => {
    onToggle();

    const input = inputRef.current;

    if (input) {
      input.focus({ preventScroll: true });

      const length = input.value.length * 2;

      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <Input
      rightElement={(
        <IconButton
          bg="transparent !important"
          variant="ghost"
          aria-label={
            isOpen
              ? t("components.password_field.mask_password")
              : t("components.password_field.reveal_password")
          }
          icon={isOpen ? <SlashEyeIcon /> : <EyeIcon />}
          onClick={onClickReveal}
        />
      )}
      type={isOpen ? "text" : "password"}
      ref={mergeRefs([inputRef, ref])}
      {...props}
    />
  );
});
