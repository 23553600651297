import React from "react";
import { Stack } from "@chakra-ui/react";

import PercentageBar from "components/PercentageBar";

import SpendingsBadge from "../SpendingsBadge";
import { SpendingsBarProps } from "./types";

const SpendingsBar: React.FC<SpendingsBarProps> = ({
  isUnlimited = false,
  isLoading = false,
  budget,
  spent,
}) => (
  <Stack
    alignItems="end"
    direction="row"
    align="center"
    spacing={2}
    w="full"
  >
    <SpendingsBadge
      isUnlimited={isUnlimited}
      isLoading={isLoading}
      budget={budget}
      spent={spent}
    />

    <PercentageBar
      isUnlimited={isUnlimited}
      isLoading={isLoading}
      value={spent}
      max={budget}
    />
  </Stack>
);

export default SpendingsBar;
