import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { ConfirmationModalProps } from "./types";

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();

  const handleConfirm = ():void => {
    if (componentProps.onConfirm) {
      componentProps.onConfirm();
    }

    hideModal();
  };

  const handleCancel = ():void => {
    if (componentProps.onCancel) {
      componentProps.onCancel();
    }

    hideModal();
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      width="full"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        bgColor="gray.100"
        borderRadius="8px"
        flexDir="column"
        width="full"
        p={10}
      >
        {
          componentProps?.title && (
            <Text
              lineHeight="24px"
              fontSize="20px"
              textStyle="h3"
              mb={4}
            >
              {componentProps.title}
            </Text>
          )
        }

        <Text
          marginBottom="30px"
          textAlign="center"
          lineHeight="20px"
          fontSize="16px"
          textStyle="p"
        >
          {componentProps.description}
        </Text>

        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="full"
        >
          <Button
            label={t("components.buttons.no")}
            onClick={handleCancel}
            fontFamily="regular"
            variant="outline"
            fontSize="18px"
            height="48px"
            w="95px"
          />

          <Button
            label={
              componentProps.confirmButtonLabel
            || t("components.buttons.yes")
            }
            onClick={handleConfirm}
            fontFamily="regular"
            fontSize="18px"
            height="48px"
            w="95px"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ConfirmationModal;
