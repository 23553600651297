import { FormState, UseFormRegister } from "react-hook-form";

import { Maybe, UpdateFixedLeasingRateParams } from "generated/graphql";
import { FinancingAndCostsSchema } from "settings/yup/schemas/financingAndCostsSchema";

import { Rates } from "../types";

export enum CustomTitleEnum {
  customMonth = "customMonth",
  customMileage = "customMileage",
}

export interface CustomHeaderProps {
  onHandleChange: React.Dispatch<React.SetStateAction<string>>;
  isMileage: boolean;
  isReadOnly?: boolean;
  value: string;
  formState: FormState<FinancingAndCostsSchema>;
  register: UseFormRegister<FinancingAndCostsSchema>;
}

export interface UpsertLeasingValue {
  newLeasingRate?: UpdateFixedLeasingRateParams;
  customTitleProperties?: {
    name: string;
    value: string;
  };
}

export interface UpdateCustomTitleValue {
  customFieldName: CustomTitleEnum;
  titleValue: string;
}

export interface CustomTitleProperties {
  name: string;
  value: string;
}

export interface ParseModifiedLeasingRatesWithCustomData {
  previousLeasingRate: UpdateFixedLeasingRateParams;
  newLeasingRate: UpdateFixedLeasingRateParams;
  customMileage?: string;
  customMonth?: string;
}

export type ValidateRateProps = {
  data: UpdateFixedLeasingRateParams[];
  validationType: Maybe<Rates>;
}

export interface ValidateRatesPayload {
  invalidRates: UpdateFixedLeasingRateParams[];
  isInvalidRates: boolean;
}
