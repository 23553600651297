import React from "react";
import {
  Flex,
  HStack,
  useToast,
  Wrap,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import {
  usePublishOffersMutation,
  useDeactivateOffersMutation,
  useDeleteOffersMutation,
} from "generated/graphql";
import { GRID_GAP } from "constants/layout";
import { useModal } from "contexts/modal";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import AlertModal from "components/Modals/AlertModal";

import useOffersStore from "./store";
import { MigrateOffersButton } from "./MigrateOffersButton";
import { OFFERS_REFETCH_QUERIES } from "../graphql/offers";
import { parseSelectedOffers } from "./utils/parseSelectedOffers";
import { getTopOffersAndMarketingOffersShortIds, hasTopOffersOrMarketingOffers } from "./utils/topOffersAndMarketingOffers";

const OffersFooter: React.FC = () => {
  const { t } = useTranslation();
  const newToast = useToast();
  const [showModal] = useModal();

  const {
    selectedItems,
    countOffers,
    clearSelectedItems,
  } = useOffersStore();

  const isDisabled = !selectedItems?.length;

  const [deleteOffers] = useDeleteOffersMutation({
    awaitRefetchQueries: true,
    refetchQueries: OFFERS_REFETCH_QUERIES,
  });

  const [deactivateOffers] = useDeactivateOffersMutation({
    awaitRefetchQueries: true,
    refetchQueries: OFFERS_REFETCH_QUERIES,
  });

  const [publishOffers] = usePublishOffersMutation({
    awaitRefetchQueries: true,
    refetchQueries: OFFERS_REFETCH_QUERIES,
  });

  const onDeleteSelected = (): void => {
    if (!selectedItems) {
      return;
    }

    deleteOffers({
      variables: {
        offersIds: parseSelectedOffers(selectedItems),
      },
    })
      .then(() => {
        newToast({
          title: t("actions.success"),
          description: t("actions.offers_has_been_deleted_successfully",
            { count: selectedItems.length }),
          status: "success",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  };

  const handleDeletion = (): void => {
    showModal({
      component: ConfirmationModal,
      componentProps: {
        title: t("actions.delete_offers_confirmation_modal.title"),
        onConfirm: () => onDeleteSelected(),
      },
    });
  };

  const onDeactivateSelectedWithoutTopOffersOrMarketingOffers = (): void => {
    if (!selectedItems) {
      return;
    }

    deactivateOffers({
      variables: {
        offersIds: parseSelectedOffers(selectedItems),
      },
    })
      .then(() => {
        newToast({
          title: t("actions.success"),
          description: t("actions.offers_has_been_deactivated_successfully",
            { count: selectedItems.length }),
          status: "success",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  };

  const onDeactivateSelectedWithTopOffersOrMarketingOffers = (): void => {
    showModal({
      componentProps: {
        variant: "warning",
        title: t("actions.deactivate_offers_confirmation_modal.title"),
        description: t(
          "actions.deactivate_offers_confirmation_modal.description", {
            shortIds: getTopOffersAndMarketingOffersShortIds(selectedItems)?.join(", "),
          },
        ),
      },
      component: AlertModal,
    });
  };

  const onDeactivateSelected = (): void => {
    if (!hasTopOffersOrMarketingOffers(selectedItems)) {
      onDeactivateSelectedWithoutTopOffersOrMarketingOffers();

      return;
    }

    onDeactivateSelectedWithTopOffersOrMarketingOffers();
  };

  const onPublishSelected = (): void => {
    if (!selectedItems) {
      return;
    }

    publishOffers({
      variables: {
        offersIds: parseSelectedOffers(selectedItems),
      },
    })
      .then(() => {
        newToast({
          title: t("actions.offers_has_been_published_successfully", { count: selectedItems.length }),
          description: t("custom_offer.toasts.note"),
          status: "info",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  };

  return (
    <Wrap spacing={GRID_GAP.MD} w="full" justify="space-between">
      <Flex align="center">
        {t("components.table.total")}
        {" "}
        {countOffers}
      </Flex>

      <HStack spacing={15}>
        <MigrateOffersButton isDisabled={isDisabled} />

        <Button
          disabled={isDisabled}
          variant="outline"
          label={t("actions.delete_selected")}
          onClick={handleDeletion}
        />

        <Button
          disabled={isDisabled}
          variant="outline"
          label={t("actions.deactivate_selected")}
          onClick={onDeactivateSelected}
        />

        <Button
          disabled={isDisabled}
          variant="solid"
          label={t("actions.publish_selected")}
          onClick={onPublishSelected}
        />
      </HStack>

    </Wrap>
  );
};

export default OffersFooter;
