import React from "react";
import { Stack } from "@chakra-ui/react";

import {
  offerDetailsSchema, OfferDetailsSchema,
} from "settings/yup/schemas/offerDetailsSchema";
import { CONTAINER_PADDING } from "constants/layout";
import useFormStep from "views/CustomOffer/components/Steps/hooks/useFormStep";

import { OfferDetailsFormProps } from "../types";
import { getOfferDetailsDefaultValues } from "./getOfferDetailsDefaultValues";
import SpecialConditionsCard from "./SpecialConditionsCard";
import OfferDetailsCard from "./OfferDetailsCard";
import AdminOptionsCard from "./AdminOptionsCard";

const OfferDetailsForm: React.FC<OfferDetailsFormProps> = ({
  specialConditions,
  defaultFormValues,
  isAdmin = false,
}) => {
  const {
    control,
    formState,
    register,
    setValue,
    watch,
  } = useFormStep<OfferDetailsSchema>({
    schema: offerDetailsSchema,
    formOptions: {
      defaultValues: getOfferDetailsDefaultValues(
        defaultFormValues,
        specialConditions,
      ),
    },
  });

  return (
    <Stack
      spacing={CONTAINER_PADDING.XS}
      w="full"
    >
      <SpecialConditionsCard
        specialConditions={specialConditions}
        formState={formState}
        register={register}
        watch={watch}
      />

      <OfferDetailsCard
        defaultFormValues={defaultFormValues}
        formState={formState}
        setValue={setValue}
        register={register}
        control={control}
      />

      {
        isAdmin && (
          <AdminOptionsCard
            formState={formState}
            defaultFormValues={defaultFormValues}
            register={register}
          />
        )
      }
    </Stack>
  );
};

export default OfferDetailsForm;
