
const GRID_COLUMNS = "repeat(12, 1fr)";

/**
 *  GRID_GAP and CONTAINER_PADDING refers to default Chakra sizes
 *  @see {@link https://chakra-ui.com/docs/theming/theme#sizes ChakraSizes}
 * */
const GRID_GAP = {
  XS: 1,
  SM: 2,
  MD: 3,
  LG: 4,
};

const CONTAINER_PADDING = {
  XS: 6,
  SM: 8,
  MD: 10,
  LG: 12,
};

/**
 * Set container_padding for each breakpoint
 * @see {@link https://chakra-ui.com/docs/features/responsive-styles#the-array-syntax ChakraResponsiveStyles}
 * */
const CONTAINER_PADDING_RESPONSIVE = [
  CONTAINER_PADDING.XS,
  CONTAINER_PADDING.XS,
  CONTAINER_PADDING.XS,
  CONTAINER_PADDING.MD,
];

export {
  GRID_GAP,
  CONTAINER_PADDING,
  CONTAINER_PADDING_RESPONSIVE,
  GRID_COLUMNS,
};
