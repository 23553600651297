import React from "react";
import { FormLabel, Text } from "@chakra-ui/react";

import { FieldTitleProps } from "./types";

const FieldTitle: React.FC<FieldTitleProps> = ({
  children,
  htmlFor,
  textProps,
  titleCss,
  ...rest
}) => (
  <FormLabel
    htmlFor={htmlFor}
    {...rest}
  >
    <Text
      color="gray.400"
      textStyle="small"
      {...titleCss}
      {...textProps}
    >
      {children}
    </Text>
  </FormLabel>
);

export default FieldTitle;
