import {
  AccessoryEnum,
  GetOfferQuery,
  OfferAccessory,
} from "generated/graphql";
import { EquipmentDataSchema } from "settings/yup/schemas/equipmentSchema";

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type Accessories = Optional<
  Pick<OfferAccessory, "id" | "accessory">,
  "id"
>[];
export interface EquipmentDefaultProps extends EquipmentDataSchema {
  accessories?: Accessories;
}

export type OfferEquipment = Pick<
  GetOfferQuery["offer"],
  | "interiorColorName"
  | "exteriorColorName"
  | "interiorBaseColor"
  | "exteriorBaseColor"
  | "interiorFabric"
  | "accessories"
  | "vehicle"
>;

export interface EquipmentFormProps {
  defaultFormValues: OfferEquipment | EquipmentDefaultProps;
  availableAccessories?: ParsedAccessory[][];
}

export enum AccessoryCategoryEnum {
  Safety = "safety",
  Technology = "technology",
  Comfort = "comfort",
  Other = "other",
}

export interface ParsedAccessory {
  value: AccessoryEnum;
  category: AccessoryCategoryEnum;
  label: string;
}
