import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const NextIcon: IconProps = createIcon({
  viewBox: "0 0 42 42",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21C0 9.40202 9.40202 0 21 0ZM19.745 14.059L21.261 12.54L29.88 21.157L21.264 29.773L19.745 28.254L25.752 22.233H12.645V20.079H25.755L19.745 14.059Z"
      fill="currentColor"
    />
  ),
});

export default NextIcon;
