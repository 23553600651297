import i18n from "translations/i18n";
import {
  Color,
  ColorTypeEnum,
  OfferInteriorFabricEnum,
  VehicleBaseColorEnum,
} from "generated/graphql";
import { OptionProps } from "components/FormComponents/Inputs/Selects/BaseSelect/types";

const otherColorValue = "Andere";

export const makeTypeOptions = (): OptionProps[] => (
  Object.keys(OfferInteriorFabricEnum).map(type => {
    const label = `custom_offer.steps.equipment.${OfferInteriorFabricEnum[type]?.toLowerCase()}`;
    return {
      label: i18n.t(label),
      value: OfferInteriorFabricEnum[type],
    };
  })
);

export const makeVehicleBaseColorOptions = (): OptionProps[] => (
  Object.keys(VehicleBaseColorEnum).map(type => {
    const label = `colors.${VehicleBaseColorEnum[type]}`;
    return {
      label: i18n.t(label),
      value: VehicleBaseColorEnum[type],
    };
  })
);

export const makeColorOptions = (vehicleColors: Color[], type: ColorTypeEnum): OptionProps[] => {
  const colorsList = vehicleColors
    ?.filter(color => color.type === type)
    ?.map(color => ({
      label: color.name,
      value: color.name,
    })) ?? [];

  return [
    {
      label: i18n.t(`custom_offer.steps.equipment.${VehicleBaseColorEnum.Other.toLowerCase()}`),
      value: otherColorValue,
    },
    ...colorsList,
  ];
};
