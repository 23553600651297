import { useEffect, useState } from "react";
import { useShallowCompareEffect } from "react-use";

import useFilter from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";

import useWizardStore from "../store";

/**
 * Automatically persists the Wizard step in the URL, returning the `initialStepIndex`
 * to be passed to Wizard to initialize it when reloading the page.
 *
 * The step will be persisted in the URL as a n + 1 count, so that index 0 for example becomes 1.
 * The returned result will be the correct index, that is, index 0 will be 0.
 */
const useWizardQueryStringPersistor = (): number => {
  const { step: queryStepIndex, handleSetFilter } = useFilter([Filters.Step]);

  const wizardStepIndex = useWizardStore((store) => store.stepIndex);

  const [hasInitialized, setHasInitialized] = useState(false);

  /**
   * When the query step index changes in the url,
   * we'll update the wizard with the new step index.
   */
  useEffect(() => {
    if (!queryStepIndex) {
      return;
    }

    useWizardStore.setState({
      stepIndex: (queryStepIndex as number) - 1,
    });

    setHasInitialized(true);
  }, [
    queryStepIndex,
  ]);

  /**
   * If the hook has already persisted the url, we only
   * need to update the filter when step changes.
   */
  useShallowCompareEffect(() => {
    if (!hasInitialized) {
      return;
    }

    const newQueryStepIndex = wizardStepIndex + 1;

    if (newQueryStepIndex !== queryStepIndex) {
      handleSetFilter("step", newQueryStepIndex);
    }
  }, [
    wizardStepIndex,
    hasInitialized,
    handleSetFilter,
  ]);

  return (queryStepIndex as number) - 1;
};

export default useWizardQueryStringPersistor;
