import React, { useEffect, useCallback } from "react";
import { Text } from "@chakra-ui/react";
import {
  swap,
} from "react-grid-dnd";
import { useTranslation } from "react-i18next";

import SectionCard from "components/SectionCard";
import Carousel from "components/Images/Carousel";
import { imagesSchema, ImagesDataSchema } from "settings/yup/schemas/imagesSchema";
import { UserRoleEnum } from "generated/graphql";
import { useCurrentUser } from "contexts/currentUser";

import { ImagesFormProps } from "./types";
import useFormStep from "../../hooks/useFormStep";
import Gallery from "./Gallery/Gallery";
import { useLimitImageSize } from "./useLimitImageSize";

const Images: React.FC<ImagesFormProps> = ({
  defaultFormValues,
}) => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    getValues,
    formState,
    watch,
  } = useFormStep<ImagesDataSchema>({
    schema: imagesSchema,
    formOptions: {
      defaultValues: {
        images: defaultFormValues?.images,
        vehicle: defaultFormValues?.vehicle,
      },
    },
  });
  const { handleValidateFiles } = useLimitImageSize();

  const [currentUser] = useCurrentUser();

  const {
    jatoSuggestedImages,
    suggestedImages,
    nlImagePrefix,
  } = getValues()?.vehicle || {};

  const files = watch("files");
  const images = watch("images");

  const slides = [...(suggestedImages ?? []), ...(jatoSuggestedImages ?? [])];
  const hasSuggestedImages = !!slides.filter(slide => !images?.find(src => src === slide)).length;

  const onChange = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
  ) : void => {
    const nextState = swap(images, sourceIndex, targetIndex);
    setValue("images", nextState);
  };

  useEffect(() => {
    register("images");

    setValue("images", defaultFormValues?.images || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeImage = (urlToRemove: string | File) : void => {
    const newImages = images.filter((url: string | File) => url !== urlToRemove);

    setValue("images", Array.from(new Set(newImages)));
  };

  const addImage = useCallback((newImage: string | File): void => setValue("images", Array.from(new Set([...images, newImage]))), [images, setValue]);

  const addMultipleImages = useCallback((newFiles: File[]): void => {
    const validFiles = handleValidateFiles(newFiles);

    setValue(
      "images",
      [...images, ...validFiles],
    );
  }, [
    handleValidateFiles,
    images,
    setValue,
  ]);

  useEffect(() => {
    if (files?.length > 0) {
      addMultipleImages(Array.from(files));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const onDrop = useCallback(acceptedFiles => addMultipleImages(
    acceptedFiles,
  ), [addMultipleImages]);

  const removeGalleryImages = useCallback((): void => {
    const newImages = images?.filter(image => ![
      ...Array.from(new Set(defaultFormValues?.vehicle?.jatoSuggestedImages)),
      ...Array.from(new Set(defaultFormValues?.vehicle?.suggestedImages)),
    ]?.includes(String(image))) ?? [];

    setValue("images", newImages, { shouldValidate: true });
  }, [
    defaultFormValues?.vehicle?.jatoSuggestedImages,
    defaultFormValues?.vehicle?.suggestedImages,
    setValue,
    images,
  ]);

  return (
    <SectionCard
      title={t("custom_offer.steps.images.title")}
    >
      <Text
        fontWeight="bold"
        marginBottom="16px"
      >
        {t("custom_offer.steps.images.sugestions_database")}
      </Text>

      {
        currentUser?.role === UserRoleEnum.Admin && (
          <Text>
            {nlImagePrefix}
          </Text>
        )
      }

      {
        (hasSuggestedImages) ? (
          <Carousel
            slides={slides
              ?.filter(src => !watch("images")?.includes(src))
              ?.map(src => ({
                src,
                callback: () => addImage(src),
                label: t("custom_offer.steps.images.take"),
              }))
              || []}
          />
        ) : (
          <Text
            fontWeight="regular"
            fontSize="14px"
            marginBottom="28px"
          >
            {t("custom_offer.steps.images.no_suggested_images")}
          </Text>
        )
      }

      <Gallery
        removeGalleryImages={removeGalleryImages}
        errors={formState.errors}
        defaultFormValues={{
          images: defaultFormValues?.images,
          vehicle: defaultFormValues?.vehicle,
        }}
        images={images}
        onChange={onChange}
        onDrop={onDrop}
        removeImage={removeImage}
        register={register}
      />

      <Text
        fontWeight="regular"
        fontSize="12px"
        textAlign="center"
      >
        {t("custom_offer.steps.images.max_size_information")}
      </Text>
    </SectionCard>
  );
};

export default Images;
