import AttachmentIcon from "components/Icons/AttachmentIcon";
import BankIcon from "components/Icons/BankIcon";
import CalculatorIcon from "components/Icons/CalculatorIcon";
import CalendarIcon from "components/Icons/CalendarIcon";
import CarIcon from "components/Icons/CarIcon";
import CardIcon from "components/Icons/CardIcon";
import CheckIcon from "components/Icons/CheckIcon";
import CloseIcon from "components/Icons/CloseIcon";
import DashIcon from "components/Icons/DashIcon";
import DownChevron from "components/Icons/DownChevron";
import DownArrowIcon from "components/Icons/DownArrowIcon";
import EllipsisIcon from "components/Icons/EllipsisIcon";
import ErrorIcon from "components/Icons/ErrorIcon";
import FlagIcon from "components/Icons/FlagIcon";
import GearIcon from "components/Icons/GearIcon";
import InfoIcon from "components/Icons/InfoIcon";
import NextIcon from "components/Icons/NextIcon";
import PencilIcon from "components/Icons/PencilIcon";
import PlusIcon from "components/Icons/PlusIcon";
import RightArrowIcon from "components/Icons/RightArrowIcon";
import SearchIcon from "components/Icons/SearchIcon";
import TrashCanIcon from "components/Icons/TrashCanIcon";
import UploadIcon from "components/Icons/UploadIcon";
import UpChevron from "components/Icons/UpChevron";
import UpArrowIcon from "components/Icons/UpArrowIcon";

export const iconsMap = {
  AttachmentIcon,
  BankIcon,
  CalculatorIcon,
  CalendarIcon,
  CarIcon,
  CardIcon,
  CheckIcon,
  CloseIcon,
  DashIcon,
  DownChevron,
  DownArrowIcon,
  EllipsisIcon,
  ErrorIcon,
  FlagIcon,
  GearIcon,
  InfoIcon,
  NextIcon,
  PencilIcon,
  PlusIcon,
  RightArrowIcon,
  SearchIcon,
  TrashCanIcon,
  UploadIcon,
  UpChevron,
  UpArrowIcon,
};
