import React, { useCallback } from "react";
import { Flex, Stack } from "@chakra-ui/react";

import MileagePrices from "components/Modals/EditLeasingRatesModal/Form/MileagePrices";
import LeasingRatesTable, { validateRates } from "components/Modals/EditLeasingRatesModal/LeasingRatesTable";
import DeliveryPrices from "components/Modals/EditLeasingRatesModal/Form/DeliveryPrices";
import useFormStep from "views/CustomOffer/components/Steps/hooks/useFormStep";
import { financingAndCostsSchema, FinancingAndCostsSchema } from "settings/yup/schemas/financingAndCostsSchema";
import { CustomerTypeEnum, DeliveryPrice, OfferCustomerTypeEnum } from "generated/graphql";
import CustomerTypeSelector from "components/CustomerTypeSelector";
import { LeasingRateTable, Rates } from "components/Modals/EditLeasingRatesModal/types";
import useCustomOfferStore from "views/CustomOffer/store";
import { getDeliveryPricesDefaultValues } from "utils/getDeliveryPricesDefaultValues";

import FinancingData from "./FinancingData";
import { FinanceAndCostsProps } from "../types";

const Form: React.FC<FinanceAndCostsProps> = ({ defaultFormValues }) => {
  const {
    bank,
    customerType,
    fixedLeasingRatesPriceType,
    fixedLeasingRates,
    extraPricePerHundredKilometersNet,
    discountPerHundredKilometersNet,
    deliveryPrices,
  } = defaultFormValues || {};

  const OfferLeasingRates = {
    fixedLeasingRatesPriceType,
    fixedLeasingRates,
  } as LeasingRateTable;

  const { setWizardStep } = useCustomOfferStore(state => ({
    setWizardStep: state.setWizardStepStore,
  }));

  const {
    setValue,
    getValues,
    register,
    reset,
    control,
    formState,
  } = useFormStep<FinancingAndCostsSchema>({
    schema: financingAndCostsSchema,
    formOptions: {
      defaultValues: {
        bankId: bank?.id?.toString(),
        customerType,
        fixedLeasingRatesPriceType,
        fixedLeasingRates,
        extraPricePerHundredKilometersNet,
        discountPerHundredKilometersNet,
        deliveryPrices: deliveryPrices?.map(dp => ({
          dealerId: dp?.dealerId,
          type: dp?.type,
          dealerPickupPrice: dp?.dealerPickupPrice,
          factoryPickupPrice: dp?.factoryPickupPrice,
          houseDeliveryPrice: dp?.houseDeliveryPrice,
        })) || [],
      },
    },
  });

  const handleSelectCustomerType = (customer: OfferCustomerTypeEnum): void => {
    setValue("customerType", customer);
    setValue("deliveryPrices", [{
      type: customer === OfferCustomerTypeEnum.PrivateOnly
        ? CustomerTypeEnum.Private : CustomerTypeEnum.Corporate,
    }]);
  };

  const handleChangeLeasingRates = useCallback((currentRates) => {
    const { isInvalidRates } = validateRates({
      data: currentRates,
      validationType: Rates.Invalid,
    });

    setWizardStep(!isInvalidRates);
  }, [setWizardStep]);

  const deliveryPricesDefaultValues = getDeliveryPricesDefaultValues(
    defaultFormValues?.deliveryPrices as DeliveryPrice[],
  );

  return (
    <Flex
      direction="column"
    >
      <Stack
        mr={3}
        flexDirection="column"
      >

        <CustomerTypeSelector
          onChange={handleSelectCustomerType}
          defaultCustomerType={defaultFormValues?.customerType as OfferCustomerTypeEnum}
        />

        <LeasingRatesTable
          setValue={setValue}
          data={OfferLeasingRates}
          getValues={getValues}
          reset={reset}
          formState={formState}
          register={register}
          handleChange={handleChangeLeasingRates}
        />

        <FinancingData
          control={control}
          register={register}
          offer={defaultFormValues}
          setValue={setValue}
        />

        <MileagePrices
          offer={defaultFormValues}
          setValue={setValue}
        />

        <DeliveryPrices
          setValue={setValue}
          defaultValues={deliveryPricesDefaultValues}
        />
      </Stack>
    </Flex>
  );
};

export default Form;
