import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";
import ConditionalWrap from "conditional-wrap";

import { OverflowPopoverProps } from "./types";

// This component renders a text with textOverflow: ellipsis and a popover with the full text
const OverflowPopover:React.FC<OverflowPopoverProps> = ({
  maxWidth = "200px",
  children,
  ...rest
}) => {
  const [hoverStatus, setHover] = useState(false);

  const textElementRef = useRef<HTMLDivElement>(null);

  const compareSize = useCallback((): void => {
    if (!textElementRef.current) {
      return;
    }

    setHover(
      textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth,
    );
  }, [
    setHover,
  ]);

  useEffect(() => {
    compareSize();

    window.addEventListener("resize", compareSize);

    return () => window.removeEventListener("resize", compareSize);
  }, [
    compareSize,
    children,
  ]);

  if (!children) {
    return null;
  }

  return (
    <ConditionalWrap
      condition={hoverStatus}
      wrap={(content) => (
        <Popover trigger="hover">
          <PopoverTrigger>
            {content}
          </PopoverTrigger>
          <PopoverContent
            bg="secondary.800"
            color="white"
          >
            <PopoverArrow bg="secondary.800" />
            <PopoverBody>
              <Text
                whiteSpace="break-spaces"
                fontWeight="bold"
                textStyle="p"
              >
                {children}
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    >
      <Text
        textOverflow="ellipsis"
        ref={textElementRef}
        maxWidth={maxWidth}
        whiteSpace="nowrap"
        overflow="hidden"
        fontSize="12px"
        textStyle="p"
        {...rest}
      >
        {children}
      </Text>
    </ConditionalWrap>
  );
};

export default OverflowPopover;
