import { TestConfig } from "yup";

import i18n from "translations/i18n";

const requiredFileTest = (): TestConfig<FileList | undefined> => ({
  name: "isRequired",
  message: i18n.t("yup.mixed_required_field"),
  test: (value) => (value ? value?.length > 0 : false),
});

export default requiredFileTest;
