import React, { useRef, useCallback, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import mergeRefs from "react-merge-refs";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Input from "components/FormComponents/Inputs/Input";
import PlusIcon from "components/Icons/PlusIcon";
import { CSV_EXTENSION } from "constants/fileExtensions";

import { UploadButtonProps } from "./types";

const UploadButton = React.forwardRef<HTMLElement, UploadButtonProps>((
  {
    onChange,
    errors,
    accept = CSV_EXTENSION,
    ...props
  },
  ref,
) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>();

  const onUploadClick = useCallback(() => {
    if (!fileInputRef.current) {
      return;
    }

    fileInputRef.current.click();
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event) {
      return;
    }

    onChange(event);

    setFile(event.currentTarget.files?.[0]);
  }, [
    onChange,
    setFile,
  ]);

  return (
    <Flex flexDir="column">
      <Button
        onClick={onUploadClick}
        colorScheme="secondary"
        label={t("components.modals.import_calculator_data_modal.upload_file")}
        Icon={PlusIcon}
        css={{
          svg: {
            width: "14px",
            height: "14px",
          },
        }}
        boxShadow="md"
        textStyle="p"
        px={6}
      />

      <Input
        ref={mergeRefs([fileInputRef, ref])}
        onChange={handleChange}
        visibility="hidden"
        variant="unstyled"
        errors={errors}
        accept={accept}
        display="none"
        type="file"
        {...props}
      />

      {
        !!file && (
          <Flex
            alignItems="center"
            mt={2}
          >
            <Text
              textStyle="p"
              mt={0.5}
            >
              {file?.name}
            </Text>
          </Flex>
        )
      }
    </Flex>
  );
});

export default UploadButton;
