export const ENERGY_CONSUMPTION = "kWh";

export const POWER_CONSUMPTION_COMBINED = "kWh/100km";

export const FUEL_CONSUMPTION = "l/100km";

export const GASEOUS_CONSUMPTION = "kg/100km";

export const CARBONIC_EMISSION = "g/km";

export const EURO_YEAR = "€/year";

export const EURO_MONTH = "€/month";

export const EURO_T = "€/t";

export const EURO_L = "€/l";

export const CURRENT_TRANSFORMER_KILOWATT_HOUR = "ct/kWh";

export const KILOMETERS = "km";

export const HOUR = "h";
