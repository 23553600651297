import { OfferTypeEnum } from "generated/graphql";
import { isWithinMonthsRange } from "utils/isWithinMonthsRange";

export const shouldRenderNonEditableFields = (
  vehicleType?: OfferTypeEnum,
  registeredAt?: Date | null,
  drivenKm?: number,
): boolean => (
  vehicleType === OfferTypeEnum.New
  || (registeredAt && isWithinMonthsRange(registeredAt, 8))
  || Number(drivenKm) <= 10000
);
