import { DeliveryPrice } from "generated/graphql";

export type DeliveryPricesDefaultValues = Pick<DeliveryPrice, "dealerPickupPrice" | "factoryPickupPrice" | "houseDeliveryPrice">

/**
 * This function builds the default values for the delivery prices form component
 * @param deliveryPricesArray The delivery prices array of objects
 * @returns The default values for the delivery prices form component
 */
export const getDeliveryPricesDefaultValues = (
  deliveryPrices?: DeliveryPrice[],
): DeliveryPricesDefaultValues[] => {
  if (!deliveryPrices) {
    return [];
  }

  return [{
    dealerPickupPrice: deliveryPrices?.[0]?.dealerPickupPrice,
    factoryPickupPrice: deliveryPrices?.[0]?.factoryPickupPrice,
    houseDeliveryPrice: deliveryPrices?.[0]?.houseDeliveryPrice,
  }];
};
