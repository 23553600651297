import { TERM_MONTHS, MILEAGES } from "constants/leasing";

export const hasDefaultValue = (list: number[], value?: string | number)
: boolean => {
  if (!value) return false;
  return list.includes(Number(value));
};

export const hasCustomValue = (list: number[], value: string | number | undefined)
: boolean => {
  if (!value) {
    return true;
  }
  if (Number(value) > 0) {
    return !list.includes(Number(value));
  }
  return false;
};

export const hasDefaultMileage = (mileage?: string | number)
: boolean => hasDefaultValue(MILEAGES, mileage);

export const hasDefaultMonth = (month?: string | number)
: boolean => hasDefaultValue(TERM_MONTHS, month);

export const hasCustomMonth = (month: string | number | undefined): boolean => hasCustomValue(
  TERM_MONTHS,
  month,
);

export const hasCustomMileage = (mileage: string | number | undefined): boolean => hasCustomValue(
  MILEAGES,
  mileage,
);
