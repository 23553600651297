import React from "react";
import { Flex } from "@chakra-ui/react";

import useCustomOfferStore from "views/CustomOffer/store";

import SpinnerContainer from "../../../SpinnerContainer";
import Form from "./Form";
import { getFinancingAndCostsDefaultFormValues } from "./getFinancingAndCostsDefaultFormValues";

const Financing: React.FC = () => {
  const { offer, financingAndCosts } = useCustomOfferStore(state => ({
    offer: state.offer,
    financingAndCosts: state.store?.financingAndCosts,
  }));

  const defaultValues = Object.keys(offer || {});

  if (!offer) {
    return <SpinnerContainer />;
  }

  const defaultFormValues = getFinancingAndCostsDefaultFormValues(
    offer,
    financingAndCosts,
  );

  return (
    <Flex
      w="full"
      h="full"
      flexDirection="column"
    >
      {Boolean(defaultValues.length) && (
        <Form
          key={offer?.id}
          defaultFormValues={defaultFormValues}
        />
      )}
    </Flex>
  );
};

export default Financing;
