import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const EllipsisIcon: IconProps = createIcon({
  viewBox: "0 0 11.007 44.028",
  path: (
    <path
      fill="currentColor"
      d="M5.5,11.007A5.5,5.5,0,1,0,0,5.5,5.52,5.52,0,0,0,5.5,11.007Zm0,5.5a5.5,5.5,0,1,0,5.5,5.5A5.52,5.52,0,0,0,5.5,16.51Zm0,16.51a5.5,5.5,0,1,0,5.5,5.5A5.52,5.52,0,0,0,5.5,33.021Z"
    />
  ),
});

export default EllipsisIcon;
