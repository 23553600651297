import React from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "@chakra-ui/react";
import {
  Control, FormState, UseFormRegister, UseFormSetValue, UseFormWatch,
} from "react-hook-form";

import SectionCard from "components/SectionCard";
import { OfferFuelTypeEnum } from "generated/graphql";
import { StandardDataSchema } from "settings/yup/schemas/standardDataSchema";

import { SectionDefaultProps } from "../..";
import { CombinedCO2Emissions } from "./Fields/CombinedCO2Emissions";
import { CO2Cost } from "./Fields/CO2Cost";
import { EnergyCost } from "./Fields/EnergyCost";
import { ElectricRange } from "./Fields/ElectricRange";
import { Consumption } from "./Fields/Consumption";
import { CO2Class } from "./Fields/CO2Class";
import { ElectricityConsumption } from "./Fields/ElectricityConsumption";
import { ConsumptionWithDischargedBattery } from "./Fields/ConsumptionWithDischargedBattery";

export interface FieldsCommonProps {
  control?: Control<StandardDataSchema>;
  register?: UseFormRegister<StandardDataSchema>;
  formState?: FormState<StandardDataSchema>;
  setValue?: UseFormSetValue<StandardDataSchema>;
  watch?: UseFormWatch<StandardDataSchema>;
}

const defaultComponents = [CombinedCO2Emissions, CO2Class, Consumption, EnergyCost, CO2Cost];
const pluginComponents = [
  CombinedCO2Emissions,
  CO2Class,
  ElectricRange,
  Consumption,
  ElectricityConsumption,
  ConsumptionWithDischargedBattery,
  EnergyCost,
  CO2Cost,
];
const electricityComponents = [
  CombinedCO2Emissions,
  CO2Class,
  ElectricRange,
  ElectricityConsumption,
  EnergyCost,
  CO2Cost,
];

const sectionsMapping = {
  [OfferFuelTypeEnum.Diesel]: () => defaultComponents,
  [OfferFuelTypeEnum.Petrol]: () => defaultComponents,
  [OfferFuelTypeEnum.Lpg]: () => defaultComponents,
  [OfferFuelTypeEnum.NaturalGas]: () => defaultComponents,
  [OfferFuelTypeEnum.Ethanol]: () => defaultComponents,
  [OfferFuelTypeEnum.Other]: () => defaultComponents,
  [OfferFuelTypeEnum.HybridDiesel]: (isPluginHybrid?: boolean) => (
    isPluginHybrid ? pluginComponents : defaultComponents
  ),
  [OfferFuelTypeEnum.Hybrid]: (isPluginHybrid?: boolean) => (
    isPluginHybrid ? pluginComponents : defaultComponents
  ),
  [OfferFuelTypeEnum.Electric]: () => electricityComponents,
  [OfferFuelTypeEnum.Hydrogen]: () => defaultComponents,
};

export const WLTP = ({
  watch,
  control,
  register,
  formState,
  setValue,
}: SectionDefaultProps): JSX.Element => {
  const { t } = useTranslation();

  const fuelType = watch("fuelType");
  const isPluginHybrid = !!watch("isPluginHybrid");

  const Components = sectionsMapping[fuelType](isPluginHybrid);

  return (
    <SectionCard
      title={t("custom_offer.steps.standard_data.fuel_card.wltp.title")}
    >
      {
        Components.map((Component, index) => (
          <React.Fragment key={`${Component.name}`}>
            <Component
              fuelType={fuelType}
              isPluginHybrid={isPluginHybrid}
              control={control}
              register={register}
              formState={formState}
              setValue={setValue}
            />

            {
              (index < Components.length - 1) && (
                <Divider mt={4} mb={6} />
              )
            }
          </React.Fragment>
        ))
      }
    </SectionCard>
  );
};
