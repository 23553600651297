import React from "react";
import { Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import { GRID_GAP } from "constants/layout";
import SectionCard from "components/SectionCard";
import Input from "components/FormComponents/Inputs/Input";
import { InputProps } from "components/FormComponents/Inputs/Input/types";
import CurrencyInput from "components/FormComponents/Inputs/CurrencyInput";
import PercentageInput from "components/FormComponents/Inputs/PercentageInput";
import EuroIcon from "components/Icons/EuroIcon";
import { CurrencyInputType } from "components/FormComponents/Inputs/CurrencyInput/types";
import { PercentageInputType } from "components/FormComponents/Inputs/PercentageInput/types";

import DeliveryPrices from "../EditLeasingRatesModal/Form/DeliveryPrices";
import { fieldTitleCss } from "./styles";
import { rightElementCss } from "../EditLeasingRatesModal/Form/Fields/styles";
import { FormProps } from "./types";

const DisabledInputField: React.FC<InputProps> = (props) => (
  <Input
    titleCss={fieldTitleCss}
    showErrorMessage={false}
    isDisabled
    {...props}
  />
);

const StyledCurrencyInput: React.FC<CurrencyInputType> = (props) => (
  <CurrencyInput
    rightElementProps={{ color: "gray.400" }}
    rightElement={(<EuroIcon />)}
    titleCss={fieldTitleCss}
    format={undefined}
    decimalSeparator=","
    thousandSeparator="."
    fixedDecimalScale
    decimalScale={2}
    {...props}
  />
);

const StyledPercentageInput: React.FC<PercentageInputType> = (props) => (
  <PercentageInput
    rightElementProps={rightElementCss}
    titleCss={fieldTitleCss}
    {...props}
  />
);

const Form: React.FC<FormProps> = ({
  deliveryPricesDefaultValues,
  formState,
  setValue,
  control,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionCard>
        <Stack
          spacing={GRID_GAP.LG}
          direction="row"
        >
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <DisabledInputField
                {...field}
                title={t("components.modals.edit_calculator_offer_modal.company_name")}
              />
            )}
          />

          <Controller
            name="customerType"
            control={control}
            render={({ field }) => (
              <DisabledInputField
                {...field}
                title={t("components.modals.edit_calculator_offer_modal.customer_type")}
              />
            )}
          />
        </Stack>
      </SectionCard>

      <SectionCard title={(t("components.modals.edit_calculator_offer_modal.pricing"))}>
        <Stack
          spacing={GRID_GAP.LG}
          direction="row"
        >
          <Controller
            name="listPriceDiscountRate"
            control={control}
            render={({ field, fieldState }) => (
              <StyledPercentageInput
                {...field}
                {...fieldState}
                title={(t("components.modals.edit_calculator_offer_modal.list_price_discount"))}
                errors={formState.errors}
              />
            )}
          />

          <Controller
            name="baseCommission"
            control={control}
            render={({ field, fieldState }) => (
              <StyledCurrencyInput
                {...field}
                {...fieldState}
                title={(t("components.modals.edit_calculator_offer_modal.base_commission"))}
                errors={formState.errors}
              />
            )}
          />

          <Controller
            name="commissionRate"
            control={control}
            render={({ field, fieldState }) => (
              <StyledPercentageInput
                {...field}
                {...fieldState}
                title={(t("components.modals.edit_calculator_offer_modal.commission_rate"))}
                errors={formState.errors}
              />
            )}
          />
        </Stack>

        <Stack
          spacing={GRID_GAP.LG}
          direction="row"
        >
          <Controller
            name="degradationFactor"
            control={control}
            render={({ field, fieldState }) => (
              <StyledPercentageInput
                {...field}
                {...fieldState}
                title={(t("components.modals.edit_calculator_offer_modal.degradation_factor"))}
                errors={formState.errors}
                rightElement={false}
              />
            )}
          />

          <Controller
            name="degradationFactorMax"
            control={control}
            render={({ field, fieldState }) => (
              <StyledPercentageInput
                {...field}
                {...fieldState}
                title={(t("components.modals.edit_calculator_offer_modal.max_degradation_factor"))}
                errors={formState.errors}
                rightElement={false}
              />
            )}
          />
        </Stack>
      </SectionCard>

      <DeliveryPrices
        setValue={setValue}
        defaultValues={deliveryPricesDefaultValues}
      />
    </>
  );
};

export default Form;
