import { LeadStatusEnum } from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

const leadSchema = yup.object().shape({
  status: yup
    .mixed()
    .required(i18n.t("yup.mixed_required_field")),
  duration: yup
    .string()
    .required(i18n.t("yup.mixed_required_field")),
  mileage: yup
    .string()
    .required(i18n.t("yup.mixed_required_field")),
});

export interface LeadSchema {
  salesperson?: string;
  status?: LeadStatusEnum;
  duration?: string;
  mileage?: string;
  deliveryDate?: Date;
  dealerNotes?: string;
}

export default leadSchema;
