import React, { useState, useCallback } from "react";
import {
  Flex,
  GridItem,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Container } from "components/Container";
import {
  useGetCreateNewOfferVehiclesQuery,
  Vehicle,
} from "generated/graphql";
import SearchIcon from "components/Icons/SearchIcon";
import Input from "components/FormComponents/Inputs/Input";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";
import { useCurrentUser } from "contexts/currentUser";
import { usePagination } from "contexts/pagination";
import { parseVehiclesWithTranslations } from "utils/getVehiclesWithTranslation";
import DealerSelect from "components/FormComponents/Inputs/Selects/DealerSelect";

import CreateNewOfferVehiclesTable from "./CreateNewOfferVehiclesTable";
import useCreateNewOfferStore from "./CreateNewOfferFooter/store";

const CreateNewOffers: React.FC = () => {
  const { paginationLimit } = usePagination();
  const [search, setSearch] = useState();
  const [, , { isAdmin }] = useCurrentUser();

  const { t } = useTranslation();

  const {
    renderFilters,
    brandId,
    modelGroup,
    model,
    isPossibleToEnableNewConfigurator,
  } = useFilters(
    [
      Filters.Vehicle,
      Filters.VehicleModel,
      ...(isAdmin ? [Filters.IsPossibleToEnableNewConfigurator] : []),
    ],
  );

  const { data, loading } = useGetCreateNewOfferVehiclesQuery({
    variables: {
      filters: {
        brandId,
        model,
        modelGroup,
        isPossibleToEnableNewConfigurator,
      },
      pagination: {
        limit: paginationLimit,
      },
      search,
    },
  });

  const {
    dealerId,
    setDealerId,
  } = useCreateNewOfferStore();

  const handleSearchChange = useCallback(({ target: { value } }) => {
    setSearch(value);
  }, [setSearch]);

  const handleDealerChange = useCallback((value) => {
    setDealerId(value);
  }, [setDealerId]);

  const vehicles: Vehicle[] = data?.vehicles as Vehicle[] || [];

  const vehiclesWithTranslation = parseVehiclesWithTranslations(vehicles);

  return (
    <GridItem colSpan={12}>
      <Container
        alignCenter
        paddingTop="27px"
        paddingLeft="24px"
        paddingRight="28px"
        paddingBottom="27px"
        borderRadius="5"
      >

        <Stack spacing="40px" width="300px" marginBottom="20px">
          <DealerSelect
            onChange={handleDealerChange}
            title={t("components.selects.dealer")}
            value={dealerId}
          />
        </Stack>
        <Flex
          shadow="md"
          direction="column"
          py="20px"
        >
          <Flex
            paddingLeft="20px"
            paddingRight="20px"
            alignItems="center"
          >
            {renderFilters}
            <Flex paddingLeft="10px" width="100%" alignSelf="flex-start">
              <Input
                w="100%"
                value={search}
                showErrorMessage={false}
                onChange={handleSearchChange}
                name="search"
                leftElement={React.createElement(SearchIcon, { color: "gray.400" })}
                rightElementProps={{
                  workBreak: "break-all",
                  overflow: "hidden",
                }}
                pl={10}
              />
            </Flex>
          </Flex>
          <Flex direction="column">
            <CreateNewOfferVehiclesTable
              data={vehiclesWithTranslation}
              loading={loading}
            />
          </Flex>
        </Flex>
      </Container>
    </GridItem>
  );
};

export default CreateNewOffers;
