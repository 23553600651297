import React from "react";
import { Text } from "@chakra-ui/react";

import Button from "components/Button";
import { Column } from "components/Table/types";
import i18n from "translations/i18n";
import { Brand, ImportMethodEnum } from "generated/graphql";

import { BrandsActions } from "../types";

/**
 * Builds the brands table columns.
 */
export const makeTableColumns = ({ onEditBrand }: BrandsActions):
  Column<Brand>[] => [
  {
    title: i18n.t("components.table.heading.brand"),
    render: ({ row }) => (
      <Text fontWeight="bold">
        { row?.name}
      </Text>
    ),
  },
  {
    title: i18n.t("components.table.heading.importMethod"),
    render: ({ row }) => {
      switch (row?.importMethod) {
        case ImportMethodEnum.Pdf:
          return <Text>{i18n.t("screens.brands.table.pdf")}</Text>;
        case ImportMethodEnum.Code:
          return <Text>{i18n.t("screens.brands.table.code")}</Text>;
        default:
          return <Text>-</Text>;
      }
    },
  },
  {
    title: i18n.t("components.table.heading.mobile_video"),
    fieldPath: "mobileVideoUrl",
  },
  {
    title: i18n.t("components.table.heading.desktop_video"),
    fieldPath: "desktopVideoUrl",
  },
  {
    title: i18n.t("components.table.heading.notes"),
    fieldPath: "priceNotes",
  },
  {
    title: i18n.t("components.table.heading.options"),
    render: ({ row }) => (
      <Button
        label={i18n.t("components.buttons.edit")}
        onClick={onEditBrand(row)}
        colorScheme="secondary"
        textStyle="p"
      />
    ),
  },
];
