export const fieldTitleCss = {
  color: "gray.400",
  fontWeight: "normal",
  fontSize: "sm",
  mb: 3,
};

export const rightElementCss = {
  color: "gray.400",
  fontWeight: "bold",
};
