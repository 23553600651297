import { TagType } from "components/TagInput/types";
import yup from "settings/yup";
import i18n from "translations/i18n";

const createBanksSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t("yup.mixed_required_field")),
  address: yup
    .string(),
  aliases: yup
    .array()
    .nullable(),
  privateTemplateFields: yup
    .array()
    .nullable(),
  corporateTemplateFields: yup
    .array()
    .nullable(),
});

export interface CreateUpdateBanksSchema {
  id?: string;
  name: string;
  address: string;
  nominalInterestRate: string;
  effectiveAnnualInterestRate: string;
  aliases: TagType[];
  privateTemplateId: string;
  corporateTemplateId: string;
  privateTemplateFields: string[];
  corporateTemplateFields: string[];
}

export default createBanksSchema;
