import React from "react";

import { LeadDetailsFormProps } from "./types";
import LeadInformation from "./LeadInformation";
import CustomerAndVehicle from "./CustomerAndVehicle";
import LeasingAndContractData from "./LeasingAndContractData";
import Notes from "./Notes";

const Form: React.FC<LeadDetailsFormProps> = ({
  isCheckout,
  formState,
  register,
  loading,
  control,
  lead,
}) => (
  <>
    <LeadInformation
      lead={lead}
      loading={loading}
      control={control}
      formState={formState}
      isCheckout={isCheckout}
    />

    <CustomerAndVehicle
      lead={lead}
      loading={loading}
    />

    <LeasingAndContractData
      lead={lead}
      loading={loading}
      control={control}
      register={register}
      formState={formState}
    />

    <Notes
      lead={lead}
      loading={loading}
      register={register}
      formState={formState}
    />
  </>
);

export default Form;
