import React, { useState } from "react";

import { PAGINATION_LIMIT } from "constants/pagination";

import { PaginationProvider } from ".";

const PaginationContainer: React.FC = ({ children }) => {
  const [paginationLimit, setPaginationLimit] = useState<number>(PAGINATION_LIMIT);

  return (
    <PaginationProvider value={{ paginationLimit, setPaginationLimit }}>
      {children}
    </PaginationProvider>
  );
};

export default PaginationContainer;
