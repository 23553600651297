import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const CloseIcon: IconProps = createIcon({
  viewBox: "0 0 13 13",
  path: (
    <>
      <rect x={11.2634} y={-0.050293} width={2} height={16} rx={1} transform="rotate(45 11.2634 -0.050293)" fill="currentColor" />
      <rect x={12.6777} y={11.2637} width={2} height={16} rx={1} transform="rotate(135 12.6777 11.2637)" fill="currentColor" />
    </>
  ),
});

export default CloseIcon;
