import { TERM_MONTHS, MILEAGES } from "constants/leasing";
import { CustomerTypeEnum } from "generated/graphql";
import { DynamicLeasingRates } from "settings/yup/schemas/editVehicleSchema";

import { LeasingColumns } from "../types";

export const makeDynamicLeasingRatesRows = (
  dynamicLeasingRates?: DynamicLeasingRates,
  customerType?: CustomerTypeEnum,
  mileages: number[] = MILEAGES,
): LeasingColumns[] => {
  const rows = mileages.map((mileage) => {
    const columns = TERM_MONTHS.reduce((acc, month) => {
      const factor = dynamicLeasingRates?.find((lf) => lf.termMonths
        === month && lf.mileageKm === mileage && lf.type === customerType);

      return {
        ...acc,
        [month]: factor || {},
      };
    }, {});

    return columns as LeasingColumns;
  });

  return rows;
};
