import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";

import { PageTitleProps } from "./PageTitleProps";

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  textStyle,
  Icon,
}) => (
  <Flex>
    {
      Icon && (
        <Box
          display="flex"
          fontSize="28"
          color="secondary.500"
          mr={5}
          mt={1}
        >
          {React.createElement(Icon)}
        </Box>
      )
    }

    <Text
      as="h1"
      color="black.700"
      fontSize={30}
      fontWeight="bold"
      wordBreak="break-all"
      textStyle={textStyle || "h1"}
      pr={5}
    >
      {title}
    </Text>
  </Flex>
);
