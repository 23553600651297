
import yup from "settings/yup";
import { FinancingAndCostsSchema } from "settings/yup/schemas/financingAndCostsSchema";
import i18n from "translations/i18n";
import { hasCustomMonth, hasCustomMileage } from "components/Modals/EditLeasingRatesModal/LeasingRatesTable/leasingRatesHelper";

const customInputErrorMessage = i18n.t("components.modals.edit_leasing_rates_modal.invalid_input");

export const editLeasingRatesSchema = yup.object().shape({
  extraPricePerHundredKilometersNet: yup.string().nullable(),
  discountPerHundredKilometersNet: yup.string().nullable(),
  customMonth: yup
    .string()
    .test({
      name: "customMonth",
      message: customInputErrorMessage,
      test: value => hasCustomMonth(value),
    }),
  customMileage: yup
    .mixed()
    .test({
      name: "customMileage",
      message: customInputErrorMessage,
      test: value => hasCustomMileage(value),
    }),
});

export type EditLeasingRatesSchema = Pick<FinancingAndCostsSchema, "customerType" | "deliveryPrices" | "customMonth" | "customMileage" |
  "effectiveAnnualInterestRate" | "nominalInterestRate" | "extraPricePerHundredKilometersNet" | "discountPerHundredKilometersNet" | "fixedLeasingRatesPriceType" | "fixedLeasingRates" | "financingSpecialConditions">

export enum EditLeasingRatesEnum {
  extraPricePerHundredKilometersNet = "extraPricePerHundredKilometersNet",
  discountPerHundredKilometersNet = "discountPerHundredKilometersNet",
  leasingRates = "leasingRates",
}
