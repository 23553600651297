import yup from "settings/yup";

export const descriptionSchema = yup.object().shape({
  descriptionMarkdown: yup
    .string()
    .nullable(),
});

export interface DescriptionDataSchema {
  descriptionMarkdown: string;
}
