
import { useCallback, useEffect, useMemo } from "react";
import {
  useQueryParams,
  StringParam,
} from "use-query-params";

import { SortingOrderEnum } from "generated/graphql";
import { parseNulls } from "utils/parseNulls";

import { SortableOrders, UseSortingPayload, UseSortingProps } from "./types";

const getNextSortingOrder = (
  order: SortableOrders,
): SortableOrders => {
  if (order !== SortingOrderEnum.Desc) {
    return SortingOrderEnum.Desc;
  }

  return SortingOrderEnum.Asc;
};

const useSorting = (props?: UseSortingProps): UseSortingPayload => {
  const {
    defaultField,
  } = props || {};

  const [{
    field,
    order,
  }, setQuery] = useQueryParams({
    field: StringParam,
    order: StringParam,
  });

  useEffect(() => {
    if (!defaultField) {
      return;
    }

    setQuery({
      field: defaultField,
      order: SortingOrderEnum.Desc,
    });
  }, [
    setQuery,
    defaultField,
  ]);

  const toggleSorting = useCallback((fieldPath: string) => {
    if (field !== fieldPath) {
      setQuery({
        field: fieldPath,
        order: SortingOrderEnum.Desc,
      });

      return;
    }

    const nextOrder = getNextSortingOrder(order as SortableOrders);

    setQuery({ order: nextOrder });
  }, [
    order,
    field,
    setQuery,
  ]);

  const payload = useMemo<UseSortingPayload>(() => ({
    field: parseNulls(field) as string,
    order: parseNulls(order) as SortableOrders,
    toggleSorting,
  }), [
    field,
    order,
    toggleSorting,
  ]);

  return payload;
};

export default useSorting;
