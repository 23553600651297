import React from "react";
import NumberFormat from "react-number-format";

import Input from "components/FormComponents/Inputs/Input";

import { PercentageInputType } from "./types";
import { borderCss } from "./styles";

const PercentageInput = ({
  rightElement = "%",
  customInput = (Input),
  decimalScale = 2,
  decimalSeparator = ",",
  focusCss,
  placeholder,
  border = borderCss,
  textAlign,
  disabled,
  ...props
}: PercentageInputType): JSX.Element => (
  <NumberFormat
    decimalScale={decimalScale}
    rightElement={rightElement}
    decimalSeparator={decimalSeparator}
    focusCss={focusCss}
    customInput={customInput}
    placeholder={placeholder}
    border={border}
    textAlign={textAlign}
    disabled={disabled}
    {...props}
  />
);

export default PercentageInput;
