export const titleCss = {
  color: "gray.400",
  fontWeight: "normal",
  fontSize: "sm",
  mb: 3,
};

export const toggleButtonCss = {
  ...titleCss,
  mb: 3,
};
