import { CUSTOM_FIELD_REPRESENTATION } from "constants/leasing";

const addCustomElements = (elementList: number[]): number[] => {
  const newList = [...elementList];
  // Added 0 to occupy a position in the list and to represent a custom field when it has
  // not been filled in yet.
  newList.push(CUSTOM_FIELD_REPRESENTATION);

  return newList;
};

export default addCustomElements;
