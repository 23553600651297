import {
  OfferCustomerTypeEnum,
  UpdateDeliveryPriceParams,
  FixedLeasingRatesPriceTypeEnum,
  UpdateFixedLeasingRateParams,
} from "generated/graphql";
import yup from "settings/yup";
import { hasCustomMonth, hasCustomMileage } from "components/Modals/EditLeasingRatesModal/LeasingRatesTable/leasingRatesHelper";
import i18n from "translations/i18n";

import { SpecialConditionsSchema } from "./offerDetailsSchema";

const customInputErrorMessage = i18n.t("components.modals.edit_leasing_rates_modal.invalid_input");

export const financingAndCostsSchema = yup.object().shape({
  customerType: yup.string().nullable(),
  bankId: yup.string().nullable(),
  extraPricePerHundredKilometersNet: yup.string().nullable(),
  discountPerHundredKilometersNet: yup.string().nullable(),
  customMonth: yup
    .string()
    .test({
      name: "customMonth",
      message: customInputErrorMessage,
      test: value => hasCustomMonth(value),
    }),
  customMileage: yup
    .mixed()
    .test({
      name: "customMileage",
      message: customInputErrorMessage,
      test: value => hasCustomMileage(value),
    }),
});

export interface FinancingAndCostsSchema {
  customerType?: OfferCustomerTypeEnum;
  deliveryPrices?: UpdateDeliveryPriceParams[];
  currentPriceNet?: string;
  baseListPriceNet?: string;
  effectiveAnnualInterestRate?: string;
  nominalInterestRate?: string;
  loanAmountNet?: string;
  extraPricePerHundredKilometersNet: string;
  discountPerHundredKilometersNet: string;
  fixedLeasingRatesPriceType: FixedLeasingRatesPriceTypeEnum;
  fixedLeasingRates: UpdateFixedLeasingRateParams[];
  bankId?: string;
  formSpecialConditions?: SpecialConditionsSchema;
  financingSpecialConditions?: SpecialConditionsSchema;
  customMonth?: string;
  customMileage?: string;
}
