import { ChakraTheme, theme as chakraUiTheme } from "@chakra-ui/react";

export const PRIMARY_COLOR = "#FD5720";
export const PRIMARY_COLOR_LIGHT = "#FFD8CB";
export const TABLE_BORDER_COLOR = "#DCDCDC";

const SECONDARY_COLOR = "#1678C2";
const SECONDARY_COLOR_LIGHT = "#E8F2F9";

const DARK_GRAY = "#5A5E66";
const GRAY = "#98999F";
const SILVER_GRAY = "#B5B9BF";
const LIGHT_GRAY = "#F0F0F0";
const LIGHTEST_GRAY = "#F4F4F4";
const LIGHTER_GRAY = "#F9F9F9";

const WARNING = "#FDD010";
const ERROR = "#EF6969";
const SUCCESS = "#50C878";

const colors: ChakraTheme["colors"] = {
  ...chakraUiTheme.colors,
  black: {
    500: "#333333",
    700: "#1E1F24",
  },
  white: "#FFFFFF",
  gray: {
    ...chakraUiTheme.colors.gray,
    100: LIGHTEST_GRAY,
    200: LIGHT_GRAY,
    300: SILVER_GRAY,
    400: GRAY,
    500: DARK_GRAY,
  },
  primary: {
    10: "#FFF5EB",
    50: PRIMARY_COLOR_LIGHT,
    100: "#FEC2AE",
    200: "#FEA486",
    300: "#FD855D",
    400: "#FD7649",
    500: PRIMARY_COLOR,
    600: "#F23E02",
    700: "#CA3402",
    800: "#B62F02",
    900: "#8D2401",
  },
  secondary: {
    50: SECONDARY_COLOR_LIGHT,
    100: "#7EBFF1",
    200: "#47A4EB",
    300: "#359BE9",
    400: "#2392E7",
    500: SECONDARY_COLOR,
    600: "#1266A5",
    700: "#1C6094",
    800: "#0A385C",
    900: "#062237",
  },
  warning: {
    50: "#FFFBEB",
    100: "#FEF3C2",
    200: "#FEE886",
    300: "#FEE472",
    400: "#FDDC49",
    500: WARNING,
    600: "#DEB602",
    700: "#CAA502",
    800: "#B69502",
    900: "#A28402",
  },
  success: {
    50: "#E0F5E7",
    100: "#C1EBCF",
    200: "#A2E2B7",
    300: "#73D393",
    400: "#64CE87",
    500: SUCCESS,
    600: "#3BBA65",
    700: "#319B54",
    800: "#2C8C4C",
    900: "#226D3B",
  },
  error: {
    50: "#FBDADA",
    100: "#F7B5B5",
    200: "#F5A3A3",
    300: "#F39090",
    400: "#F17E7E",
    500: ERROR,
    600: "#EE5959",
    700: "#EC4646",
    800: "#DE3939",
    900: "#B92626",
  },
  background: {
    50: LIGHTER_GRAY,
    100: LIGHTEST_GRAY,
    200: LIGHT_GRAY,
    300: GRAY,
    400: DARK_GRAY,
  },
};

export default colors;
