import React from "react";
import {
  FormControl,
  Radio as ChakraRadio,
} from "@chakra-ui/react";

import getErrorMessage from "utils/getErrorMessage";
import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import TextConditionalWrap from "components/TextConditionalWrap";
import { getPointerStyles } from "utils/getPointerStyles";
import { getBeforeStyles } from "utils/getBeforeStyles";

import { RadioProps } from "./types";
import {
  borderCss,
  backgroundCss,
} from "./styles";

const Radio = React.forwardRef<HTMLInputElement, RadioProps>((
  {
    renderChildrenAsText = true,
    showErrorMessage = true,
    isReadOnly = false,
    children,
    errors,
    name,
    isDisabled = false,
    ...rest
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const pointerStyles = getPointerStyles(isDisabled, isReadOnly);
  const beforeCss = getBeforeStyles(isDisabled || isReadOnly);

  return (
    <FormControl
      p={1}
      d="flex"
      w="min-content"
      css={{
        pointerEvents: pointerStyles.pointerEvents,
      }}
      _hover={{
        span: borderCss,
        label: {
          cursor: pointerStyles.cursorStyles,
        },
      }}
    >
      <ChakraRadio
        {...rest}
        ref={ref}
        name={name}
        isDisabled={isDisabled}
        isInvalid={!!error}
        cursor={pointerStyles.cursorStyles}
        css={backgroundCss}
        _hover={borderCss}
        _before={beforeCss}
      >
        <TextConditionalWrap
          renderChildrenAsText={renderChildrenAsText}
        >
          {children}
        </TextConditionalWrap>
      </ChakraRadio>

      {
        showErrorMessage && (
          <FieldErrorMessage error={error} />
        )
      }
    </FormControl>
  );
});

export default Radio;
