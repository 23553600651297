import yup from "settings/yup";
import i18n from "translations/i18n";

import passwordCaseTest from "../tests/passwordCaseTest";
import passwordLengthTest from "../tests/passwordLengthTest";
import passwordNumberTest from "../tests/passwordNumberTest";
import passwordSpecialCharTest from "../tests/passwordSpecialCharTest";

const updatePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(i18n.t("yup.mixed_required_field"))
    .test(passwordCaseTest())
    .test(passwordNumberTest())
    .test(passwordSpecialCharTest())
    .test(passwordLengthTest()),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null], i18n.t("yup.password_must_match")),
});

export interface UpdatePasswordSchema {
  password: string;
  confirmPassword: string;
}

export default updatePasswordSchema;
