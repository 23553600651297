import {
  Stack, HStack, useRadioGroup,
} from "@chakra-ui/react";
import React from "react";

import FieldTitle from "components/FormComponents/FieldTitle";
import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import getErrorMessage from "utils/getErrorMessage";

import RadioCard from "./RadioCard";
import { RadioButtonProps } from "./types";

const RadioButton: React.FC<RadioButtonProps> = ({
  showErrorMessage = true,
  options,
  name,
  defaultValue,
  titleProps,
  title,
  errors,
  onChange,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  });

  const group = getRootProps();
  const error = getErrorMessage(name, errors);

  return (
    <Stack>
      {
        title && (
          <FieldTitle
            htmlFor={name}
            {...(titleProps ?? {})}
            titleCss={{ mb: 0 }}
          >
            {title}
          </FieldTitle>
        )
      }

      <HStack {...group}>
        {options.map(({ value, label }) => {
          const radio = getRadioProps({ value, enterKeyHint: false });
          return (
            <RadioCard key={value} {...radio}>
              {label}
            </RadioCard>
          );
        })}
      </HStack>

      {
        showErrorMessage && (
          <FieldErrorMessage error={error} />
        )
      }
    </Stack>
  );
};

export default RadioButton;
