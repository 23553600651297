import { CustomerTypeEnum } from "generated/graphql";

export type GAEvent = {
  category: string;
  action: string;
  label: string;
}

const CATEGORY_CHECKOUT = "Checkout";

/**
 * Defines possible Google Analytics events.
 */
const gaEvents = {
  contractUploadedByDealer: (customerType: CustomerTypeEnum): GAEvent => ({
    category: CATEGORY_CHECKOUT,
    action: "Contract_uploaded",
    label: customerType,
  }),
  signedContractDownloadedByDealer: (customerType: CustomerTypeEnum): GAEvent => ({
    category: CATEGORY_CHECKOUT,
    action: "Contract_signed_downloaded_dealer",
    label: customerType,
  }),
};

export default gaEvents;
