import React, { useCallback } from "react";
import { addYears, subYears } from "date-fns";
import ReactDatePicker from "react-datepicker";

import getErrorMessage from "utils/getErrorMessage";
import {
  germanDateFormat,
  germanDateTimePickerFormat,
} from "utils/dateFormats";
import { getCurrentLanguageCode } from "utils/getCurrentLanguageCode";

import HelperTextContainer from "../HelperTextContainer";
import { DatePickerProps } from "./types";
import DateInput from "./DateInput";

/**
 * This component should be used with a Controller in order to work
 * with React-Hook-Form
 */
const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>((
  {
    minDate = subYears(new Date(), 50),
    maxDate = addYears(new Date(), 50),
    showErrorMessage = true,
    isDisabled = false,
    validationMessage,
    datePickerProps,
    containerProps,
    placeholder,
    onChange,
    errors,
    value,
    title,
    titleCss,
    name,
  },
  ref,
) => {
  const error = getErrorMessage(name, errors);

  const handleOnDatePickerChange = useCallback((newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  }, [
    onChange,
  ]);

  const dateFormat = (
    datePickerProps?.showTimeSelect
      ? germanDateTimePickerFormat
      : germanDateFormat
  );

  return (
    <HelperTextContainer
      validationMessage={validationMessage}
      showErrorMessage={showErrorMessage}
      title={title}
      titleCss={titleCss}
      error={error}
      name={name}
      {...containerProps}
    >
      <ReactDatePicker
        selected={value}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        disabled={isDisabled}
        showMonthYearDropdown
        onChange={handleOnDatePickerChange}
        locale={getCurrentLanguageCode()}
        placeholderText={placeholder}
        {...datePickerProps}
        customInput={(
          <DateInput
            showErrorMessage={false}
            isDisabled={isDisabled}
            isInvalid={!!error}
            name={name}
            ref={ref}
          />
        )}
      />
    </HelperTextContainer>
  );
});

export default DatePicker;
