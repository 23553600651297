import { QueryParamConfig, UrlUpdateType } from "use-query-params";

import {
  OfferCreationTypeEnum,
  CustomerTypeEnum,
  OfferCustomerTypeEnum,
  VehicleCategoryEnum,
  VehicleTypeEnum,
  LeadStatusEnum,
} from "generated/graphql";
import { availableLeadStatusType } from "components/FormComponents/Inputs/Selects/LeadStatusMultiSelect/types";

export const Filters = {
  Dealer: "Dealer",
  Vehicle: "Vehicle",
  VehicleCategory: "VehicleCategory",
  VehicleModel: "VehicleModel",
  VehicleType: "VehicleType",
  CreationType: "CreationType",
  CustomerType: "CustomerType",
  OfferCustomerType: "OfferCustomerType",
  OnlyActive: "OnlyActive",
  TopOffer: "TopOffer",
  MarketingOffer: "MarketingOffer",
  HasPricingInformation: "HasPricingInformation",
  Search: "Search",
  HasResidualInformation: "HasResidualInformation",
  Step: "Step",
  Period: "Period",
  LeadStatus: "LeadStatus",
  Salesperson: "Salesperson",
  IsPossibleToEnableNewConfigurator: "IsPossibleToEnableNewConfigurator",
  IsNewConfiguratorEnabled: "IsNewConfiguratorEnabled",
} as const;

export type Filter = keyof typeof Filters;

export type UseFilterProps = Filter[];

export type OptionsProps = {
  Dealer?: {
    onlyCalculatorDealers: boolean;
  };
  LeadStatus?: {
    initialValues: LeadStatusEnum[];
    availableLeadStatus: (availableLeadStatusType | undefined)[];
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryFiltersConfig = Record<string, QueryParamConfig<any, any>>;

export type UseFiltersConfig = Record<string, QueryFiltersConfig>;

export type UseFiltersState = {
  dealerId?: string;
  brandId?: number;
  brandName?: string;
  vehicleCategory?: VehicleCategoryEnum;
  modelGroup?: string;
  model?: string;
  customerType?: CustomerTypeEnum;
  vehicleType?: VehicleTypeEnum;
  active?: true;
  hasPricingInformation?: true;
  search?: string;
  hasResidualInformation?: true;
  creationType?: OfferCreationTypeEnum;
  topOffer?: true;
  marketingOffer?: true;
  offerCustomerType?: OfferCustomerTypeEnum;
  step?: number;
  period?: [string, string];
  leadStatus?: LeadStatusEnum[];
  salesperson?: string;
  isPossibleToEnableNewConfigurator?: true;
  isNewConfiguratorEnabled?: true;
}

export interface UseFiltersPayload extends UseFiltersState {
  renderFilters: JSX.Element;
  dirtyFields: UseFiltersState;
  handleSetFilter: (
    filter: string,
    value: unknown,
    mode?: UrlUpdateType
  ) => void;
}
