import yup from "..";

export interface IBudgetSettingsForm {
  currentTotalBudget: string | null;
}

const budgetSettingsFormSchema = yup.object().shape({
  currentTotalBudget: yup
    .string()
    .nullable(),
});

export default budgetSettingsFormSchema;
