import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const GearIcon: IconProps = createIcon({
  viewBox: "0 0 20 21",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3151 3.89542C13.1777 4.19357 13.9647 4.65367 14.6398 5.23936L16.6403 4.593L18.5153 7.84059L16.9563 9.249C17.0397 9.68378 17.0834 10.1327 17.0834 10.5918C17.0834 11.0509 17.0397 11.4998 16.9563 11.9346L18.5153 13.343L16.6403 16.5906L14.6398 15.9442C13.9647 16.5299 13.1777 16.99 12.3151 17.2882L11.8751 19.3418H8.12509L7.68502 17.2882C6.82248 16.99 6.03549 16.5299 5.36041 15.9442L3.35986 16.5906L1.48486 13.343L3.04386 11.9346C2.96043 11.4998 2.91675 11.0509 2.91675 10.5918C2.91675 10.1327 2.96043 9.68378 3.04386 9.249L1.48486 7.84059L3.35986 4.593L5.36041 5.23936C6.03549 4.65367 6.82248 4.19357 7.68502 3.89542L8.12509 1.8418H11.8751L12.3151 3.89542ZM10.0001 14.3418C12.0712 14.3418 13.7501 12.6629 13.7501 10.5918C13.7501 8.52073 12.0712 6.8418 10.0001 6.8418C7.92902 6.8418 6.25009 8.52073 6.25009 10.5918C6.25009 12.6629 7.92902 14.3418 10.0001 14.3418Z"
      fill="currentColor"
    />
  ),
});

export default GearIcon;
