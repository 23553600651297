import { UpdateFixedLeasingRateParams } from "../../../../generated/graphql";
import { CUSTOM_FIELD_REPRESENTATION } from "../../../../constants/leasing";
import { ParseModifiedLeasingRatesWithCustomData } from "./types";
import {
  hasDefaultMileage as hasDefaultMileageHelper,
  hasDefaultMonth as hasDefaultMonthHelper,
} from "./leasingRatesHelper";

const parseModifiedLeasingRatesWithCustomData = ({
  previousLeasingRate,
  newLeasingRate,
  customMileage,
  customMonth,
}: ParseModifiedLeasingRatesWithCustomData): UpdateFixedLeasingRateParams => {
  const customMileageField = !hasDefaultMileageHelper(previousLeasingRate.mileageKm)
    && hasDefaultMonthHelper(previousLeasingRate.termMonths)
    && previousLeasingRate.termMonths === newLeasingRate.termMonths;

  const customMonthField = !hasDefaultMonthHelper(previousLeasingRate.termMonths)
    && hasDefaultMileageHelper(previousLeasingRate.mileageKm)
    && previousLeasingRate.mileageKm === newLeasingRate.mileageKm;

  const customMileageAndCustomMonth = !hasDefaultMonthHelper(previousLeasingRate.termMonths)
    && !hasDefaultMileageHelper(previousLeasingRate.mileageKm);

  switch (true) {
    case customMileageField:
      return {
        ...previousLeasingRate,
        id: Number(`${newLeasingRate.termMonths}${CUSTOM_FIELD_REPRESENTATION}`),
        mileageKm: Number(customMileage),
      };
    case customMonthField:
      return {
        ...previousLeasingRate,
        id: Number(`${CUSTOM_FIELD_REPRESENTATION}${newLeasingRate.mileageKm}`),
        termMonths: Number(customMonth),
      };
    case customMileageAndCustomMonth:
      return {
        ...previousLeasingRate,
        id: CUSTOM_FIELD_REPRESENTATION,
        termMonths: Number(customMonth),
        mileageKm: Number(customMileage),
      };
    default:
      return previousLeasingRate;
  }
};

export default parseModifiedLeasingRatesWithCustomData;
