import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import React from "react";

import { TagListProps } from "../types";

const TagList: React.FC<TagListProps> = ({
  data,
  handleDelete,
}) => (
  <>
    {
      data.map(({ id, name }) => (
        <Tag
          key={id}
          borderRadius="full"
          variant="solid"
          colorScheme="primary"
          mr="5px"
          mb="5px"
        >
          <TagLabel>{name}</TagLabel>
          <TagCloseButton onClick={() => handleDelete(id)} />
        </Tag>
      ))
    }
  </>
);

export default TagList;
