import { StatusBulletProps, StatusTypes } from "components/StatusBullet/types";
import { OfferImportLog } from "generated/graphql";
import i18n from "translations/i18n";

const getBulletStatusList = (log?: OfferImportLog): StatusBulletProps[] => ([
  {
    id: 1,
    status: StatusTypes.created,
    label: String(log?.offersCreated?.length ?? i18n.t("logs.details.created")),
  },
  {
    id: 2,
    status: StatusTypes.deleted,
    label: String(log?.offersDeleted?.length ?? i18n.t("logs.details.deleted")),
  },
  {
    id: 3,
    status: StatusTypes.failed,
    label: String(log?.offersFailed?.length ?? i18n.t("logs.details.failed")),
  },
  {
    id: 4,
    status: StatusTypes.skipped,
    label: String(log?.offersSkipped?.length ?? i18n.t("logs.details.skipped")),
  },
  {
    id: 5,
    status: StatusTypes.updated,
    label: String(log?.offersUpdated?.length ?? i18n.t("logs.details.updated")),
  },
  {
    id: 6,
    status: StatusTypes.published,
    label: String(log?.offersPublished?.length ?? i18n.t("logs.details.published")),
  },
]);

export default getBulletStatusList;
