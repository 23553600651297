/**
 *
 * @param value
 * e.g. : "10.000,00" -> 10000.00
 */
export const germanNotationToDecimal = (value?: string | number): number | undefined => {
  if (!value) return undefined;
  if (typeof value === "number") return value;

  return parseFloat(value?.replace(/[^0-9,]/g, "")?.replace(",", "."));
};
