import React from "react";
import { NumberFormatValues } from "react-number-format";
import { Box, Stack } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import i18n from "translations/i18n";
import SpendingsBar from "components/BudgetOverview/SpendingsBar";
import AlertModal from "components/Modals/AlertModal";
import Checkbox from "components/FormComponents/Checkbox";
import Button from "components/Button";
import { Budget } from "generated/graphql";
import { useModal } from "contexts/modal";

import { BudgetSettingsFormProps } from "../types";
import BudgetInput from "./BudgetInput";

const Form: React.FC<BudgetSettingsFormProps> = ({
  loading,
  budget,
  handleSubmit,
  setValue,
  control,
  formState,
}) => {
  const [budgetData, setBudgetData] = React.useState<Budget>(budget);

  const [showModal] = useModal();

  React.useEffect(() => {
    setBudgetData(budget);
  }, [
    budget,
  ]);

  const disabledCondition = !formState.dirtyFields?.currentTotalBudget;

  const interceptorSubmit = (): void => {
    const totalBudget = Number(budgetData.currentTotalBudget);

    const showModalCondition = totalBudget < budget.budgetSpent
      && (formState.dirtyFields.currentTotalBudget && !budgetData.isUnlimited);

    if (showModalCondition) {
      showModal({
        componentProps: {
          variant: "warning",
          title: i18n.t("screens.settings.tabs.budget.error_modal_title"),
          buttonLabel: i18n.t("screens.settings.tabs.budget.error_modal_button"),
          description: i18n.t("screens.settings.tabs.budget.error_modal_description"),
        },
        component: AlertModal,
        size: "sm",
      });

      return;
    }

    handleSubmit({
      currentTotalBudget: budgetData.isUnlimited ? null : budgetData.currentTotalBudget,
    });
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    const newCurrentTotalBudget = checked ? 0 : budgetData.currentTotalBudget;

    setBudgetData({
      ...budgetData,
      currentTotalBudget: newCurrentTotalBudget,
      isUnlimited: checked,
    });

    if (checked) {
      setValue("currentTotalBudget", newCurrentTotalBudget);
    }
  };

  const handleChangeBudgetInput = (value: number | undefined): void => {
    setBudgetData({
      ...budgetData,
      currentTotalBudget: value,
    });
  };

  const currentTotalBudgetValue = budgetData.isUnlimited ? 0 : budgetData.currentTotalBudget;

  return (
    <>
      <Stack
        alignItems="start"
        direction="row"
      >
        {
          budgetData && (
            <Checkbox
              name="isUnlimited"
              onChange={handleChangeCheckbox}
              isChecked={budgetData.isUnlimited}
            >
              {i18n.t("settings.unlimited")}
            </Checkbox>
          )
        }
      </Stack>

      <Stack
        direction="row"
        w="full"
        mb={8}
      >
        <Box>
          <Controller
            name="currentTotalBudget"
            control={control}
            render={({ field: { onChange } }) => (
              <BudgetInput
                isLoading={loading}
                disabled={budgetData.isUnlimited}
                value={currentTotalBudgetValue}
                defaultValue={currentTotalBudgetValue}
                onValueChange={(values: NumberFormatValues) => [
                  handleChangeBudgetInput(values.floatValue),
                  onChange(values.floatValue),
                ]}
              />
            )}
          />
        </Box>

        <SpendingsBar
          isUnlimited={budgetData.isUnlimited}
          isLoading={loading}
          budget={budgetData.currentTotalBudget}
          spent={budgetData.budgetSpent}
        />
      </Stack>

      <Button
        label={i18n.t("components.buttons.save_changes")}
        disabled={disabledCondition}
        variant="solid"
        fontSize={12}
        isLoading={loading}
        onClick={interceptorSubmit}
      />
    </>
  );
};

export default Form;
