import React from "react";
import { Stack } from "@chakra-ui/react";

import BankDetails from "../BankDetails";
import { PrivateLeadProps } from "./types";
import HousingSituation from "../HousingSituation";
import EmploymentDetails from "../EmploymentDetails";
import Debits from "../Debits";

const PrivateLead: React.FC<PrivateLeadProps> = ({
  isLoading,
  lead,
}) => (
  <>
    <Stack spacing={4} direction="row">
      <HousingSituation
        lead={lead}
        isLoading={isLoading}
      />

      <EmploymentDetails
        lead={lead}
        isLoading={isLoading}
      />
    </Stack>

    <Stack
      spacing={4}
      direction="row"
    >
      <Debits
        lead={lead}
        isLoading={isLoading}
      />

      <BankDetails
        lead={lead}
        isLoading={isLoading}
      />
    </Stack>
  </>
);

export default PrivateLead;
