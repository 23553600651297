import { SpecialConditionsSchema } from "settings/yup/schemas/offerDetailsSchema";
import { camelToSnakeCase } from "utils/camelToSnakeCase";
import { SpecialCondition } from "generated/graphql";

import { UpdateSpecialConditionsPayload } from "./types";

export const parsedOfferSpecialConditions = (
  financingSpecialConditions: SpecialConditionsSchema,
  specialConditions: SpecialCondition[],
): UpdateSpecialConditionsPayload[] => (
  Object.keys(financingSpecialConditions)
    .reduce((conditionsList, key) => {
      if (financingSpecialConditions?.[key].isChecked) {
        conditionsList.push({
          specialConditionId: specialConditions?.find(
            (item: SpecialCondition) => item.slug === camelToSnakeCase(key),
          )?.id,
          description: financingSpecialConditions?.[key]?.description,
        });
      }
      return conditionsList;
    }, [] as UpdateSpecialConditionsPayload[])
);
