import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import { useModal } from "contexts/modal";
import BanksModal from "components/Modals/BanksModal";
import { Bank } from "generated/graphql";

import { makeTableColumns } from "./tableColumns";
import { BanksTableProp } from "../types";

const BanksTable: React.FC<BanksTableProp> = ({
  banks,
  loading,
}) => {
  const { t } = useTranslation();
  const [showModal] = useModal();

  const onEditBank = useCallback((bank: Bank) => () => {
    showModal({
      componentProps: {
        bank,
      },
      component: BanksModal,
      closeOnOverlayClick: false,
      size: "6xl",
    });
  }, [
    showModal,
  ]);

  const columns = makeTableColumns({
    onEditBank,
  });

  return (
    <Table
      columns={columns}
      data={banks}
      emptyDataProps={{
        message: t("empty_data_messages.no_information_to_be_displayed"),
        isDataLoading: loading,
      }}
      isPageable
    />
  );
};

export default BanksTable;
