import i18n from "translations/i18n";
import { OptionProps } from "components/FormComponents/Inputs/Selects/BaseSelect/types";
import { FixedLeasingRatesPriceTypeEnum } from "generated/graphql";

export const makeRatesOptions = (): OptionProps[] => (
  Object.values(FixedLeasingRatesPriceTypeEnum).map(value => {
    const label = `components.modals.edit_leasing_rates_modal.${value.toLowerCase()}`;
    return {
      label: i18n.t(label),
      value,
    };
  })
    .reverse()
);
