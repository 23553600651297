import { ChakraTheme, theme as chakraUiTheme } from "@chakra-ui/react";

import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "./colors";

const sizes: ChakraTheme["styles"] = {
  ...chakraUiTheme.styles,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  global: (props) => ({
    body: {
      color: "gray.500",
      bg: "background.100",
    },
    "::-webkit-scrollbar": {
      height: "8px",
      width: "8px",
    },
    "::-webkit-scrollbar-track": {
      background: PRIMARY_COLOR_LIGHT,
      width: "8px",
      borderRadius: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      background: PRIMARY_COLOR,
      borderRadius: "24px",
    },
  }),
};

export default sizes;
