export const disabledCss = {
  filter: "grayscale(1)",
  opacity: "0.5",
  pointerEvents: "none",
  bg: "red",
};

export const checkedStyles = {
  p: {
    color: "white",
  },
  backgroundColor: "secondary.500",
  "&:hover p": {
    color: "white",
  },
};

export const uncheckedStyles = {
  p: {
    color: "gray.500",
  },
  backgroundColor: "white",
  "&:hover p": {
    color: "secondary.500",
  },
};
