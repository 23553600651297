import { AccessoryEnum, OfferAccessory } from "generated/graphql";

import {
  Accessories,
  EquipmentDefaultProps,
  EquipmentFormProps,
  OfferEquipment,
} from "./types";

export const parseAccessories = (
  offerAccessories: Pick<OfferAccessory, "id" | "accessory">[],
  selectedAccessories: AccessoryEnum[],
): Accessories => [...new Set(selectedAccessories)]?.map(accessory => {
  const existingAccessory = offerAccessories
    ?.find(offerAccessory => offerAccessory.accessory === accessory);

  if (existingAccessory) {
    return {
      accessory: existingAccessory.accessory,
      id: existingAccessory.id,
    };
  }

  return { accessory };
});

export const getEquipmentDefaultValues = (
  offer: OfferEquipment,
  equipment?: EquipmentDefaultProps,
): EquipmentFormProps["defaultFormValues"] => {
  const selectedAccessories = equipment?.selectedAccessories || [];
  const offerAccessories = offer.accessories;
  const accessories = selectedAccessories?.length >= 0
    ? parseAccessories(offerAccessories, selectedAccessories)
    : offerAccessories;

  return (
    equipment
      ? {
        ...equipment,
        accessories,
      } : offer
  );
};
