import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "settings/theme/colors";

export const activeFieldCss = {
  borderColor: "secondary.500",
};

export const disabledFieldCss = {
  backgroundColor: "gray.200",
  borderColor: "gray.400",
  pointerEvents: "none",
  color: "gray.400",
};

export const placeholderCss = {
  color: "gray.400",
};

export const invalidCss = {
  borderWidth: "1px",
  borderColor: "error.500",
};

export const generalCss = {
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    background: PRIMARY_COLOR_LIGHT,
    width: "8px",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: PRIMARY_COLOR,
    borderRadius: "4px",
  },
};
