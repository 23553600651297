import { parseISO as dateFnsParseISO } from "date-fns";

export const parseISO = (
  date?: string | null,
  options?: {additionalDigits: 0 | 1 | 2 | undefined },
): undefined | Date => {
  if (typeof date !== "string" || !date) {
    return undefined;
  }

  return dateFnsParseISO(date, options);
};
