import React, {
  useCallback,
} from "react";
import {
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import i18n from "translations/i18n";
import {
  useUpdateVehicleMutation,
  useGetVehicleDataQuery,
  Vehicle,
  UpdateDynamicLeasingRateParams,
  UpdateBrandDiscountParams,
  UpdateVehicleParams,
} from "generated/graphql";
import {
  BrandDiscount,
  editVehicleSchema,
  EditVehicleSchema,
  DynamicLeasingRates,
} from "settings/yup/schemas/editVehicleSchema";
import isPositiveValue from "utils/isPositiveValue";

import { EditVehicleModalProps } from "./types";
import EditVehicleForm from "./Form";

export const
  normalizeNumber = (number: string | number | undefined) : number | undefined => {
    if (!number) {
      return undefined;
    }

    const normalizedNumber = parseFloat(String(number || 0)?.replace(/\./g, "")
      ?.replace(/,/g, "."));

    return normalizedNumber;
  };

export const parseDynamicLeasingRates = (
  values: DynamicLeasingRates,
): UpdateDynamicLeasingRateParams[] => (
  values.reduce((acc, dynamicLeasingRate) => {
    const {
      termMonths,
      mileageKm,
      residualRate,
      residualFactor,
      salesFactor,
      nominalInterestRate,
      id,
    } = dynamicLeasingRate;

    const normalizedResidualRate = normalizeNumber(residualRate);
    const normalizedResidualFactor = normalizeNumber(residualFactor);
    const normalizedSalesFactor = normalizeNumber(salesFactor);
    const normalizedNominalInterestRate = normalizeNumber(nominalInterestRate);

    if (
      normalizedResidualRate
      || normalizedResidualFactor
      || normalizedSalesFactor
      || normalizedNominalInterestRate
    ) {
      acc.push({
        termMonths,
        mileageKm,
        id,
        residualRate: normalizedResidualRate,
        residualFactor: normalizedResidualFactor,
        salesFactor: normalizedSalesFactor,
        nominalInterestRate: normalizedNominalInterestRate,
      });
    }

    return acc;
  }, [] as UpdateDynamicLeasingRateParams[])
);

export const parseBrandDiscounts = (brandDiscount: BrandDiscount): UpdateBrandDiscountParams[] => [
  { ...brandDiscount.corporate },
  { ...brandDiscount.private },
]?.filter(b => isPositiveValue(b.discount)
  || isPositiveValue(b.downPaymentDiscount)
  || isPositiveValue(b.listPriceDiscountRate));

const EditVehicleModal: React.FC<EditVehicleModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const newToast = useToast();
  const [editVehicle, { loading }] = useUpdateVehicleMutation();

  const { id } = componentProps.data;

  const {
    data,
  } = useGetVehicleDataQuery({
    variables: {
      id: Number(id),
    },
  });

  const vehicle: Vehicle = data?.vehicle as Vehicle;

  const resolver = yupResolver(editVehicleSchema);

  const {
    handleSubmit,
    formState,
    register,
    setValue,
    getValues,
    control,
  } = useForm<EditVehicleSchema>({
    resolver,
    mode: "onSubmit",
  });

  const onUpdate = useCallback(
    (values: EditVehicleSchema) => {
      const bankId = values?.bankId === undefined
        ? vehicle?.bank?.id : (values.bankId || null);

      const vehicleParams = {
        bankId: bankId ? Number(bankId) : null,
        baseListPriceNet: normalizeNumber(values.listPrice || vehicle?.baseListPriceNet),
        manufacturerBonusNet: normalizeNumber(
          values.manufacturerBonusNet || vehicle?.manufacturerBonusNet,
        ),
        configuratorLink: values.configuratorLink || vehicle?.configuratorLink,
        configurationNotes: values.configurationNotes || vehicle?.configurationNotes,
        calculateWithFactors: values.calculateWithFactors === "true",
        dynamicLeasingRates: parseDynamicLeasingRates(values.dynamicLeasingRates),
        brandDiscounts: parseBrandDiscounts({
          corporate: values.brandDiscounts.corporate,
          private: values.brandDiscounts.private,
        }),
      } as UpdateVehicleParams;

      editVehicle({
        awaitRefetchQueries: true,
        refetchQueries: ["GetBaseVehicles", "GetVehicleData"],
        variables: {
          id: vehicle.id,
          params: vehicleParams,
        },
      })
        .then(() => {
          newToast({
            title: i18n.t("success.success"),
            description: i18n.t("success.vehicle_updated"),
            status: "success",
            duration: 6000,
            isClosable: true,
          });

          hideModal();
        })
        .catch((error: { message: string }) => {
          newToast({
            title: i18n.t("errors.something_went_wrong"),
            description: error.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        });
    },
    [
      hideModal,
      editVehicle,
      vehicle,
      newToast,
    ],
  );

  const handleCancel = useCallback(() => {
    hideModal();
    setValue("dynamicLeasingRates", vehicle?.dynamicLeasingRates || []);
  }, [
    hideModal,
    setValue,
    vehicle?.dynamicLeasingRates,
  ]);

  const makeModalTitle = (): string => (
    [
      vehicle?.brand?.name,
      vehicle?.modelGroup,
      vehicle?.model,
    ].filter(Boolean).join(" - ")
  );

  return (
    <Flex
      flexDir="column"
      overflow="auto"
      p="6"
    >
      <Text
        lineHeight="24px"
        fontSize="20px"
        textStyle="h3"
        mb={6}
      >
        {makeModalTitle()}
      </Text>

      {vehicle && (
        <EditVehicleForm
          vehicle={vehicle}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit(onUpdate)}
          register={register}
          errors={formState.errors}
          submitState={!loading}
          setValue={setValue}
          getValues={getValues}
          control={control}
          formState={formState}
          defaultFormValues={componentProps?.data}
        />
      )}
    </Flex>
  );
};

export default EditVehicleModal;
