import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const DashIcon: IconProps = createIcon({
  viewBox: "0 0 14 4",
  path: <rect y={0.5} width={14} height={3} rx={1.5} fill="currentColor" />,
});

export default DashIcon;
