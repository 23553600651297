import { ChakraTheme, theme as chakraUiTheme } from "@chakra-ui/react";

const fontSizes: ChakraTheme["fontSizes"] = {
  ...chakraUiTheme.fontSizes,
  xxs: "10px",
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "34px",
};

export default fontSizes;
