import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import BrandsModal from "components/Modals/BrandsModal";
import { useModal } from "contexts/modal";
import { Brand } from "generated/graphql";

import { makeTableColumns } from "./tableColumns";
import { BrandsTableProp } from "../types";

const BrandsTable: React.FC<BrandsTableProp> = ({
  brands,
  loading,
}) => {
  const { t } = useTranslation();
  const [showModal] = useModal();

  const onEditBrand = useCallback((brand : Brand) => () => {
    showModal({
      componentProps: {
        brand,
      },
      component: BrandsModal,
      closeOnOverlayClick: false,
      size: "xl",
    });
  }, [
    showModal,
  ]);

  const columns = makeTableColumns({
    onEditBrand,
  });

  return (
    <Table
      columns={columns}
      data={brands}
      emptyDataProps={{
        message: t("empty_data_messages.no_information_to_be_displayed"),
        isDataLoading: loading,
      }}
      isPageable
    />
  );
};

export default BrandsTable;
