import React, { forwardRef } from "react";
import {
  Box,
  chakra,
  TabProps,
  Text,
  useStyles,
  useTab,
} from "@chakra-ui/react";

import { tabCss } from "./styles";

const StyledTab = chakra("div", {
  baseStyle: {
    padding: 0,
  },
});

const Tab = forwardRef<HTMLSpanElement, TabProps>((
  props,
  ref,
) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps["aria-selected"];
  const tabColor = isSelected ? "white" : "gray.200";
  const tabLabelColor = isSelected ? "secondary.500" : "gray.500";

  const styles = useStyles();

  return (
    <Box
      as="button"
      type="button"
      px={1}
      pt={1}
      mr={2}
      overflow="hidden"
    >
      <StyledTab
        __css={styles.tab}
        outline="none !important"
        borderRadius={tabCss.borderRadius}
        boxShadow={tabCss.boxShadow}
        _focus={tabCss}
        {...tabProps}
      >
        <Box
          p={3}
          minW="100px"
          bgColor={tabColor}
          fontFamily="regular"
          borderRadius={tabCss.borderRadius}
        >
          <Text
            mr={2}
            as="span"
            textStyle="h3"
            color={tabLabelColor}
            margin="auto"
          >
            {props.children}
          </Text>
        </Box>
      </StyledTab>
    </Box>
  );
});

export default Tab;
