import React from "react";
import {
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { iconsMap } from "constants/icons";
import { formatDate, germanDateTimePickerFormat } from "utils/dateFormats";
import {
  useDeleteLeadFileMutation,
} from "generated/graphql";
import { useModal } from "contexts/modal";
import ConfirmationModal from "components/Modals/ConfirmationModal";

import { DocumentAccordionItemProps } from "./types";
import DownloadButton from "../DownloadButton";
import { getCheckoutLeadDocumentsQuery } from "../../graphql/getCheckoutLeadDocumentsQuery";

const DocumentAccordionItem: React.FC<DocumentAccordionItemProps> = ({
  document,
  downloadDocument,
  fileType,
  lead,
}) => {
  const { t } = useTranslation();
  const [showModal] = useModal();
  const showToast = useToast();
  const [deleteLeadFile, { loading }] = useDeleteLeadFileMutation();

  const deleteFile = (): void => {
    if (loading) {
      return;
    }

    showToast({
      status: "info",
      title: t("progress.deleting_file"),
    });

    deleteLeadFile({
      variables: {
        id: document.id,
      },
      refetchQueries: [{
        query: getCheckoutLeadDocumentsQuery,
        variables: {
          id: lead.id,
        },
      }],
      awaitRefetchQueries: true,
    })
      .then(() => {
        showToast({
          status: "success",
          title: t("success.document_deleted"),
        });
      })
      .catch(() => {
        showToast({
          status: "error",
          title: t("errors.something_went_wrong"),
        });
      });
  };

  const openDeleteDocumentConfirmationModal = (): void => {
    showModal({
      componentProps: {
        onConfirm: () => deleteFile(),
        title: t("actions.delete_document_confirmation_modal.title"),
      },
      closeOnOverlayClick: false,
      component: ConfirmationModal,
      size: "sm",
    });
  };

  return (
    <Flex
      borderTopWidth={1}
      borderTopStyle="solid"
      borderTopColor="gray.200"
      py={2}
      direction="column"
    >
      <Flex alignItems="center" justifyContent="space-between" mx={4}>
        <Flex alignItems="center">
          {
            fileType.canDelete && (
              <iconsMap.CloseIcon
                cursor={loading ? "default" : "pointer"}
                onClick={openDeleteDocumentConfirmationModal}
                color="error.500"
                opacity={loading ? "30%" : 1}
              />
            )
          }

          <Text
            fontSize={14}
            fontWeight="400"
            ml={fileType.canDelete ? 4 : 8}
          >
            {formatDate(document?.insertedAt, germanDateTimePickerFormat)}
          </Text>
        </Flex>

        <Flex alignItems="center">
          <DownloadButton
            onClick={() => downloadDocument(document.id)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DocumentAccordionItem;
