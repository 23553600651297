import { CustomerTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";

export const MAP_CUSTOMER_TYPE = {
  [CustomerTypeEnum.Private]: i18n.t("enum.customer_type.private"),
  [CustomerTypeEnum.Corporate]: i18n.t("enum.customer_type.corporate"),
};

export const MAP_TAB_TITLE = {
  [CustomerTypeEnum.Private]: i18n.t("components.modals.edit_vehicle_modal.private_customer_fields"),
  [CustomerTypeEnum.Corporate]: i18n.t("components.modals.edit_vehicle_modal.corporate_customer_fields"),
};

export enum TableFactorsEnum {
  residualRate = "residualRate",
  residualFactor = "residualFactor",
  salesFactor = "salesFactor",
  nominalInterestRate = "nominalInterestRate",
  servicePackage = "servicePackage",
  insurance = "insurance",
}

export const MAP_FACTORS = {
  residualRate: {
    name: "residualRate",
    translationKey: i18n.t("components.modals.edit_vehicle_modal.residual_rate"),
  },
  residualFactor: {
    name: "residualFactor",
    translationKey: i18n.t("components.modals.edit_vehicle_modal.residual_factor"),
  },
  salesFactor: {
    name: "salesFactor",
    translationKey: i18n.t("components.modals.edit_vehicle_modal.sales_factor"),
  },
  nominalInterestRate: {
    name: "nominalInterestRate",
    translationKey: i18n.t("components.modals.edit_vehicle_modal.nominal_interest_rate"),
  },
  servicePackage: {
    name: "servicePackage",
    translationKey: i18n.t("components.modals.edit_vehicle_modal.service_package"),
  },
  insurance: {
    name: "insurance",
    translationKey: i18n.t("components.modals.edit_vehicle_modal.insurance"),
  },
};
