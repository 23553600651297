import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { LoginComponent } from "components/LoginComponent";
import loginSchema, { LoginSchema } from "settings/yup/schemas/loginSchema";
import { SellerRoleEnum, UserRoleEnum, useSignInUserMutation } from "generated/graphql";
import { CLASSIC_LEADS_OVERVIEW_PAGE_PATH, OFFERS_PAGE_PATH } from "routes";
import { useSession } from "contexts/session";
import { useLoading } from "contexts/loading";

const SignIn: React.FC = () => {
  const { createSession } = useSession();
  const { start, done } = useLoading();
  const { t } = useTranslation();
  const newToast = useToast();

  const [signInUser, { loading }] = useSignInUserMutation();

  const { handleSubmit, formState, register } = useForm<LoginSchema>({
    resolver: yupResolver(loginSchema),
    mode: "all",
  });

  const onSubmit = useCallback(
    (values: LoginSchema) => {
      signInUser({
        variables: {
          password: values.password,
          email: values.email,
        },
      })
        .then((response) => {
          start();
          const isAdmin = (
            response.data?.signIn?.user?.role === UserRoleEnum.Admin
          );
          const isSeller = !!response.data?.signIn.user.seller;
          const isSalesUser = response.data?.signIn?.user?.seller?.role === SellerRoleEnum.Sales;

          if (!isSeller && !isAdmin) {
            throw new Error(t("errors.access_denied"));
          }

          createSession(isSalesUser ? CLASSIC_LEADS_OVERVIEW_PAGE_PATH : OFFERS_PAGE_PATH);

          done();
        })
        .catch((error) => {
          newToast({
            title: t("errors.something_went_wrong"),
            description: error.message,
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });
    },
    [
      createSession,
      signInUser,
      newToast,
      start,
      done,
      t,
    ],
  );

  return (
    <LoginComponent
      handleSubmit={handleSubmit(onSubmit)}
      errors={formState.errors}
      register={register}
      isLoading={loading}
    />
  );
};

export default SignIn;
