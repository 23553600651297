import React, {
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import { useModal } from "contexts/modal";
import { EditCalculatorOfferModal } from "components/Modals/EditCalculatorOfferModal";

import { makeTableColumns } from "./tableColumns";
import { CalculatorOfferRow, CalculatorOffersTableProps } from "../types";
import useCalculatorOffersStore from "../CalculatorOffersFooter/store";

const CalculatorOffersTable: React.FC<CalculatorOffersTableProps> = ({
  data,
  loading,
}) => {
  const { t } = useTranslation();

  const [showModal] = useModal();

  const {
    selectedItems,
    toggleItem,
    toggleAllItems,
    clearSelectedItems,
  } = useCalculatorOffersStore();

  const identifiedRows = useMemo(() => (
    data?.map((row) => ({
      ...row,
      id: row.vehicle.id + row.customerType + row.dealer.id,
    }))
  ), [data]);

  const calculatorOffers: CalculatorOfferRow[] = useMemo(() => (
    identifiedRows || []
  ), [
    identifiedRows,
  ]);

  const toggleCalculatorOffer = useCallback((row) => () => {
    toggleItem(row);
  }, [toggleItem]);

  const hasOffers = calculatorOffers.length > 0;

  const isAllSelected = (
    hasOffers
    && selectedItems.length === calculatorOffers.length
  );

  const handleToggleAllItems = useCallback(() => {
    toggleAllItems(isAllSelected, calculatorOffers);
  }, [
    toggleAllItems,
    isAllSelected,
    calculatorOffers,
  ]);

  const onEdit = useCallback((row: CalculatorOfferRow) => {
    showModal({
      component: EditCalculatorOfferModal,
      size: "6xl",
      isCloseable: false,
      componentProps: {
        data: row,
      },
    });
  }, [
    showModal,
  ]);

  const columns = makeTableColumns({
    toggleCalculatorOffer,
    selectedCalculatorOffers: selectedItems,
    toggleAllItems: handleToggleAllItems,
    isAllSelected,
    hasOffers,
    onEdit,
  });

  const message = t("empty_data_messages.no_calculator_offers_were_found");

  useEffect(() => {
    clearSelectedItems();
  }, [
    calculatorOffers,
    clearSelectedItems,
  ]);

  return (
    <Table
      columns={columns}
      data={calculatorOffers}
      emptyDataProps={{
        message,
        isDataLoading: loading,
      }}
      isPageable
    />
  );
};

export default CalculatorOffersTable;
