import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Flex, Stack, Text, useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { nanoid } from "nanoid";

import { useModal } from "contexts/modal";
import Button from "components/Button";
import Input from "components/FormComponents/Inputs/Input";
import createBanksSchema, { CreateUpdateBanksSchema } from "settings/yup/schemas/createBanksSchema";
import { useCreateBankMutation, useUpdateBankMutation } from "generated/graphql";
import TagInput from "components/TagInput";
import { TagType } from "components/TagInput/types";

import { BanksModalProps } from "./types";
import { CREATE_BANK_REFETCH_QUERIES } from "./graphql/createBank";
import { UPDATE_BANK_REFETCH_QUERIES } from "./graphql/updateBank";
import AlertModal from "../AlertModal";
import CustomPercentageField from "../EditLeasingRatesModal/Form/Fields/CustomPercentageField";

const BanksModal: React.FC<BanksModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();
  const [showModal] = useModal();
  const newToast = useToast();

  const { bank } = componentProps;

  const aliasesDefaultValue = bank?.aliases.map(alias => ({ id: `${nanoid()}${alias}`, name: alias }));

  const {
    handleSubmit,
    formState,
    register,
    setValue,
  } = useForm<CreateUpdateBanksSchema>({
    defaultValues: {
      ...bank,
      aliases: aliasesDefaultValue,
      id: bank?.id?.toString(),
    },
    resolver: yupResolver(createBanksSchema),
    mode: "onChange",
  });

  const handleAliasesUpdate = useCallback((aliases: TagType[]) => {
    register("aliases");
    setValue("aliases", aliases);
  }, [register, setValue]);

  const handleCorporateTemplateFieldsUpdate = useCallback((corporateTemplateFields: TagType[]) => {
    register("corporateTemplateFields");
    setValue("corporateTemplateFields", corporateTemplateFields?.map(ct => ct.name));
  }, [register, setValue]);

  const handlePrivateTemplateFieldsUpdate = useCallback((privateTemplateFields: TagType[]) => {
    register("privateTemplateFields");
    setValue("privateTemplateFields", privateTemplateFields?.map(ct => ct.name));
  }, [register, setValue]);

  const [createBank] = useCreateBankMutation({
    awaitRefetchQueries: true,
    refetchQueries: CREATE_BANK_REFETCH_QUERIES,
  });

  const [updateBank] = useUpdateBankMutation({
    awaitRefetchQueries: true,
    refetchQueries: UPDATE_BANK_REFETCH_QUERIES,
  });

  const handleCancel = useCallback(() => {
    hideModal();
  }, [
    hideModal,
  ]);

  const onSubmit = useCallback((values: CreateUpdateBanksSchema) => {
    if (bank?.id) {
      return updateBank({
        variables: {
          id: bank?.id,
          params: {
            name: values.name,
            address: values.address,
            nominalInterestRate: values.nominalInterestRate,
            effectiveAnnualInterestRate: values.effectiveAnnualInterestRate,
            aliases: values.aliases?.map(alias => alias.name),
            privateTemplateId: values.privateTemplateId,
            corporateTemplateId: values.corporateTemplateId,
            privateTemplateFields: values?.privateTemplateFields,
            corporateTemplateFields: values?.corporateTemplateFields,
          },
        },
      }).then(() => {
        showModal({
          componentProps: {
            title: t("components.modals.banks.updated_successfully"),
            variant: "success",
          },
          closeOnOverlayClick: false,
          component: AlertModal,
          size: "xl",
        });
      })
        .catch(error => {
          hideModal();
          newToast({
            title: t("errors.something_went_wrong"),
            description: error.message,
            status: "error",
          });
        });
    }

    return createBank({
      variables: {
        params: {
          name: values.name,
          address: values.address,
          nominalInterestRate: values.nominalInterestRate,
          effectiveAnnualInterestRate: values.effectiveAnnualInterestRate,
          aliases: values.aliases?.map(alias => alias.name),
          privateTemplateId: values.privateTemplateId,
          corporateTemplateId: values.corporateTemplateId,
          privateTemplateFields: values.privateTemplateFields,
          corporateTemplateFields: values.corporateTemplateFields,
        },
      },
    }).then(() => {
      showModal({
        componentProps: {
          title: t("components.modals.banks.created_successfully"),
          variant: "success",
        },
        closeOnOverlayClick: false,
        component: AlertModal,
        size: "xl",
      });
    })
      .catch(error => {
        hideModal();
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
        });
      });
  }, [
    bank,
    createBank,
    hideModal,
    newToast,
    showModal,
    t,
    updateBank,
  ]);

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        h="full"
        w="full"
        p={6}
        spacing={6}
        overflow="auto"
      >
        <Text
          lineHeight="24px"
          fontSize="20px"
          textStyle="h3"
          mb={6}
        >
          {
            bank?.id
              ? t("components.modals.banks.edit_title")
              : t("components.modals.banks.create_title")
          }
        </Text>

        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Flex marginRight="10px" w="full">
            <Input
              {...register("name")}
              errors={formState.errors}
              title={t("components.modals.banks.name_field")}
              defaultValue={bank?.name}
            />
          </Flex>
          <Flex w="full">
            <Input
              {...register("address")}
              errors={formState.errors}
              title={t("components.modals.banks.address_field")}
              defaultValue={bank?.address}
            />
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Flex marginRight="10px" w="full">
            <CustomPercentageField
              allowNegative
              netTitle={t("components.modals.banks.nominal_interest_rate_field")}
              defaultValue={bank?.nominalInterestRate}
              setValue={setValue}
              name="nominalInterestRate"
              border="1px solid"
              width="full"
            />
          </Flex>
          <Flex w="full">
            <CustomPercentageField
              allowNegative
              netTitle={t("components.modals.banks.effective_annual_interest_rate")}
              defaultValue={bank?.effectiveAnnualInterestRate}
              name="effectiveAnnualInterestRate"
              setValue={setValue}
              border="1px solid"
              width="full"
            />
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Flex marginRight="10px" w="full">
            <TagInput
              label={t("components.modals.banks.aliases_field")}
              setValue={handleAliasesUpdate}
              name="aliases"
              defaultValues={aliasesDefaultValue}
              errors={formState.errors}
            />
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Flex marginRight="10px" w="full">
            <Input
              {...register("privateTemplateId")}
              errors={formState.errors}
              title={t("components.modals.banks.private_template_id")}
              defaultValue={bank?.privateTemplateId}
            />
          </Flex>
          <Flex w="full">
            <Input
              {...register("corporateTemplateId")}
              errors={formState.errors}
              title={t("components.modals.banks.corporate_template_id")}
              defaultValue={bank?.corporateTemplateId}
            />
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Flex marginRight="10px" w="full">
            <TagInput
              label={t("components.modals.banks.private_template_fields")}
              setValue={handlePrivateTemplateFieldsUpdate}
              name="privateTemplateFields"
              defaultValues={bank?.privateTemplateFields?.map(pt => ({ name: pt, id: nanoid() }))}
              errors={formState.errors}
            />
          </Flex>
          <Flex w="full">
            <TagInput
              label={t("components.modals.banks.corporate_template_fields")}
              name="corporateTemplateFields"
              setValue={handleCorporateTemplateFieldsUpdate}
              defaultValues={bank?.corporateTemplateFields?.map(ct => ({ name: ct, id: nanoid() }))}
              errors={formState.errors}
            />
          </Flex>
        </Flex>

      </Stack>

      <Flex
        justifyContent="space-between"
        borderRadius="0 0 8px 8px"
        bg="gray.200"
        w="full"
        p={6}
      >
        <Button
          label={t("components.buttons.cancel")}
          onClick={handleCancel}
          variant="ghost"
          bgColor="white"
          boxShadow="md"
        />

        <Button
          label={t("components.buttons.save")}
          onClick={handleSubmit(onSubmit)}
          isDisabled={!formState.isValid}
          boxShadow="md"
        />
      </Flex>
    </>
  );
};

export default BanksModal;
