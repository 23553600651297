import React, { useCallback, useMemo, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import {
  Offer,
  usePublishOffersMutation,
  useDeactivateOffersMutation,
  useDeleteOffersMutation,
} from "generated/graphql";
import { useModal } from "contexts/modal";
import AlertModal from "components/Modals/AlertModal";
import EditLeasingRatesModal from "components/Modals/EditLeasingRatesModal";
import DuplicateOfferModal from "components/Modals/DuplicateOfferModal";

import { makeTableColumns } from "./tableColumns";
import { OffersTableProps } from "../types";
import useOffersStore from "../OffersFooter/store";

const OFFERS_REFETCH_QUERIES = ["Offers"];

const OffersTable: React.FC<OffersTableProps> = ({
  data,
  specialConditionsData,
  loading,
}) => {
  const { t } = useTranslation();
  const newToast = useToast();

  const {
    selectedItems,
    toggleItem,
    toggleAllItems,
    clearSelectedItems,
  } = useOffersStore();

  const [showModal] = useModal();

  const hasOffers = data?.length > 0;

  const offers: Offer[] = useMemo(() => (data || []), [data]);

  const [deactivateOffers] = useDeactivateOffersMutation({
    awaitRefetchQueries: true,
    refetchQueries: OFFERS_REFETCH_QUERIES,
  });

  const [publishOffers] = usePublishOffersMutation({
    awaitRefetchQueries: true,
    refetchQueries: OFFERS_REFETCH_QUERIES,
  });

  const [deleteOffers] = useDeleteOffersMutation({
    awaitRefetchQueries: true,
    refetchQueries: OFFERS_REFETCH_QUERIES,
  });

  const isAllSelected = (
    hasOffers
    && selectedItems?.length === data?.length
  );

  const onEditOffer = useCallback((row) => () => {
    const editOfferUrl = `/custom-offer/${row?.id}?step=1`;
    window.open(editOfferUrl, "_blank");
  }, []);

  const onEditLeasingRates = useCallback((row) => () => {
    showModal({
      componentProps: {
        data: row,
        specialConditionsData,
      },
      size: "6xl",
      component: EditLeasingRatesModal,
      isCloseable: false,
      isCentered: false,
    });
  }, [specialConditionsData, showModal]);

  const onDuplicate = useCallback((offer) => () => {
    showModal({
      componentProps: {
        offer,
      },
      size: "xl",
      component: DuplicateOfferModal,
      isCloseable: true,
      isCentered: true,
    });
  }, [showModal]);

  const onActivate = useCallback((row) => () => {
    if (!row?.id) {
      return;
    }

    publishOffers({
      variables: {
        offersIds: row?.id,
      },
    })
      .then(() => {
        newToast({
          title: t("actions.success"),
          description: t("actions.offers_has_been_published_successfully", { count: 1 }),
          status: "success",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  }, [
    clearSelectedItems,
    newToast,
    publishOffers,
    t,
  ]);

  const onDeactivate = useCallback((row) => () => {
    if (!row?.id) {
      return;
    }

    deactivateOffers({
      variables: {
        offersIds: row?.id,
      },
    })
      .then(() => {
        newToast({
          title: t("actions.success"),
          description: t("actions.offers_has_been_deactivated_successfully", { count: 1 }),
          status: "success",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  }, [
    clearSelectedItems,
    newToast,
    deactivateOffers,
    t,
  ]);

  const showOnWebsite = useCallback((row) => () => {
    if (!row?.url) {
      return;
    }

    window.open(row?.url, "_blank");
  }, []);

  const handleDelete = useCallback((row) => () => {
    if (!row?.id) {
      return;
    }

    deleteOffers({
      variables: {
        offersIds: row?.id,
      },
    })
      .then(() => {
        newToast({
          title: t("actions.success"),
          description: t("actions.offers_has_been_deleted_successfully",
            { count: 1 }),
          status: "success",
          isClosable: true,
        });

        clearSelectedItems();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  }, [
    clearSelectedItems,
    newToast,
    deleteOffers,
    t,
  ]);

  const onDeleteOffer = useCallback((row: Offer) => () => {
    showModal({
      componentProps: {
        title: t("warnings.warning"),
        description: t("warnings.are_you_sure_want_to_proceed"),
        variant: "warning",
        callback: handleDelete(row),
      },
      closeOnOverlayClick: false,
      component: AlertModal,
      size: "sm",
    });
  }, [
    t,
    showModal,
    handleDelete,
  ]);

  const toggleOffer = useCallback((row) => () => {
    toggleItem(row);
  }, [toggleItem]);

  const handleToggleAllItems = useCallback(() => {
    toggleAllItems(isAllSelected, data);
  }, [
    toggleAllItems,
    isAllSelected,
    data,
  ]);

  const columns = makeTableColumns({
    onEditOffer,
    onEditLeasingRates,
    onActivate,
    onDeactivate,
    showOnWebsite,
    onDeleteOffer,
    onDuplicate,
    selectedOffers: selectedItems,
    toggleOffer,
    toggleAllItems: handleToggleAllItems,
    isAllSelected,
    hasOffers,
  });

  useEffect(() => {
    clearSelectedItems();
  }, [
    offers,
    clearSelectedItems,
  ]);

  return (
    <Table
      columns={columns}
      data={offers}
      emptyDataProps={{
        message: t("empty_data_messages.no_offers_were_found"),
        isDataLoading: loading,
      }}
      isPageable
    />
  );
};

export default OffersTable;
