import { HousingSituationEnum, Lead } from "generated/graphql";

const getHousingCost = (lead: Lead): string => {
  const {
    housingSituation, rentalCostApartment, houseCost, rentalCost,
  } = lead;

  const HOUSING_SITUATIONS = {
    [HousingSituationEnum.Apartment]: rentalCostApartment,
    [HousingSituationEnum.House]: houseCost,
    [HousingSituationEnum.Rent]: rentalCost,
  };

  return HOUSING_SITUATIONS[housingSituation] ?? "";
};

export default getHousingCost;
