import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Text,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
} from "react-grid-dnd";
import { useDropzone } from "react-dropzone";

import DelectableImage from "components/Images/DelectableImage";
import UploadIcon from "components/Icons/UploadIcon";

import UploadButton from "../UploadButton";
import { GalleryProps, ParsedImage } from "./types";
import { PurgeGalleryImagesButton } from "../PurgeGalleryImagesButton";

const Gallery: React.FC<GalleryProps> = ({
  removeGalleryImages,
  onDrop,
  errors,
  register,
  defaultFormValues,
  images,
  onChange,
  removeImage,
}) => {
  const { t } = useTranslation();
  const { getRootProps } = useDropzone({ onDrop });
  const [
    normalizedImages,
    setNormalizedImages,
  ] = useState<ParsedImage[]>([]);

  const readFileAsync = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(String(reader.result));
    };

    reader.onerror = reject;

    if (file) {
      reader.readAsDataURL(file);
    }
  });

  const calculatedHeight = `${(Math.ceil((images?.length || 0) / 4) + 1) * 200}px`;

  const sortBasedOnImageNameList = useCallback(
    (parsedImages: ParsedImage[]): ParsedImage[] => parsedImages
      .sort((a, b) => {
        const imageNameList = images?.map(image => {
          if (typeof image !== "string") {
            return image?.name;
          }
          return image;
        }) || [];

        const parsedImageName = (parsedImage : ParsedImage): string => (typeof parsedImage?.originalImage !== "string"
          ? parsedImage?.originalImage?.name
          : parsedImage?.originalImage);

        return imageNameList?.indexOf(parsedImageName(a))
        - imageNameList?.indexOf(parsedImageName(b));
      }), [
      images,
    ],
  );

  const parseImages = useCallback(async (): Promise<void> => {
    setNormalizedImages([]);

    let parsedImages: ParsedImage[] = [];
    const chain: Promise<void>[] = [];

    images?.forEach((imageUrl: string | File) => {
      if (typeof imageUrl === "string") {
        parsedImages = [...parsedImages, {
          src: imageUrl,
          originalImage: imageUrl,
          key: imageUrl,
        }];
      } else {
        chain.push((async () => {
          const parsedImage = await readFileAsync(imageUrl);

          parsedImages = [...parsedImages, {
            src: parsedImage,
            originalImage: imageUrl,
            key: imageUrl.name + imageUrl.lastModified,
          }];
        })());
      }
    });

    await Promise.all(chain);

    sortBasedOnImageNameList(parsedImages);

    setNormalizedImages(parsedImages);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    images,
    sortBasedOnImageNameList,
  ]);

  useEffect(() => {
    parseImages();
  }, [parseImages]);

  const shouldRenderPurgeButton = (
    normalizedImages?.filter(image => [
      ...(defaultFormValues?.vehicle?.jatoSuggestedImages || []),
      ...(defaultFormValues?.vehicle?.suggestedImages || []),
    ]?.includes(image.src)).length > 0
  );

  const addedVehicleImgExists = (src: string): boolean => (
    [
      ...defaultFormValues?.vehicle?.jatoSuggestedImages || [],
      ...defaultFormValues?.vehicle?.suggestedImages || [],
    ].includes(src)
  );

  return (
    <>
      <Text
        fontWeight="bold"
        marginBottom="16px"
      >
        {t("custom_offer.steps.images.image_selection")}
      </Text>

      <GridContextProvider onChange={onChange}>
        <Stack
          {...getRootProps()}
          justifyContent="space-between"
          p={5}
          border="4px dashed #93999F"
          borderRadius="8px"
          style={{
            height: calculatedHeight,
          }}
        >
          {
            (shouldRenderPurgeButton && removeGalleryImages) && (
              <PurgeGalleryImagesButton
                onConfirm={removeGalleryImages}
              />
            )
          }

          <GridDropZone
            id="images"
            boxesPerRow={4}
            rowHeight={200}
            style={{
              height: calculatedHeight,
            }}
          >
            {
              normalizedImages.map((image: ParsedImage) => (
                <GridItem
                  key={image.key}
                  style={{
                    cursor: "pointer",
                    width: "246px",
                    height: "178px",
                  }}
                  onDragStart={(e) => { e.preventDefault(); }}
                >
                  <DelectableImage
                    callback={() => removeImage(image.originalImage)}
                    isHighlighted={addedVehicleImgExists(image.src)}
                    objectFit="contain"
                    label={t("custom_offer.steps.images.gallery_image")}
                    src={image.src}
                  />
                </GridItem>
              ))
            }
          </GridDropZone>

          <Flex
            height="130px"
            flexDirection="column"
            alignContent="center"
            textAlign="center"
            justifyContent="space-between"
          >
            <div><UploadIcon /></div>
            {t("custom_offer.steps.images.drag_n_drop")}
            <UploadButton
              {...register("files")}
              errors={errors}
            />
          </Flex>
        </Stack>
      </GridContextProvider>
    </>
  );
};

export default Gallery;
