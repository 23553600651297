import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const PencilIcon: IconProps = createIcon({
  viewBox: "0 0 36.738 36.96",
  path: (
    <path
      fill="currentColor"
      d="M23.732,1.177,2.966,22.055.092,35.2A3.943,3.943,0,0,0,1.167,38.84a4,4,0,0,0,3.667,1.068l13.219-2.857L38.848,16.14a3.945,3.945,0,0,0-.014-5.587L29.387,1.16a4,4,0,0,0-5.656.017ZM7.955,35.607,4.408,32.085l1.81-8.264,10.058,10Z"
      transform="scale(0.9)"
    />
  ),
});

export default PencilIcon;
