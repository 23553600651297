import React, { useEffect, useRef } from "react";
import { Props as ReactSelectProps } from "react-select/src/Select";

import i18n from "translations/i18n";
import { SortingOrderEnum, useDealersQuery } from "generated/graphql";

import { parseDealer } from "./parse";
import { DealerSelectProps } from "./types";
import Select from "../Select";

const DealerSelect: React.FC<DealerSelectProps> = (props) => {
  const {
    value,
    isDisabled,
    onlyCalculatorDealers,
    onChange,
    titleCss,
  } = props;

  const {
    data,
    loading,
  } = useDealersQuery({
    variables: {
      filters: {
        checkoutProcessEnabled: onlyCalculatorDealers,
      },
      pagination: {
        orderBy: [{
          field: "company_name",
          order: SortingOrderEnum.Asc,
        }],
      },
    },
  });

  const dealers = data?.dealers || [];
  const options = dealers.map(parseDealer);

  const isClearable = options.length !== 1;

  const defaultValue = options.length === 1
    ? options[0]
    : undefined;

  const defaultValueRef = useRef<number>();

  useEffect(() => {
    if (options.length === 1 && !defaultValueRef.current) {
      defaultValueRef.current = options.length;
      onChange?.(defaultValue?.value);
    }
  }, [
    defaultValue?.value,
    onChange,
    options.length,
  ]);

  return (
    <Select
      {...props}
      titleCss={titleCss}
      isClearable={isClearable}
      options={options}
      isDisabled={loading || isDisabled}
      value={value || defaultValue}
      styles={{
        menu: (styles: ReactSelectProps): ReactSelectProps => ({
          ...styles,
          maxWidth: "80vw",
          width: "fit-content",
          zIndex: 10,
        }),
      }}
    />
  );
};

export default DealerSelect;

DealerSelect.defaultProps = {
  placeholder: i18n.t("components.selects.dealer"),
  isSearchable: true,
};
