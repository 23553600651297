import React, { useCallback } from "react";
import {
  Text,
  Stack,
  Heading,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";

import forgotPasswordSchema, {
  ForgotPasswordSchema,
} from "settings/yup/schemas/forgotPasswordSchema";
import { Card } from "components/Card";
import Input from "components/FormComponents/Inputs/Input";
import Button from "components/Button";
import { useRequestResetPasswordMutation } from "generated/graphql";
import { ROOT_PAGE_PATH } from "routes";

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const newToast = useToast();
  const history = useHistory();

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<ForgotPasswordSchema>({
    resolver: yupResolver(forgotPasswordSchema),
    mode: "onBlur",
  });

  const [requestResetPassword, { loading }] = useRequestResetPasswordMutation();

  const onSubmit = useCallback(
    (data: ForgotPasswordSchema) => {
      requestResetPassword({
        variables: {
          email: data.email,
        },
      })
        .then(() => {
          newToast({
            description: t("forgot_password.success_msg"),
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          reset();
        })
        .catch((error) => {
          newToast({
            title: t("errors.something_went_wrong"),
            description: error.message,
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        });
    },
    [
      newToast,
      t,
      requestResetPassword,
      reset,
    ],
  );

  return (
    <Card p={6}>
      <Stack direction="row" mb={2}>
        <IconButton
          icon={<ArrowBackIcon />}
          fontSize="28px"
          variant="unstyled"
          color="gray.500"
          aria-label="Go back"
          onClick={() => history.push(ROOT_PAGE_PATH)}
        />
      </Stack>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <Stack>
            <Heading
              as="h1"
              lineHeight={1.1}
              fontSize="28px"
              textAlign="center"
            >
              {t("forgot_password.title")}
            </Heading>
            <Text
              textAlign="center"
              fontSize={{ base: "sm", sm: "md" }}
              color="gray.500"
            >
              {t("forgot_password.description")}
            </Text>
          </Stack>

          <Input
            title={t("forms.email")}
            {...register("email")}
            autoComplete="email"
            errors={errors}
            type="email"
          />

          <Button
            label={t("forgot_password.request_btn")}
            isLoading={loading}
            colorScheme="primary"
            fontFamily="regular"
            type="submit"
            fontSize="md"
            size="lg"
            w="full"
          />
        </Stack>
      </form>
    </Card>
  );
};

export default ForgotPassword;
