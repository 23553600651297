export const Statuses = <const>{
  warning: "warning",
  error: "error",
  success: "success",
  hidden: "hidden",
};

export interface StatusComponentProps {
  status: typeof Statuses[keyof typeof Statuses];
  label?: string;
}
