import { gql } from "@apollo/client";

export const UPDATE_OFFER_REFETCH_QUERIES = [
  "GetOffer",
  "Offers",
];

export default gql`
  mutation UpdateOffer($id: Int!, $params: UpdateOfferParams!) {
    updateOffer(id: $id, params: $params) {
      id
    }
  }
`;
