import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const UpChevron: IconProps = createIcon({
  viewBox: "0 0 14 10",
  path: (
    <path
      fill="currentColor"
      d="M14 7.62728C14 8.08271 13.8248 8.52062 13.492 8.87095C12.8089 9.55409 11.7053 9.55409 11.0222 8.87095L6.99343 4.84217L2.98217 8.85343C2.29903 9.53657 1.1955 9.53657 0.512355 8.85343C-0.170785 8.17029 -0.170785 7.06676 0.512355 6.38362L5.76728 1.12869C6.45042 0.445551 7.55396 0.445551 8.2371 1.12869L13.492 6.38362C13.8248 6.73395 14 7.17185 14 7.62728Z"
    />
  ),
});

export default UpChevron;
