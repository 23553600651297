import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const PlusIcon: IconProps = createIcon({
  viewBox: "0 0 44 45",
  path: (
    <>
      <rect fill="currentColor" width="5" height="45" rx="2" transform="translate(19)" />
      <rect fill="currentColor" width="5" height="44" rx="2" transform="translate(44 19) rotate(90)" />
    </>
  ),
});

export default PlusIcon;
