import { useCallback } from "react";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { onQueryError } from "utils/queryHandlers";
import useCustomOfferStore from "views/CustomOffer/store";
import useToast from "hooks/useToast";
import { UPDATE_OFFER_REFETCH_QUERIES } from "views/CustomOffer/graphql/updateOfferMutation";
import { PUBLISH_OFFER_REFETCH_QUERIES } from "views/CustomOffer/graphql/publishOfferMutation";
import {
  OfferImageParams,
  UpdateOfferParams,
  SpecialCondition,
  UpdateFixedLeasingRateParams,
  UserRoleEnum,
  OfferFuelTypeEnum,
} from "generated/graphql";
import { parsedOfferSpecialConditions } from "components/Modals/EditLeasingRatesModal/offerSpecialConditionsParser";
import { parseAccessories } from "views/CustomOffer/components/Steps/CustomOfferSteps/Equipment/getEquipmentDefaultValues";
import { germanNotationToDecimal } from "utils/germanNotationToDecimal";
import { getDeliveryPrices } from "utils/getDeliveryPrices";
import { useCurrentUser } from "contexts/currentUser";
import { formatDate } from "utils/dateFormats";

import { getStepKey } from "../../steps";
import {
  ParseMutationPayload,
  StoreSchema,
  UseFormHandlerOptions,
  UseFormHandlerResult,
} from "./types";

export const parseFixedLeasingRates = (
  values: UpdateFixedLeasingRateParams[],
): UpdateFixedLeasingRateParams[] => values?.map(
  (
    {
      mileageKm,
      price,
      termMonths,
    },
  ) => ({
    mileageKm,
    price,
    termMonths,
  }),
);

/**
 * Handles the mutation logic for a given form step according to a specific schema.
 * Exposes the `onSubmit` & `onSaveAsDraft` functions.
 */
const useFormHandler = ({
  useUpdateOfferMutation,
  usePublishOfferMutation,
  specialConditions,
}: UseFormHandlerOptions): UseFormHandlerResult => {
  const [showToast] = useToast();
  const { t } = useTranslation();

  const {
    offerId,
    dealerId,
    getValues,
    isValid,
    store,
  } = useCustomOfferStore((state) => ({
    offerId: state.offer?.id,
    dealerId: state.offer?.dealer?.id,
    getValues: state.wizardStepStore?.getValues,
    store: state.store,
    isValid: state.wizardStepStore?.isValid,
  }));

  const [updateOffer, {
    loading: updateOfferLoading,
  }] = useUpdateOfferMutation({
    awaitRefetchQueries: true,
    refetchQueries: UPDATE_OFFER_REFETCH_QUERIES,
  });

  const [publishOffer, {
    loading: publishOfferLoading,
  }] = usePublishOfferMutation({
    awaitRefetchQueries: true,
    refetchQueries: PUBLISH_OFFER_REFETCH_QUERIES,
  });

  const [currentUser] = useCurrentUser();

  const getMutationPayload = useCallback(() => {
    if (!getValues) {
      return {};
    }

    const {
      ...payload
    } = getValues();
    return {
      ...payload,
    };
  }, [
    getValues,
  ]);

  const getStorePayload = useCallback((): Partial<StoreSchema> | undefined => {
    if (!store) {
      return undefined;
    }

    return Object.values(store)
      .reduce((acc, curr) => {
        const entries = curr ? Object.entries(curr) : [];

        if (entries.length) {
          entries.forEach(([k, v]) => {
            acc[k] = v;
          });
        }

        return acc;
      }, {});
  }, [store]);

  const parseMutationPayload = useCallback((
    payload: ParseMutationPayload,
  ): UpdateOfferParams => {
    const {
      numberOfDoors,
      financingSpecialConditions,
      formSpecialConditions,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      files,
      vehicle,
      customMonth,
      customMileage,
      /* eslint-enable */
      bankId,
      currentPriceNet,
      baseListPriceNet,
      effectiveAnnualInterestRate,
      nominalInterestRate,
      loanAmountNet,
      extraPricePerHundredKilometersNet,
      discountPerHundredKilometersNet,
      fixedLeasingRates,
      interiorColorName,
      exteriorColorName,
      interiorBaseColor,
      exteriorBaseColor,
      offerAccessories,
      selectedAccessories,
      images,
      registeredAt,
      availableUntil,
      wltpCarbonicEmissionPricePeriodToYear,
      wltpCarbonicEmissionPricePeriodFromYear,
      ...payloadRest
    } = payload;

    const conditionsToBeParsed = {
      ...formSpecialConditions,
      ...financingSpecialConditions,
    };

    const selectedConditions = specialConditions && !!Object.keys(conditionsToBeParsed).length
      ? parsedOfferSpecialConditions(
        conditionsToBeParsed,
        specialConditions as SpecialCondition[],
      ) : undefined;
    const validateFixedLeasingRates = (rates: UpdateFixedLeasingRateParams[]):
    UpdateFixedLeasingRateParams[] => parseFixedLeasingRates(rates)
      .filter(({ price }) => price !== "" && price >= 0);

    return {
      ...payloadRest,
      availableUntil: availableUntil ? availableUntil.toISOString() : availableUntil,
      registeredAt: registeredAt ? formatDate(registeredAt) : registeredAt,
      wltpCarbonicEmissionPricePeriodToYear:
        wltpCarbonicEmissionPricePeriodToYear?.getFullYear() || null,
      wltpCarbonicEmissionPricePeriodFromYear:
        wltpCarbonicEmissionPricePeriodFromYear?.getFullYear() || null,
      numberOfDoors: numberOfDoors ? parseInt(numberOfDoors) : undefined,
      interiorColorName,
      exteriorColorName,
      interiorBaseColor,
      exteriorBaseColor,
      accessories: selectedAccessories?.length >= 0
        ? parseAccessories(offerAccessories, selectedAccessories)
        : undefined,
      offerImages: images?.map((path: string | File, position: number) : OfferImageParams => {
        if (typeof path === "string") {
          return {
            path,
            position,
          };
        }

        return {
          upload: path,
          position,
        };
      }),
      bankId: bankId ? Number(bankId) : undefined,
      currentPriceNet,
      ...((baseListPriceNet) && {
        baseListPriceNet,
      }),
      effectiveAnnualInterestRate,
      nominalInterestRate,
      loanAmountNet,
      extraPricePerHundredKilometersNet,
      discountPerHundredKilometersNet,
      ...((fixedLeasingRates)
        && { fixedLeasingRates: validateFixedLeasingRates(fixedLeasingRates) }),
      deliveryPrices: getDeliveryPrices(
        payload?.deliveryPrices,
        payload?.customerType,
        dealerId,
      ),
      offerSpecialConditions: selectedConditions,
      wltpConsumptionCombined: germanNotationToDecimal(
        payloadRest.wltpConsumptionCombined,
      ),
      wltpConsumptionCombinedWeighted: germanNotationToDecimal(
        payloadRest.wltpConsumptionCombinedWeighted,
      ),
      wltpConsumptionCity: germanNotationToDecimal(payloadRest.wltpConsumptionCity),
      wltpConsumptionSuburban: germanNotationToDecimal(payloadRest.wltpConsumptionSuburban),
      wltpConsumptionRural: germanNotationToDecimal(payloadRest.wltpConsumptionRural),
      wltpConsumptionHighway: germanNotationToDecimal(payloadRest.wltpConsumptionHighway),
      wltpCarbonicEmissionCombined: germanNotationToDecimal(
        payloadRest.wltpCarbonicEmissionCombined,
      ),
      wltpCarbonicEmissionCombinedWeighted: germanNotationToDecimal(
        payloadRest.wltpCarbonicEmissionCombinedWeighted,
      ),
      wltpConsumptionCombinedWithDischargedBattery: germanNotationToDecimal(
        payloadRest.wltpConsumptionCombinedWithDischargedBattery,
      ),
      wltpConsumptionCityWithDischargedBattery: germanNotationToDecimal(
        payloadRest.wltpConsumptionCityWithDischargedBattery,
      ),
      wltpConsumptionSuburbanWithDischargedBattery: germanNotationToDecimal(
        payloadRest.wltpConsumptionSuburbanWithDischargedBattery,
      ),
      wltpConsumptionRuralWithDischargedBattery: germanNotationToDecimal(
        payloadRest.wltpConsumptionRuralWithDischargedBattery,
      ),
      wltpConsumptionHighwayWithDischargedBattery: germanNotationToDecimal(
        payloadRest.wltpConsumptionHighwayWithDischargedBattery,
      ),
      wltpPowerConsumptionCombined: germanNotationToDecimal(
        payloadRest?.wltpPowerConsumptionCombined,
      ),
      wltpPowerConsumptionCombinedWeighted: germanNotationToDecimal(
        payloadRest?.wltpPowerConsumptionCombinedWeighted,
      ),
      wltpPowerConsumptionCity: germanNotationToDecimal(
        payloadRest?.wltpPowerConsumptionCity,
      ),
      wltpPowerConsumptionSuburban: germanNotationToDecimal(
        payloadRest?.wltpPowerConsumptionSuburban,
      ),
      wltpPowerConsumptionRural: germanNotationToDecimal(
        payloadRest?.wltpPowerConsumptionRural,
      ),
      wltpPowerConsumptionHighway: germanNotationToDecimal(
        payloadRest?.wltpPowerConsumptionHighway,
      ),
      wltpCarbonicEmissionPriceAverage: germanNotationToDecimal(
        payloadRest?.wltpCarbonicEmissionPriceAverage,
      ),
      wltpCarbonicEmissionPriceAverageAccumulated: germanNotationToDecimal(
        payloadRest?.wltpCarbonicEmissionPriceAverageAccumulated,
      ),
      wltpCarbonicEmissionPriceLowAverage: germanNotationToDecimal(
        payloadRest?.wltpCarbonicEmissionPriceLowAverage,
      ),
      wltpCarbonicEmissionPriceLowAverageAccumulated: germanNotationToDecimal(
        payloadRest?.wltpCarbonicEmissionPriceLowAverageAccumulated,
      ),
      wltpCarbonicEmissionPriceHighAverage: germanNotationToDecimal(
        payloadRest?.wltpCarbonicEmissionPriceHighAverage,
      ),
      wltpCarbonicEmissionPriceHighAverageAccumulated: germanNotationToDecimal(
        payloadRest?.wltpCarbonicEmissionPriceHighAverageAccumulated,
      ),
      vehicleTax: germanNotationToDecimal(payloadRest?.vehicleTax),
      vehicleTaxPerYear: germanNotationToDecimal(payloadRest?.vehicleTaxPerYear),
      energyCostsPerAnnualMileage: germanNotationToDecimal(
        payloadRest?.energyCostsPerAnnualMileage,
      ),
      fuelPrice: germanNotationToDecimal(payloadRest?.fuelPrice),
      powerPrice: germanNotationToDecimal(payloadRest.powerPrice),
      fuelConsumptionCity: germanNotationToDecimal(payloadRest.fuelConsumptionCity),
      fuelConsumptionCombined: germanNotationToDecimal(payloadRest.fuelConsumptionCombined),
      fuelConsumptionHighway: germanNotationToDecimal(payloadRest.fuelConsumptionHighway),
      carbonicEmission: payloadRest.fuelType === OfferFuelTypeEnum.Electric
        ? 0
        : germanNotationToDecimal(payloadRest.carbonicEmission),
      powerConsumptionCombined: germanNotationToDecimal(
        payloadRest.powerConsumptionCombined,
      ),
      ...(payloadRest?.battery && {
        rangeKm: undefined,
        battery: {
          rangeKm: germanNotationToDecimal(payloadRest.rangeKm),
          capacityKwh: germanNotationToDecimal(payloadRest.battery.capacityKwh),
          chargeTimeFastAcHour: germanNotationToDecimal(payloadRest.battery.chargeTimeFastAcHour),
          chargeTimeFastHour: germanNotationToDecimal(payloadRest.battery.chargeTimeFastHour),
          chargeTimeHour: germanNotationToDecimal(payloadRest.battery.chargeTimeHour),
          type: payloadRest.battery.type,
        },
      }),
      ...(currentUser?.role !== UserRoleEnum.Admin && {
        topOffer: undefined,
        isCheckout: undefined,
        isNewConfiguratorEnabled: undefined,
        marketingOffer: undefined,
        offerOfTheDay: undefined,
        hideInSearch: undefined,
      }),
    };
  }, [
    dealerId,
    specialConditions,
    currentUser?.role,
  ]);

  const handleUpdateOffer = useCallback(() => new Promise<void>((resolve) => {
    if (!offerId) {
      return;
    }

    const storePayload = getStorePayload();

    const combinedPayloads = { ...storePayload, ...getMutationPayload() };

    const payload = parseMutationPayload(combinedPayloads as ParseMutationPayload);

    const promise = updateOffer({
      variables: {
        id: offerId,
        params: payload,
      },
    }) as Promise<unknown>;

    promise
      .then(() => {
        showToast({
          title: t("custom_offer.toasts.custom_offer_updated"),
          position: "bottom",
          isClosable: true,
          status: "info",
        });
      })
      .catch((error: ApolloError) => {
        onQueryError(error, showToast);
      })
      .finally(resolve);
  }), [
    getStorePayload,
    getMutationPayload,
    parseMutationPayload,
    updateOffer,
    showToast,
    offerId,
    t,
  ]);

  const handleSaveAsDraft = useCallback(() => new Promise<void>((resolve, reject) => {
    if (!offerId) {
      return;
    }

    const storePayload = getStorePayload();

    const combinedPayloads = { ...storePayload, ...getMutationPayload() };

    const payload = parseMutationPayload(combinedPayloads);

    const promise = updateOffer({
      variables: {
        id: offerId,
        params: payload,
      },
    }) as Promise<unknown>;

    promise
      .then(() => {
        showToast({
          title: t("custom_offer.toasts.successfully_saved_as_draft"),
          position: "bottom",
          isClosable: true,
          status: "info",
        });
        resolve();
      })
      .catch((error: ApolloError) => {
        reject(error);
        onQueryError(error, showToast);
      });
  }), [
    parseMutationPayload,
    getStorePayload,
    getMutationPayload,
    updateOffer,
    showToast,
    offerId,
    t,
  ]);

  const handlePublish = useCallback(() => new Promise<void>((resolve, reject) => {
    if (!offerId) {
      return;
    }

    const payload = parseMutationPayload(getMutationPayload());

    const promiseUpdate = updateOffer({
      variables: {
        id: offerId,
        params: payload,
      },
    }) as Promise<unknown>;

    const promisePublish = (): Promise<unknown> => publishOffer({
      variables: {
        id: offerId,
      },
    });

    promiseUpdate
      .then(() => promisePublish())
      .then(() => {
        showToast({
          title: t("custom_offer.toasts.successfully_published"),
          description: t("custom_offer.toasts.note"),
          duration: "LONG",
          position: "bottom",
          isClosable: true,
          status: "info",
        });
        resolve();
      })
      .catch((error: ApolloError) => {
        reject(error);
        onQueryError(error, showToast);
      });
  }), [
    parseMutationPayload,
    getMutationPayload,
    updateOffer,
    publishOffer,
    showToast,
    offerId,
    t,
  ]);

  const onSaveProgress = useCallback(async (stepIndex: number) => new Promise<void>(
    (res) => {
      const stepData = getMutationPayload();

      const getStep = getStepKey(stepIndex);

      useCustomOfferStore.setState(state => ({
        store: {
          ...state.store,
          [getStep]: {
            ...stepData,
            wltpCarbonicEmissionPricePeriodToYear: stepData?.wltpCarbonicEmissionPricePeriodToYear,
            wltpCarbonicEmissionPricePeriodFromYear:
              stepData?.wltpCarbonicEmissionPricePeriodFromYear,
            wltpConsumptionCombined: germanNotationToDecimal(stepData?.wltpConsumptionCombined),
            wltpConsumptionCity: germanNotationToDecimal(stepData?.wltpConsumptionCity),
            wltpConsumptionSuburban: germanNotationToDecimal(stepData?.wltpConsumptionSuburban),
            wltpConsumptionRural: germanNotationToDecimal(stepData?.wltpConsumptionRural),
            wltpConsumptionHighway: germanNotationToDecimal(stepData?.wltpConsumptionHighway),
            wltpCarbonicEmissionCombined: germanNotationToDecimal(
              stepData?.wltpCarbonicEmissionCombined,
            ),
            wltpCarbonicEmissionCombinedWeighted: germanNotationToDecimal(
              stepData?.wltpCarbonicEmissionCombinedWeighted,
            ),
            wltpConsumptionCombinedWeighted: germanNotationToDecimal(
              stepData?.wltpConsumptionCombinedWeighted,
            ),
            wltpConsumptionCombinedWithDischargedBattery: germanNotationToDecimal(
              stepData?.wltpConsumptionCombinedWithDischargedBattery,
            ),
            wltpConsumptionCityWithDischargedBattery: germanNotationToDecimal(
              stepData?.wltpConsumptionCityWithDischargedBattery,
            ),
            wltpConsumptionSuburbanWithDischargedBattery: germanNotationToDecimal(
              stepData?.wltpConsumptionSuburbanWithDischargedBattery,
            ),
            wltpConsumptionRuralWithDischargedBattery: germanNotationToDecimal(
              stepData?.wltpConsumptionRuralWithDischargedBattery,
            ),
            wltpConsumptionHighwayWithDischargedBattery: germanNotationToDecimal(
              stepData?.wltpConsumptionHighwayWithDischargedBattery,
            ),
            fuelConsumptionCity: germanNotationToDecimal(stepData.fuelConsumptionCity),
            fuelConsumptionCombined: germanNotationToDecimal(stepData.fuelConsumptionCombined),
            fuelConsumptionHighway: germanNotationToDecimal(stepData.fuelConsumptionHighway),
            carbonicEmission: germanNotationToDecimal(stepData.carbonicEmission),
            powerConsumptionCombined: germanNotationToDecimal(
              stepData.powerConsumptionCombined,
            ),
            wltpPowerConsumptionCombined: germanNotationToDecimal(
              stepData.wltpPowerConsumptionCombined,
            ),
            wltpPowerConsumptionCombinedWeighted: germanNotationToDecimal(
              stepData?.wltpPowerConsumptionCombinedWeighted,
            ),
            wltpPowerConsumptionCity: germanNotationToDecimal(
              stepData?.wltpPowerConsumptionCity,
            ),
            wltpPowerConsumptionSuburban: germanNotationToDecimal(
              stepData?.wltpPowerConsumptionSuburban,
            ),
            wltpPowerConsumptionRural: germanNotationToDecimal(
              stepData?.wltpPowerConsumptionRural,
            ),
            wltpPowerConsumptionHighway: germanNotationToDecimal(
              stepData?.wltpPowerConsumptionHighway,
            ),
            wltpCarbonicEmissionPriceAverage: germanNotationToDecimal(
              stepData?.wltpCarbonicEmissionPriceAverage,
            ),
            wltpCarbonicEmissionPriceAverageAccumulated: germanNotationToDecimal(
              stepData?.wltpCarbonicEmissionPriceAverageAccumulated,
            ),
            wltpCarbonicEmissionPriceLowAverage: germanNotationToDecimal(
              stepData?.wltpCarbonicEmissionPriceLowAverage,
            ),
            wltpCarbonicEmissionPriceLowAverageAccumulated: germanNotationToDecimal(
              stepData?.wltpCarbonicEmissionPriceLowAverageAccumulated,
            ),
            wltpCarbonicEmissionPriceHighAverage: germanNotationToDecimal(
              stepData?.wltpCarbonicEmissionPriceHighAverage,
            ),
            wltpCarbonicEmissionPriceHighAverageAccumulated: germanNotationToDecimal(
              stepData?.wltpCarbonicEmissionPriceHighAverageAccumulated,
            ),
            vehicleTax: germanNotationToDecimal(stepData?.vehicleTax),
            vehicleTaxPerYear: germanNotationToDecimal(stepData?.vehicleTaxPerYear),
            powerPrice: germanNotationToDecimal(stepData?.powerPrice),
            energyCostsPerAnnualMileage: germanNotationToDecimal(
              stepData?.energyCostsPerAnnualMileage,
            ),
            fuelPrice: germanNotationToDecimal(stepData?.fuelPrice),
            battery: {
              rangeKm: germanNotationToDecimal(stepData?.rangeKm),
              capacityKwh: germanNotationToDecimal(stepData?.battery?.capacityKwh),
              chargeTimeFastAcHour: germanNotationToDecimal(
                stepData?.battery?.chargeTimeFastAcHour,
              ),
              chargeTimeFastHour: germanNotationToDecimal(stepData?.battery?.chargeTimeFastHour),
              chargeTimeHour: germanNotationToDecimal(stepData?.battery?.chargeTimeHour),
              type: stepData?.battery?.type,
            },
          },
        },
      }));

      res();
    },
  ), [getMutationPayload]);

  const onContinue = useCallback(async () => {
    await handleUpdateOffer();
  }, [
    handleUpdateOffer,
  ]);

  return {
    onContinue,
    onSaveAsDraft: handleSaveAsDraft,
    onSaveProgress,
    onPublish: handlePublish,
    isValid,
    loading: updateOfferLoading || publishOfferLoading,
  };
};

export default useFormHandler;
