import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const PersonIcon: IconProps = createIcon({
  viewBox: "0 0 20 21",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6665 13.0921C11.6665 13.0921 14.1665 10.5921 14.1665 8.09212C14.1665 5.59212 14.1665 2.25879 9.99984 2.25879C5.83317 2.25879 5.83317 5.59212 5.83317 8.09212C5.83317 10.5921 8.33317 13.0921 8.33317 13.0921L7.9165 13.9255C7.9165 13.9255 1.6665 15.5921 1.6665 18.0921V18.9255H18.3332V18.0921C18.3332 15.5921 12.0832 13.9255 12.0832 13.9255L11.6665 13.0921Z"
      fill="currentColor"
    />
  ),
});

export default PersonIcon;
