import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";

import { makeTableColumns } from "./tableColumns";
import { OfferImportLogsTableProps } from "../types";

const OfferImportLogsTable: React.FC<OfferImportLogsTableProps> = ({
  logs,
  loading,
}) => {
  const { t } = useTranslation();

  const columns = makeTableColumns();

  const data = useMemo(() => (logs || []), [logs]);

  return (
    <Table
      columns={columns}
      data={data}
      emptyDataProps={{
        message: t("empty_data_messages.no_information_to_be_displayed"),
        isDataLoading: loading,
      }}
      isPageable
    />
  );
};

export default OfferImportLogsTable;
