import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
} from "swiper";
import { PaginationOptions } from "swiper/types/components/pagination";

import { CarouselProps } from "../types";
import SelectableImage from "../SelectableImage";
import { slideCss, swiperCss } from "./styles";
import CarouselArrow from "./CarouselArrow";

SwiperCore.use([Navigation, Pagination]);

const navigationOptions = {
  nextEl: ".swipe-button-next",
  prevEl: ".swipe-button-prev",
};

const paginationOptions: PaginationOptions = {
  el: ".swipe-pagination-element",
  type: "fraction",
};

const Carousel: React.FC<CarouselProps> = ({
  spaceBetween = 100,
  slidesPerView = 4,
  autoHeight = true,
  slides,
}) => (
  <Flex
    overflow="hidden"
    flexDir="column"
  >
    <Flex
      justifyContent="center"
      alignItems="center"
    >
      <CarouselArrow
        variant="prev"
        mr={3}
      />

      <Swiper
        pagination={paginationOptions}
        navigation={navigationOptions}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        autoHeight={autoHeight}
        style={swiperCss}
      >
        {
          slides.map((slide) => (
            <SwiperSlide
              style={slideCss}
              key={slide.src}
            >
              <SelectableImage
                callback={slide.callback}
                label={slide.label}
                src={slide.src}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>

      <CarouselArrow
        variant="next"
        ml={3}
      />
    </Flex>

    <Box
      className="swipe-pagination-element"
      textAlign="center"
      textStyle="p"
      mt="10px"
    />
  </Flex>
);

export default Carousel;
