export const rightElementCss = {
  color: "gray.400",
  fontWeight: 800,
};

export const centCss = {
  color: "gray.400",
  fontWeight: 400,
  fontSize: "22px",
};
