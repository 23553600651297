import React from "react";
import {
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";

import i18n from "translations/i18n";

import { StatusComponentProps, Statuses } from "./types";

const statusesMap = {
  [Statuses.error]: {
    color: "error.500",
    label: i18n.t("stories.status.offline"),
  },
  [Statuses.hidden]: {
    color: "error.500",
    label: i18n.t("stories.status.hidden"),
  },
  [Statuses.success]: {
    color: "success.500",
    label: i18n.t("stories.status.online"),
  },
  [Statuses.warning]: {
    color: "warning.500",
    label: i18n.t("stories.status.draft"),
  },
};

const StatusComponent: React.FC<StatusComponentProps> = ({
  status,
  label,
}) => {
  const currentStatus = statusesMap[status];

  return (
    <Flex
      justify="start"
      align="center"
      w="full"
      p={1}
    >
      {
        status !== undefined
          ? (
            <>
              <Box
                h={3}
                w={3}
                borderRadius={6}
                bgColor={currentStatus.color}
              />

              <Text
                ml={2}
                mt="2px"
                textStyle="p"
                textTransform="capitalize"
              >
                {label || currentStatus.label}
              </Text>
            </>
          )
          : (
            <span>
              -
            </span>
          )
      }
    </Flex>
  );
};

export default StatusComponent;
