import i18n from "translations/i18n";
import {
  OfferFuelTypeEnum, OfferTypeEnum, OfferGearTypeEnum, PollutantClassEnum, VehicleBatteryTypeEnum,
} from "generated/graphql";
import { OptionProps } from "components/FormComponents/Inputs/Selects/BaseSelect/types";

import { EnergyEfficiencyClassEnum } from "./types";

export const makeOfferTypeOptions = (): OptionProps[] => (
  Object.keys(OfferFuelTypeEnum).map(type => {
    const label = `fuel_types.${OfferFuelTypeEnum[type]}`;
    return {
      label: i18n.t(label),
      value: OfferFuelTypeEnum[type],
    };
  })
);

export const makeDoorOptions = (): OptionProps[] => {
  const doors = Array.from({ length: 7 }, (_, i) => (i + 2).toString());
  return Object.values(doors).map(qty => ({
    label: qty,
    value: qty,
  }));
};

export const makeGearOptions = (): OptionProps[] => [
  {
    label: i18n.t("custom_offer.steps.standard_data.base_data_card.gear_options.manual"),
    value: OfferGearTypeEnum.Manual,
  },
  {
    label: i18n.t("custom_offer.steps.standard_data.base_data_card.gear_options.automatic"),
    value: OfferGearTypeEnum.Automatic,
  },
];

export const makeVehicleTypeOptions = (): OptionProps[] => (
  Object.values(OfferTypeEnum).map(type => {
    const label = `custom_offer.steps.standard_data.vehicle_card.type_options.${type.toLowerCase()}`;
    return {
      label: i18n.t(label),
      value: type,
    };
  })
);

export const makePollutantClassOptions = (): OptionProps[] => (
  Object.keys(PollutantClassEnum).map(type => {
    const label = `pollutant_class_options.${PollutantClassEnum[type]}`;

    return {
      label: i18n.t(label),
      value: PollutantClassEnum[type],
    };
  })
);

export const makeBatteryTypeOptions = (): OptionProps[] => (
  Object.keys(VehicleBatteryTypeEnum).map(type => {
    const label = `battery_options.${VehicleBatteryTypeEnum[type]}`;

    return {
      label: i18n.t(label),
      value: VehicleBatteryTypeEnum[type],
    };
  })
);

export const makeEnergyEfficiencyClassOptions = (): OptionProps[] => (
  Object.keys(EnergyEfficiencyClassEnum).map(type => ({
    label: EnergyEfficiencyClassEnum[type],
    value: EnergyEfficiencyClassEnum[type],
  }))
);
