import yup from "settings/yup";
import i18n from "translations/i18n";

const duplicateOfferSchema = yup.object().shape({
  dealer: yup
    .string()
    .nullable()
    .required(i18n.t("yup.mixed_required_field")),
});

export interface DuplicateOfferSchema {
  dealer: string;
}

export default duplicateOfferSchema;
