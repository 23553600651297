import React from "react";

import { SellerRoleEnum, UserRoleEnum } from "generated/graphql";
import { TitleBarButtonInfoProps } from "components/TitleBar/types";
import { settingsHeaderTabs } from "views/Settings/types";

export type ComponentProps = Record<string, unknown>;

export enum SidebarSectionsEnum {
  Dashboard = "DASHBOARD",
  Offers = "OFFERS",
  Configurations = "CONFIGURATIONS",
  Leads = "LEADS",
  Settings = "SETTINGS"
}

export interface DefaultRouteProps extends Record<string, unknown> {
  /**
   * Defines if the route is exact in react-router.
   */
  exact: boolean;

  /**
   * Defines if the route is strict in react-router.
   */
  strict: boolean;

  /**
   * Defines if the route is only accessible when authenticated.
   */
  requireAuthentication: boolean;

  /**
   * Defines if the header will render in this route.
   */
  showHeader: boolean;

  /**
   * Defines which layout current route is using.
   */
  layout: React.FC;

  /**
   * Props that will be injected into the rendered component.
   * Can be used with the useRouteComponentProps hook.
   */
  componentProps: ComponentProps;

  /**
   * Easily enable or disable any view
   */
  isEnabled: boolean;

  showInSidebar: boolean;

  FooterContent?: React.FC;

  footerVariant?: string;

  titleBarButton?: TitleBarButtonInfoProps;

  extraAction?: () => void;

  /**
   * Defines which user's roles are allowed to access a given route.
   */
  userRequiredRoles: UserRoleEnum[];

  /**
   * Defines which seller's roles are allowed to access a given route.
   */
  sellerRequiredRoles?: SellerRoleEnum[];

  settingsHeaderTabs?: settingsHeaderTabs[];

  /**
   * Defines the sidebar section that this route will be placed in.
   */
  sidebarSection?: SidebarSectionsEnum;
}

export interface RouteProps extends DefaultRouteProps {
  /**
   * The route path.
   */
  path: string;

  /**
   * Component to be rendered.
   */
  component: React.FC;
}

export interface SidebarRouteProps extends RouteProps {
  /**
   * This will be rendered in the sidebar and header.
   */
  Icon: React.FC;

  /**
   * Defines the text to be used in the sidebar and header.
   */
  label: string;
}

export interface RouteContainerProps {
  routeProps: RouteProps;
}
