import React from "react";
import {
  Text,
  Stack,
  Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { EmptyDataMessageProps } from "./types";

export const EmptyDataMessage: React.FC<EmptyDataMessageProps> = ({
  containerProps,
  isDataLoading,
  message,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      {...(containerProps || {})}
      alignItems="center"
      direction="column"
      spacing={2}
      m={6}
    >
      {
        isDataLoading
          ? (
            <Spinner
              color="secondary.500"
              size="lg"
              thickness="2px"
            />
          )
          : (
            <Text
              fontSize="md"
              color="gray.500"
              textAlign="center"
            >
              {message ?? t("empty_data_messages.no_information_to_be_displayed")}
            </Text>
          )
      }
    </Stack>
  );
};
