import React from "react";
import { Router, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryParamProvider } from "use-query-params";
import { de } from "date-fns/locale";
import { registerLocale } from "react-datepicker";

import MemoizedApolloProvider from "settings/apolloClient/MemoizedApolloProvider";
import CurrentUserContainer from "contexts/currentUser/CurrentUserContainer";
import SessionContainer from "contexts/session/SessionContainer";
import ErrorBoundary from "components/ErrorBoundary";
import AppRouter from "components/AppRouter";
import history from "settings/history";
import theme from "settings/theme";
import FontsComponent from "settings/theme/FontsComponent";
import ModalContainer from "contexts/modal/ModalContainer";
import PaginationContainer from "contexts/pagination/PaginationContainer";
import { ExpiredSessionErrorNotifier } from "components/ExpiredSessionErrorNotifier";
import LoadingContainer from "contexts/loading/LoadingContainer";

registerLocale("de", de);

const App: React.FC = () => (
  <ErrorBoundary>
    <ChakraProvider resetCSS theme={theme}>
      <FontsComponent />
      <Router history={history}>
        <ExpiredSessionErrorNotifier>
          {({ showAuthError }) => (
            <MemoizedApolloProvider showAuthError={showAuthError}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <SessionContainer>
                  <CurrentUserContainer>
                    <ModalContainer>
                      <PaginationContainer>
                        <LoadingContainer>
                          <AppRouter />
                        </LoadingContainer>
                      </PaginationContainer>
                    </ModalContainer>
                  </CurrentUserContainer>
                </SessionContainer>
              </QueryParamProvider>
            </MemoizedApolloProvider>
          )}
        </ExpiredSessionErrorNotifier>
      </Router>
    </ChakraProvider>
  </ErrorBoundary>
);

export default App;
