import React from "react";
import { GridItem, Text } from "@chakra-ui/react";

import { Container } from "components/Container";
import { LOREM } from "constants/lorem";

const ManageConditions: React.FC = () => (
  <GridItem colSpan={12}>
    <Container
      alignCenter
      title="ManageConditions page content."
    >
      <Text>{LOREM.repeat(12)}</Text>
    </Container>
  </GridItem>
);

export default ManageConditions;
