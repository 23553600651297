import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Center, Stack } from "@chakra-ui/react";

import { usePagination } from "contexts/pagination";
import { PAGINATION_LIMIT } from "constants/pagination";
import { EmptyDataMessage } from "components/EmptyDataMessage";
import Button from "components/Button";
import i18n from "translations/i18n";

import { TableProps } from "./types";
import {
  TableContainer,
  TableHeader,
  TableTitle,
  TableRoot,
  TableBody,
  TableCell,
  TableHead,
  TableFoot,
  TableRow,
} from "./TableComponents";

/**
 * Represents tabular data - that is, information presented in a two-dimensional table
 * comprised of rows and columns of cells containing data.
 * It renders a native table HTML components according to `TableComponents` definitions.
 */
function Table<T = Record<string, unknown>>({
  cellFontSize = "xs",
  containerProps,
  emptyDataProps,
  titleProps,
  columns,
  title,
  data,
  isPageable = false,
  footerColumns,
  onChange,
  tableCss,
  collapseButtonCss,
  isCollapsable,
}: TableProps<T>): React.ReactElement {
  const { t } = useTranslation();
  const { setPaginationLimit, paginationLimit } = usePagination();

  const scrollableElement = React.useMemo(() => React.createRef<HTMLTableRowElement>(), []);
  const collapsableElement = React.useMemo(() => React.createRef<HTMLTableRowElement>(), []);

  const [collapse, setCollapse] = React.useState(false);

  const handleScroll = (): void => {
    collapsableElement.current?.scrollIntoView({ behavior: "smooth" });
    setCollapse(!collapse);
  };

  React.useEffect(() => {
    if (isPageable && data.length > PAGINATION_LIMIT) {
      scrollableElement.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isPageable, data.length, scrollableElement]);

  return (
    <Flex
      direction="column"
      {...(isCollapsable && { maxHeight: collapse ? "100%" : "300px" })}
      {...(isCollapsable && { overflow: "hidden" })}
      ref={collapsableElement}
      borderRadius="8px"
    >
      <TableContainer
        {...(containerProps || {})}
        {...tableCss}
      >
        {
          title && (
            <TableTitle
              {...(titleProps || {})}
              title={title}
            />
          )
        }

        <TableRoot>
          <TableHead>
            {
              columns.map((column, index) => (
                <TableHeader
                  onChange={e => (onChange ? onChange(e, index) : onChange)}
                  key={String(index)}
                  column={column}
                >
                  {column.title}
                </TableHeader>
              ))
            }
          </TableHead>

          <TableBody>
            {
              data.length > 0 && data.map((row, rowIndex) => (
                <TableRow key={String(rowIndex)} ref={scrollableElement}>
                  {
                    columns.map((column, columnIndex) => (
                      <TableCell
                        onChange={e => (onChange ? onChange(e, rowIndex) : onChange)}
                        key={String(columnIndex)}
                        fontSize={cellFontSize}
                        currentIndex={rowIndex}
                        column={column}
                        row={row}
                      />
                    ))
                  }
                </TableRow>
              ))
            }
          </TableBody>

          {footerColumns && (
            <TableFoot>
              {
                footerColumns.map((column, columnIndex) => (
                  <TableCell
                    key={String(columnIndex)}
                    fontSize={cellFontSize}
                    currentIndex={columnIndex}
                    column={column}
                    row={data[0]}
                  />
                ))
              }
            </TableFoot>
          )}

        </TableRoot>

        {isPageable && data.length >= paginationLimit && (
          <Center>
            <Button
              marginBlock={4}
              variant="ghost"
              label={t("components.table.pagination.button_load_more")}
              onClick={() => { setPaginationLimit(paginationLimit + PAGINATION_LIMIT); }}
            />
          </Center>
        )}

        {
          data.length <= 0 && (
            <EmptyDataMessage
              {...(emptyDataProps || {})}
            />
          )
        }
      </TableContainer>

      {isCollapsable && (
        <Stack
          justifyContent="center"
          alignItems="center"
          mb="10"
        >
          <Button
            {...collapseButtonCss}
            label={
              collapse
                ? i18n.t("components.table.collapse.collapse")
                : i18n.t("components.table.collapse.expand")
            }
            variant="ghost"
            onClick={handleScroll}
          />
        </Stack>
      )}
    </Flex>
  );
}

export default Table;
