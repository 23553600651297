import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const EuroIcon: IconProps = createIcon({
  viewBox: "0 0 49.252 32.834",
  path: (
    <path
      fill="currentColor"
      d="M29.667,37.444a14.4,14.4,0,0,1-12.8-7.778h12.8V25.222H15.4a13.978,13.978,0,0,1,0-4.444H29.667V16.333h-12.8a14.416,14.416,0,0,1,22.2-4.289L43,8.111a19.971,19.971,0,0,0-32.178,8.222H3v4.444H9.8a18.585,18.585,0,0,0,0,4.444H3v4.444h7.822A19.971,19.971,0,0,0,43,37.889l-3.956-3.933A14.234,14.234,0,0,1,29.667,37.444Z"
      transform="translate(2 -7)"
    />
  ),
});

export default EuroIcon;
