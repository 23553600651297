import React from "react";
import { useTranslation } from "react-i18next";

import { descriptionSchema, DescriptionDataSchema } from "settings/yup/schemas/descriptionSchema";
import SectionCard from "components/SectionCard";
import VehicleDescription from "views/CustomOffer/components/Steps/CustomOfferSteps/Description/components/VehicleDescription";

import { DescriptionFormProps } from "./types";
import useFormStep from "../../hooks/useFormStep";

const Description: React.FC<DescriptionFormProps> = ({
  defaultFormValues,
}) => {
  const { t } = useTranslation();
  const {
    setValue,
  } = useFormStep<DescriptionDataSchema>({
    schema: descriptionSchema,
    formOptions: {
      defaultValues: {
        descriptionMarkdown: defaultFormValues?.descriptionMarkdown || "",
      },
    },
  });

  return (
    <SectionCard
      title={t("custom_offer.steps.description.title")}
    >
      <VehicleDescription
        onChange={(val) => setValue("descriptionMarkdown", val || "")}
        defaultValue={defaultFormValues?.descriptionMarkdown}
      />
    </SectionCard>
  );
};

export default Description;
