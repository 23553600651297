import { Props as ReactSelectProps } from "react-select/src/Select";

const leadSelectStyles = {
  valueContainer: (styles: ReactSelectProps): ReactSelectProps => ({
    ...styles,
    cursor: "text",
    fontSize: "12px",
  }),
  container: (styles: ReactSelectProps): ReactSelectProps => ({
    ...styles,
    "> div": {
      border: 0,
      cursor: "pointer",
    },
  }),
  indicatorsContainer: (styles: ReactSelectProps): ReactSelectProps => ({
    ...styles,
    cursor: "pointer",
    ".chakra-divider": {
      display: "none",
    },
  }),
};

export default leadSelectStyles;
