import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";

import useCustomOfferStore from "views/CustomOffer/store";

import ImagesForm from "./Images";

const Images: React.FC = () => {
  const { offer, images } = useCustomOfferStore(state => ({
    offer: state?.offer,
    images: state.store?.images,
  }));

  if (!offer) {
    return (
      <Flex
        justifyContent="center"
        w="full"
        h="full"
      >
        <Spinner
          color="secondary.500"
          size="lg"
          thickness="2px"
        />
      </Flex>
    );
  }

  const defaultFormValues = images ? { ...offer, ...images } : offer;

  return <ImagesForm key={offer?.id} defaultFormValues={defaultFormValues} />;
};

export default Images;
