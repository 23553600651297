import React, { useState, useEffect } from "react";
import {
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import i18n from "translations/i18n";
import {
  MAP_FACTORS, MAP_TAB_TITLE, TableFactorsEnum,
} from "components/Modals/EditVehicleModal/constants";
import ModalRow from "components/Modals/EditVehicleModal/ModalRow";
import Tabs from "components/Tabs";
import { UpdateBrandDiscountParams } from "generated/graphql";
import LeasingFactorsTable from "components/Modals/EditVehicleModal/LeasingFactorsTable";
import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import { parseDiscountValues } from "components/Modals/EditVehicleModal/CustomerTabs/CustomerTabContent";
import PercentageInput from "components/FormComponents/Inputs/PercentageInput";
import CurrencyInput from "components/FormComponents/Inputs/CurrencyInput";
import EuroIcon from "components/Icons/EuroIcon";

import { RatesTabContentProps } from "../types";

const RatesTabContent: React.FC<RatesTabContentProps> = ({
  type,
  vehicle,
  setValue,
  getValues,
  control,
  formState,
}) => {
  const [brandDiscount, setBrandDiscount] = useState<UpdateBrandDiscountParams>(
    vehicle.brandDiscounts?.find(v => v.type === type) || {},
  );

  useEffect(() => {
    const vehicleBrand = { ...vehicle.brandDiscounts?.find(v => v.type === type) };

    // eslint-disable-next-line no-underscore-dangle
    delete vehicleBrand?.__typename;

    setBrandDiscount(vehicleBrand);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultDiscount = parseDiscountValues(brandDiscount?.discount);
  const defaultDownPaymentDiscount = parseDiscountValues(brandDiscount?.downPaymentDiscount);
  const defaultDiscountRate = parseDiscountValues(brandDiscount?.listPriceDiscountRate);

  const hasRatesError = true;

  const currencyInputDefaultProps = {
    rightElementProps: {
      color: "gray.400",
    },
    rightElement: (<EuroIcon />),
    decimalSeparator: ",",
    thousandSeparator: ".",
    fixedDecimalScale: true,
    decimalScale: 2,
    showErrorMessage: true,
    errors: formState?.errors,
  };

  return (
    <>
      <Text
        fontSize="18px"
        textStyle="h3"
        mb={8}
        mt={4}
      >
        {MAP_TAB_TITLE[type]}
      </Text>

      <ModalRow>

        <Controller
          control={control}
          name="discount"
          defaultValue={defaultDiscount}
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              {...fieldState}
              {...currencyInputDefaultProps}
              title={i18n.t("components.modals.edit_vehicle_modal.discount")}
            />
          )}
        />

        <Controller
          control={control}
          name="downPaymentDiscount"
          defaultValue={defaultDownPaymentDiscount}
          render={({ field, fieldState }) => (
            <CurrencyInput
              {...field}
              {...fieldState}
              {...currencyInputDefaultProps}
              title={i18n.t("components.modals.edit_vehicle_modal.down_payment")}
            />
          )}
        />

        <Controller
          control={control}
          name="brandDiscountRate"
          defaultValue={String(Number(defaultDiscountRate) * 100).replace(".", ",")}
          render={({ field, fieldState }) => (
            <PercentageInput
              {...field}
              {...fieldState}
              title={i18n.t("components.modals.edit_vehicle_modal.discount_rate")}
              placeholder="0,00"
              errors={formState?.errors}
              showErrorMessage
            />
          )}
        />
      </ModalRow>

      <ModalRow pb={8}>
        <Stack w="full">
          <Tabs
            boxShadow="lg"
            tabs={[
              {
                label: MAP_FACTORS.residualRate.translationKey,
                content: (
                  <LeasingFactorsTable
                    data={vehicle?.dynamicLeasingRates}
                    factor={TableFactorsEnum.residualRate}
                    type={type}
                    setValue={setValue}
                    getValues={getValues}
                  />
                ),
              },
              {
                label: MAP_FACTORS.residualFactor.translationKey,
                content: (
                  <LeasingFactorsTable
                    data={vehicle?.dynamicLeasingRates}
                    factor={TableFactorsEnum.residualFactor}
                    type={type}
                    setValue={setValue}
                    getValues={getValues}
                  />
                ),
              },
              {
                label: MAP_FACTORS.salesFactor.translationKey,
                content: (
                  <LeasingFactorsTable
                    data={vehicle?.dynamicLeasingRates}
                    factor={TableFactorsEnum.salesFactor}
                    type={type}
                    setValue={setValue}
                    getValues={getValues}
                  />
                ),
              },
              {
                label: MAP_FACTORS.nominalInterestRate.translationKey,
                content: (
                  <LeasingFactorsTable
                    data={vehicle?.dynamicLeasingRates}
                    factor={TableFactorsEnum.nominalInterestRate}
                    type={type}
                    setValue={setValue}
                    getValues={getValues}
                  />
                ),
              },
            ]}
          />
          {
            hasRatesError && (
              <FieldErrorMessage
                error=""
                mt="8px"
              />
            )
          }
        </Stack>
      </ModalRow>
    </>
  );
};

export default RatesTabContent;
