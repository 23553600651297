import { OfferRow } from "views/Offers/types";

export const hasTopOffersOrMarketingOffers = (offers: OfferRow[]): boolean => (
  offers.some(offer => offer?.topOffer || offer?.marketingOffer)
);

export const getTopOffersAndMarketingOffersShortIds = (
  offers: OfferRow[],
): string[] | undefined => {
  if (!hasTopOffersOrMarketingOffers(offers)) {
    return undefined;
  }

  return offers.filter(
    offer => offer?.marketingOffer || offer?.topOffer,
  ).map(offer => offer?.shortId);
};
