import React, { FC } from "react";
import { Th, TableColumnHeaderProps } from "@chakra-ui/react";

export const TableHeaderCell: FC<TableColumnHeaderProps> = ({
  children,
  ...props
}) => (
  <Th
    textTransform="none"
    fontFamily="regular"
    fontWeight="normal"
    lineHeight="1.3"
    color="gray.400"
    fontSize="12px"
    pb={0}
    {...props}
  >
    {children}
  </Th>
);
