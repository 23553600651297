import {
  GetOfferQuery,
  FixedLeasingRatesPriceTypeEnum,
} from "generated/graphql";
import { FinanceAndCostsProps } from "views/CustomOffer/components/Steps/CustomOfferSteps/FinanceAndCosts/types";
import { FinancingAndCostsSchema } from "settings/yup/schemas/financingAndCostsSchema";
import { OfferSpecialCondition } from "views/CustomOffer/components/Steps/CustomOfferSteps/OfferDetails/types";

export const getFinancingAndCostsDefaultFormValues = (
  offer?: GetOfferQuery["offer"],
  financingAndCosts?: FinancingAndCostsSchema,
): FinanceAndCostsProps["defaultFormValues"] => {
  const selectedSpecialConditions = Object.values(
    {
      ...financingAndCosts?.financingSpecialConditions,
    } || {},
  );

  const priceType = financingAndCosts?.fixedLeasingRatesPriceType
    === FixedLeasingRatesPriceTypeEnum.Gross
    ? "priceGross"
    : "priceNet";

  return financingAndCosts
    ? {
      ...offer,
      ...financingAndCosts,
      bank: {
        id: financingAndCosts.bankId,
        name: offer?.bank?.name,
      },
      fixedLeasingRates: financingAndCosts
        .fixedLeasingRates?.filter(fixedLeasingRate => !Number.isNaN(fixedLeasingRate.price))
        .map(fixedLeasingRate => ({
          ...fixedLeasingRate,
          [priceType]: fixedLeasingRate?.price,
        })),
      offerSpecialConditions: selectedSpecialConditions?.reduce(
        (acc: OfferSpecialCondition[], curr) => {
          if (curr?.isChecked && curr.slug) {
            acc.push({
              displayedDescription: "",
              specialCondition: {
                slug: curr.slug,
              },
            });
          }
          return acc;
        }, [],
      ) || [],
    }
    : offer;
};
