import React from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";

import EllipsisIcon from "components/Icons/EllipsisIcon";

import { ActionsButtonProps } from "./types";
import { hoverCss } from "./styles";

const ActionsButton: React.FC<ActionsButtonProps> = ({
  actions,
  ...props
}) => (
  <Menu placement="bottom-end">
    <MenuButton
      bg="white"
      boxSize="34px"
      boxShadow="md"
      borderRadius="4px"
      {...props}
    >
      <EllipsisIcon />
    </MenuButton>

    <MenuList
      py={2.5}
      minW="4xs"
      boxShadow="md"
      borderRadius="4px"
    >
      {
        actions.map(action => (
          <MenuItem
            py={0.5}
            _hover={hoverCss}
            key={action.label}
            onClick={action.onClick}
            fontFamily="regular"
            isDisabled={action.isDisabled || false}
          >
            <Text textStyle="p">
              {action.label}
            </Text>
          </MenuItem>
        ))
      }
    </MenuList>
  </Menu>
);

export default ActionsButton;
