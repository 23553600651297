import React from "react";
import { Box, Skeleton, Text } from "@chakra-ui/react";

import i18n from "translations/i18n";
import CurrencyInput from "components/FormComponents/Inputs/CurrencyInput";
import useLocalizedNumberSeparators from "hooks/useLocalizedNumberSeparators";

import { BudgetInputProps } from "./types";

const BudgetInput: React.FC<BudgetInputProps> = ({
  isLoading = false,
  ...rest
}) => {
  const {
    localizedDecimalSeparator,
    localizedThousandSeparator,
  } = useLocalizedNumberSeparators();

  const inputPlaceholder = `1${localizedThousandSeparator}500${localizedDecimalSeparator}00 €`;

  if (isLoading) {
    return (
      <Box h={9}>
        <Text
          fontWeight="bold"
          fontSize="sm"
          lineHeight="18px"
          whiteSpace="nowrap"
        >
          {i18n.t("settings.budget_per_month")}
        </Text>

        <Skeleton
          isLoaded={!isLoading}
          borderRadius="sm"
          w="full"
          height={7}
          mt="8px"
        />
      </Box>
    );
  }

  return (
    <Box h={9}>
      <CurrencyInput
        name="currentTotalBudget"
        title={i18n.t("settings.budget_per_month")}
        placeholder={inputPlaceholder}
        isNumericString
        format={undefined}
        fixedDecimalScale
        decimalScale={2}
        suffix=" €"
        h="29px"
        {...rest}
      />
    </Box>
  );
};

export default BudgetInput;
