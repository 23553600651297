import { CustomerTypeEnum, UpdateBrandDiscountParams } from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

import maxValueTest from "../tests/maxValueTest";

const requiredErrorMessage = i18n.t("yup.mixed_required_field");

export const editVehicleSchema = yup.object().shape({
  listPrice: yup
    .string()
    .typeError(requiredErrorMessage)
    .required(requiredErrorMessage),
  manufacturerBonusNet: yup
    .string()
    .typeError(requiredErrorMessage)
    .required(requiredErrorMessage),
  configuratorLink: yup
    .string()
    .typeError(requiredErrorMessage)
    .required(requiredErrorMessage),
  configurationNotes: yup.string(),
  bankId: yup
    .string()
    .typeError(requiredErrorMessage)
    .required(requiredErrorMessage),
  discount: yup
    .string()
    .typeError(requiredErrorMessage)
    .required(requiredErrorMessage),
  downPaymentDiscount: yup
    .string()
    .typeError(requiredErrorMessage)
    .required(requiredErrorMessage),
  brandDiscountRate: yup
    .string()
    .test(maxValueTest(100))
    .required(requiredErrorMessage),
  calculateWithFactors: yup
    .string()
    .typeError(requiredErrorMessage)
    .required(requiredErrorMessage),
});

export type Leasing = {
  id?: number;
  type?: CustomerTypeEnum;
  termMonths?: number;
  mileageKm?: number;
  residualRate?: number | string;
  residualFactor?: number | string;
  salesFactor?: number | string;
  nominalInterestRate?: number | string;
}

export type DynamicLeasingRates = Leasing[];

export type BrandDiscount = {
  private?: UpdateBrandDiscountParams;
  corporate?: UpdateBrandDiscountParams;
}

export interface EditVehicleOfferSchema {
  discount?: number;
  downPaymentDiscount?: number;
  brandDiscountRate?: string;
  customerType: CustomerTypeEnum;
  listPrice: string;
  manufacturerBonusNet: string;
  configuratorLink: string;
  configurationNotes: string;
  bankId: string;
  calculateWithFactors: string;
  brandDiscounts: BrandDiscount;
  dynamicLeasingRates: DynamicLeasingRates;
}
