export const backendUrl = (
  process.env.NODE_ENV === "production"
    ? `${window.location.protocol}//${window.location.host}`
    : `${window.location.protocol}//localhost:4000`
);

export const ROOT_PAGE_PATH = "/";

export const FORGOT_PASSWORD_PATH = "/forgot-password";

export const RESET_PASSWORD_PATH = "/reset-password";

export const SET_PASSWORD_PATH = "/set-password";

export const OFFERS_PAGE_PATH = "/offers";

export const CALCULATOR_OFFERS_PAGE_PATH = "/calculator-offers";

export const CREATE_NEW_OFFER_PAGE_PATH = "/create-new-offer";

export const CUSTOM_OFFER_PAGE_PATH = "/custom-offer/:id";

export const MANAGE_CONDITIONS_PAGE_PATH = "/manage-conditions";

export const BASE_VEHICLES_PAGE_PATH = "/base-vehicles";

export const BRANDS_PAGE_PATH = "/brands";

export const BANKS_PAGE_PATH = "/banks";

export const HISTORIC_PAGE_PATH = "/historic";

export const OFFER_IMPORT_LOGS_PAGE_PATH = "/offer-import-logs";

export const OFFER_IMPORT_LOGS_DETAILS_PAGE_PATH = "/offer-import-logs/:id";

export const SETTINGS_PAGE_PATH = "/settings";

/* Leads routes */
export const CLASSIC_LEADS_OVERVIEW_PAGE_PATH = "/leads/classic/overview";

export const CHECKOUT_LEADS_OVERVIEW_PAGE_PATH = "/leads/checkout/overview";

export const BILLING_LEADS_OVERVIEW_PAGE_PATH = "/leads/billing/overview";

export const LEAD_DETAILS_PAGE_PATH = "/lead/:id";
