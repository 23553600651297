import React from "react";
import { Flex } from "@chakra-ui/react";

import i18n from "translations/i18n";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import { formatCurrency } from "utils/currencyFormat";

import { RightSectionProps } from "./types";

const getFakeFieldValue = (value?: number): string => formatCurrency({
  amount: value ?? 0,
  symbolPosition: "before",
});

const RightSection: React.FC<RightSectionProps> = ({ lead }) => (
  <Flex flex={1} direction="column">
    <Flex mb={30}>
      <FakeTextField
        value={lead?.companyRegistrationNumber}
        label={i18n.t("leads.details.form.company_registration_number")}
        w="50%"
      />

      <FakeTextField
        value={lead?.taxId}
        label={i18n.t("leads.details.form.tax_id")}
        w="50%"
      />
    </Flex>

    <Flex mb={30}>
      <FakeTextField
        value={getFakeFieldValue(lead?.previousYearRevenue)}
        label={i18n.t("leads.details.form.previous_year_revenue")}
        w="50%"
      />

      <FakeTextField
        value={getFakeFieldValue(lead?.expectedRevenue)}
        label={i18n.t("leads.details.form.expected_revenue")}
        w="50%"
      />
    </Flex>

    <Flex mb={30}>
      <FakeTextField
        value={lead?.fleetSize}
        label={i18n.t("leads.details.form.fleet_size")}
      />
    </Flex>
  </Flex>
);

export default RightSection;
