export const baseStyle = {
  bgColor: "transparent",
  _hover: {
    bgColor: "transparent",
    color: "primary.500",
  },
  _focus: {
    color: "primary.500",
  },
  _active: {
    color: "primary.500",
  },
};

export const highlightedStyle = {
  backgroundColor: "primary.10",
  borderRadius: "4px",
  color: "primary.500",
  _hover: {
    color: "primary.500",
  },
  _active: {
    color: "primary.500",
  },
  _focus: {
    color: "primary.500",
  },
};

export const logOutButtonStyle = {
  _hover: {
    boxShadow: "none",
  },
};
