import React from "react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useModal } from "contexts/modal";
import ConfirmationModal from "components/Modals/ConfirmationModal";

import { PurgeGalleryImagesButtonProps } from "./types";

export function PurgeGalleryImagesButton({
  onConfirm,
}: PurgeGalleryImagesButtonProps): JSX.Element {
  const [showModal] = useModal();
  const { t } = useTranslation();

  const onClick = (): void => {
    showModal({
      componentProps: {
        onConfirm,
        description: t("custom_offer.steps.images.are_you_sure_you_want_to_remove_all_gallery_images"),
      },
      closeOnOverlayClick: false,
      component: ConfirmationModal,
      size: "sm",
    });
  };

  return (
    <Button
      colorScheme="primary"
      alignSelf="center"
      onClick={onClick}
      textStyle="p"
      fontSize="sm"
      mb={5}
      w="full"
      minH="40px"
      boxShadow="sm"
    >
      {t("custom_offer.steps.images.remove_gallery_images")}
    </Button>
  );
}
