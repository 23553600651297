import { gql } from "@apollo/client";

export const UPDATE_BANK_REFETCH_QUERIES = [
  "GetBanks",
];

export default gql`
  mutation UpdateBank (
    $id: Int!,
    $params: UpdateBankParams!
  ) {
    updateBank(
      id: $id
      params: $params
    ) {
      id
    }
  }
`;
