import React from "react";
import {
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";

import { StatusBulletProps } from "./types";

const StatusBullet: React.FC<StatusBulletProps> = ({ status, label }) => {
  const statusColor = `${status}.500`;

  return (
    <Flex align="center" mr={3}>
      <Box
        h={3}
        w={3}
        borderRadius={6}
        bgColor={statusColor}
      />

      <Text
        ml={1}
        textStyle="p"
        textTransform="capitalize"
      >
        {label}
      </Text>
    </Flex>
  );
};

export default StatusBullet;
