import React from "react";

import StatusSelector from "views/Leads/components/StatusSelector";
import {
  Lead,
} from "generated/graphql";
import { Column } from "components/Table/types";
import i18n from "translations/i18n";
import DocumentsAvailable from "components/DocumentsAvailable";
import { GET_CHECKOUT_LEADS_REFETCH_QUERIES } from "views/Leads/graphql/updateLeadBySellerMutation";
import { MakeLeadsTableColumnsProps } from "views/Leads/types";
import { makeDefaultLeadsTableColumns } from "views/Leads/makeDefaultLeadsTableColumns";
import { ButtonAsLink } from "components/ButtonAsLink";
import { LEAD_DETAILS_PAGE_PATH } from "routes";
import leadSelectStyles from "views/Leads/components/Select/styles";

const MemoizedStatusSelector = React.memo(StatusSelector);
const MemoizedDocumentsAvailable = React.memo(DocumentsAvailable);

export const makeTableColumns = ({
  selectedLeads,
  toggleLead,
  toggleAllLeads,
  isAllSelected = false,
  hasLeads,
}: MakeLeadsTableColumnsProps): Column<Lead>[] => {
  const makeColumnsProps = {
    selectedLeads,
    toggleLead,
    toggleAllLeads,
    isAllSelected,
    hasLeads,
    refetchQueries: GET_CHECKOUT_LEADS_REFETCH_QUERIES,
  };

  return [
    ...makeDefaultLeadsTableColumns(makeColumnsProps),
    {
      title: i18n.t("components.table.heading.documents"),
      render: ({ row }) => (
        <MemoizedDocumentsAvailable
          isHandlerContractPresent={row.isHandlerContractPresent}
          isClientContractPresent={row.isClientContractPresent}
          isPersonalDocumentPresent={row.isPersonalDocumentPresent}
          isSelfAssessmentPresent={row.isSelfAssessmentPresent}
          isFinancialEvidencePresent={row.isFinancialEvidencePresent}
          isBusinessRegistrationPresent={row.isBusinessRegistrationPresent}
        />
      ),
    },
    {
      title: i18n.t("components.table.heading.status"),
      fieldResolver: lead => i18n.t([`status.${lead?.status}`, "-"]),
      render: ({ row }) => (
        <MemoizedStatusSelector
          refetchQueries={GET_CHECKOUT_LEADS_REFETCH_QUERIES}
          defaultValue={row?.status}
          value={row?.status}
          leadId={row?.id}
          isCheckout
          styles={leadSelectStyles}
        />
      ),
      cellProps: {
        minW: "180px",
      },
    },
    {
      render: ({ row }) => (
        <ButtonAsLink
          path={LEAD_DETAILS_PAGE_PATH.replace(":id", String(row.id))}
          label={i18n.t("components.buttons.details")}
          colorScheme="secondary"
          textStyle="p"
          variant="ghost"
          lineHeight={1}
        />
      ),
      cellProps: {
        textAlign: "right",
      },
    },
  ];
};
