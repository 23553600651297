import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const CheckIcon: IconProps = createIcon({
  viewBox: "0 0 512 512",
  path: (
    <path
      fill="currentColor"
      d="M20,0A20,20,0,1,0,40,20,20.007,20.007,0,0,0,20,0ZM16,30,6,20l2.82-2.82L16,24.34,31.18,9.16,34,12Z"
      transform="scale(12), translate(1 1)"
    />
  ),
});

export default CheckIcon;
