import React from "react";
import { Global, css } from "@emotion/react";

const FontsComponent = (): JSX.Element => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Lato Regular';
        src: local('Lato Regular'), url('assets/fonts/Lato-Regular.woff') format('woff');
      }

      @font-face {
        font-family: 'Lato Bold';
        src: local('Lato Bold'), url('assets/fonts/Lato-Bold.woff') format('woff');
      }
    `}
  />
);

export default FontsComponent;
