import { HousingSituationEnum } from "generated/graphql";
import i18n from "translations/i18n";

const getHousingCostLabel = (housingSituation: HousingSituationEnum): string => {
  const COSTS_LABELS_BY_HOUSING_SITUATION = {
    [HousingSituationEnum.Apartment]: i18n.t("housing_costs.apartment"),
    [HousingSituationEnum.House]: i18n.t("housing_costs.house"),
    [HousingSituationEnum.Rent]: i18n.t("housing_costs.rent"),
  };

  return COSTS_LABELS_BY_HOUSING_SITUATION[housingSituation] ?? "";
};

export default getHousingCostLabel;
