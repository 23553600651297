import React, { useRef } from "react";
import {
  Box,
  Flex,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import i18n from "translations/i18n";
import MonetaryField from "components/FormComponents/Inputs/MonetaryField";
import CurrencyInput from "components/FormComponents/Inputs/CurrencyInput";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import DatePicker from "components/FormComponents/Inputs/DatePicker";
import { formatCurrency } from "utils/currencyFormat";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import useDownloadFile from "hooks/useDownloadFile";
import Button from "components/Button";
import { skeletonStyles } from "views/Leads/Details/Checkout/Details/styles";

import { LeasingAndContractDataSectionFormProps } from "./types";

const LeasingAndContractData: React.FC<LeasingAndContractDataSectionFormProps> = ({
  register,
  formState,
  control,
  lead,
  loading,
}) => {
  const dateInputRef = useRef<HTMLInputElement>(null);

  const downloadFile = useDownloadFile();

  const leasingRateGrossFormatted = lead?.leasingRateGross ? formatCurrency({
    amount: lead?.leasingRateGross,
    addSymbol: false,
    symbolPosition: "before",
  }) : 0;

  const leasingRateNetFormatted = lead?.leasingRateNet ? formatCurrency({
    amount: lead?.leasingRateNet,
    addSymbol: false,
    symbolPosition: "before",
  }) : 0;

  const provisioningCostsGrossFormatted = lead?.provisioningCostsGross ? formatCurrency({
    amount: lead?.provisioningCostsGross,
    addSymbol: false,
    symbolPosition: "before",
  }) : 0;

  const provisioningCostsNetFormatted = lead?.provisioningCostsNet ? formatCurrency({
    amount: lead?.provisioningCostsNet,
    addSymbol: false,
    symbolPosition: "before",
  }) : 0;

  const configurationListPriceGrossFormatted = lead?.configurationListPriceGross ? formatCurrency({
    amount: lead?.configurationListPriceGross,
    addSymbol: false,
    symbolPosition: "before",
  }) : undefined;

  const configurationListPriceNetFormatted = lead?.configurationListPriceGross ? formatCurrency({
    amount: lead?.configurationListPriceNet,
    addSymbol: false,
    symbolPosition: "before",
  }) : undefined;

  return (
    <Stack mb={4}>
      <Flex justifyContent="space-between">
        <Flex flexDir="column" mr={4} w="full">
          <LeadDetailsCard
            title={i18n.t("leads.details.form.leasing")}
          >
            {
              loading ? (
                <Flex flexDir="column" w="full">
                  <Skeleton w="sm" h={8} mb={4} />
                  <Skeleton w="full" h={8} mb={4} />
                  <Skeleton w="full" h={8} />
                </Flex>
              )
                : (
                  <>
                    <Flex mb="20px">
                      <Box w={48} mr={8}>
                        <MonetaryField
                          {...register("duration")}
                          errors={formState.errors}
                          title={i18n.t("leads.details.form.duration")}
                          defaultValue={Number(lead?.duration)}
                          placeholder={i18n.t("leads.details.form.duration")}
                          rightElement
                        />
                      </Box>

                      <Box w={48} mr={8}>
                        <CurrencyInput
                          {...register("mileage")}
                          errors={formState.errors}
                          title={i18n.t("leads.details.form.mileage")}
                          defaultValue={lead?.mileage}
                          placeholder={i18n.t("leads.details.form.mileage")}
                          rightElement
                          decimalScale={0}
                        />
                      </Box>

                      <Flex w="auto">
                        <FakeTextField
                          value={`${lead?.deliveryTime} ${i18n.t("leads.details.form.months")}`}
                          label={i18n.t("leads.details.form.delivery_time")}
                        />
                      </Flex>
                    </Flex>

                    <Flex>
                      <Flex w={48} mr={8}>
                        <FakeTextField
                          value={`€ ${leasingRateGrossFormatted} (€ ${leasingRateNetFormatted})`}
                          label={i18n.t("leads.details.form.leasing_rate_info")}
                        />
                      </Flex>

                      <Flex w={48} mr={8}>
                        <FakeTextField
                          value={`€ ${provisioningCostsGrossFormatted} (€ ${provisioningCostsNetFormatted})`}
                          label={i18n.t("leads.details.form.provisioning")}
                        />
                      </Flex>

                      {
                        configurationListPriceGrossFormatted && (
                          <Flex w={56}>
                            <FakeTextField
                              value={
                                `€ ${configurationListPriceGrossFormatted}
                                ${lead?.configurationListPriceNet ? `(€ ${configurationListPriceNetFormatted})` : ""}
                                `
                              }
                              label={i18n.t("leads.details.form.configuration_list_price_gross")}
                            />
                          </Flex>
                        )
                      }
                    </Flex>

                    {
                      lead?.configurationCode && (
                        <Flex w="auto" mt="20px">
                          <FakeTextField
                            value={lead?.configurationCode}
                            label={i18n.t("leads.details.form.configuration_code")}
                          />
                        </Flex>
                      )
                    }

                    {
                      lead?.configurationPdf && (
                        <Flex w="auto" flexDir="column" mt="20px">
                          <Text
                            fontSize={12}
                            mb={1}
                            color="gray.400"
                          >
                            {i18n.t("leads.details.form.configuration_pdf")}
                          </Text>

                          <Button
                            variant="link"
                            label={i18n.t("components.buttons.download_file") as string}
                            colorScheme="secondary"
                            onClick={() => downloadFile(lead?.configurationPdf?.id)}
                          />
                        </Flex>
                      )
                    }
                  </>
                )
            }
          </LeadDetailsCard>
        </Flex>
        <Flex flexDir="column" w="full">
          <LeadDetailsCard
            title={i18n.t("leads.details.form.contract_data")}
          >
            {
              loading ? (
                <Flex flexDir="column" w="full">
                  <Skeleton {...skeletonStyles.sm} />
                  <Skeleton {...skeletonStyles.full} />
                  <Skeleton w="full" h={8} />
                </Flex>
              )
                : (
                  <>
                    <Box w={48} mb="20px">
                      <FakeTextField
                        value={formatCurrency({
                          amount: lead?.commission ?? 0,
                          symbolPosition: "before",
                        })}
                        label={i18n.t("leads.details.form.commission")}
                      />
                    </Box>
                    <Box w={48}>
                      <Controller
                        name="deliveryDate"
                        control={control}
                        render={({
                          field,
                          fieldState,
                        }) => (
                          <DatePicker
                            {...field}
                            {...fieldState}
                            title={i18n.t("leads.details.form.delivery_date")}
                            errors={formState.errors}
                            ref={dateInputRef}
                          />
                        )}
                      />
                    </Box>
                  </>
                )
            }
          </LeadDetailsCard>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default LeasingAndContractData;
