import React from "react";

import useCustomOfferStore from "views/CustomOffer/store";

import SpinnerContainer from "../../../SpinnerContainer";
import EquipmentForm from "./Equipment";
import { getAccessoriesList } from "./getAccessoriesByCategories";
import { getEquipmentDefaultValues } from "./getEquipmentDefaultValues";

const Equipment: React.FC = () => {
  const { offer, equipment } = useCustomOfferStore(state => ({
    offer: state.offer,
    equipment: state.store?.equipment,
  }));

  if (!offer) {
    return <SpinnerContainer />;
  }

  const defaultFormValues = getEquipmentDefaultValues(offer, equipment);

  const availableAccessories = getAccessoriesList(
    offer?.vehicle?.accessories ?? [],
  );

  return (
    <EquipmentForm
      key={offer?.id}
      defaultFormValues={defaultFormValues}
      availableAccessories={availableAccessories}
    />
  );
};

export default Equipment;
