import create from "zustand";

import { CreateNewOfferRow } from "../types";
import { CreateNewOfferStore } from "./types";

const useCreateNewOfferStore = create<CreateNewOfferStore>((set, get) => ({
  selectedItem: get()?.selectedItem,
  dealerId: get()?.dealerId,
  clearSelectedItem: () => {
    set({ selectedItem: {} as CreateNewOfferRow });
  },
  toggleItem: (item: CreateNewOfferRow) => {
    set(({ selectedItem }) => {
      if (selectedItem === item) {
        return {
          ...get,
          selectedItem: {} as CreateNewOfferRow,
        };
      }

      return {
        selectedItem: item,
      };
    });
  },
  setDealerId: (dealerId: string) => {
    set(() => ({
      ...get,
      dealerId,
    }));
  },
}));

export default useCreateNewOfferStore;
