import { gql } from "@apollo/client";

export const CREATE_BANK_REFETCH_QUERIES = [
  "GetBanks",
];

export default gql`
  mutation CreateBank (
    $params: CreateBankParams!
  ) {
    createBank(
      params: $params
    ) {
      id
    }
  }
`;
