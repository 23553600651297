import React, {
  FormEvent,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { Flex, Stack } from "@chakra-ui/react";

import SectionCard from "components/SectionCard";
import Button from "components/Button";
import Table from "components/Table";
import RadioButton from "components/FormComponents/RadioButton";
import { formatCurrencyToDecimal } from "utils/currencyFormat";
import { FixedLeasingRatesPriceTypeEnum, UpdateFixedLeasingRateParams } from "generated/graphql";
import { CUSTOM_FIELD_REPRESENTATION } from "constants/leasing";

import parseModifiedLeasingRatesWithCustomData from "./parseModifiedLeasingRatesWithCustomData";
import { makeRatesOptions } from "./makeOptions";
import {
  LeasingRatesTableProps,
  LeasingColumns,
  LeasingRateTable,
  LeasingRateTableFieldProperties,
  Rates,
} from "../types";
import { MakeLeasingRatesColumns } from "./tableColumns";
import { makeFixedLeasingRatesRows } from "./tableRows";
import { buttonCss, collapseButtonCss, tableCss } from "./styles";
import {
  hasCustomMileage as hasCustomMileageHelper,
  hasCustomMonth as hasCustomMonthHelper,
  hasDefaultMileage as hasDefaultMileageHelper,
  hasDefaultMonth as hasDefaultMonthHelper,
} from "./leasingRatesHelper";
import {
  CustomTitleEnum,
  CustomTitleProperties,
  UpdateCustomTitleValue,
  UpsertLeasingValue,
  ValidateRateProps,
  ValidateRatesPayload,
} from "./types";

export const parseLeasingRates = ({
  fixedLeasingRates,
  fixedLeasingRatesPriceType,
}: LeasingRateTable,
customMileage: number,
customMonth: number)
: UpdateFixedLeasingRateParams[] => fixedLeasingRates?.map(({
  mileageKm,
  termMonths,
  priceGross,
  priceNet,
}) => {
  const hasCustomMileage = hasCustomMileageHelper(mileageKm);
  const hasCustomMonth = hasCustomMonthHelper(termMonths);

  return ({
    id: Number(`${termMonths}${mileageKm}`),
    mileageKm: (hasCustomMileage && customMileage)
      ? customMileage
      : mileageKm,
    termMonths: (hasCustomMonth && customMonth)
      ? customMonth
      : termMonths,
    price: fixedLeasingRatesPriceType === FixedLeasingRatesPriceTypeEnum.Net
      ? parseFloat(String(priceNet || 0))
      : parseFloat(String(priceGross || 0)),
  });
});

export const validateRates = ({
  data,
  validationType,
}: ValidateRateProps): ValidateRatesPayload => {
  const validate = (item: UpdateFixedLeasingRateParams): boolean => {
    switch (validationType) {
      case Rates.Limit:
        return item.price < Rates.Limit;

      default:
        return item.price === Rates.Invalid;
    }
  };

  const invalidRates = data.filter((item: UpdateFixedLeasingRateParams) => validate(item));

  const isInvalidRates = data.some((item: UpdateFixedLeasingRateParams) => validate(item));

  return ({ invalidRates, isInvalidRates });
};

const LeasingRatesTable: React.FC<LeasingRatesTableProps> = ({
  data,
  loading,
  setValue,
  getValues,
  reset,
  formState,
  register,
  handleChange,
}) => {
  const { t } = useTranslation();
  const [rateType, setRateType] = useState(data?.fixedLeasingRatesPriceType || "");
  const [rows, setRows] = useState<LeasingColumns[]>([]);
  const {
    fixedLeasingRates,
    customMonth,
    customMileage,
  } = getValues();
  const parsedLeasingRates = parseLeasingRates(data, Number(customMileage), Number(customMonth));

  const updateRows = useCallback((newFixedLeasingRates: UpdateFixedLeasingRateParams[]): void => {
    setRows(makeFixedLeasingRatesRows(newFixedLeasingRates));
    setValue("fixedLeasingRates", newFixedLeasingRates);

    if (handleChange) handleChange(newFixedLeasingRates);
  }, [setValue, setRows, handleChange]);

  useEffect(() => {
    updateRows(parsedLeasingRates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCustomFields = useCallback((
    customTitleProperties: CustomTitleProperties,
    previousLeasingRates: UpdateFixedLeasingRateParams[],
  ): void => {
    const { name, value } = customTitleProperties;
    const isCustomMileage = name === "customMileage";

    if (!value) {
      const removeAllTitleValuesInLeasingRate = previousLeasingRates?.filter(leasingRate => {
        const { mileageKm, termMonths } = leasingRate;

        return isCustomMileage
          ? hasDefaultMileageHelper(mileageKm)
          : hasDefaultMonthHelper(termMonths);
      });

      updateRows(removeAllTitleValuesInLeasingRate);
      return;
    }

    const updatedAllTitleValuesInLeasingRate = previousLeasingRates?.map(leasingRate => {
      const { mileageKm, termMonths, ...rest } = leasingRate;
      const hasCustomMileage = !hasDefaultMileageHelper(mileageKm);
      const hasCustomMonth = !hasDefaultMonthHelper(termMonths);

      if (hasCustomMileage || hasCustomMonth) {
        return {
          ...rest,
          id: hasCustomMileage
            ? Number(`${termMonths}${CUSTOM_FIELD_REPRESENTATION}`)
            : Number(`${CUSTOM_FIELD_REPRESENTATION}${mileageKm}`),
          mileageKm: (hasCustomMileage && isCustomMileage) ? Number(value) : mileageKm,
          termMonths: (hasCustomMonth && !isCustomMileage) ? Number(value) : termMonths,
        };
      }
      return leasingRate;
    });

    updateRows(updatedAllTitleValuesInLeasingRate);
  }, [updateRows]);

  const upsertLeasingValue = useCallback(({
    newLeasingRate = {},
    customTitleProperties,
  }: UpsertLeasingValue): void => {
    const previousLeasingRates: UpdateFixedLeasingRateParams[] = fixedLeasingRates || [];

    if (customTitleProperties) {
      updateCustomFields(customTitleProperties, previousLeasingRates);
      return;
    }

    const leasingRatesWithCustomFields = previousLeasingRates.map(
      previousLeasingRate => parseModifiedLeasingRatesWithCustomData({
        previousLeasingRate,
        newLeasingRate,
        customMileage,
        customMonth,
      }),
    );

    const leasingRatesItem = leasingRatesWithCustomFields.find((
      { id },
    ) => id === newLeasingRate.id);

    const newLeasingRates: UpdateFixedLeasingRateParams[] = leasingRatesItem
      ? leasingRatesWithCustomFields.map((actualLeasingRate: UpdateFixedLeasingRateParams) => {
        if (actualLeasingRate.id === newLeasingRate.id) {
          return { ...actualLeasingRate, ...newLeasingRate };
        }
        return actualLeasingRate;
      })
      : ([...leasingRatesWithCustomFields, newLeasingRate]);

    updateRows(newLeasingRates);
  }, [
    fixedLeasingRates,
    updateRows,
    updateCustomFields,
    customMileage,
    customMonth,
  ]);

  const updateCustomTitlesValues = ({
    customFieldName,
    titleValue,
  }: UpdateCustomTitleValue): void => {
    const customTitleData = {
      customTitleProperties: {
        name: customFieldName,
        value: titleValue,
      },
    };

    upsertLeasingValue(customTitleData);
  };

  const getLeasingRateTableFieldProperties = (event: React.ChangeEvent<HTMLInputElement>)
  : LeasingRateTableFieldProperties => {
    const id: string | null = event.target.getAttribute("id");
    const cellName: string = event.target.getAttribute("name") || "";
    const termMonths: string = cellName?.match(/.+?(?=-)/g)?.[0] || "";
    const mileageKm: string = cellName?.match(/[^-]+$/g)?.[0] || "";

    return {
      id,
      cellName,
      termMonths,
      mileageKm,
    };
  };

  const handleInputChange = (
    e: FormEvent<HTMLDivElement>,
  ): void => {
    const event = e as React.ChangeEvent<HTMLInputElement>;

    const {
      id,
      cellName,
      termMonths,
      mileageKm,
    } = getLeasingRateTableFieldProperties(event);

    const isCustomTitleField = Object.values(CustomTitleEnum).some(v => v === cellName);

    if (isCustomTitleField) {
      const titleValue = event.target.value?.replace(".", "")?.match(/^\d+/g)?.[0] || "";
      const hasCustomTitleValues = !(cellName === "customMileage"
        ? hasDefaultMileageHelper(titleValue)
        : hasDefaultMonthHelper(titleValue));
      const customFieldName = cellName === CustomTitleEnum.customMileage
        ? CustomTitleEnum.customMileage
        : CustomTitleEnum.customMonth;

      if (hasCustomTitleValues) {
        updateCustomTitlesValues({ customFieldName, titleValue });
      }

      return;
    }

    const newLeasingRate: UpdateFixedLeasingRateParams = {
      id: Number(id),
      mileageKm: Number(mileageKm),
      termMonths: parseInt(termMonths),
      price: formatCurrencyToDecimal(event.target.value),
    };

    upsertLeasingValue({ newLeasingRate });
  };

  const clearLeasingRates = (resetValue: []): void => {
    setRows(makeFixedLeasingRatesRows(resetValue));
    reset({ ...getValues(), fixedLeasingRates: resetValue });
  };

  const columns = MakeLeasingRatesColumns({
    leasingRates: parsedLeasingRates,
    formState,
    register,
  });

  return (
    <SectionCard
      title={t("components.modals.edit_leasing_rates_modal.leasing_rates")}
    >
      <Stack spacing="40px">
        <Flex justifyContent="space-between" width="100%">
          <Flex>
            <RadioButton
              name="rateType"
              defaultValue={rateType}
              options={makeRatesOptions()}
              title={t("components.modals.edit_leasing_rates_modal.rates")}
              onChange={value => {
                setRateType(value as FixedLeasingRatesPriceTypeEnum);
                setValue("fixedLeasingRatesPriceType", value as FixedLeasingRatesPriceTypeEnum);
              }}
            />
          </Flex>
          <Button
            {...buttonCss}
            label={t("components.modals.edit_leasing_rates_modal.clear_all_rates")}
            onClick={() => clearLeasingRates([])}
          />
        </Flex>
      </Stack>
      <Table
        columns={columns}
        data={rows}
        emptyDataProps={{
          message: t("errors.something_went_wrong"),
          isDataLoading: loading,
        }}
        onChange={handleInputChange}
        tableCss={tableCss}
        collapseButtonCss={collapseButtonCss}
        isCollapsable
      />
    </SectionCard>
  );
};

export default LeasingRatesTable;
