import { HousingSituationEnum } from "generated/graphql";
import i18n from "translations/i18n";

const getHousingSituationValue = (HousingSituation: HousingSituationEnum): string => {
  const HOUSING_SITUATIONS = {
    [HousingSituationEnum.Apartment]: i18n.t("housing_situations.apartment"),
    [HousingSituationEnum.House]: i18n.t("housing_situations.house"),
    [HousingSituationEnum.Rent]: i18n.t("housing_situations.rent"),
  };

  return HOUSING_SITUATIONS[HousingSituation] ?? "";
};

export default getHousingSituationValue;
