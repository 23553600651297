import React from "react";
import { Stack, useTheme } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Props as ReactSelectProps } from "react-select/src/Select";

import { numberMask } from "constants/inputMasks";
import SectionCard from "components/SectionCard";
import Input from "components/FormComponents/Inputs/Input";
import Select from "components/FormComponents/Inputs/Selects/Select";
import ToggleButton from "components/FormComponents/ToggleButton";
import { OfferFuelTypeEnum, OfferGearTypeEnum } from "generated/graphql";

import { titleCss, toggleButtonCss } from "../../styles";
import { makeDoorOptions, makeGearOptions } from "../makeOptions";
import { StandardDataFormProps } from "../types";
import { FuelTypeFields } from "./FuelTypeFields";

const BaseDataCard: React.FC<StandardDataFormProps> = ({
  defaultFormValues,
  control,
  register,
  formState,
  watch,
  setValue,
}) => {
  const {
    fuelType,
    numberOfDoors,
    engineDisplacementCcm,
    powerKw,
    horsepower,
    vin,
    internalNumber,
    gearType,
  } = defaultFormValues || {};

  const { t } = useTranslation();

  const chakraTheme = useTheme();

  const selectedFuelType = watch("fuelType");

  const isElectric = selectedFuelType === OfferFuelTypeEnum.Electric;

  const isHybrid = selectedFuelType === OfferFuelTypeEnum.Hybrid
    || selectedFuelType === OfferFuelTypeEnum.HybridDiesel;

  const gearTypeSetValue = React.useCallback((value: string): void => {
    setValue("gearType", value as OfferGearTypeEnum);
  }, [
    setValue,
  ]);

  return (
    <SectionCard
      title={t("custom_offer.steps.standard_data.base_data_card.title")}
    >
      <FuelTypeFields
        control={control}
        formState={formState}
        register={register}
        isHybrid={isHybrid}
        fuelType={fuelType}
      />

      <Stack
        spacing={4}
        direction="row"
        mb="6"
        width="63.5%"
      >
        <Controller
          name="numberOfDoors"
          control={control}
          defaultValue={numberOfDoors?.toString()}
          render={({
            field,
            fieldState,
          }) => (
            <Select
              {...field}
              {...fieldState}
              titleCss={titleCss}
              errors={formState.errors}
              options={makeDoorOptions()}
              title={t("custom_offer.steps.standard_data.base_data_card.doors")}
              placeholder={t("custom_offer.steps.standard_data.base_data_card.select_doors")}
              styles={{
                container: (styles: ReactSelectProps, props: ReactSelectProps) => ({
                  ...styles,
                  width: "100%",
                  maxWidth: "300px",
                  borderColor: (
                    props?.selectProps?.isInvalid
                      ? chakraTheme.colors.error[500]
                      : props?.selectProps?.value
                        ? chakraTheme.colors.black[700]
                        : chakraTheme.colors.gray[300]
                  ),
                }),
              }}
            />
          )}
        />

        <Input
          {...register("engineDisplacementCcm", {
            valueAsNumber: true,
            required: !isElectric,
          })}
          defaultValue={engineDisplacementCcm}
          errors={formState.errors}
          mask={numberMask}
          title={t("custom_offer.steps.standard_data.base_data_card.engine_displacement")}
          titleCss={titleCss}
          placeholder={t("custom_offer.steps.standard_data.base_data_card.insert_engine_displacement")}
          leftAddon={null}
          rightAddon={(<span>{t("custom_offer.steps.standard_data.base_data_card.ccm")}</span>)}
          rightAddonProps={{
            color: "gray.400",
          }}
          maxW={300}
          disabled={isElectric}
          {...isElectric && ({ value: "" })}
          {...isElectric && ({ placeholder: "-" })}
        />

        <ToggleButton
          title={t("custom_offer.steps.standard_data.base_data_card.gear")}
          setValue={gearTypeSetValue}
          options={makeGearOptions()}
          titleCss={toggleButtonCss}
          {...register("gearType")}
          defaultValue={gearType}
        />
      </Stack>
      <Stack
        spacing={4}
        direction="row"
      >
        <Input
          {...register("powerKw", { valueAsNumber: true })}
          defaultValue={powerKw}
          errors={formState.errors}
          mask={numberMask}
          title={t("custom_offer.steps.standard_data.base_data_card.power_kw")}
          titleCss={titleCss}
          placeholder={t("custom_offer.steps.standard_data.base_data_card.insert_power_kw")}
          rightAddon={(<span>{t("custom_offer.steps.standard_data.base_data_card.kw")}</span>)}
          rightAddonProps={{
            color: "gray.400",
          }}
          isRequired
        />

        <Input
          {...register("horsepower", { valueAsNumber: true })}
          defaultValue={horsepower}
          errors={formState.errors}
          mask={numberMask}
          title={t("custom_offer.steps.standard_data.base_data_card.power_ps")}
          titleCss={titleCss}
          placeholder={t("custom_offer.steps.standard_data.base_data_card.insert_power_ps")}
          rightAddon={(<span>{t("custom_offer.steps.standard_data.base_data_card.ps")}</span>)}
          rightAddonProps={{
            color: "gray.400",
          }}
          isRequired
        />

        <Input
          {...register("vin")}
          defaultValue={vin}
          errors={formState.errors}
          title={t("custom_offer.steps.standard_data.base_data_card.vin")}
          titleCss={titleCss}
          placeholder={t("custom_offer.steps.standard_data.base_data_card.insert_vin")}
        />

        <Input
          {...register("internalNumber")}
          defaultValue={internalNumber}
          errors={formState.errors}
          title={t("custom_offer.steps.standard_data.base_data_card.internal_number")}
          titleCss={titleCss}
          placeholder={t("custom_offer.steps.standard_data.base_data_card.insert_internal_number")}
        />
      </Stack>
    </SectionCard>
  );
};
export default BaseDataCard;
