import { Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CustomMonetaryField from "components/Modals/EditLeasingRatesModal/Form/Fields/CustomMonetaryField";
import CustomPercentageField from "components/Modals/EditLeasingRatesModal/Form/Fields/CustomPercentageField";
import SectionCard from "components/SectionCard";
import BankSelect from "components/FormComponents/Inputs/Selects/BankSelect";
import GenericDivider from "components/GenericDivider";

import { titleCss } from "../../../styles";
import { FinancingDataProps } from "../../types";

const FinancingData: React.FC<FinancingDataProps> = ({
  offer,
  setValue,
  control,
}) => {
  const { t } = useTranslation();

  return (
    <SectionCard
      title={t("components.modals.edit_leasing_rates_modal.financing_data")}
    >
      <Flex w="md" mb="15px">
        <Controller
          name="bankId"
          control={control}
          defaultValue={offer?.bank?.id?.toString()}
          render={({
            field,
            fieldState,
          }) => (
            <BankSelect
              {...field}
              {...fieldState}
              title={t("components.modals.edit_vehicle_modal.bank")}
              titleCss={titleCss}
            />
          )}
        />
      </Flex>

      <GenericDivider />

      <Flex justifyContent="space-between" mb="15px">
        <Stack w="md" mr="10">
          <CustomMonetaryField
            netTitle={t("components.modals.edit_leasing_rates_modal.current_price_net")}
            grossTitle={t("components.modals.edit_leasing_rates_modal.current_price_gross")}
            name="currentPriceNet"
            titleCss={titleCss}
            defaultValue={offer?.currentPriceNet}
            setValue={setValue}
          />
        </Stack>

        <Stack w="md" mr="10">
          <CustomMonetaryField
            netTitle={t("components.modals.edit_leasing_rates_modal.base_list_price_net")}
            grossTitle={t("components.modals.edit_leasing_rates_modal.base_list_price_gross")}
            name="baseListPriceNet"
            titleCss={titleCss}
            defaultValue={offer?.baseListPriceNet}
            setValue={setValue}
          />
        </Stack>

        <Stack w="md">
          <CustomMonetaryField
            netTitle={t("components.modals.edit_leasing_rates_modal.loan_amount_net")}
            grossTitle={t("components.modals.edit_leasing_rates_modal.loan_amount_gross")}
            name="loanAmountNet"
            titleCss={titleCss}
            defaultValue={offer?.loanAmountNet}
            setValue={setValue}
          />
        </Stack>
      </Flex>

      <GenericDivider />

      <Flex
        direction="row"
        mb="15px"
        justify="space-between"
      >
        <CustomPercentageField
          allowNegative
          netTitle={t("components.modals.edit_leasing_rates_modal.effective_annual_interest")}
          name="effectiveAnnualInterestRate"
          titleCss={titleCss}
          defaultValue={offer?.effectiveAnnualInterestRate}
          setValue={setValue}
          rightElement="%"
          width="sm"
        />

        <CustomPercentageField
          allowNegative
          netTitle={t("components.modals.edit_leasing_rates_modal.nominal_interest_rate")}
          name="nominalInterestRate"
          titleCss={titleCss}
          defaultValue={offer?.nominalInterestRate}
          setValue={setValue}
          rightElement="%"
          width="sm"
        />
        <Stack w="sm" />
      </Flex>

    </SectionCard>
  );
};

export default FinancingData;
