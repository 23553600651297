import React, { useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { Editor } from "react-draft-wysiwyg";

import { VehicleDescriptionProps } from "./types";

const markdownToDraftOptions = {
  preserveNewlines: true,
  blockTypes: {
    hr: () => ({
      type: "HR",
      mutability: "IMMUTABLE",
      text: "----",
      data: {
        tag: "----",
      },
    }),
  },
};

const VehicleDescription : React.FC<VehicleDescriptionProps> = ({ onChange, defaultValue }) => {
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(
      convertFromRaw(
        markdownToDraft(
          defaultValue || "",
          markdownToDraftOptions,
        ),
      ),
    ),
  );

  const toolbar = {
    options: ["inline", "list", "history"],
    inline: {
      options: ["bold"],
    },
    list: {
      inDropdown: false,
      options: ["unordered"],
    },
    history: {
      inDropdown: false,
      options: ["undo", "redo"],
    },
  };

  const onEditorStateChange = (newEditorState: EditorState) : void => {
    const markdown = draftToMarkdown(
      convertToRaw(newEditorState.getCurrentContent()),
      { preserveNewlines: true },
    );

    onChange?.(markdown);
    setEditorState(newEditorState);
  };

  return (
    <Editor
      handlePastedText={() => false}
      onEditorStateChange={onEditorStateChange}
      editorClassName="vehicle-description"
      editorState={editorState}
      toolbar={toolbar}
    />
  );
};

export default VehicleDescription;
