import React, { forwardRef } from "react";
import {
  Text,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Checkbox from "components/FormComponents/Checkbox";
import { CheckboxProps } from "components/FormComponents/Checkbox/types";

import { titleCss } from "../../styles";

export type CheckoutCheckboxProps = CheckboxProps

const CheckoutCheckbox = forwardRef<HTMLInputElement, CheckboxProps>((
  {
    isDisabled,
    ...props
  },
  ref,
) => {
  const { t } = useTranslation();

  return (
    <Flex
      w="fit-content"
    >
      <Tooltip
        label={t("custom_offer.steps.offer_details.checkout_is_enabled_by_default_for_this_offer")}
        isDisabled={!isDisabled}
        shouldWrapChildren
        borderRadius={8}
        hasArrow
      >
        <Text
          textStyle="p"
          {...titleCss}
          mb={1}
        >
          {t("custom_offer.steps.offer_details.checkout")}
        </Text>

        <Checkbox
          showErrorMessage={false}
          isDisabled={isDisabled}
          ref={ref}
          {...props}
        >
          {t("custom_offer.steps.offer_details.enable_checkout_process")}
        </Checkbox>
      </Tooltip>
    </Flex>
  );
});

export default CheckoutCheckbox;
