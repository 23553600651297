import DescriptionComponent from "views/CustomOffer/components/Steps/CustomOfferSteps/Description";
import StandardDataComponent from "views/CustomOffer/components/Steps/CustomOfferSteps/StandardData";
import FinanceAndCostsComponent from "views/CustomOffer/components/Steps/CustomOfferSteps/FinanceAndCosts";
import ImagesComponent from "views/CustomOffer/components/Steps/CustomOfferSteps/Images";
import EquipmentComponent from "views/CustomOffer/components/Steps/CustomOfferSteps/Equipment";
import OfferDetailsComponent from "views/CustomOffer/components/Steps/CustomOfferSteps/OfferDetails";
import { WizardStep } from "components/Wizard/types";
import i18n from "translations/i18n";

import { CustomOfferSteps } from "./types";

export const getStepKey = (stepIndex: number): string => {
  const stepKey = Object.entries(CustomOfferSteps).find(([_, v]) => v === stepIndex)?.[0];
  return `${stepKey?.charAt(0)?.toLowerCase()}${stepKey?.slice(1)}`;
};

export const customOfferSteps: WizardStep[] = [
  {
    id: CustomOfferSteps.StandardData,
    title: i18n.t("custom_offer.steps.standard_data.title"),
    component: StandardDataComponent,
  },
  {
    id: CustomOfferSteps.Equipment,
    title: i18n.t("custom_offer.steps.equipment.title"),
    component: EquipmentComponent,
  },
  {
    id: CustomOfferSteps.Description,
    title: i18n.t("custom_offer.steps.description.title"),
    component: DescriptionComponent,
  },
  {
    id: CustomOfferSteps.Images,
    title: i18n.t("custom_offer.steps.images.title"),
    component: ImagesComponent,
  },
  {
    id: CustomOfferSteps.FinancingAndCosts,
    title: i18n.t("custom_offer.steps.financing_and_costs.title"),
    component: FinanceAndCostsComponent,
  },
  {
    id: CustomOfferSteps.OfferDetails,
    title: i18n.t("custom_offer.steps.offer_details.title"),
    component: OfferDetailsComponent,
  },
];
