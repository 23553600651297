import React from "react";
import {
  Flex,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import i18n from "translations/i18n";

import { PercentageBarProps } from "./types";
import CustomProgressBar from "./CustomProgressBar";

const LABEL_WIDTH_FACTOR = 4.8;

const getBarColor = (percentage?: number): string => {
  if (!percentage) {
    return "success";
  }

  if (percentage <= 40) {
    return "success";
  }

  if (percentage <= 70) {
    return "warning";
  }

  return "red";
};

const PercentageBar: React.FC<PercentageBarProps> = ({
  maxExceededLabel = i18n.t("budget_overview.over_budget"),
  isUnlimited = false,
  isLoading = false,
  value = 0,
  max = 0,
}) => {
  const { t } = useTranslation();

  const isOverMax = Number(value) > Number(max);

  const percentage = !isOverMax ? (value / max) * 100 : 0;

  const roundedPercentage = percentage > 0 ? percentage.toFixed(2) : 0;

  const barLabel = (function getBarLabel() {
    if (isUnlimited) {
      return t("budget_overview.unlimited");
    }

    if (isOverMax) {
      return maxExceededLabel;
    }

    return `${roundedPercentage} %`;
  }());

  const textColor = (isUnlimited || percentage >= LABEL_WIDTH_FACTOR || isOverMax) ? "white" : "black.500";

  const barColorScheme = React.useMemo(() => {
    if (isUnlimited) {
      return "success";
    }

    if (isOverMax) {
      return "red";
    }

    return getBarColor(isUnlimited ? undefined : percentage);
  }, [
    isUnlimited,
    percentage,
    isOverMax,
  ]);

  const isOverBudget = !isUnlimited && isOverMax;

  return (
    <Flex
      position="relative"
      align="center"
      w="full"
    >
      <Text
        position="absolute"
        hidden={isLoading}
        color={textColor}
        fontWeight="bold"
        textStyle="th"
        lineHeight="1"
        zIndex="docked"
        left={2.5}
      >
        {barLabel}
      </Text>

      <Skeleton
        isLoaded={!isLoading}
        borderRadius="sm"
        w="full"
      >
        <CustomProgressBar
          colorScheme={barColorScheme}
          value={(isUnlimited || isOverBudget) ? 100 : percentage}
        />
      </Skeleton>
    </Flex>
  );
};

export default PercentageBar;
