import React from "react";
import { Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import {
  CURRENT_TRANSFORMER_KILOWATT_HOUR, EURO_L, EURO_YEAR,
} from "constants/units";
import { OfferFuelTypeEnum } from "generated/graphql";

import { InnerSection } from "../../../../InnerSections";
import { FieldsCommonProps } from "..";
import { InputField } from "../shared/InputField";

export interface EnergyCostProps extends FieldsCommonProps{
  fuelType: OfferFuelTypeEnum;
  isPluginHybrid: boolean;
}

export const EnergyCost = ({
  isPluginHybrid = false,
  fuelType,
  register,
  formState,
}: EnergyCostProps): JSX.Element => {
  const { t } = useTranslation();

  const shouldRenderPowerPrice = isPluginHybrid
    && [OfferFuelTypeEnum.HybridDiesel, OfferFuelTypeEnum.Hybrid].includes(fuelType);

  return (
    <InnerSection label={t("custom_offer.steps.standard_data.fuel_card.energy_costs")}>
      <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
        <InputField
          {...register?.("fuelPrice")}
          name="fuelPrice"
          errors={formState?.errors}
          title={t("custom_offer.steps.standard_data.fuel_card.fuel_price")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_fuel_price")}
          measurementUnit={EURO_L}
        />

        {
          shouldRenderPowerPrice && (
            <InputField
              {...register?.("powerPrice")}
              name="powerPrice"
              errors={formState?.errors}
              title={t("custom_offer.steps.standard_data.fuel_card.energy_costs")}
              placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_power_price")}
              measurementUnit={CURRENT_TRANSFORMER_KILOWATT_HOUR}
            />
          )
        }

        <InputField
          {...register?.("energyCostsPerAnnualMileage")}
          name="energyCostsPerAnnualMileage"
          errors={formState?.errors}
          title={t("custom_offer.steps.standard_data.fuel_card.annual_mileage_energy_costs")}
          placeholder={t("custom_offer.steps.standard_data.fuel_card.insert_energy_costs")}
          measurementUnit={EURO_YEAR}
        />
      </Grid>
    </InnerSection>
  );
};
