import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Text,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

import SectionCard from "components/SectionCard";
import Checkbox from "components/FormComponents/Checkbox";
import DealerSelect from "components/FormComponents/Inputs/Selects/DealerSelect";
import Input from "components/FormComponents/Inputs/Input";
import DatePicker from "components/FormComponents/Inputs/DatePicker/index";

import { titleCss } from "../../styles";
import { OfferDetailsCardProps } from "./types";

const OfferDetailsCard: React.FC<OfferDetailsCardProps> = ({
  defaultFormValues,
  formState,
  setValue,
  register,
  control,
}) => {
  const [hasExpirationDate, setHasExpirationDate] = useState(!defaultFormValues?.availableUntil);
  const [expirationDate, setExpirationDate] = useState<Date | null>(
    defaultFormValues?.availableUntil
      ? new Date(defaultFormValues?.availableUntil)
      : null,
  );

  const { t } = useTranslation();

  const dateInputRef = useRef<HTMLInputElement>(null);

  const defaultDealer = {
    label: defaultFormValues?.dealer?.companyName,
    value: defaultFormValues?.dealer?.id,
  };

  const handleHasExpirationDate = useCallback(() => {
    setHasExpirationDate(!hasExpirationDate);
  }, [
    hasExpirationDate,
  ]);

  const handleExpirationDateChange = useCallback((value: Date) => {
    setExpirationDate(value);
  }, [
    setExpirationDate,
  ]);

  useEffect(() => {
    if (!hasExpirationDate) {
      return;
    }

    setExpirationDate(null);
  }, [
    hasExpirationDate,
  ]);

  useEffect(() => {
    setValue("availableUntil", expirationDate);
  }, [
    expirationDate,
    setValue,
  ]);

  return (
    <SectionCard
      title={t("custom_offer.steps.offer_details.offer_details")}
    >
      <Stack
        spacing={6}
        maxW="50%"
      >
        <HStack
          spacing={8}
        >
          <DealerSelect
            title={t("custom_offer.steps.offer_details.dealer")}
            titleCss={titleCss}
            defaultValue={defaultDealer}
            isDisabled
          />

          <Input
            title={t("custom_offer.steps.offer_details.offer_id")}
            titleCss={titleCss}
            defaultValue={defaultFormValues?.shortId}
            textTransform="uppercase"
            name="shortId"
            isDisabled
          />
        </HStack>

        <HStack spacing={8}>
          <Controller
            name="availableUntil"
            control={control}
            render={({
              field,
              fieldState,
            }) => (
              <DatePicker
                {...field}
                {...fieldState}
                title={t("custom_offer.steps.offer_details.available_until")}
                titleCss={titleCss}
                onChange={handleExpirationDateChange}
                isDisabled={hasExpirationDate}
                errors={formState.errors}
                datePickerProps={{
                  selected: (
                    !hasExpirationDate
                      ? expirationDate
                      : null
                  ),
                }}
                ref={dateInputRef}
              />
            )}
          />

          <Checkbox
            defaultChecked={!defaultFormValues?.availableUntil}
            onChange={handleHasExpirationDate}
            name="hasAvailability"
            mt={6}
          >
            {t("custom_offer.steps.offer_details.no_expiration_date")}
          </Checkbox>
        </HStack>

        <Stack>
          <Text
            textStyle="p"
            {...titleCss}
            mb={-1}
          >
            {t("custom_offer.steps.offer_details.overwrite_protection")}
          </Text>

          <Checkbox
            {...register("preventOverwrite")}
            errors={formState.errors}
            ml={-1}
          >
            {t("custom_offer.steps.offer_details.do_not_overwrite_with_the_next_import")}
          </Checkbox>
        </Stack>
      </Stack>
    </SectionCard>
  );
};

export default OfferDetailsCard;
