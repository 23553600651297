import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import { FakeLabelProps } from "./types";

const FakeTextField = (({
  label,
  value,
  fallbackValue = "-",
  innerHTML,
  ...rest
}: FakeLabelProps): JSX.Element => (
  <Flex flexDir="column" {...rest}>
    <Text fontSize={12} mb={1} color="gray.400">
      {label}
    </Text>

    {
      innerHTML
        ? (
          <Flex
            dangerouslySetInnerHTML={{ __html: innerHTML }}
            fontWeight="500"
            fontSize={16}
          />
        ) : (
          <Text fontSize={16} fontWeight="500">
            {value ?? fallbackValue}
          </Text>
        )
    }
  </Flex>
));

export default FakeTextField;
