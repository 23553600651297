import { Vehicle } from "generated/graphql";

import { getFuelTypes } from "./getFuelTypes";
import getVehicleCategories from "./getVehicleCategories";

export const parseVehiclesWithTranslations = (vehicles?: Vehicle[]): Vehicle[] | [] => {
  if (!vehicles) {
    return [];
  }

  const vehicleCategories = getVehicleCategories();
  const fuelTypes = getFuelTypes();

  const vehiclesWithTranslation = vehicles.map(value => ({
    ...value,
    categoryDescription: vehicleCategories?.find((v) => v.value === value.category)?.label || "-",
    fuelTypeDescription: fuelTypes?.find((v) => v.value === value.fuelType)?.label || "-",
  }));

  return vehiclesWithTranslation;
};
