import React from "react";
import {
  Flex,
} from "@chakra-ui/react";
import { useDebounce } from "react-use";

import { standardDataSchema, StandardDataSchema } from "settings/yup/schemas/standardDataSchema";
import { OfferFuelTypeEnum, OfferGearTypeEnum } from "generated/graphql";
import { formatNumberToGerman } from "utils/currencyFormat";
import { parseISO } from "utils/parseISO";
import { parseYearToDate } from "utils/parseYearToDate";

import useFormStep from "../../hooks/useFormStep";
import BaseDataCard from "./components/BaseDataCard";
import { FuelConsumption } from "./components/FuelConsumption";
import OfferTitleCard from "./components/OfferTitleCard";
import VehicleCard from "./components/VehicleCard";
import { StandardDataProps } from "./types";
import { getFuelConsumptionDefaultValues } from "./getFuelConsumptionDefaultValues";

const FIELD_VALIDATION_DEBOUNCE_IN_MILLISECONDS = 500;

const StandardDataForm: React.FC<StandardDataProps> = ({
  defaultFormValues,
}) => {
  const fuelConsumptionDefaultValues = getFuelConsumptionDefaultValues(defaultFormValues);

  const {
    register,
    setValue,
    control,
    formState,
    watch,
    trigger,
    clearErrors,
  } = useFormStep<StandardDataSchema>({
    schema: standardDataSchema,
    formOptions: {
      defaultValues: {
        fuelType: defaultFormValues?.fuelType,
        numberOfDoors: defaultFormValues?.numberOfDoors?.toString() || "",
        engineDisplacementCcm: defaultFormValues?.engineDisplacementCcm ?? undefined,
        isPluginHybrid: defaultFormValues?.isPluginHybrid,
        gearType: defaultFormValues?.gearType || OfferGearTypeEnum.Manual,
        powerKw: defaultFormValues?.powerKw ?? undefined,
        horsepower: defaultFormValues?.horsepower ?? undefined,
        vin: defaultFormValues?.vin || "",
        internalNumber: defaultFormValues?.internalNumber || "",
        title: defaultFormValues?.title || "",
        vehicleType: defaultFormValues?.vehicleType || undefined,
        registeredAt: parseISO(defaultFormValues?.registeredAt),
        drivenKm: defaultFormValues?.drivenKm ?? undefined,
        availability: defaultFormValues?.availability ?? undefined,
        oneDayRegistration: defaultFormValues?.oneDayRegistration,
        equipmentConfigurable: defaultFormValues?.equipmentConfigurable,
        pollutantClassDetailed: defaultFormValues?.pollutantClassDetailed,
        wltpPollutantClassDetailed: defaultFormValues?.wltpPollutantClassDetailed,
        wltpCarbonicEmissionCombined: formatNumberToGerman(
          defaultFormValues?.wltpCarbonicEmissionCombined,
        ),
        wltpCarbonicEmissionCombinedWeighted: formatNumberToGerman(
          defaultFormValues?.wltpCarbonicEmissionCombinedWeighted,
        ),
        carbonicEmission: defaultFormValues?.fuelType === OfferFuelTypeEnum.Electric
          ? "0"
          : formatNumberToGerman(defaultFormValues?.carbonicEmission),
        pollutantClass: defaultFormValues?.pollutantClass,
        wltpPollutantClass: defaultFormValues?.wltpPollutantClass,
        wltpCarbonicEmissionCombinedClass: defaultFormValues?.wltpCarbonicEmissionCombinedClass,
        wltpCarbonicEmissionCombinedWithDischargedBatteryClass: (
          defaultFormValues?.wltpCarbonicEmissionCombinedWithDischargedBatteryClass
        ),
        wltpCarbonicEmissionWeightedCombinedClass: (
          defaultFormValues?.wltpCarbonicEmissionWeightedCombinedClass
        ),
        wltpConsumptionCombinedWithDischargedBattery: formatNumberToGerman(
          defaultFormValues?.wltpConsumptionCombinedWithDischargedBattery,
        ),
        wltpConsumptionCityWithDischargedBattery: formatNumberToGerman(
          defaultFormValues?.wltpConsumptionCityWithDischargedBattery,
        ),
        wltpConsumptionSuburbanWithDischargedBattery: formatNumberToGerman(
          defaultFormValues?.wltpConsumptionSuburbanWithDischargedBattery,
        ),
        wltpConsumptionRuralWithDischargedBattery: formatNumberToGerman(
          defaultFormValues?.wltpConsumptionRuralWithDischargedBattery,
        ),
        wltpConsumptionHighwayWithDischargedBattery: formatNumberToGerman(
          defaultFormValues?.wltpConsumptionHighwayWithDischargedBattery,
        ),
        wltpPowerConsumptionCombined: formatNumberToGerman(
          defaultFormValues?.wltpPowerConsumptionCombined,
        ),
        wltpPowerConsumptionCombinedWeighted: formatNumberToGerman(
          defaultFormValues?.wltpPowerConsumptionCombinedWeighted,
        ),
        wltpPowerConsumptionCity: formatNumberToGerman(
          defaultFormValues?.wltpPowerConsumptionCity,
        ),
        wltpPowerConsumptionSuburban: formatNumberToGerman(
          defaultFormValues?.wltpPowerConsumptionSuburban,
        ),
        wltpPowerConsumptionRural: formatNumberToGerman(
          defaultFormValues?.wltpPowerConsumptionRural,
        ),
        wltpPowerConsumptionHighway: formatNumberToGerman(
          defaultFormValues?.wltpPowerConsumptionHighway,
        ),
        wltpCarbonicEmissionPriceAverage: formatNumberToGerman(
          defaultFormValues?.wltpCarbonicEmissionPriceAverage,
        ),
        wltpCarbonicEmissionPriceAverageAccumulated: formatNumberToGerman(
          defaultFormValues?.wltpCarbonicEmissionPriceAverageAccumulated,
        ),
        wltpCarbonicEmissionPriceLowAverage: formatNumberToGerman(
          defaultFormValues?.wltpCarbonicEmissionPriceLowAverage,
        ),
        wltpCarbonicEmissionPriceLowAverageAccumulated: formatNumberToGerman(
          defaultFormValues?.wltpCarbonicEmissionPriceLowAverageAccumulated,
        ),
        wltpCarbonicEmissionPriceHighAverage: formatNumberToGerman(
          defaultFormValues?.wltpCarbonicEmissionPriceHighAverage,
        ),
        wltpCarbonicEmissionPriceHighAverageAccumulated: formatNumberToGerman(
          defaultFormValues?.wltpCarbonicEmissionPriceHighAverageAccumulated,
        ),
        vehicleTax: formatNumberToGerman(defaultFormValues?.vehicleTax),
        vehicleTaxPerYear: formatNumberToGerman(defaultFormValues?.vehicleTaxPerYear),
        powerPrice: formatNumberToGerman(defaultFormValues?.powerPrice),
        fuelPrice: formatNumberToGerman(defaultFormValues?.fuelPrice),
        energyCostsPerAnnualMileage: formatNumberToGerman(
          defaultFormValues?.energyCostsPerAnnualMileage,
        ),
        wltpCarbonicEmissionPricePeriodFromYear: parseYearToDate(
          defaultFormValues?.wltpCarbonicEmissionPricePeriodFromYear,
        ),
        wltpCarbonicEmissionPricePeriodToYear: parseYearToDate(
          defaultFormValues?.wltpCarbonicEmissionPricePeriodToYear,
        ),
        ...fuelConsumptionDefaultValues,
      },
    },
  });

  // triggers these fields validation manually since react-hook-forms
  // is focused on validating each field individually
  useDebounce(() => {
    if (!formState?.errors?.pollutantClassDetailed && !formState?.errors?.pollutantClass) {
      return;
    }

    trigger(["pollutantClass", "pollutantClassDetailed"]);
  },
  FIELD_VALIDATION_DEBOUNCE_IN_MILLISECONDS,
  [
    formState?.errors?.pollutantClassDetailed,
    formState?.errors?.pollutantClass,
    trigger,
  ]);

  const props = {
    defaultFormValues,
    register,
    setValue,
    control,
    formState,
    watch,
    clearErrors,
    trigger,
  };

  return (
    <Flex
      w="full"
      h="full"
      flexDirection="column"
    >
      <BaseDataCard {...props} />

      <OfferTitleCard {...props} />

      <VehicleCard {...props} />

      <FuelConsumption {...props} />
    </Flex>
  );
};

export default StandardDataForm;
