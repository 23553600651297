import { gql } from "@apollo/client";

export const UPDATE_VEHICLE_CALCULATOR_INFO_REFETCH_QUERIES = [
  "CalculatorOffers",
];

export default gql`
  mutation UpdateVehicleCalculatorInfo (
    $customerType: CustomerTypeEnum!
    $dealerId: String!
    $vehicleId: Int!
    $params: UpdateVehicleCalculatorInfoParams!
  ) {
    updateVehicleCalculatorInfo(
      customerType: $customerType
      dealerId: $dealerId
      vehicleId: $vehicleId
      params: $params
    ) {
      id
    }
  }
`;
