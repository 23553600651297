import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const DownChevron: IconProps = createIcon({
  viewBox: "0 0 21.757 13.625",
  path: (
    <path
      fill="currentColor"
      d="M2.729,21.757A2.788,2.788,0,0,1,.8,20.968a2.711,2.711,0,0,1,0-3.838l6.261-6.261L.823,4.635A2.714,2.714,0,1,1,4.662.8l8.167,8.167a2.711,2.711,0,0,1,0,3.838L4.662,20.968A2.788,2.788,0,0,1,2.729,21.757Z"
      transform="translate(21.757 0) rotate(90)"
    />
  ),
});

export default DownChevron;
