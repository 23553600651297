import i18n from "translations/i18n";
import { OfferCustomerTypeEnum } from "generated/graphql";

export default [
  {
    value: OfferCustomerTypeEnum.PrivateOnly,
    label: i18n.t("enum.offer_customer_type.private_only"),
  },
  {
    value: OfferCustomerTypeEnum.CorporateOnly,
    label: i18n.t("enum.offer_customer_type.corporate_only"),
  },
  {
    value: OfferCustomerTypeEnum.Both,
    label: i18n.t("enum.offer_customer_type.both"),
  },
];
