import { Lead } from "generated/graphql/index";

const COMPANY_FALLBACK_LEGACY = "[nicht vorhanden]";

/**
 * Returns if lead is corporate.
 * @param {*} lead The lead
 */
const checkIsCorporateLead = (lead: Lead): boolean => {
  const leadCompany = lead?.company;

  return !!leadCompany && leadCompany !== COMPANY_FALLBACK_LEGACY;
};

export default checkIsCorporateLead;
