import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const useLimitImageSize = (): {
  handleValidateFiles: (filesList: File[]) => File[];
} => {
  const toast = useToast();
  const { t } = useTranslation();

  const isAllowedFileSize = (fileSize: number): boolean => {
    const tenMBNumber = 10485760;

    return fileSize < tenMBNumber;
  };

  const handleValidateFiles = (filesList: File[]): File[] => {
    const validFiles = filesList.filter(file => {
      const allowedSizeFile = isAllowedFileSize(file.size);
      if (!allowedSizeFile) {
        toast({
          title: t("custom_offer.steps.images.max_size_toast_title"),
          description: t("custom_offer.steps.images.max_size_information"),
          status: "error",
        });
      }

      return allowedSizeFile;
    });

    return validFiles;
  };

  return {
    handleValidateFiles,
  };
};
