import React from "react";
import {
  Flex,
  Skeleton,
} from "@chakra-ui/react";

import i18n from "translations/i18n";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";

import { BankDetailsProps } from "./types";
import { skeletonStyles } from "../styles";

const BankDetails: React.FC<BankDetailsProps> = ({
  isExpandable = false,
  isLoading,
  lead,
}) => (
  <Flex w="full" flexDir="column">
    <LeadDetailsCard
      title={i18n.t("leads.details.form.bank_details")}
      isExpandable={isExpandable}
    >
      {
        isLoading
          ? (
            <Flex flexDir="column" w="full">
              <Skeleton {...skeletonStyles.sm} />
              <Skeleton {...skeletonStyles.full} />
            </Flex>
          ) : (
            <Flex mb={30}>
              <FakeTextField
                value={lead?.accountOwner}
                label={i18n.t("leads.details.form.account_owner")}
                w="25%"
              />

              <FakeTextField
                value={lead?.iban}
                label={i18n.t("leads.details.form.iban")}
                w="25%"
              />

              <FakeTextField
                value={lead?.bicOrSwift}
                label={i18n.t("leads.details.form.bic_swift")}
              />
            </Flex>
          )
      }
    </LeadDetailsCard>
  </Flex>
);

export default BankDetails;
