import appHostnames from "constants/appHostnames";

const BETA_URI = "https://partner.null-leasing.jungsoft.io/graphql";
const PROD_URI = "https://partner.null-leasing.com/graphql";
const HOMOLOGATION_URI = "https://master.null-leasing.jungsoft.dev/graphql";

const endpoints = [
  { // development
    hostname: "localhost",
    url: process.env.REACT_APP_BACKEND_LOCAL_URI || BETA_URI,
  },
  { // homologation
    hostname: appHostnames.homologationHostname,
    url: HOMOLOGATION_URI,
  },
  { // vercel preview
    hostname: appHostnames.vercelPreviewHostname,
    url: BETA_URI,
  },
  { // beta
    hostname: "admin.null-leasing.jungsoft.io",
    url: BETA_URI,
  },
  { // production
    hostname: "admin.null-leasing.com",
    url: PROD_URI,
  },
];

const graphQLUri = endpoints.find((value) => value.hostname === appHostnames.hostname)?.url;

export default graphQLUri;
