import { gql } from "@apollo/client";

export const CREATE_BRAND_REFETCH_QUERIES = [
  "GetBrands",
];

export default gql`
  mutation CreateBrand ($params: CreateBrandParams!) {
    createBrand(params: $params) {
      id
    }
  }
`;
