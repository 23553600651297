import React from "react";
import ReactSelect from "react-select";
import { Box, Skeleton } from "@chakra-ui/react";

import { useGetDealerUsersQuery } from "generated/graphql";

import Select from "../Select";
import { DealerUsersSelectProps } from "./types";

/**
 * This component renders a select component with the dealer users.
 * It fetches the dealer users from the backend and renders them as options.
 * @param defaultValue - The default value of the select. It must be one of the values
 * in the options array
 */
const DealerUsersSelect = React.forwardRef<ReactSelect, DealerUsersSelectProps>((
  {
    defaultValue,
    placeholder,
    isDisabled,
    isLoading,
    dealerId,
    ...props
  },
  ref,
) => {
  const { data, loading } = useGetDealerUsersQuery({
    variables: {
      dealerId,
    },
    skip: !dealerId,
  });

  const options = React.useMemo(() => ([
    ...data?.dealerUsers?.map(user => ({
      label: user?.seller?.name,
      value: user?.seller?.contactId,
    })) || [],
  ]), [
    data,
  ]);

  const selectDefaultValue = options?.find(item => item?.value === defaultValue);

  if (loading) {
    return (
      <Box width="full">
        <Skeleton height="40px" />
      </Box>
    );
  }

  return (
    <Select
      {...props}
      isDisabled={loading || isDisabled || !dealerId}
      defaultValue={selectDefaultValue}
      placeholder={placeholder}
      isLoading={isLoading}
      options={options}
      ref={ref}
    />
  );
});

export default DealerUsersSelect;
