import React, { useCallback } from "react";
import ReactSelect from "react-select";

import i18n from "translations/i18n";

import { BaseSelectProps } from "./types";
import parseSelectValue from "./parseSelectValue";
import { chakraStyles, chakraComponents } from "./theme";

const BaseSelect = React.forwardRef<ReactSelect, BaseSelectProps>(({
  onChange,
  isClearable,
  isSearchable,
  styles,
  valueAsObject,
  ...props
}, ref) => {
  const { value, options } = props;

  const selectValue = parseSelectValue(value, options);

  const handleSelect = useCallback((option, action) => {
    if (onChange) {
      onChange(valueAsObject ? option : option?.value ?? null, action);
    }
  }, [onChange, valueAsObject]);

  return (
    <ReactSelect
      {...props}
      ref={ref}
      components={chakraComponents}
      styles={{ ...chakraStyles, ...styles }}
      value={selectValue}
      options={options}
      onChange={handleSelect}
      isClearable={isClearable}
      isSearchable={isSearchable}
      menuPlacement="auto"
    />
  );
});

export default BaseSelect;

BaseSelect.defaultProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: `${i18n.t("actions.search")}...`,
  valueAsObject: false,
};
