export const placeholderStyles = {
  textStyle: "p",
  color: "gray.400",
};

export const hoverCss = {
  borderColor: "secondary.500",
};

export const disabledCss = {
  backgroundColor: "gray.200",
  borderColor: "gray.400",
  pointerEvents: "none",
};

export const defaultFocusCss = {
  borderWidth: "1px",
  borderColor: "secondary.500",
};

export const invalidCss = {
  borderWidth: "1px",
  borderColor: "error.500",
};

export const addonCss = {
  backgroundColor: "initial",
  borderWidth: 0,
};
