import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const TrashCanIcon: IconProps = createIcon({
  viewBox: "0 0 36.739 38.558",
  path: (
    <>
      <path
        fill="currentColor"
        d="M35.311,2.292H25.394V1.818C25.394.275,24.113,0,22.548,0H14.4c-1.564,0-2.835.275-2.835,1.818v.474H1.689a1.693,1.693,0,0,0-1.2.49A1.669,1.669,0,0,0,0,3.969V7.208A1.671,1.671,0,0,0,.495,8.394a1.7,1.7,0,0,0,1.194.491H35.311a1.7,1.7,0,0,0,1.194-.491A1.671,1.671,0,0,0,37,7.208V3.969a1.671,1.671,0,0,0-.495-1.186A1.7,1.7,0,0,0,35.311,2.292Z"
        transform="translate(0)"
      />
      <path
        fill="currentColor"
        d="M28.256,0H1.68A1.7,1.7,0,0,0,.42.573,1.67,1.67,0,0,0,.013,1.887L2.739,22.328a3.3,3.3,0,0,0,1.1,2.057,3.351,3.351,0,0,0,2.191.832H23.911a3.351,3.351,0,0,0,2.2-.828,3.3,3.3,0,0,0,1.1-2.062L29.934,1.887a1.668,1.668,0,0,0-.41-1.319A1.7,1.7,0,0,0,28.256,0Z"
        transform="translate(3.533 14.782)"
      />
    </>
  ),
});

export default TrashCanIcon;
