import { Grid, Text } from "@chakra-ui/react";
import React from "react";

interface Props {
  label: string;
}

export const InnerSection: React.FC<Props> = ({
  label,
  children,
}) => (
  <Grid gap={4}>
    <Text
      fontWeight="bold"
      color="black.500"
      fontFamily="regular"
    >
      {label}
    </Text>

    {children}
  </Grid>
);

