import { gql } from "@apollo/client";

export const PUBLISH_OFFER_REFETCH_QUERIES = [
  "Offers",
];

export default gql`
  mutation PublishOffer($id: Int!) {
    publishOffers(offersIds: [$id])
  }
`;
