import React from "react";
import {
  Box, Text,
} from "@chakra-ui/react";

import { ToggleButtonBoxProps } from "./types";
import { checkedStyles, uncheckedStyles } from "./styles";

const ToggleButtonBox: React.FC<ToggleButtonBoxProps> = ({
  index,
  value,
  children,
  selectedValue,
  ...props
}) => {
  const borderRadiusStyles = index === 0 ? "3px 0 0 3px" : "0 3px 3px 0";
  const styles = value === selectedValue ? checkedStyles : uncheckedStyles;

  return (
    <Box
      px={1}
      d="flex"
      h="full"
      as="span"
      minW="75px"
      alignItems="center"
      justifyContent="center"
      __css={{
        ...styles,
        borderRadius: borderRadiusStyles,
      }}
      {...props}
    >
      <Text
        textStyle="p"
        userSelect="none"
        textAlign="center"
        wordBreak="break-all"
      >
        {children}
      </Text>
    </Box>
  );
};

export default ToggleButtonBox;
