const hostname = String(window?.location?.hostname || "");

const homologationRegex = /[a-z0-9 -]*.null-leasing-admin.jungsoft.dev/;
const vercelPreviewRegex = /[a-z0-9 -.]*.vercel.app/;

const homologationHostname = hostname.match(homologationRegex)?.[0];
const vercelPreviewHostname = hostname.match(vercelPreviewRegex)?.[0];

/**
 * Exports the app's current hostname & homologation hostname.
 */
const appHostnames = {
  hostname,
  homologationHostname,
  vercelPreviewHostname,
};

export default appHostnames;
