import React from "react";
import {
  Flex,
  Stack,
  Box,
  Spacer,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import { CustomerTypeEnum, OfferCustomerTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";
import TextField from "components/FormComponents/Inputs/TextField";
import Textarea from "components/FormComponents/Inputs/Textarea";
import Button from "components/Button";
import BankSelect from "components/FormComponents/Inputs/Selects/BankSelect";
import Select from "components/FormComponents/Inputs/Selects/Select";
import ModalRow from "components/Modals/EditVehicleModal/ModalRow";
import { calculatedWithFactorsOptions } from "constants/offer";
import EuroIcon from "components/Icons/EuroIcon";
import CurrencyInput from "components/FormComponents/Inputs/CurrencyInput";

import RatesTabContent from "../RatesTabContent";
import { EditVehicleFormProps } from "../types";

const EditVehicleForm: React.FC<EditVehicleFormProps> = ({
  defaultFormValues,
  vehicle,
  register,
  handleSubmit,
  handleCancel,
  submitState,
  setValue,
  getValues,
  isLoading,
  control,
  formState,
}) => {
  const customerType = OfferCustomerTypeEnum.PrivateOnly === defaultFormValues?.customerType
    ? CustomerTypeEnum.Private
    : CustomerTypeEnum.Corporate;

  const calculateWithFactorsDefaultValue = defaultFormValues?.calculateWithFactors
    ? calculatedWithFactorsOptions[0].value
    : calculatedWithFactorsOptions[1].value;

  const currencyInputDefaultProps = {
    decimalSeparator: ",",
    thousandSeparator: ".",
    fixedDecimalScale: true,
    decimalScale: 2,
    showErrorMessage: true,
    errors: formState?.errors,
    rightElement: (<EuroIcon />),
    rightElementProps: {
      color: "gray.400",
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <ModalRow mt={0} mb={4}>
        <Box minWidth="sm">
          <Flex flexDirection="column" alignItems="center">
            <Controller
              control={control}
              name="listPrice"
              render={({ field, fieldState }) => (
                <CurrencyInput
                  {...field}
                  {...fieldState}
                  {...currencyInputDefaultProps}
                  title={i18n.t("components.modals.edit_vehicle_modal.list_price")}
                />
              )}
            />

            <Spacer mb={4} />

            <Controller
              control={control}
              name="manufacturerBonusNet"
              render={({ field, fieldState }) => (
                <CurrencyInput
                  {...field}
                  {...fieldState}
                  {...currencyInputDefaultProps}
                  title={i18n.t("components.modals.edit_vehicle_modal.manufacturer_bonus")}
                />
              )}
            />

            <Spacer mb={4} />

            <Controller
              control={control}
              name="configuratorLink"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  errors={formState?.errors}
                  title={i18n.t("components.modals.edit_vehicle_modal.configurator_url")}
                  showErrorMessage
                />
              )}
            />
          </Flex>
        </Box>

        <Box w="100%">
          <Textarea
            placeholder={i18n.t("empty_data_messages.no_configuration_notes_for_this_vehicle").toString()}
            title={i18n.t("components.modals.edit_vehicle_modal.configurator_note")}
            showErrorMessage
            minHeight="203px"
            defaultValue={defaultFormValues?.configurationNotes}
            {...register("configurationNotes")}
          />
        </Box>
      </ModalRow>

      <ModalRow mt="3" mb="5" justifyContent="space-between" alignItems="center">
        <Controller
          control={control}
          name="bankId"
          render={({ field, fieldState }) => (
            <BankSelect
              {...field}
              {...fieldState}
              title={i18n.t("components.modals.edit_vehicle_modal.bank")}
              errors={formState?.errors}
              isClearable={false}
            />
          )}
        />

        <Controller
          name="calculateWithFactors"
          control={control}
          defaultValue={calculateWithFactorsDefaultValue}
          render={({
            field,
            fieldState,
          }) => (
            <Select
              {...field}
              {...fieldState}
              title={i18n.t("components.modals.edit_vehicle_modal.calculator_method")}
              options={calculatedWithFactorsOptions}
              errors={formState?.errors}
              isClearable={false}
            />
          )}
        />
      </ModalRow>

      <RatesTabContent
        vehicle={vehicle}
        type={customerType}
        register={register}
        setValue={setValue}
        getValues={getValues}
        control={control}
        formState={formState}
      />

      <Stack spacing="40px" mt="14">
        <Flex justifyContent="space-between">
          <Button
            label={i18n.t("components.buttons.cancel")}
            variant="ghost"
            onClick={handleCancel}
          />

          <Button
            type="submit"
            label={i18n.t("components.buttons.update")}
            isLoading={isLoading}
            isDisabled={!submitState}
            onClick={handleSubmit}
          />
        </Flex>
      </Stack>
    </form>
  );
};

export default EditVehicleForm;
