import { ChakraTheme, extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import fonts from "./fonts";
import fontSizes from "./fontSizes";
import sizes from "./sizes";
import textStyles from "./textStyles";
import styles from "./styles";
import shadows from "./shadows";

const theme: ChakraTheme = extendTheme({
  colors,
  fonts,
  fontSizes,
  sizes,
  textStyles,
  styles,
  shadows,
});

export default theme;
