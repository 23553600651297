import { UpdateVehicleCalculatorInfoParams } from "generated/graphql";
import { EditCalculatorOfferSchema } from "settings/yup/schemas/editCalculatorOfferSchema";
import { formatCurrencyToDecimal } from "utils/currencyFormat";

const parseValue = (value?: string): string | undefined => {
  if (!value) {
    return undefined;
  }

  return value?.replace(",", ".");
};

export const getParsedParams = (
  rawParams?: EditCalculatorOfferSchema,
): UpdateVehicleCalculatorInfoParams => ({
  dealerDiscount: {
    baseCommission: rawParams?.baseCommission
      ? formatCurrencyToDecimal(rawParams.baseCommission)
      : null,
    commissionRate: parseValue(rawParams?.commissionRate),
    degradationFactor: parseValue(rawParams?.degradationFactor),
    degradationFactorMax: parseValue(rawParams?.degradationFactorMax),
    listPriceDiscountRate: parseValue(rawParams?.listPriceDiscountRate),
  },
  deliveryPrice: {
    dealerPickupPrice: rawParams?.deliveryPrices?.[0]?.dealerPickupPrice,
    factoryPickupPrice: rawParams?.deliveryPrices?.[0]?.factoryPickupPrice,
    houseDeliveryPrice: rawParams?.deliveryPrices?.[0]?.houseDeliveryPrice,
  },
});
