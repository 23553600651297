import { OfferFuelTypeEnum } from "generated/graphql";

type DriveTypeInfo = {
  driveType?: string;
  otherEnergySources?: string;
};

export enum DriveTypeEnum {
  InternalCombustionEngine = "INTERNAL_COMBUSTION_ENGINE",
  PlugInHybrid = "PLUG_IN_HYBRID",
  ElectricMotor = "ELECTRIC_MOTOR",
  FuelCell = "FUEL_CELL",
  None = "NONE"
}

export enum OtherEnergySourcesEnum {
  Electricity = "ELECTRICITY",
  Hydrogen = "HYDROGEN",
  None = "NONE"
}

const defaultCombustionEngineInfo: DriveTypeInfo = {
  driveType: DriveTypeEnum.InternalCombustionEngine,
  otherEnergySources: OtherEnergySourcesEnum.None,
};

const defaultPlugInHybridInfo: DriveTypeInfo = {
  driveType: DriveTypeEnum.PlugInHybrid,
  otherEnergySources: OtherEnergySourcesEnum.Electricity,
};

const driveTypeMapping: Record<OfferFuelTypeEnum, (isPlugin?: boolean) => DriveTypeInfo> = {
  PETROL: () => defaultCombustionEngineInfo,
  HYBRID_DIESEL: (isPlugin) => (isPlugin ? defaultPlugInHybridInfo : defaultCombustionEngineInfo),
  DIESEL: () => defaultCombustionEngineInfo,
  HYBRID: (isPlugin) => (isPlugin ? defaultPlugInHybridInfo : defaultCombustionEngineInfo),
  LPG: () => defaultCombustionEngineInfo,
  NATURAL_GAS: () => defaultCombustionEngineInfo,
  ELECTRIC: () => (
    {
      driveType: DriveTypeEnum.ElectricMotor,
      otherEnergySources: OtherEnergySourcesEnum.Electricity,
    }
  ),
  HYDROGEN: () => (
    { driveType: DriveTypeEnum.FuelCell, otherEnergySources: OtherEnergySourcesEnum.Hydrogen }
  ),
  ETHANOL: () => (
    { driveType: DriveTypeEnum.None, otherEnergySources: OtherEnergySourcesEnum.None }
  ),
  OTHER: () => ({ driveType: DriveTypeEnum.None, otherEnergySources: OtherEnergySourcesEnum.None }),
};

export const getDriveType = (
  fuelType?: OfferFuelTypeEnum,
  isPluginHybrid? :boolean,
): DriveTypeInfo => (
  fuelType ? driveTypeMapping[fuelType](isPluginHybrid) : driveTypeMapping.OTHER()
);

