import React from "react";
import { Flex, Stack } from "@chakra-ui/react";

import NullLeasingMobileDeLogo from "components/Logo/NullLeasingMobileDeLogo";

const AuthLayout: React.FC = ({ children }) => (
  <Flex minH="100vh" align="center" justify="center">
    <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
      <Stack align="center" h="fit-content">
        <NullLeasingMobileDeLogo w="300px" h="auto" />
      </Stack>

      <Flex flexDir="column" w="md">
        {children}
      </Flex>
    </Stack>
  </Flex>
);

export default AuthLayout;
