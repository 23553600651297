import React from "react";
import { GridItem } from "@chakra-ui/react";

import BaseVehiclesTable from "views/BaseVehicles/BaseVehiclesTable";
import { Container } from "components/Container";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";
import {
  useGetBaseVehiclesQuery,
  Vehicle,
  VehicleSortFieldsEnum,
} from "generated/graphql";
import useSorting from "hooks/useSorting";
import { usePagination } from "contexts/pagination";
import { parseVehiclesWithTranslations } from "utils/getVehiclesWithTranslation";

const BaseVehicles: React.FC = () => {
  const { paginationLimit } = usePagination();

  const {
    renderFilters,
    brandId,
    modelGroup,
    model,
    vehicleCategory,
    hasResidualInformation,
    search,
  } = useFilters([
    Filters.Vehicle,
    Filters.Search,
    Filters.VehicleCategory,
    Filters.HasResidualInformation,
    Filters.Search,
  ]);

  const {
    field,
    order,
  } = useSorting({ defaultField: VehicleSortFieldsEnum.Id });

  const { data, loading } = useGetBaseVehiclesQuery({
    variables: {
      filters: {
        brandId,
        category: vehicleCategory,
        hasDynamicLeasingRates: hasResidualInformation,
        modelGroup,
        model,
      },
      search,
      pagination: {
        limit: paginationLimit,
        orderBy: (
          field
            ? [{
              field: field as VehicleSortFieldsEnum,
              order,
            }]
            : undefined
        ),
      },
    },
  });

  const vehicles: Vehicle[] = data?.vehicles as Vehicle[] || [];

  const vehiclesWithTranslation = parseVehiclesWithTranslations(vehicles);

  return (
    <GridItem colSpan={6}>
      <Container alignCenter>
        {renderFilters}

        <BaseVehiclesTable
          vehicles={vehiclesWithTranslation}
          loading={loading}
        />
      </Container>
    </GridItem>
  );
};

export default BaseVehicles;
