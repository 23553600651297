import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import withRouterSessionValidator from "hocs/withRouterSessionValidator";
import RouteContainer from "contexts/route/RouteContainer";
import AppWrapper from "components/AppWrapper";
import appRoutes from "routes/appRoutes";
import NotFound from "views/NotFound";
import { RouteProps } from "contexts/route/types";
import { OFFERS_PAGE_PATH, ROOT_PAGE_PATH } from "routes";
import { useSession } from "contexts/session";

const AppRouter: React.FC = () => {
  const { isAuthenticated } = useSession();

  return (
    <Switch>
      {
        appRoutes.map((routeProps: RouteProps) => {
          const Component = routeProps.component;

          const isSignInRoute = routeProps.path === ROOT_PAGE_PATH;

          const isLoggedInSignIn = isSignInRoute && isAuthenticated;

          return (
            <Route
              strict={routeProps?.strict}
              exact={routeProps?.exact}
              path={routeProps?.path}
              key={routeProps?.path}
            >
              {
                isLoggedInSignIn
                  ? <Redirect to={OFFERS_PAGE_PATH} exact />
                  : (
                    <RouteContainer routeProps={routeProps}>
                      <AppWrapper
                        layout={routeProps?.layout}
                        title={routeProps.label as string | undefined}
                        Icon={routeProps.Icon as React.FC | undefined}
                        footerVariant={routeProps?.footerVariant}
                      >
                        <Component />
                      </AppWrapper>
                    </RouteContainer>
                  )
              }
            </Route>
          );
        })
      }
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouterSessionValidator(AppRouter);
