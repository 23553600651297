import React from "react";
import {
  Control, Controller, FormState, UseFormRegister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@chakra-ui/react";

import Select from "components/FormComponents/Inputs/Selects/Select";
import Input from "components/FormComponents/Inputs/Input";
import { StandardDataSchema } from "settings/yup/schemas/standardDataSchema";
import { titleCss } from "views/CustomOffer/components/Steps/CustomOfferSteps/styles";
import { germanNumberMask } from "constants/inputMasks";
import { ENERGY_CONSUMPTION, HOUR } from "constants/units";
import { OfferFuelTypeEnum } from "generated/graphql";

import { makeBatteryTypeOptions } from "../../../../../makeOptions";

interface Props {
  control: Control<StandardDataSchema>;
  formState: FormState<StandardDataSchema>;
  register: UseFormRegister<StandardDataSchema>;
  battery: StandardDataSchema["battery"];
  fuelType: OfferFuelTypeEnum;
}

export const Fields = ({
  control,
  formState,
  register,
  battery,
}: Props):JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      w="full"
      gap={4}
    >
      <Controller
        name="battery.type"
        control={control}
        render={({
          field,
          fieldState,
        }) => (
          <Select
            {...field}
            {...fieldState}
            placeholder={t("custom_offer.steps.standard_data.fuel_card.battery_type_placeholder")}
            title={t("custom_offer.steps.standard_data.fuel_card.battery_type")}
            options={makeBatteryTypeOptions()}
            errors={formState.errors}
            titleCss={titleCss}
          />
        )}
      />

      <Input
        {...register("battery.capacityKwh")}
        placeholder={t("custom_offer.steps.standard_data.fuel_card.battery_capacity_placeholder")}
        title={t("custom_offer.steps.standard_data.fuel_card.battery_capacity")}
        rightAddon={(<span>{ENERGY_CONSUMPTION}</span>)}
        errors={formState.errors}
        titleCss={titleCss}
        mask={germanNumberMask}
        rightAddonProps={{
          color: "gray.400",
        }}
      />

      <Input
        {...register("battery.chargeTimeHour")}
        placeholder={t("custom_offer.steps.standard_data.fuel_card.charge_time_placeholder")}
        title={t("custom_offer.steps.standard_data.fuel_card.charge_time")}
        rightAddon={(<span>{HOUR}</span>)}
        defaultValue={battery?.chargeTimeHour}
        errors={formState.errors}
        titleCss={titleCss}
        mask={germanNumberMask}
        rightAddonProps={{
          color: "gray.400",
        }}
      />

      <Input
        {...register("battery.chargeTimeFastHour")}
        placeholder={t("custom_offer.steps.standard_data.fuel_card.fast_charge_time_placeholder")}
        title={t("custom_offer.steps.standard_data.fuel_card.fast_charge_time")}
        rightAddon={(<span>{HOUR}</span>)}
        defaultValue={battery?.chargeTimeFastHour}
        errors={formState.errors}
        titleCss={titleCss}
        mask={germanNumberMask}
        rightAddonProps={{
          color: "gray.400",
        }}
      />

      <Input
        {...register("battery.chargeTimeFastAcHour")}
        placeholder={t("custom_offer.steps.standard_data.fuel_card.fast_ac_charge_time_placeholder")}
        title={t("custom_offer.steps.standard_data.fuel_card.fast_ac_charge_time")}
        rightAddon={(<span>{HOUR}</span>)}
        defaultValue={battery?.chargeTimeFastAcHour}
        errors={formState.errors}
        titleCss={titleCss}
        mask={germanNumberMask}
        rightAddonProps={{
          color: "gray.400",
        }}
      />
    </Grid>
  );
};
