/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { components as selectComponents } from "react-select";
import {
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Divider,
  CloseButton,
  Box,
  Portal,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  useTheme,
} from "@chakra-ui/react";

export const chakraStyles = {
  container: (provided: any, props: any) => {
    const chakraTheme = useTheme();

    return {
      ...provided,
      width: "100%",
      whiteSpace: "nowrap",
      borderColor: (
        props?.selectProps?.isInvalid
          ? chakraTheme.colors.error[500]
          : props?.selectProps?.value ? chakraTheme.colors.black[700] : chakraTheme.colors.gray[300]
      ),
    };
  },
  input: (provided: any) => ({
    ...provided,
    color: "inherit",
    lineHeight: 1,
  }),
  menu: (provided: any, props: any) => ({
    ...provided,
    ...props?.selectProps?.isAddon ? { width: 200 } : {},
    boxShadow: "none",
    zIndex: 10,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: 14,
    padding: "0.125rem 1rem",
  }),
};

export const chakraComponents = {
  Control: ({
    children,
    innerRef,
    innerProps,
    isDisabled,
    isFocused,
    selectProps,
  }: any) => {
    const inputStyles = useMultiStyleConfig("Input", innerProps);

    return (
      <StylesProvider value={inputStyles}>
        <Flex
          ref={innerRef}
          sx={{
            ...inputStyles.field,
            p: 0,
            overflow: "hidden",
            h: "auto",
            minH: 10,
            borderWidth: 1,
            boxShadow: "none",
            "&:hover": {
              borderColor: "black.700",
            },
            ...(
              selectProps?.isAddon
                ? {
                  borderTopRightRadius: "initial",
                  borderBottomRightRadius: "initial",
                }
                : {}
            ),
          }}
          {...innerProps}
          {...(isFocused && { "data-focus": true })}
          {...(isDisabled && { disabled: true })}
        >
          {children}
        </Flex>
      </StylesProvider>
    );
  },
  MultiValueContainer: ({
    children,
    innerRef,
    innerProps,
    data: { isFixed },
  }: any) => (
    <Tag
      ref={innerRef}
      {...innerProps}
      m="0.125rem"
      variant={isFixed ? "solid" : "subtle"}
    >
      {children}
    </Tag>
  ),
  MultiValueLabel: ({ children, innerRef, innerProps }: any) => (
    <TagLabel ref={innerRef} {...innerProps}>
      {children}
    </TagLabel>
  ),
  MultiValueRemove: ({
    children,
    innerRef,
    innerProps,
    data: { isFixed },
  }: any) => {
    if (isFixed) {
      return null;
    }

    return (
      <TagCloseButton ref={innerRef} {...innerProps}>
        {children}
      </TagCloseButton>
    );
  },
  IndicatorSeparator: ({ innerRef, innerProps }: any) => (
    <Divider
      ref={innerRef}
      {...innerProps}
      orientation="vertical"
      opacity="1"
    />
  ),
  ClearIndicator: ({ innerRef, innerProps }: any) => (
    <CloseButton ref={innerRef} {...innerProps} size="sm" mx={2} />
  ),
  MenuPortal: ({ innerRef, innerProps, children }: any) => (
    <Portal ref={innerRef} {...innerProps}>
      {children}
    </Portal>
  ),
  Menu: ({
    children,
    ...props
  }: any) => {
    const menuStyles = useMultiStyleConfig("Menu", props);
    return (
      <selectComponents.Menu {...props}>
        <StylesProvider value={menuStyles}>{children}</StylesProvider>
      </selectComponents.Menu>
    );
  },
  MenuList: ({
    innerRef,
    innerProps,
    children,
    maxHeight,
  }: any) => {
    const { list } = useStyles();
    return (
      <Box
        sx={{
          ...list,
          boxShadow: "lg",
          maxH: `${maxHeight}px`,
          overflowY: "auto",
        }}
        ref={innerRef}
        {...innerProps}
      >
        {children}
      </Box>
    );
  },
  GroupHeading: ({ innerProps, children }: any) => {
    const { groupTitle } = useStyles();
    return (
      <Box sx={groupTitle} {...innerProps}>
        {children}
      </Box>
    );
  },
};
