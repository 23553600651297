import React from "react";

import { CustomerTypeEnum } from "generated/graphql";
import { MAP_CUSTOMER_TYPE } from "components/Modals/EditVehicleModal/constants";
import { CustomerTabsProps } from "components/Modals/EditVehicleModal/types";
import Tabs from "components/Tabs";

import CustomerTabContent from "./CustomerTabContent";

export const CustomerTabs: React.FC<CustomerTabsProps> = ({
  vehicle,
  register,
  setValue,
  getValues,
}) => (
  <Tabs
    boxShadow="lg"
    tabs={[
      {
        label: MAP_CUSTOMER_TYPE.PRIVATE,
        index: 1,
        content: (
          <CustomerTabContent
            vehicle={vehicle}
            type={CustomerTypeEnum.Private}
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        ),
      },
      {
        label: MAP_CUSTOMER_TYPE.CORPORATE,
        index: 2,
        content: (
          <CustomerTabContent
            vehicle={vehicle}
            type={CustomerTypeEnum.Corporate}
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        ),
      },
    ]}
  />
);

export default CustomerTabs;
