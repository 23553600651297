import React, { useCallback } from "react";
import {
  Text,
  Stack,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import SectionCard from "components/SectionCard";
import Checkbox from "components/FormComponents/Checkbox";
import Button from "components/Button";
import { useModal } from "contexts/modal";
import { OfferCreationTypeEnum, OfferCustomerTypeEnum } from "generated/graphql";
import useCustomOfferStore from "views/CustomOffer/store";

import { AdminOptionsCardProps } from "./types";
import { titleCss } from "../../styles";
import EditVehicleModal from "../modals/EditVehicleModal";
import CheckoutCheckbox from "./CheckoutCheckbox";

const AdminOptionsCard: React.FC<AdminOptionsCardProps> = ({
  defaultFormValues,
  formState,
  register,
}) => {
  const { t } = useTranslation();

  const [showModal] = useModal();

  const handleClick = useCallback((row) => () => showModal({
    componentProps: {
      data: row,
    },
    size: "6xl",
    component: EditVehicleModal,
    isCloseable: false,
    isCentered: false,
  }), [
    showModal,
  ]);

  const { offer } = useCustomOfferStore(state => ({
    offer: state?.offer,
  }));

  const isCustomerTypeBoth = offer?.customerType === OfferCustomerTypeEnum.Both;

  const isCalculator = defaultFormValues?.creationType === OfferCreationTypeEnum.Calculator;

  return (
    <SectionCard
      title={t("custom_offer.steps.offer_details.admin_settings")}
    >
      <Stack
        spacing={6}
        maxW="60%"
      >
        <Text
          {...titleCss}
          textStyle="p"
        >
          {t("custom_offer.steps.offer_details.offer_types")}
        </Text>

        <Flex mt="0 !important" alignItems="center" flexDir="column" gridGap={2}>
          <Checkbox
            {...register("topOffer")}
            errors={formState.errors}
            padding={0}
            showErrorMessage={false}
          >
            {t("custom_offer.steps.offer_details.top_offer")}
          </Checkbox>

          <Checkbox
            {...register("marketingOffer")}
            errors={formState.errors}
            padding={0}
            showErrorMessage={false}
          >
            {
              t("custom_offer.steps.offer_details.marketing_offer")
            }
          </Checkbox>

          <Checkbox
            {...register("offerOfTheDay")}
            errors={formState.errors}
            showErrorMessage={false}
            padding={0}
          >
            {
              t("custom_offer.steps.offer_details.offer_of_the_day")
            }
          </Checkbox>

          <Checkbox
            {...register("hideInSearch")}
            errors={formState.errors}
            showErrorMessage={false}
            padding={0}
          >
            {
              t("custom_offer.steps.offer_details.hide_in_search")
            }
          </Checkbox>

          <Tooltip
            label={t("custom_offer.steps.offer_details.only_available_for_calculator_offers")}
            isDisabled={isCalculator}
            placement="bottom-start"
            hasArrow
          >
            <Flex alignSelf="flex-start">
              <Checkbox
                {...register("isNewConfiguratorEnabled")}
                errors={formState.errors}
                isDisabled={!isCalculator}
                showErrorMessage={false}
                padding={0}
              >
                {
                  t("custom_offer.steps.offer_details.enable_new_configurator")
                }
              </Checkbox>
            </Flex>
          </Tooltip>
        </Flex>

        {
          !isCustomerTypeBoth && (
            <Button
              colorScheme="secondary"
              label="Create individual calc data"
              onClick={handleClick(offer)}
              textStyle="p"
              maxW="200px"
              w="full"
            />
          )
        }

        <CheckoutCheckbox
          {...register("isCheckout")}
          defaultChecked={isCalculator}
          isDisabled={isCalculator}
          errors={formState.errors}
        />
      </Stack>
    </SectionCard>
  );
};

export default AdminOptionsCard;
