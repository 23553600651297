import { OptionProps } from "components/FormComponents/Inputs/Selects/BaseSelect/types";
import { VehicleFuelTypeEnum } from "generated/graphql";
import i18n from "translations/i18n";

export const getFuelTypes = (): OptionProps[] | [] => {
  const fuelTypes = Object.values(VehicleFuelTypeEnum);

  const categoriesOptions = fuelTypes.map(value => ({
    value,
    label: i18n.t(`fuel_types.${value}`),
  }));

  return categoriesOptions ?? [];
};
