import React from "react";
import {
  Checkbox, Stack, useTheme,
} from "@chakra-ui/react";
import { Props as ReactSelectProps } from "react-select/src/Select";
import {
  Controller, UseFormRegister, Control, FormState,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import Select from "components/FormComponents/Inputs/Selects/Select";
import { StandardDataSchema } from "settings/yup/schemas/standardDataSchema";
import { OfferFuelTypeEnum } from "generated/graphql";
import useCustomOfferStore from "views/CustomOffer/store";
import Input from "components/FormComponents/Inputs/Input";

import { makeOfferTypeOptions } from "../makeOptions";
import { titleCss } from "../../styles";
import { shouldRenderNonEditableFields } from "../utils/shouldRenderNonEditableFields";
import { getDriveType } from "../utils/getDriveType";

interface Props {
  isHybrid?: boolean;
  fuelType?: OfferFuelTypeEnum;
  register: UseFormRegister<StandardDataSchema>;
  control: Control<StandardDataSchema>;
  formState: FormState<StandardDataSchema>;
}

export const FuelTypeFields = ({
  isHybrid = false,
  fuelType,
  register,
  control,
  formState,
}: Props): JSX.Element => {
  const chakraTheme = useTheme();
  const { t } = useTranslation();
  const { wizardStepStore } = useCustomOfferStore();

  const data = wizardStepStore?.getValues?.();

  const isPluginHybrid = data?.isPluginHybrid;

  const shouldRenderDriveTypeAndOtherEnergySources = shouldRenderNonEditableFields(
    data?.vehicleType, data?.registeredAt, data?.drivenKm,
  );

  const {
    driveType,
    otherEnergySources,
  } = getDriveType(data?.fuelType, isPluginHybrid);

  return (
    <Stack
      direction="row"
      w="full"
      mb="6"
      spacing={4}
    >
      <Controller
        name="fuelType"
        control={control}
        defaultValue={fuelType}
        render={({
          field,
          fieldState,
        }) => (
          <Select
            {...field}
            {...fieldState}
            titleCss={titleCss}
            options={makeOfferTypeOptions()}
            errors={formState?.errors}
            title={t("custom_offer.steps.standard_data.base_data_card.fuel_type")}
            placeholder={t("custom_offer.steps.standard_data.base_data_card.select_fuel_type")}
            formControlCss={{ width: "unset" }}
            styles={{
              container: (
                styles: ReactSelectProps,
                props: ReactSelectProps,
              ): ReactSelectProps => ({
                ...styles,
                width: "300px",
                maxW: "300px",
                whiteSpace: "nowrap",
                borderColor: (
                  props?.selectProps?.isInvalid
                    ? chakraTheme.colors.error[500]
                    : props?.selectProps?.value
                      ? chakraTheme.colors.black[700]
                      : chakraTheme.colors.gray[300]
                ),
              }),
            }}
          />
        )}
      />
      {!!isHybrid && (
        <Checkbox
          {...register?.("isPluginHybrid")}
          style={{ marginTop: 30, marginLeft: 16, width: "100%" }}
          whiteSpace="nowrap"
          checked={isPluginHybrid}
        >
          {t("custom_offer.steps.standard_data.base_data_card.plug_in")}
        </Checkbox>
      )}

      {
        shouldRenderDriveTypeAndOtherEnergySources && (
          <>
            <Input
              title="Drive Type"
              titleCss={titleCss}
              placeholder="-"
              w="300px"
              disabled
              formControlCss={{ width: "unset" }}
              name="driveType"
              value={t<string>(`drive_types.${driveType}`)}
            />

            <Input
              title="Other Energy Sources"
              titleCss={titleCss}
              placeholder="-"
              w="300px"
              disabled
              name="otherEnergySources"
              value={t<string>(`energy_sources.${otherEnergySources}`)}
            />
          </>
        )
      }
    </Stack>
  );
};
