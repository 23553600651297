import * as CSS from "csstype";

interface getPointerStylesResult {
  cursorStyles: string;
  pointerEvents: CSS.Property.PointerEvents;
}

export function getPointerStyles(
  isReadOnly?: boolean,
  isDisabled?: boolean,
): getPointerStylesResult {
  const isPointerDisabled = isDisabled || isReadOnly;

  return {
    cursorStyles: isReadOnly ? "default" : "pointer",
    pointerEvents: isPointerDisabled ? "none" : "all",
  };
}
