import i18n from "translations/i18n";
import { OfferCreationTypeEnum } from "generated/graphql";

export default [
  {
    value: OfferCreationTypeEnum.Calculator,
    label: i18n.t("enum.creation_type.calculator"),
  },
  {
    value: OfferCreationTypeEnum.Importer,
    label: i18n.t("enum.creation_type.importer"),
  },
  {
    value: OfferCreationTypeEnum.Manual,
    label: i18n.t("enum.creation_type.manual"),
  },
];
