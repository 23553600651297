import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";
import { DealerInvoice } from "generated/graphql";
import { BillingTableProps } from "views/Leads/types";

import { makeTableColumns } from "./makeTableColumns";

const BillingTable: React.FC<BillingTableProps> = ({
  data = [] as DealerInvoice[],
  isLoading = true,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(() => makeTableColumns(), []);

  return (
    <Table
      columns={columns}
      data={data}
      emptyDataProps={{
        message: t("empty_data_messages.no_billing_leads_were_found"),
        isDataLoading: isLoading,
      }}
    />
  );
};

export default BillingTable;
