import React, { useState, useEffect } from "react";
import { Flex, useToast } from "@chakra-ui/react";
import { StringParam, useQueryParams } from "use-query-params";
import { useTranslation } from "react-i18next";

import {
  DealerInvoice,
  GetDealerLeadsInvoicesQueryVariables,
  useGetDealerLeadsInvoicesQuery,
} from "generated/graphql";
import { Container } from "components/Container";
import { AlertBox } from "components/AlertBox";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";

import BillingTable from "./BillingTable";

const BillingLeads: React.FC = () => {
  const newToast = useToast();
  const { t } = useTranslation();
  const [variables, setVariables] = useState<GetDealerLeadsInvoicesQueryVariables>();

  const [{ dealerId }] = useQueryParams({ dealerId: StringParam });

  const { renderFilters, period } = useFilters([Filters.Period]);

  useEffect(() => {
    const isValidPeriod = period && !!period[1];

    setVariables({
      dealerId: dealerId ?? "",
      period: isValidPeriod ? {
        startDate: isValidPeriod && period?.[0],
        endDate: isValidPeriod && period?.[1],
      } : undefined,
    });
  }, [
    dealerId,
    period,
  ]);

  const { data, error, loading } = useGetDealerLeadsInvoicesQuery({
    variables: {
      ...variables,
      dealerId: dealerId ?? "",
    },
    skip: !dealerId,
  });

  if (error) {
    newToast({
      title: error?.name,
      description: error?.message,
      status: "error",
    });
  }

  if (!dealerId) {
    return (
      <AlertBox
        label={t("empty_data_messages.select_a_dealer")}
        height="86px"
      />
    );
  }

  return (
    <Container
      paddingBottom="0"
      paddingTop="0"
      paddingX="0"
      alignCenter
      padding="0"
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        padding="6"
      >
        {renderFilters}
      </Flex>
      <BillingTable
        data={data?.dealerLeadsInvoices as DealerInvoice[] || []}
        isLoading={loading}
      />
    </Container>
  );
};

export default BillingLeads;
