import React from "react";

import CompanyInformation from "../CompanyInformation";
import ManagingDirectorInformation from "../ManagingDirectorInformation";
import BankDetails from "../BankDetails";
import { CorporateLeadProps } from "./types";

const CorporateLead: React.FC<CorporateLeadProps> = ({
  isLoading,
  lead,
}) => (
  <>
    <CompanyInformation
      isLoading={isLoading}
      lead={lead}
    />

    <ManagingDirectorInformation
      isLoading={isLoading}
      lead={lead}
    />

    <BankDetails
      isLoading={isLoading}
      isExpandable
      lead={lead}
    />
  </>
);

export default CorporateLead;
