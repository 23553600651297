import React, { useMemo, useState } from "react";
import {
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";

import { iconsMap } from "constants/icons";
import useDownloadFile from "hooks/useDownloadFile";
import { FileTypeEnum } from "generated/graphql";
import trackGAEvent from "utils/trackGAEvent";
import gaEvents from "constants/gaEvents";
import getLeadCustomerType from "utils/getLeadCustomerType";

import { DocumentAccordionProps } from "./types";
import DocumentAccordionItem from "./DocumentAccordionItem";
import UploadButton from "./UploadButton";
import DownloadButton from "./DownloadButton";

const DocumentAccordion: React.FC<DocumentAccordionProps> = ({
  fileType,
  documents,
  lead,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const downloadFile = useDownloadFile();

  const documentsQuantity = useMemo(() => {
    if (Array.isArray(documents)) {
      return documents.length;
    }

    return documents ? 1 : 0;
  }, [documents]);

  const hasDocuments = useMemo(() => documentsQuantity > 0, [documentsQuantity]);

  const downloadDocument = async (id: number): Promise<void> => {
    if (fileType.type === FileTypeEnum.ClientContract) {
      const customerType = getLeadCustomerType(lead);

      trackGAEvent(gaEvents.signedContractDownloadedByDealer(customerType));
    }

    await downloadFile(id);
  };

  const downloadLastDocument = async (): Promise<void> => {
    if (typeof documents === "string") {
      window.open(documents, "_blank");
      return;
    }

    const id = documents?.[0]?.id;
    await downloadDocument(id);
  };

  const toggleIsOpen = (): void => {
    if (hasDocuments) {
      setIsOpen(prevIsOpen => !prevIsOpen);
    }
  };

  const RenderIcon = (): JSX.Element => {
    if (fileType.isVersionable && isOpen && hasDocuments) {
      return (
        <iconsMap.UpChevron
          cursor="pointer"
          color="gray.500"
        />
      );
    } if (fileType.isVersionable && (!isOpen || !hasDocuments)) {
      return (
        <iconsMap.DownChevron
          cursor={hasDocuments ? "pointer" : "default"}
          color={hasDocuments ? "gray.500" : "gray.200"}
        />
      );
    }

    return (
      <iconsMap.DashIcon color="gray.200" />
    );
  };

  const RenderDownloadOrUploadButton = (): JSX.Element => {
    if (fileType.canUpload) {
      return (
        <UploadButton
          lead={lead}
          fileType={fileType}
        />
      );
    }

    return (
      <DownloadButton
        onClick={downloadLastDocument}
        disabled={!hasDocuments}
      />
    );
  };

  return (
    <Flex
      borderWidth={1}
      borderStyle="solid"
      borderColor={isOpen ? "secondary.500" : "gray.400"}
      borderRadius={8}
      direction="column"
      mb={4}
      opacity={hasDocuments || fileType.type === FileTypeEnum.HandlerContract ? "100%" : "80%"}
    >
      <Flex
        alignItems="center"
      >
        <Flex
          alignItems="center"
          width="100%"
          py={4}
          px={4}
          cursor={hasDocuments ? "pointer" : "default"}
          onClick={toggleIsOpen}
        >
          <RenderIcon />

          <Text
            fontSize={14}
            fontWeight="600"
            ml={4}
            color={hasDocuments ? "gray.500" : "gray.400"}
          >
            {fileType.label}
          </Text>
        </Flex>

        <Flex
          alignItems="center"
          marginLeft="auto"
          mr={4}
        >
          <Text
            color={hasDocuments ? "success.500" : "error.500"}
            mr={16}
          >
            {documentsQuantity}
          </Text>

          <RenderDownloadOrUploadButton />
        </Flex>
      </Flex>

      {
        isOpen
        && Array.isArray(documents)
        && hasDocuments
        && (
          <Box mt={2}>
            {
              documents.map(document => (
                <DocumentAccordionItem
                  key={document.file}
                  document={document}
                  downloadDocument={downloadDocument}
                  fileType={fileType}
                  lead={lead}
                />
              ))
            }
          </Box>
        )
      }
    </Flex>
  );
};

export default DocumentAccordion;
