import { Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { EditLeasingRatesEnum } from "settings/yup/schemas/editLeasingRatesSchema";
import SectionCard from "components/SectionCard";

import { titleCss } from "../../styles";
import { MileagePricesProps } from "../../types";
import CustomMonetaryField from "../Fields/CustomMonetaryField";

const MileagePrices: React.FC<MileagePricesProps> = ({
  offer,
  setValue,
}) => {
  const { t } = useTranslation();

  return (
    <SectionCard
      title={t("components.modals.edit_leasing_rates_modal.mileage_prices")}
    >
      <Flex justifyContent="space-between">
        <Stack w="lg" pr={10}>
          <CustomMonetaryField
            isCent
            netTitle={t("components.modals.edit_leasing_rates_modal.extra_price_per_kilometer_net")}
            grossTitle={t("components.modals.edit_leasing_rates_modal.extra_price_per_kilometer_gross")}
            titleCss={titleCss}
            name={EditLeasingRatesEnum.extraPricePerHundredKilometersNet}
            defaultValue={offer?.extraPricePerHundredKilometersNet}
            setValue={setValue}
          />
        </Stack>

        <Stack w="lg">
          <CustomMonetaryField
            isCent
            netTitle={t("components.modals.edit_leasing_rates_modal.discount_per_kilometer_net")}
            grossTitle={t("components.modals.edit_leasing_rates_modal.discount_per_kilometer_gross")}
            titleCss={titleCss}
            name={EditLeasingRatesEnum.discountPerHundredKilometersNet}
            defaultValue={offer?.discountPerHundredKilometersNet}
            setValue={setValue}
          />
        </Stack>
      </Flex>

    </SectionCard>
  );
};

export default MileagePrices;
