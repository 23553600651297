import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const BillingIcon: IconProps = createIcon({
  viewBox: "0 0 20 21",
  path: (
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833252 10.5924C0.833252 5.52878 4.93625 1.42578 9.99992 1.42578C15.0624 1.42578 19.1666 5.52878 19.1666 10.5924C19.1666 15.6561 15.0624 19.7591 9.99992 19.7591C4.93625 19.7591 0.833252 15.6561 0.833252 10.5924ZM2.91659 10.5924C2.91659 14.5048 6.08756 17.6758 9.99992 17.6758C13.9111 17.6758 17.0833 14.5048 17.0833 10.5924C17.0833 6.68009 13.9111 3.50911 9.99992 3.50911C6.08756 3.50911 2.91659 6.68009 2.91659 10.5924ZM12.636 13.0035L13.3333 14.6069C13.1735 14.7475 12.4036 15.6758 10.3699 15.6758C8.35084 15.6758 6.85466 14.5787 6.34625 12.3143H5.41658V11.0063H6.15741C6.15741 11.0063 6.14288 10.7953 6.14288 10.5984V10.2187H5.41658V8.92473H6.3753C6.59319 7.91207 7.1597 7.02599 7.84243 6.49153C8.52515 5.94301 9.36766 5.67578 10.4281 5.67578C11.7209 5.67578 12.6941 6.11179 13.0282 6.32276L12.4907 8.10897C12.2293 7.86987 11.5901 7.40574 10.5733 7.40574C9.55649 7.40574 8.93188 7.91207 8.74304 8.92473H11.532V10.2187H8.48157C8.48157 10.2187 8.46704 10.4296 8.46704 10.5984C8.46704 10.7672 8.48157 11.0063 8.48157 11.0063H11.532V12.3143H8.74304C8.87377 13.2567 9.54197 13.8474 10.5588 13.8474C11.5756 13.8474 12.2293 13.341 12.636 13.0035Z"
      fill="currentColor"
    />
  ),
});

export default BillingIcon;
