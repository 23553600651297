import i18n from "translations/i18n";

const locale = {
  string: {
    email: i18n.t("yup.string_email"),
    max: i18n.t("yup.string_max"),
    min: i18n.t("yup.string_min"),
  },
};

export default locale;
