import { TestConfig } from "yup";

import i18n from "translations/i18n";

const passwordSpecialCharTest = (): TestConfig<string | undefined> => ({
  name: "passwordSpecialCharTest",
  message: i18n.t("yup.password_strength_must_have"),
  test: (value): boolean => (
    !value || (
      !!value
        && /(?=.*[" !"#$%&'()*+,-./:;<=>?@[\]^ˆ_`{|}~˜\\"])/.test(value)
    )
  ),
});

export default passwordSpecialCharTest;
