import React, { useCallback, useState } from "react";
import { Flex, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { FetchResult } from "@apollo/client";

import { editLeasingRatesSchema, EditLeasingRatesSchema } from "settings/yup/schemas/editLeasingRatesSchema";
import {
  OfferCustomerTypeEnum,
  PublishOfferMutation,
  UpdateFixedLeasingRateParams,
  UpdateOfferMutation,
  usePublishOffersMutation,
  useUpdateOfferMutation,
  UpdateDeliveryPriceParams,
} from "generated/graphql";
import { GetErrorMessageAsList } from "utils/GetErrorMesssageAsList";
import { getDeliveryPrices } from "utils/getDeliveryPrices";

import { parsedOfferSpecialConditions } from "./offerSpecialConditionsParser";
import { EditLeasingRatesModalProps } from "./types";
import Form from "./Form";
import { UPDATE_OFFERS_MUTATION_REFETCH_QUERIES } from "./graphql/UpdateOfferMutation";
import { PUBLISH_OFFER_REFETCH_QUERIES } from "./graphql/PublishOfferMutation";

export const parseFixedLeasingRates = (
  values: UpdateFixedLeasingRateParams[],
): UpdateFixedLeasingRateParams[] => values?.map(
  (
    {
      mileageKm,
      price,
      termMonths,
    },
  ) => ({
    mileageKm,
    price: price || "",
    termMonths,
  }),
).filter(
  ({ price }) => price !== "" && price >= 0,
);

const parseDeliveryPrices = (
  customerType: OfferCustomerTypeEnum,
  deliveryPrices?: UpdateDeliveryPriceParams[],
  dealerId?: string,
): UpdateDeliveryPriceParams[] | undefined => {
  if (!dealerId || !deliveryPrices?.length) {
    return undefined;
  }

  const parsedDeliveryPrices = deliveryPrices?.map((dp) => ({
    ...dp,
    factoryPickupPrice: dp?.factoryPickupPrice >= 0
      ? parseFloat(dp?.factoryPickupPrice)
      : undefined,
    dealerPickupPrice: dp?.dealerPickupPrice >= 0
      ? parseFloat(dp?.dealerPickupPrice)
      : undefined,
    houseDeliveryPrice: dp?.houseDeliveryPrice >= 0
      ? parseFloat(dp?.houseDeliveryPrice)
      : undefined,
  }));

  return getDeliveryPrices(
    parsedDeliveryPrices,
    customerType,
    dealerId,
  );
};

export const EditLeasingRatesModal: React.FC<EditLeasingRatesModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const {
    data,
    specialConditionsData,
    callback,
  } = componentProps || {};

  const {
    id,
    nominalInterestRate,
    effectiveAnnualInterestRate,
    extraPricePerHundredKilometersNet,
    discountPerHundredKilometersNet,
    fixedLeasingRatesPriceType,
    fixedLeasingRates,
    deliveryPrices,
    dealer,
    customerType,
  } = data;

  const [submitState, setSubmitState] = useState(false);
  const [updateOffer] = useUpdateOfferMutation();
  const [publishOffer] = usePublishOffersMutation();

  const { t } = useTranslation();

  const validDeliveryPrices = deliveryPrices.filter(deliveryPrice => !!deliveryPrice);

  const newToast = useToast();

  const handleClick = useCallback(() => {
    if (callback) {
      callback();
    }

    hideModal();
  }, [
    callback,
    hideModal,
  ]);

  const {
    handleSubmit,
    formState,
    register,
    setValue,
    getValues,
    reset,
  } = useForm<EditLeasingRatesSchema>({
    defaultValues: {
      nominalInterestRate,
      effectiveAnnualInterestRate,
      extraPricePerHundredKilometersNet,
      discountPerHundredKilometersNet,
      fixedLeasingRatesPriceType,
      fixedLeasingRates,
      customerType,
      deliveryPrices: validDeliveryPrices.map(({
        dealerId,
        type,
        dealerPickupPrice,
        factoryPickupPrice,
        houseDeliveryPrice,
      }) => ({
        dealerId,
        type,
        dealerPickupPrice,
        factoryPickupPrice,
        houseDeliveryPrice,
      })),
    },
    resolver: yupResolver(editLeasingRatesSchema),
    mode: "all",
  });

  const handleOfferUpdate = useCallback((
    values: EditLeasingRatesSchema,
  ): Promise<FetchResult<UpdateOfferMutation>> => {
    const offerSpecialConditions = (values?.financingSpecialConditions && specialConditionsData)
      ? parsedOfferSpecialConditions(
        values.financingSpecialConditions,
        specialConditionsData,
      ) : undefined;

    return updateOffer({
      awaitRefetchQueries: true,
      refetchQueries: UPDATE_OFFERS_MUTATION_REFETCH_QUERIES,
      variables: {
        id,
        params: {
          effectiveAnnualInterestRate: values.effectiveAnnualInterestRate,
          nominalInterestRate: values.nominalInterestRate,
          extraPricePerHundredKilometersNet: values.extraPricePerHundredKilometersNet,
          discountPerHundredKilometersNet: values.discountPerHundredKilometersNet,
          fixedLeasingRatesPriceType: values.fixedLeasingRatesPriceType,
          fixedLeasingRates: parseFixedLeasingRates(values.fixedLeasingRates),
          deliveryPrices: parseDeliveryPrices(
            values.customerType as OfferCustomerTypeEnum,
            values.deliveryPrices,
            dealer?.id,
          ),
          customerType: values.customerType,
          offerSpecialConditions,
        },
      },
    });
  }, [
    specialConditionsData,
    updateOffer,
    dealer,
    id,
  ]);

  const handlePublishOffer = useCallback(
    (): Promise<FetchResult<PublishOfferMutation>> => publishOffer({
      refetchQueries: PUBLISH_OFFER_REFETCH_QUERIES,
      awaitRefetchQueries: true,
      variables: {
        offersIds: id,
      },
    }), [
      publishOffer,
      id,
    ],
  );

  const onUpdate = useCallback((
    values: EditLeasingRatesSchema,
  ): Promise<void | FetchResult<UpdateOfferMutation>> => handleOfferUpdate(values)
    .catch((error) => {
      newToast({
        title: t("errors.something_went_wrong"),
        description: error.message,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    })
    .finally(() => {
      handlePublishOffer()
        .then(() => {
          newToast({
            title: t("custom_offer.toasts.successfully_published"),
            description: t("custom_offer.toasts.note"),
            status: "info",
            duration: 6000,
            isClosable: true,
          });

          hideModal();

          setSubmitState(false);
        })
        .catch((error) => {
          newToast({
            title: t("errors.offer_updated_but_not_published"),
            description: GetErrorMessageAsList(error?.message),
            status: "warning",
            duration: 6000,
            isClosable: true,
          });
        });
    }),
  [
    handleOfferUpdate,
    handlePublishOffer,
    hideModal,
    newToast,
    t,
  ]);

  return (
    <Flex
      overflow="hidden"
      flexDir="column"
      p="6"
    >
      {data && (
        <Form
          handleCancel={handleClick}
          handleSubmit={handleSubmit(onUpdate)}
          register={register}
          errors={formState.errors}
          submitState={submitState}
          setSubmitState={setSubmitState}
          offer={data}
          setValue={setValue}
          getValues={getValues}
          reset={reset}
          formState={formState}
        />
      )}
    </Flex>
  );
};

export default EditLeasingRatesModal;
