import React from "react";
import { Tooltip, Button, Center } from "@chakra-ui/react";

import i18n from "translations/i18n";
import { CustomerTypeEnum } from "generated/graphql";
import { Column } from "components/Table/types";
import CopyIcon from "components/Icons/CopyIcon";
import { TableFactorsEnum } from "components/Modals/EditVehicleModal/constants";
import { TERM_MONTHS } from "constants/leasing";

import { LeasingColumns } from "../types";

export const MakeLeasingFactorFooterColumns = (
  factor: TableFactorsEnum,
  type: CustomerTypeEnum,
  copyRows: (month: number) => void,
): Column<LeasingColumns>[] => {
  const months = TERM_MONTHS.map((month) => ({
    render: () => (
      <Center>
        <Tooltip
          label={i18n.t("components.tooltips.copy_values_line")}
          bg="gray.100"
          color="secondary.800"
          hasArrow
        >
          <Button
            variant="ghost"
            onClick={() => copyRows(month)}
          >
            <CopyIcon />
          </Button>
        </Tooltip>
      </Center>
    ),
  }));

  return [
    {
      cellProps: {
        textAlign: "center",
        fontSize: "xs",
      },
    },
    ...months,
    {
      cellProps: {
        textAlign: "center",
        fontSize: "xs",
      },
    },
  ];
};
