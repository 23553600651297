import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const DatabaseIcon: IconProps = createIcon({
  viewBox: "0 0 49.252 32.834",
  path: (
    <path
      fill="currentColor"
      d="M2,36H42V28H2Zm4-6h4v4H6ZM2,4v8H42V4Zm8,6H6V6h4ZM2,24H42V16H2Zm4-6h4v4H6Z"
      transform="translate(3 -2)"
    />
  ),
});

export default DatabaseIcon;
