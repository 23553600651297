import { gql } from "@apollo/client";

export const OFFERS_REFETCH_QUERIES = ["Offers"];

export default gql`
  query Offers($filters: FilterOfferParams, $search: String, $pagination: OfferPaginationParams) {
    offers(filters: $filters, search: $search, pagination: $pagination) {
      isNewConfiguratorEnabled
      id
      title
      shortId
      internalNumber
      vin
      insertedAt
      updatedAt
      status
      creationType
      customerType
      url
      effectiveAnnualInterestRate
      nominalInterestRate
      discountPerHundredKilometersNet
      extraPricePerHundredKilometersNet
      topOffer
      marketingOffer
      deliveryPrices {
        dealerId
        dealerPickupPrice
        factoryPickupPrice
        houseDeliveryPrice
      }
      dealer {
        id
        companyName
        currentBudget {
          id
          budgetSpent
          currentTotalBudget
          isUnlimited
        }
      }
      minFinalLeasingRate {
        priceNet
        priceGross
        factor
        termMonths
        mileageKm
      }
      brand {
        id
        name
      }
      fixedLeasingRatesPriceType
      fixedLeasingRates {
        id
        mileageKm
        priceGross
        priceNet
        termMonths
      }
      offerSpecialConditions {
        displayedDescription
        specialCondition {
          description
          id
          name
          slug
        }
      }
    }
    countOffers(filters: $filters, search: $search)
  }
`;
