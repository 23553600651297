import React, { useState } from "react";
import { Stack } from "@chakra-ui/react";

import PercentageInput from "components/FormComponents/Inputs/PercentageInput";
import { maskWithoutCurrencySymbol } from "constants/inputMasks";

import { rightElementCss } from "../styles";
import { CustomFieldProps } from "../types";

const CustomPercentageField: React.FC<CustomFieldProps> = (
  {
    setValue: formSetValue,
    netTitle,
    name,
    titleCss,
    defaultValue,
    width,
    border,
    allowNegative,
  },
) => {
  const parsePercentageValue = (value: number): string => (
    value.toFixed(2).replace(".", ",")
  );

  const calculateDefaultValue = parseFloat(defaultValue) * 100;
  const initialStateValue = parsePercentageValue(calculateDefaultValue) || "";

  const [inputValue, setInputValue] = useState<string>(initialStateValue);

  const handleOnValueChange = (value: number | undefined): void => {
    if (typeof value === "number") {
      const strValue = String(value);
      const parsedValue = parseFloat(strValue) / 100;

      setInputValue(strValue.replace(".", ","));
      return formSetValue?.(name, parsedValue);
    }

    setInputValue("");
    return formSetValue?.(name, null);
  };

  return (
    <>
      <Stack
        key={`${name}-${defaultValue}`}
        width={width}
      >
        <PercentageInput
          allowNegative={allowNegative}
          name={name}
          value={inputValue}
          title={netTitle}
          titleCss={titleCss}
          placeholder="0,00"
          border={border}
          format={!allowNegative ? maskWithoutCurrencySymbol : undefined}
          isAllowed={(values) => {
            const { formattedValue, floatValue = 0 } = values;
            return formattedValue === "" || floatValue <= 10000.00;
          }}
          onValueChange={(e) => handleOnValueChange(e?.floatValue)}
          rightElementProps={rightElementCss}
        />
      </Stack>
    </>
  );
};

export default CustomPercentageField;
