import create from "zustand";

import { OfferRow } from "../types";
import { OffersStore } from "./types";

const useOffersStore = create<OffersStore>((set, get) => ({
  selectedItems: get()?.selectedItems || [],
  countOffers: 0,
  clearSelectedItems: () => {
    set({ selectedItems: [] });
  },
  toggleItem: (item: OfferRow) => {
    set(({ selectedItems }) => {
      const selectedRow = selectedItems.find(({ id }) => id === item.id);

      if (!selectedRow) {
        return {
          selectedItems: [
            ...selectedItems,
            item,
          ],
        };
      }

      return {
        selectedItems: selectedItems.filter(({ id }) => id !== item.id),
      };
    });
  },
  toggleAllItems: (isAllSelected: boolean, items: OfferRow[]) => {
    set(() => {
      if (isAllSelected) {
        return {
          selectedItems: [],
        };
      }

      return {
        selectedItems: items,
      };
    });
  },
  setCountOffers: (countOffers: number) => {
    set(() => ({ countOffers }));
  },
}));

export default useOffersStore;
