import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const UpArrowIcon: IconProps = createIcon({
  viewBox: "0 0 16 16",
  d: "M8.99995 15.5019L9.01281 3.33188L14.5969 8.92778L16.0084 7.50927L8.01685 -0.49917L0.00840706 7.49238L1.41692 8.90387L7.01281 3.32977L6.99996 15.4998L8.99995 15.5019Z",
});

export default UpArrowIcon;
