import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const SearchIcon: IconProps = createIcon({
  viewBox: "0 0 45.819 45.819",
  path: (
    <path
      fill="currentColor"
      d="M32.747,28.817h-2.07l-.734-.707a17.054,17.054,0,1,0-1.834,1.834l.707.734v2.07l13.1,13.072,3.9-3.9-13.072-13.1Zm-15.718,0A11.789,11.789,0,1,1,28.817,17.028,11.773,11.773,0,0,1,17.028,28.817Z"
    />
  ),
});

export default SearchIcon;
