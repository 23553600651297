import { Text, FormLabel as ChakraFormLabel } from "@chakra-ui/react";
import React from "react";

import { FormLabelProps } from "./types";

const FormLabel: React.FC<FormLabelProps> = ({
  htmlFor,
  title,
  titleCss,
  ...props
}) => (
  <ChakraFormLabel htmlFor={htmlFor}>
    <Text
      fontWeight="bold"
      textStyle="p"
      color="black.500"
      {...titleCss}
      {...props}
    >
      {title}
    </Text>
  </ChakraFormLabel>
);

export default FormLabel;
