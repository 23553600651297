/**
 * Defines errors that should be ignored and should not be reported to Sentry.
 * Each string may contain a piece of the error or the whole message.
 */
const ignoreErrors = [
  // Random plugins/extensions
  "top.GLOBALS",

  // Webpack errors
  "Unexpected end of input",
  "export not found",
  "ChunkLoadError",
  "Loading chunk",
  "LoadingChunk",

  // Google Tag Manager errors
  "Cannot read property 'postMessage' of null",
  "Can't find variable: jQuery",
  "Can't find variable: iom",
  "NS_ERROR_FILE_CORRUPTED",
  "'iom' is not defined",
  "iom is not defined",
  "Illegal invocation",
  "gaconnector2",
  "reading 'postMessage'",

  // React Router errors
  "SecurityError: Failed to execute 'pushState' on 'History'",

  // React static errors
  "ResizeObserver loop completed with undelivered notifications",
  "Can't find variable: wpmobileappStartLoading",
  "ResizeObserver loop limit exceeded",
  "Failed to construct 'MouseEvent'",
  "fullscreen error",
];

export const ignoredNetworkAndGraphqlErrors = [
  "Nicht authentifiziert",
  "Failed to fetch",
  "Load failed",
];

export default ignoreErrors;
