import React from "react";
import { GridItem, Text } from "@chakra-ui/react";

import { Container } from "components/Container";
import { LOREM } from "constants/lorem";

const Historic: React.FC = () => (
  <GridItem colSpan={6}>
    <Container
      title="Historic page content."
      alignCenter
    >
      <Text>{LOREM.repeat(12)}</Text>
    </Container>
  </GridItem>
);

export default Historic;
