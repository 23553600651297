import React from "react";

import RequestLeadRejection from "./RequestLeadRejection";
import { LeadDetailsSectionCommonProps } from "../../types";

const ClassicLeadsDetails: React.FC<LeadDetailsSectionCommonProps> = ({
  isLoading,
  lead,
}) => (
  <RequestLeadRejection
    lead={lead}
    isLoading={isLoading}
  />
);

export default ClassicLeadsDetails;
