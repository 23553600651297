import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

import getErrorMessage from "utils/getErrorMessage";
import { getPointerStyles } from "utils/getPointerStyles";
import TextConditionalWrap from "components/TextConditionalWrap";

import FieldErrorMessage from "../FieldErrorMessage";
import { disabledCss } from "./styles";
import { ToggleButtonProps } from "./types";
import ToggleButtonBox from "./ToggleButtonBox";
import { defaultOptionLabels } from "./defaultOptionLabels";

/**
 * Renders a controlled component that behaviors like a checkbox
 * @param options an array of objects e.g.: [{ label:"On", value: "on"}]
 * The default value is the first element of the array
 *
 * In order to use it with the react-hook-form library, you need to register
 * its field name in the formState object
 */
const ToggleButton = React.forwardRef<HTMLInputElement, ToggleButtonProps>((
  {
    name,
    errors,
    isReadOnly,
    isDisabled,
    showErrorMessage = true,
    renderChildrenAsText = true,
    options = defaultOptionLabels,
    defaultValue,
    title,
    titleCss,
    setValue,
    ...props
  },
  ref,
) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultValue || options[0].value);

  const error = getErrorMessage(name, errors);

  const pointerStyles = getPointerStyles(isDisabled, isReadOnly);

  const handleClick = React.useCallback((value: string): void => {
    if (value === selectedValue) {
      return;
    }

    setSelectedValue(value);
    setValue?.(value);
  }, [
    selectedValue,
    setValue,
  ]);

  return (
    <FormControl
      p={1}
      w="min-content"
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <Input
        type="hidden"
        ref={ref}
        id={name}
        name={name}
        value={selectedValue}
        {...props}
      />

      <TextConditionalWrap
        mb={2}
        cursor="default"
        titleCss={titleCss}
        renderChildrenAsText={renderChildrenAsText}
      >
        {title}
      </TextConditionalWrap>

      <FormLabel
        m={0}
        h={6}
        d="flex"
        htmlFor={name}
        borderWidth="1px"
        borderRadius="4px"
        borderColor="secondary.500"
        alignItems="center"
        justifyContent="center"
        cursor={pointerStyles.cursorStyles}
        pointerEvents={pointerStyles.pointerEvents}
        _disabled={disabledCss}
      >
        {
          options.map((option, index) => (
            <ToggleButtonBox
              index={index}
              key={option.value}
              value={option.value}
              selectedValue={selectedValue}
              onClick={() => handleClick(option.value)}
            >
              {option.label}
            </ToggleButtonBox>
          ))
        }
      </FormLabel>

      {
        showErrorMessage && (
          <FieldErrorMessage error={error} />
        )
      }
    </FormControl>
  );
});

export default ToggleButton;
