export enum StatusTypes {
  created = "blue",
  deleted = "yellow",
  failed = "red",
  skipped = "gray",
  updated = "green",
  published = "purple",
}

export interface StatusBulletProps {
  id: number;
  status: typeof StatusTypes[keyof typeof StatusTypes];
  label?: string;
}
