export const borderCss = {
  borderColor: "secondary.500",
};

export const beforeCss = {
  width: "60% !important",
  height: "60% !important",
};

export const backgroundCss = {
  backgroundColor: "white !important",
};
