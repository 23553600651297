import React from "react";

import { LoadingContextProps } from "./types";

const LoadingContext = React.createContext<LoadingContextProps>({} as LoadingContextProps);

export const LoadingProvider = LoadingContext.Provider;

/**
 * Exposes Loading data and methods.
 */
export const useLoading = (): LoadingContextProps => {
  const context = React.useContext(LoadingContext);

  if (!context) {
    throw new Error("useLoading should be within LoadingProvider");
  }

  return context;
};
