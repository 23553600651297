import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Table from "components/Table";

import { makeTableColumns } from "./tableColumns";
import { CreateNewOfferVehicleTableProps } from "../types";
import useCreateNewOfferStore from "../CreateNewOfferFooter/store";

const CreateNewOfferVehiclesTable: React.FC<CreateNewOfferVehicleTableProps> = ({
  data,
  loading,
}) => {
  const { t } = useTranslation();

  const {
    selectedItem,
    toggleItem,
  } = useCreateNewOfferStore();

  const toggleCreateNewOffer = useCallback((row) => () => {
    toggleItem(row);
  }, [toggleItem]);

  const columns = makeTableColumns({
    toggleCreateNewOffer,
    selectedCreateNewOffer: selectedItem,
  });

  return (
    <Table
      columns={columns}
      data={data}
      emptyDataProps={{
        message: t("empty_data_messages.no_information_to_be_displayed"),
        isDataLoading: loading,
      }}
      isPageable
    />
  );
};

export default CreateNewOfferVehiclesTable;
