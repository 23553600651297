export const isRightElementCss = {
  borderRadius: "5px 0px 0px 5px",
};

export const isLeftElementCss = {
  borderRadius: "0px 5px 5px 0px",
};

export const bothElementsCss = {
  borderRadius: "0px",
};

export const defaultCss = {
  borderRadius: "5px",
};
