import React from "react";
import { Flex, Text } from "@chakra-ui/react";

import i18n from "translations/i18n";
import StatusBullet from "components/StatusBullet";
import { Column } from "components/Table/types";
import { formatFullDate } from "utils/dateFormats";
import { OfferImportLog } from "generated/graphql";
import { OFFER_IMPORT_LOGS_DETAILS_PAGE_PATH } from "routes";
import getBulletStatusList from "utils/getBulletStatusList";
import { ButtonAsLink } from "components/ButtonAsLink";

import { ImportLogsSortFieldsEnum } from "../types";

/**
 * Builds the Offer Import Logs table columns.
 */
export const makeTableColumns = ():
  Column<OfferImportLog>[] => [
  {
    title: i18n.t("logs.table.dealer_name"),
    render: ({ row }) => (
      <>
        <Text fontWeight="bold">
          {row.dealer.companyName}
        </Text>
        <Text>
          {row.dealer.id}
        </Text>
      </>
    ),
  },
  {
    title: i18n.t("logs.table.imported_at"),
    fieldResolver: (offer) => formatFullDate(offer.importedAt),
    sortingColumn: ImportLogsSortFieldsEnum.ImportedAt,
  },
  {
    title: i18n.t("logs.table.offers_provided"),
    fieldPath: "numberOfOffersProvided",
  },
  {
    title: i18n.t("logs.table.status"),
    render: ({ row }) => {
      const statusList = getBulletStatusList(row);

      const content = statusList.map((item) => (
        <StatusBullet
          id={item.id}
          key={item.id}
          label={item.label}
          status={item.status}
        />
      ));

      return (
        <Flex>
          {content}
        </Flex>
      );
    },
  },
  {
    title: i18n.t("components.table.heading.options"),
    render: ({ row }) => (
      <ButtonAsLink
        label={i18n.t("components.buttons.details")}
        path={OFFER_IMPORT_LOGS_DETAILS_PAGE_PATH.replace(":id", String(row.id))}
        colorScheme="secondary"
        textStyle="p"
      />
    ),
  },
];
