import { isWithinInterval, subMonths } from "date-fns";

export const isWithinMonthsRange = (date: Date, monthsRange: number): boolean => {
  const today = new Date();

  return isWithinInterval(date, {
    start: subMonths(today, monthsRange),
    end: today,
  });
};
