export const TERM_MONTHS = [
  12,
  24,
  36,
  48,
  60,
  72,
];

export const MILEAGES = [
  5000,
  10000,
  15000,
  20000,
  25000,
  30000,
];

export const CUSTOM_FIELD_REPRESENTATION = 0;

export const GROSS_TAX = 1.19;
