import React from "react";
import {
  Flex,
  Skeleton,
} from "@chakra-ui/react";

import i18n from "translations/i18n";
import LeadDetailsCard from "views/Leads/components/LeadDetailsCard";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import { formatDate, germanDateFormat } from "utils/dateFormats";
import { formatCurrency } from "utils/currencyFormat";
import getEmploymentStatusValue from "utils/getEmploymentStatusValue";
import { EmploymentStatusEnum } from "generated/graphql";

import { EmploymentDetailsProps } from "./types";
import { skeletonStyles } from "../styles";

const EmploymentDetails: React.FC<EmploymentDetailsProps> = ({
  isLoading,
  lead,
}) => (
  <Flex w="full" flexDir="column">
    <LeadDetailsCard title={i18n.t("leads.details.form.employment_details")}>
      {
        isLoading
          ? (
            <Flex flexDir="column" w="full">
              <Skeleton {...skeletonStyles.sm} />
              <Skeleton {...skeletonStyles.full} />
              <Skeleton {...skeletonStyles.full} />
              <Skeleton {...skeletonStyles.full} />
            </Flex>
          ) : (
            <Flex>
              <Flex
                direction="column"
                flex={1}
              >
                <Flex mb={30}>
                  <FakeTextField
                    value={getEmploymentStatusValue(lead?.employmentStatus)}
                    label={i18n.t("leads.details.form.employment_relationship")}
                    w="50%"
                  />

                  <FakeTextField
                    value={lead?.monthlyIncome && formatCurrency({
                      amount: lead?.monthlyIncome,
                      symbolPosition: "before",
                    })}
                    label={i18n.t("leads.details.form.monthly_income")}
                    w="25%"
                  />

                  <FakeTextField
                    value={lead?.otherIncome && formatCurrency({
                      amount: lead?.otherIncome,
                      symbolPosition: "before",
                    })}
                    label={i18n.t("leads.details.form.other_income_net")}
                    w="25%"
                  />

                </Flex>

                {
                  lead?.employmentStatus !== EmploymentStatusEnum.Retired && (
                    <Flex mb={30}>
                      <FakeTextField
                        value={lead?.employer}
                        label={i18n.t("leads.details.form.your_employer")}
                        w="50%"
                      />

                      <FakeTextField
                        value={formatDate(lead?.employedSince, germanDateFormat, "-")}
                        label={i18n.t("leads.details.form.employed_there_since")}
                        w="25%"
                      />

                      <FakeTextField
                        value={lead?.jobTitle}
                        label={i18n.t("leads.details.form.job_title")}
                        w="25%"
                      />

                    </Flex>
                  )
                }
              </Flex>
            </Flex>
          )
      }
    </LeadDetailsCard>
  </Flex>
);

export default EmploymentDetails;
