import React from "react";

import { PaginationContextPayload } from "./types";

const PaginationContext = React.createContext({} as PaginationContextPayload);

export const PaginationProvider = PaginationContext.Provider;

/**
 * Exposes the current Pagination data.
*/

export const usePagination = (): PaginationContextPayload => {
  const context = React.useContext(PaginationContext);

  if (!context) {
    throw new Error("usePagination should be able within PaginationProvider");
  }

  return context;
};
