import React, { useEffect } from "react";
import { Flex } from "@chakra-ui/react";

import {
  useOffersLazyQuery,
  Offer,
  OfferSortFieldsEnum,
  OfferStatusEnum,
  useGetSpecialConditionsQuery,
  SpecialCondition,
} from "generated/graphql";
import useFilters from "hooks/useFilters";
import useSorting from "hooks/useSorting";
import { Filters } from "hooks/useFilters/types";
import { Container } from "components/Container";
import { usePagination } from "contexts/pagination";
import { useCurrentUser } from "contexts/currentUser";

import OffersTable from "./OffersTable";
import useOffersStore from "./OffersFooter/store";

const Offers: React.FC = () => {
  const { paginationLimit } = usePagination();

  const [, , { isAdmin }] = useCurrentUser();

  const setCountOffers = useOffersStore((state) => state.setCountOffers);

  const adminOnlyFilters = [
    Filters.TopOffer,
    Filters.MarketingOffer,
    Filters.IsPossibleToEnableNewConfigurator,
    Filters.IsNewConfiguratorEnabled,
  ];

  const filters = [
    Filters.Dealer,
    Filters.Vehicle,
    Filters.VehicleModel,
    Filters.OfferCustomerType,
    Filters.CreationType,
    Filters.Search,
    Filters.OnlyActive,
    ...(isAdmin ? adminOnlyFilters : []),
  ];

  const {
    renderFilters,
    dealerId,
    brandId,
    modelGroup,
    model,
    offerCustomerType,
    creationType,
    active,
    topOffer,
    marketingOffer,
    search,
    isPossibleToEnableNewConfigurator,
    isNewConfiguratorEnabled,
  } = useFilters(filters);

  const { data: specialConditionsData } = useGetSpecialConditionsQuery();

  const [listOffers, { data, loading }] = useOffersLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (onCompletedData) => setCountOffers(onCompletedData?.countOffers || 0),
    errorPolicy: "all",
  });

  const { field, order } = useSorting({
    defaultField: OfferSortFieldsEnum.InsertedAt,
  });

  useEffect(() => {
    listOffers({
      variables: {
        search,
        pagination: {
          limit: paginationLimit,
          orderBy: field
            ? [{
              field: field as OfferSortFieldsEnum,
              order,
            }]
            : undefined,
        },
        filters: {
          dealerId,
          brandId,
          vehicleModelGroup: modelGroup,
          vehicleModel: model,
          creationType,
          customerType: offerCustomerType,
          status: active ? OfferStatusEnum.Live : active,
          topOffer,
          marketingOffer,
          isPossibleToEnableNewConfigurator,
          isNewConfiguratorEnabled:
            isNewConfiguratorEnabled || (isPossibleToEnableNewConfigurator ? false : undefined),
        },
      },
    });
  }, [
    isPossibleToEnableNewConfigurator,
    isNewConfiguratorEnabled,
    offerCustomerType,
    paginationLimit,
    marketingOffer,
    creationType,
    listOffers,
    modelGroup,
    topOffer,
    dealerId,
    brandId,
    active,
    search,
    model,
    order,
    field,
  ]);

  return (
    <Container
      alignCenter
      padding="0"
      paddingTop="0"
      paddingX="0"
      paddingBottom="0"
    >
      <Flex justifyContent="space-between" alignItems="center" padding="6">
        {renderFilters}
      </Flex>

      <OffersTable
        data={data?.offers as Offer[]}
        specialConditionsData={
          specialConditionsData?.specialConditions as SpecialCondition[]
        }
        loading={loading}
      />
    </Container>
  );
};

export default Offers;
