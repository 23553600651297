import { CustomerTypeEnum } from "generated/graphql";
import yup from "settings/yup";
import i18n from "translations/i18n";

import csvFileTypeTest from "../tests/csvFileTypeTest";
import requiredFileTest from "../tests/requiredFileTest";

const importCalculatorDataSchema = yup.object().shape({
  dealer: yup
    .string()
    .nullable()
    .when("dealerDiscounts", {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(i18n.t("yup.mixed_required_field")),
    }),
  customerType: yup
    .string()
    .nullable()
    .required(i18n.t("yup.mixed_required_field")),
  dealerDiscounts: yup
    .boolean(),
  brandDiscounts: yup
    .boolean(),
  file: yup
    .mixed<FileList>()
    .test(requiredFileTest())
    .test(csvFileTypeTest()),
});

export interface ImportCalculatorDataSchema {
  dealer: string;
  customerType: CustomerTypeEnum;
  dealerDiscounts: boolean;
  brandDiscounts: boolean;
  file: FileList;
}

export default importCalculatorDataSchema;
