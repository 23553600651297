import React from "react";
import {
  Box,
  Flex,
  Stack,
} from "@chakra-ui/react";

import {
  CONTAINER_PADDING,
} from "constants/layout";
import TitleBar from "components/TitleBar";
import Footer from "components/Footer";
import { LayoutProps } from "layouts/types";
import { LeadsStoreProvider } from "views/Leads/context/leadsSelection";
import { DefaultLayoutContainer } from "layouts/components/DefaultLayoutContainer";
import useFilters from "hooks/useFilters";
import { Filters } from "hooks/useFilters/types";
import { PageTitle } from "components/TitleBar/PageTitle";

const Leads: React.FC<LayoutProps> = ({
  children,
  title,
  Icon,
  FooterContent,
  footerVariant,
  titleBarButton,
  dealerSelector,
}) => {
  const { renderFilters } = useFilters([Filters.Dealer]);

  return (
    <DefaultLayoutContainer>
      <LeadsStoreProvider>
        {!dealerSelector
          ? (
            <Flex
              grow={1}
              shrink={0}
              basis="auto"
              direction="column"
            >
              <TitleBar
                button={titleBarButton}
                title={title || ""}
                Icon={Icon}
              />

              <Box
                py={CONTAINER_PADDING.XS}
                pr={CONTAINER_PADDING.XS}
              >
                {children}
              </Box>
            </Flex>
          ) : (
            <Flex
              direction="column"
              basis="auto"
              shrink={0}
              grow={1}
              w="full"
            >
              <Flex
                boxShadow="default"
                bgColor="white"
                w="full"
                padding="16px"
              >
                <Stack
                  spacing={5}
                  w="full"
                >
                  <Flex>
                    <PageTitle
                      title={title || ""}
                      textStyle="h1"
                      Icon={Icon}
                    />

                    <Flex ml={8}>
                      {renderFilters}
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>

              <Box
                py={CONTAINER_PADDING.XS}
                pr={CONTAINER_PADDING.XS}
              >
                {children}
              </Box>
            </Flex>
          )}

        {
          FooterContent && (
            <Flex shrink={0}>
              <Footer
                FooterContent={FooterContent}
                footerVariant={footerVariant}
              />
            </Flex>
          )
        }
      </LeadsStoreProvider>
    </DefaultLayoutContainer>
  );
};

export default Leads;
