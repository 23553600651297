import React from "react";

import useCustomOfferStore from "views/CustomOffer/store";
import {
  useGetSpecialConditionsQuery, UserRoleEnum,
} from "generated/graphql";
import { useCurrentUser } from "contexts/currentUser";

import SpinnerContainer from "../../../SpinnerContainer";
import OfferDetailsForm from "./OfferDetailsForm";
import { getOfferDetailsDefaultFormValues } from "./OfferDetailsForm/getOfferDetailsDefaultValues";

const OfferDetails: React.FC = () => {
  const { offer, offerDetails, financingSpecialConditions } = useCustomOfferStore(state => ({
    offer: state?.offer,
    offerDetails: state.store?.offerDetails,
    financingSpecialConditions: state.store?.financingAndCosts?.financingSpecialConditions,
  }));

  const {
    loading: specialConditionsLoading,
    data,
  } = useGetSpecialConditionsQuery();

  const [
    currentUser,
    {
      loading: currentUserLoading,
    },
  ] = useCurrentUser();

  const isLoading = !offer
    || specialConditionsLoading
    || currentUserLoading;

  if (isLoading) {
    return <SpinnerContainer />;
  }

  const specialConditions = data?.specialConditions;

  const defaultFormValues = getOfferDetailsDefaultFormValues({
    offer,
    offerDetails,
    specialConditions,
    financingSpecialConditions,
  });

  return (
    <OfferDetailsForm
      key={offer?.id}
      isAdmin={currentUser?.role === UserRoleEnum.Admin}
      specialConditions={data?.specialConditions}
      defaultFormValues={defaultFormValues}
    />
  );
};

export default OfferDetails;
