import React, { FC } from "react";
import {
  Flex, Skeleton,
} from "@chakra-ui/react";

import { isJsonString } from "utils/isJsonString";
import i18n from "translations/i18n";

import LeadDetailsCard from "../../../../components/LeadDetailsCard";
import { ConfigurationDetailsTable } from "./ConfigurationDetailsTable";
import {
  ConfigurationDetailsProps,
  ParsedConfigurationDetailsExtra,
} from "./types";
import { parseConfigurationDetails } from "./parseConfigurationDetails";
import { skeletonStyles } from "../styles";

export const ConfigurationDetails: FC<ConfigurationDetailsProps> = ({
  configurationDetails,
  isLoading = false,
}) => {
  const configurationDetailsObject = (
    configurationDetails && isJsonString(configurationDetails)
      ? JSON.parse(configurationDetails)
      : undefined
  );

  const parsedConfigurationDetails = parseConfigurationDetails(configurationDetailsObject);

  if (!configurationDetails) {
    return null;
  }

  return (
    <LeadDetailsCard title={i18n.t("leads.details.form.configuration_details")}>
      <Flex
        flexDir="column"
        mt="20px"
        w="auto"
      >
        {
          isLoading ? (
            <Flex flexDir="column" w="full">
              <Skeleton {...skeletonStyles.sm} />
              <Skeleton {...skeletonStyles.full} />

              <Skeleton {...skeletonStyles.sm} />
              <Skeleton {...skeletonStyles.full} />

              <Skeleton {...skeletonStyles.sm} />
              <Skeleton {...skeletonStyles.full} />
            </Flex>
          ) : (
            parsedConfigurationDetails?.map((item: ParsedConfigurationDetailsExtra) => (
              <ConfigurationDetailsTable
                item={item?.extras ? item.extras : item}
                sectionName={item.section}
              />
            ))
          )
        }
      </Flex>
    </LeadDetailsCard>
  );
};
