import { TestConfig } from "yup";

import i18n from "translations/i18n";

const maxValueTest = (maxValue = 1): TestConfig<string | undefined> => ({
  name: "maxValueTest",
  message: i18n.t("yup.string_equal_or_smaller", { max: maxValue }),
  test: (value): boolean => {
    if (!value) {
      return true;
    }

    return parseFloat(value.replace(",", ".")) <= maxValue;
  },
});

export default maxValueTest;
