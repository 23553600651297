import React, { useCallback } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import { iconsMap } from "constants/icons";

import { AlertModalProps } from "./types";

const iconsOptions = {
  success: iconsMap.CheckIcon,
  warning: iconsMap.InfoIcon,
  error: iconsMap.ErrorIcon,
};

const AlertModal: React.FC<AlertModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (componentProps.callback) {
      componentProps.callback();
    }

    hideModal();
  }, [
    componentProps,
    hideModal,
  ]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Flex>
        {
          React.createElement(
            iconsOptions[`${componentProps.variant}`],
            {
              color: `${componentProps.variant}.500`,
              fontSize: "100px",
              my: "50px",
            },
          )
        }
      </Flex>

      <Flex
        justifyContent="center"
        alignItems="center"
        bgColor="gray.100"
        borderRadius="8px"
        flexDir="column"
        p={10}
      >
        <Text
          lineHeight="24px"
          fontSize="20px"
          textStyle="h3"
          mb={4}
          textAlign="center"
        >
          {componentProps.title}
        </Text>

        <Text
          marginBottom="30px"
          textAlign="center"
          lineHeight="20px"
          fontSize="16px"
          textStyle="p"
        >
          {componentProps.description}
        </Text>

        <Button
          label={
            componentProps.buttonLabel
            || t("components.buttons.ok").toUpperCase()
          }
          onClick={handleClick}
          fontFamily="regular"
          fontSize="18px"
          height="48px"
          w="full"
        />
      </Flex>
    </Flex>
  );
};

export default AlertModal;
