import React from "react";
import { Divider } from "@chakra-ui/react";

import { styles } from "./styles";

const GenericDivider = (): JSX.Element => (
  <Divider
    {...styles}
  />
);

export default GenericDivider;
