import { OptionProps } from "components/FormComponents/Inputs/Selects/BaseSelect/types";
import i18n from "translations/i18n";
import { OfferCustomerTypeEnum } from "generated/graphql";

export const makeCustomerTypeOptions = (): OptionProps[] => (
  Object.keys(OfferCustomerTypeEnum).map(type => {
    const customerType = OfferCustomerTypeEnum[type].toLowerCase();
    const label = `custom_offer.steps.financing_and_costs.customer_types.${customerType}`;

    return {
      label: i18n.t(label),
      value: OfferCustomerTypeEnum[type],
    };
  })
);
