import { ChakraTheme } from "@chakra-ui/react";

const baseTextStyle = {
  fontFamily: "bold",
};

const textStyles: ChakraTheme["textStyles"] = {
  h1: {
    ...baseTextStyle,
    fontSize: "xl",
    lineHeight: "38px",
  },
  h2: {
    ...baseTextStyle,
    fontSize: "lg",
    lineHeight: "22px",
  },
  h3: {
    ...baseTextStyle,
    fontSize: "md",
    lineHeight: "20px",
  },
  p: {
    ...baseTextStyle,
    fontSize: "sm",
    lineHeight: "18px",
    fontFamily: "regular",
  },
  th: {
    ...baseTextStyle,
    fontSize: "sm",
    lineHeight: "18px",
    fontFamily: "bold",
  },
  small: {
    ...baseTextStyle,
    fontSize: "xs",
    lineHeight: "16px",
    fontFamily: "regular",
  },
  "image-label": {
    ...baseTextStyle,
    fontSize: "sm",
    lineHeight: "18px",
    fontFamily: "bold",
    color: "secondary.500",
  },
};

export default textStyles;
