/* eslint-disable react/no-children-prop */
import React from "react";
import {
  InputRightElement,
  InputLeftElement,
  InputRightAddon,
  InputLeftAddon,
  InputGroup,
} from "@chakra-ui/react";

import HelperTextContainer from "./HelperTextContainer";
import { InputContainerProps } from "./types";

const InputContainer: React.FC<InputContainerProps> = ({
  rightElementProps = {},
  validationMessage,
  showErrorMessage = true,
  leftElementProps = {},
  rightAddonProps = {},
  leftAddonProps = {},
  rightElement,
  leftElement,
  rightAddon,
  leftAddon,
  children,
  error,
  title,
  titleCss,
  name,
  formControlCss,
  hasCustomErrorCss,
  ...props
}) => (
  <HelperTextContainer
    validationMessage={validationMessage}
    showErrorMessage={showErrorMessage}
    error={error}
    title={title}
    titleCss={titleCss}
    name={name}
    formControlCss={formControlCss}
    hasCustomErrorCss={hasCustomErrorCss}
    {...props}
  >

    <InputGroup>
      {
        leftElement && (
          <InputLeftElement
            children={leftElement}
            {...leftElementProps}
          />
        )
      }

      {
        leftAddon && (
          <InputLeftAddon
            backgroundColor="gray.200"
            borderColor="gray.400"
            children={leftAddon}
            textStyle="p"
            {...leftAddonProps}
          />
        )
      }

      {children}

      {
        rightAddon && (
          <InputRightAddon
            backgroundColor="gray.200"
            borderColor="gray.400"
            children={rightAddon}
            textStyle="p"
            {...rightAddonProps}
          />
        )
      }

      {
        rightElement && (
          <InputRightElement
            children={rightElement}
            {...rightElementProps}
          />
        )
      }
    </InputGroup>
  </HelperTextContainer>
);

export default InputContainer;
