import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const ClockIcon: IconProps = createIcon({
  viewBox: "0 0 20 20",
  path: (
    <>
      <path
        d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM15.8333 10.8333C15.8333 11.2936 15.4602 11.6667 15 11.6667H9.16667V5C9.16667 4.53976 9.53976 4.16667 10 4.16667C10.4602 4.16667 10.8333 4.53976 10.8333 5V10H15C15.4602 10 15.8333 10.3731 15.8333 10.8333Z"
        fill="currentColor"
      />
    </>
  ),
});

export default ClockIcon;
