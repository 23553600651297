import React from "react";
import {
  Box,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useQueryParams } from "use-query-params";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import i18n from "translations/i18n";
import budgetSettingsFormSchema, { IBudgetSettingsForm } from "settings/yup/schemas/budgetSettingsSchema";
import { Budget, useGetBudgetSettingsLazyQuery, useUpdateBudgetMutation } from "generated/graphql";
import { TabComponentProps } from "views/Settings/types";
import { Container } from "components/Container";
import { AlertBox } from "components/AlertBox";

import { UPDATE_BUDGET_REFETCH_QUERIES } from "./graphql/updateBudgetMutation";
import { tabsQueryParams } from "../tabs";
import Form from "./Form";

export const BudgetSettings: React.FC<TabComponentProps> = ({
  description,
  title,
}) => {
  const newToast = useToast();

  const [params] = useQueryParams(tabsQueryParams);

  const [updateBudget, updateBudgetData] = useUpdateBudgetMutation({
    awaitRefetchQueries: true,
    refetchQueries: UPDATE_BUDGET_REFETCH_QUERIES,
  });

  const [getBudgetSettings, getBudgetData] = useGetBudgetSettingsLazyQuery();

  const budget = getBudgetData.data?.dealer?.currentBudget as Budget;

  const {
    setValue,
    control,
    formState,
    register,
    reset,
  } = useForm<IBudgetSettingsForm>({
    resolver: yupResolver(budgetSettingsFormSchema),
    mode: "all",
  });

  React.useEffect(() => {
    if (!params?.dealerId) {
      return;
    }

    reset({ currentTotalBudget: "" });

    getBudgetSettings({
      variables: {
        id: params?.dealerId ?? "",
      },
    })
      .then(() => {
        reset({ currentTotalBudget: "" });
      })
      .catch((error) => {
        if (error) {
          newToast({
            title: error?.name,
            description: error?.message,
            status: "error",
          });
        }
      });
  }, [
    getBudgetSettings,
    newToast,
    params,
    reset,
  ]);

  const onSubmit = React.useCallback((values: IBudgetSettingsForm) => {
    const totalBudget = Number(values.currentTotalBudget);

    const currentTotalBudget = totalBudget !== 0 ? totalBudget : null;

    updateBudget({
      variables: {
        id: budget?.id,
        params: {
          currentTotalBudget,
        },
      },
    })
      .then(() => {
        newToast({
          title: i18n.t("success.success"),
          description: i18n.t("screens.settings.tabs.budget.updated_successfully"),
          status: "success",
        });

        reset();
      })
      .catch(() => {
        newToast({
          status: "error",
          title: i18n.t("errors.something_went_wrong"),
        });
      });
  }, [
    updateBudget,
    newToast,
    budget,
    reset,
  ]);

  if (!params.dealerId) {
    return (
      <AlertBox
        label={i18n.t("leads.footer.please_select_a_dealer")}
      />
    );
  }

  return (
    <Container>
      <Box mb={8}>
        <Text textStyle="h1" mb={4}>{title}</Text>

        <Text textStyle="p">{description}</Text>
      </Box>

      {
        budget && (
          <Form
            budget={budget}
            loading={updateBudgetData.loading || getBudgetData.loading}
            handleSubmit={onSubmit}
            setValue={setValue}
            control={control}
            register={register}
            formState={formState}
          />
        )
      }
    </Container>
  );
};
