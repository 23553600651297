import React from "react";
import { useTranslation } from "react-i18next";

import { OfferCustomerTypeEnum } from "generated/graphql";
import RadioButton from "components/FormComponents/RadioButton";
import SectionCard from "components/SectionCard";

import { titleCss } from "./styles";
import { CustomerTypeSelectorProps } from "./types";
import { makeCustomerTypeOptions } from "./makeOptions";

const CustomerTypeSelector: React.FC<CustomerTypeSelectorProps> = (
  {
    onChange,
    defaultCustomerType,
  },
) => {
  const { t } = useTranslation();

  return (
    <SectionCard
      title={t("custom_offer.steps.financing_and_costs.customer")}
    >
      <RadioButton
        name="customerType"
        title={t("custom_offer.steps.financing_and_costs.type")}
        titleProps={{ titleCss }}
        defaultValue={defaultCustomerType}
        options={makeCustomerTypeOptions()}
        onChange={value => onChange(value as OfferCustomerTypeEnum)}
      />
    </SectionCard>
  );
};

export default CustomerTypeSelector;
