import React from "react";
import {
  Grid,
  GridItem,
} from "@chakra-ui/react";

import {
  GRID_COLUMNS,
  CONTAINER_PADDING,
} from "constants/layout";
import Sidebar from "components/Sidebar";

/**
 * Component that wraps the children with a grid layout and a sidebar.
 */
export const DefaultLayoutContainer: React.FC = ({
  children,
}) => (
  <Grid
    templateRows="repeat(1, 1fr)"
    templateColumns={GRID_COLUMNS}
    gap={CONTAINER_PADDING.XS}
    height="100vh"
  >
    <Sidebar />

    <GridItem
      colSpan={11}
      width="100%"
      flexDirection="column"
      display="flex"
    >
      {children}
    </GridItem>
  </Grid>
);
