import React, {
  FunctionComponent,
  FormEvent,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Flex, Text } from "@chakra-ui/react";

import { LeasingColumns, LeasingFactorsTableProps } from "components/Modals/EditVehicleModal/types";
import { MILEAGES, TERM_MONTHS } from "constants/leasing";
import { Leasing, DynamicLeasingRates } from "settings/yup/schemas/editVehicleSchema";
import i18n from "translations/i18n";
import Table from "components/Table";
import { numeralLocalized } from "utils/currencyFormat";
import RoundedExclamationMarkIcon from "components/Icons/RoundedExclamationMarkIcon";

import { MakeLeasingFactorColumns } from "./tableColumns";
import { MakeLeasingFactorFooterColumns } from "./tableFooterColumns";
import { makeDynamicLeasingRatesRows } from "./tableRows";

const normalizeFactors = (factor?: number | string): string => (factor
  ? numeralLocalized(parseFloat(String(factor)))
  : "");

const parseDynamicLeasingRates = (
  dynamicLeasingRates: DynamicLeasingRates,
): DynamicLeasingRates => (
  dynamicLeasingRates?.map(({
    id,
    type,
    mileageKm,
    termMonths,
    residualRate,
    residualFactor,
    salesFactor,
    nominalInterestRate,
  }) => ({
    id,
    type,
    termMonths,
    mileageKm,
    residualRate: normalizeFactors(residualRate),
    residualFactor: normalizeFactors(residualFactor),
    salesFactor: normalizeFactors(salesFactor),
    nominalInterestRate: normalizeFactors(nominalInterestRate),
  }))
);

const LeasingFactorsTable: React.FC<LeasingFactorsTableProps> = ({
  data = [],
  tableTitle,
  disclaimer,
  loading,
  setValue,
  getValues,
  factor,
  type: CustomerType,
}) => {
  const [rows, setRows] = useState<LeasingColumns[]>([]);

  useEffect(() => {
    const parsedLeasingRates = parseDynamicLeasingRates(data);
    setRows(makeDynamicLeasingRatesRows(parsedLeasingRates, CustomerType));
    setValue("dynamicLeasingRates", parsedLeasingRates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upsertLeasingValue = useCallback((newLeasingFactor: Leasing): void => {
    const dynamicLeasingRates = getValues()?.dynamicLeasingRates || [];

    const oldLeasingFactors = dynamicLeasingRates.map(dynamicLeasingFactor => ({
      ...dynamicLeasingFactor,
      internalId: `${dynamicLeasingFactor?.mileageKm}${dynamicLeasingFactor?.termMonths}${dynamicLeasingFactor?.type}`,
    }));

    const leasingFactorItem = oldLeasingFactors.find(
      ({ internalId }) => internalId === newLeasingFactor.internalId,
    );

    const newLeasingFactors: Leasing[] = leasingFactorItem
      ? oldLeasingFactors.map(actualLeasingFactor => {
        if (actualLeasingFactor.internalId === newLeasingFactor.internalId) {
          return {
            ...actualLeasingFactor,
            ...newLeasingFactor,
          };
        }

        return actualLeasingFactor;
      })
      : ([
        ...oldLeasingFactors,
        newLeasingFactor,
      ]);

    setValue("dynamicLeasingRates", newLeasingFactors);
    setRows(makeDynamicLeasingRatesRows(newLeasingFactors, CustomerType));
  }, [CustomerType, getValues, setValue, setRows]);

  const handleInputChange = (
    e: FormEvent<HTMLDivElement>,
    rowIndex: number,
  ): void => {
    const event = e as React.ChangeEvent<HTMLInputElement>;

    const termMonths: string | null = event.target.getAttribute("month");
    if (!termMonths) {
      return;
    }

    const newLeasingFactor: Leasing = {
      internalId: `${MILEAGES[rowIndex]}${termMonths}${CustomerType}`,
      mileageKm: MILEAGES[rowIndex],
      termMonths: parseInt(termMonths),
      [factor]: event.target.value || 0,
      type: CustomerType,
    };

    upsertLeasingValue(newLeasingFactor);
  };

  const copyColumns = useCallback((mileage: number): void => {
    const oldLeasingFactors = getValues()?.dynamicLeasingRates || [];
    const valueToCopy = oldLeasingFactors.find(lf => lf.mileageKm === mileage
      && lf.type === CustomerType && lf.termMonths === TERM_MONTHS[0]);

    TERM_MONTHS
      ?.map(month => ({
        internalId: mileage?.toString() + month?.toString() + CustomerType,
        type: CustomerType,
        mileageKm: mileage,
        [factor]: valueToCopy?.[factor],
        termMonths: month,
      }))
      .forEach(newLeasingFactor => upsertLeasingValue(newLeasingFactor));
  }, [
    CustomerType,
    getValues,
    upsertLeasingValue,
    factor,
  ]);

  const copyRows = useCallback((month: number): void => {
    const oldLeasingFactors = getValues()?.dynamicLeasingRates || [];
    const valueToCopy = oldLeasingFactors.find(lf => lf.mileageKm === MILEAGES[0]
      && lf.type === CustomerType && lf.termMonths === month);

    MILEAGES
      ?.map(mileage => ({
        internalId: mileage?.toString() + month?.toString() + CustomerType,
        type: CustomerType,
        mileageKm: mileage,
        [factor]: valueToCopy?.[factor],
        termMonths: month,
      }))
      .forEach(newLeasingFactor => upsertLeasingValue(newLeasingFactor));
  }, [
    CustomerType,
    getValues,
    upsertLeasingValue,
    factor,
  ]);

  const Title: FunctionComponent = ({ children }) => (
    <Text
      fontSize="16px"
      textStyle="h3"
      mt={3}
      mb={6}
    >
      {children}
    </Text>
  );

  const columns = useMemo(
    () => MakeLeasingFactorColumns(factor, copyColumns),
    [factor, copyColumns],
  );

  const footerColumns = useMemo(
    () => MakeLeasingFactorFooterColumns(factor, CustomerType, copyRows),
    [factor, CustomerType, copyRows],
  );

  return (
    <>
      <Title>
        {tableTitle}
      </Title>

      {
        disclaimer && (
          <Flex
            alignItems="center"
          >
            <RoundedExclamationMarkIcon
              color="secondary.500"
              mr={1}
            />

            <Text
              fontWeight="semibold"
              lineHeight={1}
              fontSize="xs"
            >
              {
                disclaimer
              }
            </Text>
          </Flex>
        )
      }

      <Table
        columns={columns}
        footerColumns={footerColumns}
        data={rows}
        emptyDataProps={{
          message: i18n.t("errors.something_went_wrong"),
          isDataLoading: loading,
        }}
        onChange={handleInputChange}
      />
    </>
  );
};

export default LeasingFactorsTable;
