import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const LeftArrowIcon: IconProps = createIcon({
  viewBox: "0 0 21.656 21.656",
  path: (
    <path
      style={{ transform: "rotate(0deg)" }}
      fill="currentColor"
      d="M21.656,9.474H5.184L12.75,1.908,10.828,0,0,10.828,10.828,21.656l1.908-1.908L5.184,12.181H21.656Z"
      transform="translate(21.656 21.656) rotate(-180)"
    />
  ),
});

export default LeftArrowIcon;
