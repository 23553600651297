import {
  Flex,
  HStack,
  Text,
  Input,
} from "@chakra-ui/react";
import { nanoid } from "nanoid";
import React, { useState } from "react";

import FieldErrorMessage from "components/FormComponents/FieldErrorMessage";
import i18n from "translations/i18n";
import getErrorMessage from "utils/getErrorMessage";

import TagList from "./TagList";
import { TagType, TagInputProps } from "./types";

const TagInput: React.FC<TagInputProps> = ({
  label,
  setValue,
  name,
  defaultValues,
  errors,
  ...rest
}) => {
  const [tags, setTags] = useState<TagType[]>(defaultValues || []);

  const error = getErrorMessage(name, errors);

  const handleCreate = (e?: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e?.key === "," || e?.key === "Enter") {
      const input = e.currentTarget?.value?.trim()?.split(",");

      if (input.length === 0 || input[0] === "") return;
      const newTag = { id: nanoid(), name: input[0] };
      setTags([newTag, ...tags]);
      e.currentTarget.value = "";
      setValue([newTag, ...tags]);
    }
  };

  const handleDelete = (id?: number | string): void => {
    const deletedTags = [...tags.filter((t: TagType) => t.id !== id)];
    setTags(deletedTags);
    setValue(deletedTags);
  };

  return (
    <HStack
      flexDir="column"
      w="full"
    >
      <Flex justifyContent="left" w="full">
        <Text
          fontWeight="bold"
          fontSize="sm"
          mb="2"
        >
          {label}
        </Text>
      </Flex>
      <Flex
        w="full"
        bg="gray.200"
        p="10px 10px 10px 5px"
        justifyContent="space-between"
        wrap="wrap"
        borderRightRadius="1rem"
      >
        <Flex
          flexWrap="wrap"
          maxHeight="100px"
          overflowY="auto"
          overflowX="hidden"
        >
          <TagList
            data={tags}
            handleDelete={handleDelete}
          />
        </Flex>
        <Flex w="full" flexDir="column">
          <Input
            background="transparent"
            name={name}
            placeholder={i18n.t("forms.tag_input_placeholder")}
            onKeyUp={e => handleCreate(e)}
            p={5}
            {...rest}
          />
          {error && (
            <FieldErrorMessage
              paddingLeft="1rem"
              error={error}
              mt="8px"
            />
          )}
        </Flex>
      </Flex>
    </HStack>
  );
};

export default TagInput;
