import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Flex, Skeleton } from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import { getCurrentLanguageCode } from "utils/getCurrentLanguageCode";
import { parseDateTime } from "utils/dateFormats";

import DateRangeDisplay from "./DateRangeDisplay";
import { getContainerWidth } from "./getContainerWidth";
import { getFormattedDisplayValue } from "./getFormattedDisplayValue";
import { DateRangeFilterProps, DateSelection } from "./types";

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  isMonthFilter = false,
  defaultStartDate,
  defaultEndDate,
  onChange,
}) => {
  const [startDate, setStartDate] = useState<DateSelection>(null);
  const [endDate, setEndDate] = useState<DateSelection>(null);
  const [setShouldCloseOnSelect, shouldCloseOnSelect] = useState(false);

  useEffect(() => {
    setStartDate(defaultStartDate || null);
    setEndDate(defaultEndDate || null);
  }, [
    defaultStartDate,
    defaultEndDate,
  ]);

  const { t } = useTranslation();

  const handleChangeSelect = (dates: Date | [Date, Date] | null): void => {
    shouldCloseOnSelect(false);
    if (dates === null) {
      return;
    }

    if (dates instanceof Date) {
      setStartDate(dates);
      setEndDate(null);

      shouldCloseOnSelect(false);
      return;
    }

    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    onChange?.([start, end]);

    shouldCloseOnSelect(!end);
  };

  useEffect(() => {
    if (startDate && endDate) {
      shouldCloseOnSelect(false);
    }
  }, [
    startDate,
    endDate,
  ]);

  const onClear = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    onChange?.([null, null]);
    shouldCloseOnSelect(false);
  }, [
    onChange,
  ]);

  const displayValue = useMemo(() => (
    startDate && endDate
      ? getFormattedDisplayValue(parseDateTime(startDate), parseDateTime(endDate), isMonthFilter)
      : t("components.table.filters.select_date_range")
  ), [
    startDate,
    endDate,
    isMonthFilter,
    t,
  ]);

  const skeletonWidth = getContainerWidth(isClearable);

  return (
    <Flex w="fit-content">
      <Skeleton
        isLoaded={!isLoading}
        w={skeletonWidth}
        borderRadius="md"
        h={10}
      >
        <ReactDatePicker
          shouldCloseOnSelect={setShouldCloseOnSelect}
          selectsEnd={Boolean(startDate)}
          onChange={handleChangeSelect}
          locale={getCurrentLanguageCode()}
          disabled={isDisabled}
          startDate={startDate}
          selected={startDate}
          endDate={endDate}
          selectsRange
          showMonthYearPicker={isMonthFilter}
          customInput={(
            <div>
              <DateRangeDisplay
                startDate={startDate}
                isClearable={isClearable}
                isDisabled={isDisabled}
                value={displayValue}
                onClear={onClear}
              />
            </div>
          )}
        />
      </Skeleton>
    </Flex>
  );
};

export default DateRangeFilter;
