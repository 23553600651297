import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Input, useToast } from "@chakra-ui/react";

import { FileTypeEnum, useUploadLeadFileMutation } from "generated/graphql";
import { iconsMap } from "constants/icons";
import Button from "components/Button";
import { VALID_LEAD_DOCUMENT_EXTENSIONS } from "constants/fileExtensions";
import getLeadCustomerType from "utils/getLeadCustomerType";
import trackGAEvent from "utils/trackGAEvent";
import gaEvents from "constants/gaEvents";

import { UploadButtonProps } from "./types";
import { getCheckoutLeadDocumentsQuery } from "../../graphql/getCheckoutLeadDocumentsQuery";

const UploadButton: React.FC<UploadButtonProps> = ({
  lead,
  fileType,
}) => {
  const { t } = useTranslation();
  const [uploadLeadFile] = useUploadLeadFileMutation();
  const showToast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = (): void => {
    inputRef?.current?.click();
  };

  const handleUploadFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files as FileList;

    showToast({
      status: "info",
      title: t("progress.uploading_files"),
    });

    const fileList = Array.from(files).map(file => file);

    if (files?.length) {
      uploadLeadFile({
        variables: {
          leadId: lead.id,
          files: fileList,
          fileType: fileType.type as FileTypeEnum,
        },
        refetchQueries: [{
          query: getCheckoutLeadDocumentsQuery,
          variables: {
            id: lead.id,
          },
        }],
        awaitRefetchQueries: true,
      })
        .then(() => {
          if (fileType.type === FileTypeEnum.HandlerContract) {
            const customerType = getLeadCustomerType(lead);

            trackGAEvent(gaEvents.contractUploadedByDealer(customerType));
          }

          showToast({
            status: "success",
            title: t("success.document_uploaded"),
          });
        })
        .catch(() => {
          showToast({
            status: "error",
            title: t("errors.something_went_wrong"),
          });
        });
    }
  }, [
    lead,
    showToast,
    t,
    fileType.type,
    uploadLeadFile,
  ]);

  return (
    <>
      <Button
        label={t("components.buttons.upload")}
        colorScheme="secondary"
        textStyle="p"
        w="130px"
        leftIcon={<iconsMap.UpArrowIcon />}
        onClick={handleUploadClick}
      />

      <Input
        display="none"
        onChange={handleUploadFile}
        accept={VALID_LEAD_DOCUMENT_EXTENSIONS}
        ref={inputRef}
        type="file"
        multiple
      />
    </>
  );
};

export default UploadButton;
