import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Flex, Link as ChakraLink } from "@chakra-ui/react";

import { ROOT_PAGE_PATH } from "routes";

const NotFound: React.FC = () => {
  // Console error on Not Found so we can catch these errors in the integration test,
  // since SPAs do not return HTTP status code 404
  // eslint-disable-next-line no-console
  console.error("Not found");

  const history = useHistory();

  useEffect(() => {
    const TIMEOUT_IN_MILLISECONDS = 2000;

    setTimeout(() => {
      history.push(ROOT_PAGE_PATH);
    }, TIMEOUT_IN_MILLISECONDS);
  }, [history]);

  return (
    <Flex
      width="100%"
      height="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <p>
        Page not found, redirecting to home…
      </p>

      <p>
        If you are not redirected automatically,
        {" "}
        <ChakraLink
          color="blue.500"
          as={Link}
          to={ROOT_PAGE_PATH}
        >
          click here
        </ChakraLink>
      </p>
    </Flex>
  );
};

export default NotFound;
