import React, { FunctionComponent, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParam } from "react-use";

import { useRouteProps } from "contexts/route";
import Header from "components/Header";
import i18n from "translations/i18n";
import { useCrmChannel } from "hooks/useCrmChannel";
import crmSearchParams from "constants/crmSearchParams";

import { AppWrapperProps, LayoutProps } from "./types";

/**
 * App wrapper. Can be used to render Header, Footer, etc.
 * @param param0 options
 */

const AppWrapper: React.FC<AppWrapperProps> = ({
  children,
  layout,
  title,
  Icon,
}) => {
  const Layout: FunctionComponent<LayoutProps> = layout;

  const {
    showHeader,
    FooterContent,
    footerVariant,
    titleBarButton,
    settingsHeaderTabs,
    dealerSelector,
  } = useRouteProps();

  const crmChannelQueryParam = useSearchParam(crmSearchParams.crmChannel);
  const { setCrmChannelCookie } = useCrmChannel();

  useEffect(() => {
    if (!crmChannelQueryParam) {
      return;
    }

    setCrmChannelCookie(crmChannelQueryParam);
  }, [
    crmChannelQueryParam,
    setCrmChannelCookie,
  ]);

  const htmlAttributes = {
    lang: i18n.language,
  };

  return (
    <div className="app-wrapper">
      <Helmet htmlAttributes={htmlAttributes} />

      {
        showHeader && (
          <Header />
        )
      }

      <Layout
        title={title}
        Icon={Icon}
        FooterContent={FooterContent}
        footerVariant={footerVariant}
        titleBarButton={titleBarButton}
        settingsHeaderTabs={settingsHeaderTabs}
        dealerSelector={!!dealerSelector}
      >
        {children}
      </Layout>
    </div>
  );
};

export default AppWrapper;
