import { VehicleFuelTypeEnum } from "generated/graphql";

export const fuelTypeMatcher = (
  fuelsList: VehicleFuelTypeEnum[],
) => (value?: VehicleFuelTypeEnum): boolean => {
  if (!value) {
    return false;
  }

  return fuelsList.includes(value);
};
