import React, { useEffect } from "react";
import { Stack, Flex } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { numberMask } from "constants/inputMasks";
import SectionCard from "components/SectionCard";
import Input from "components/FormComponents/Inputs/Input";
import RadioGroup from "components/FormComponents/RadioGroup";
import DatePicker from "components/FormComponents/Inputs/DatePicker";
import Checkbox from "components/FormComponents/Checkbox";
import { OfferTypeEnum } from "generated/graphql";

import { titleCss } from "../../styles";
import { makeVehicleTypeOptions } from "../makeOptions";
import { StandardDataFormProps } from "../types";

const VehicleCard: React.FC<StandardDataFormProps> = ({
  defaultFormValues,
  control,
  register,
  formState,
  watch,
  clearErrors,
  trigger,
}) => {
  const {
    vehicleType,
    registeredAt,
    drivenKm,
    availability,
  } = defaultFormValues || {};

  const { t } = useTranslation();
  const checkboxDisabled = watch("vehicleType") !== OfferTypeEnum.New;
  const fuelType = watch("fuelType");

  useEffect(() => {
    const vehicleTypeChanged = formState.isDirty && checkboxDisabled;
    const fuelTypeChanged = formState.isDirty && !!fuelType;

    if (vehicleTypeChanged || fuelTypeChanged) {
      clearErrors([
        "wltpConsumptionCombinedWeighted",
        "wltpConsumptionCombined",
        "wltpCarbonicEmissionCombinedClass",
        "wltpCarbonicEmissionCombinedWithDischargedBatteryClass",
        "wltpCarbonicEmissionWeightedCombinedClass",
        "wltpConsumptionCity",
        "wltpConsumptionSuburban",
        "wltpConsumptionRural",
        "wltpConsumptionHighway",
        "wltpCarbonicEmissionCombined",
        "wltpCarbonicEmissionCombinedWeighted",
        "wltpPowerConsumptionCombined",
        "fuelConsumptionCombined",
        "fuelConsumptionCity",
        "fuelConsumptionHighway",
        "carbonicEmission",
        "pollutantClass",
        "pollutantClassDetailed",
        "wltpPollutantClass",
        "wltpPollutantClassDetailed",
        "energyEfficiencyClass",
        "powerConsumptionCombined",
        "rangeKm",
      ]);
    }
  }, [
    checkboxDisabled,
    fuelType,
    formState.isDirty,
    clearErrors,
  ]);

  const vehicleTypeWatcher = watch("vehicleType");

  useEffect(() => {
    if (!vehicleTypeWatcher) {
      return;
    }

    trigger("registeredAt");
  }, [
    vehicleTypeWatcher,
    trigger,
  ]);

  return (
    <SectionCard
      title={t("custom_offer.steps.standard_data.vehicle_card.title")}
    >
      <Stack
        spacing={4}
        direction="row"
        mb={4}
      >
        <Controller
          name="vehicleType"
          control={control}
          defaultValue={vehicleType}
          render={({
            field,
          }) => (
            <RadioGroup
              {...field}
              title={t("custom_offer.steps.standard_data.vehicle_card.type")}
              titleProps={{ titleCss }}
              errors={formState.errors}
              spacing={75}
              options={makeVehicleTypeOptions()}
              direction="row"
            />
          )}
        />
      </Stack>
      <Flex
        w="min-content"
      >
        <Checkbox
          {...register("oneDayRegistration", {
            setValueAs: isChecked => (checkboxDisabled ? false : isChecked),
          })}
          {...(checkboxDisabled && { isChecked: false })}
          isDisabled={checkboxDisabled}
          w="min-content"
          mr={8}
        >
          {t("custom_offer.steps.standard_data.vehicle_card.one_day_registration")}
        </Checkbox>

        <Checkbox
          {...register("equipmentConfigurable", {
            setValueAs: isChecked => (checkboxDisabled ? false : isChecked),
          })}
          {...(checkboxDisabled && { isChecked: false })}
          isDisabled={checkboxDisabled}
          w="min-content"
        >
          {t("custom_offer.steps.standard_data.vehicle_card.equipment_configurable")}
        </Checkbox>
      </Flex>
      <Stack
        spacing={4}
        direction="row"
      >
        <Controller
          defaultValue={registeredAt}
          name="registeredAt"
          control={control}
          render={({
            field,
            fieldState,
          }) => (
            <DatePicker
              {...field}
              {...fieldState}
              title={t("custom_offer.steps.standard_data.vehicle_card.first_registration")}
              titleCss={titleCss}
              placeholder={t("custom_offer.steps.standard_data.vehicle_card.date_format")}
              errors={formState.errors}
              dateFormat="dd.MM.yy"
            />
          )}
        />

        <Input
          {...register("drivenKm", { valueAsNumber: true })}
          defaultValue={drivenKm}
          errors={formState.errors}
          mask={numberMask}
          title={t("custom_offer.steps.standard_data.vehicle_card.mileage")}
          titleCss={titleCss}
          placeholder={t("custom_offer.steps.standard_data.vehicle_card.insert_mileage")}
          leftAddon={null}
          rightAddon={(<span>KM</span>)}
          rightAddonProps={{
            color: "gray.400",
          }}
          isRequired
        />

        <Input
          {...register("availability", { valueAsNumber: true })}
          defaultValue={availability}
          errors={formState.errors}
          mask={numberMask}
          title={t("custom_offer.steps.standard_data.vehicle_card.availability")}
          titleCss={titleCss}
          placeholder={t("custom_offer.steps.standard_data.vehicle_card.insert_availability")}
        />
      </Stack>
    </SectionCard>
  );
};

export default VehicleCard;
