import yup from "settings/yup";
import i18n from "translations/i18n";

const requestLeadRejectionSchema = yup.object().shape({
  reason: yup
    .string()
    .required(i18n.t("yup.mixed_required_field"))
    .min(10, i18n.t("yup.string_min", {
      min: 10,
    })),
});

export interface RequestLeadRejectionSchema {
  reason: string;
}

export default requestLeadRejectionSchema;
