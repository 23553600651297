import React from "react";
import { Flex, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import { OFFERS_PAGE_PATH } from "routes/index";
import { useCreateOfferFromVehiclesMutation } from "generated/graphql";
import useNavigate from "hooks/useNavigate";
import { CUSTOM_OFFER_PAGE_LOCATION } from "routes/locations";
import useCustomOfferStore from "views/CustomOffer/store";

import useCreateNewOfferStore from "./store";

const CreateNewOfferFooter: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const navigate = useNavigate();
  const newToast = useToast();

  const { resetStore } = useCustomOfferStore();

  const {
    dealerId,
    selectedItem,
    clearSelectedItem,
  } = useCreateNewOfferStore();

  const canPublish = !Object.keys(selectedItem || {})?.length || !dealerId?.length;

  const [createOfferFromVehicles] = useCreateOfferFromVehiclesMutation({
    awaitRefetchQueries: true,
    refetchQueries: ["Offers"],
  });

  const onPublishSelected = (): void => {
    if (!dealerId) {
      return;
    }

    createOfferFromVehicles({
      variables: {
        dealerId,
        baseVehicleId: selectedItem.id,
      },
    })
      .then((result) => {
        clearSelectedItem();

        resetStore();

        history.push(CUSTOM_OFFER_PAGE_LOCATION.toUrl({
          id: result?.data?.createOfferFromVehicle?.id,
        }));
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
          isClosable: true,
        });
      });
  };

  return (
    <Flex justifyContent="space-between" w="100%">
      <Button
        boxShadow="md"
        variant="ghost"
        label={t("actions.cancel")}
        onClick={navigate(OFFERS_PAGE_PATH)}
      />
      <Button
        boxShadow="md"
        variant="solid"
        label={t("actions.create_custom_offer")}
        onClick={onPublishSelected}
        disabled={canPublish}
      />
    </Flex>
  );
};

export default CreateNewOfferFooter;
