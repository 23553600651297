import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import { SellerRoleEnum, useGetCurrentUserLazyQuery, UserRoleEnum } from "generated/graphql";
import { useSession } from "contexts/session";

import { CurrentUserContainerState, CurrentUserContextPayload } from "./types";
import { CurrentUserProvider } from ".";

const CurrentUserContainer: React.FC = ({
  children,
}) => {
  const [{ loading, currentUser }, setState] = useState<CurrentUserContainerState>({
    currentUser: null,
    loading: true,
  });

  const { isAuthenticated, endSession } = useSession();

  const [loadQuery, {
    loading: queryLoading,
    refetch: queryRefetch,
    called: queryCalled,
    error: queryError,
  }] = useGetCurrentUserLazyQuery({
    fetchPolicy: "network-only",
  });

  const handleRefetch = useCallback(() => {
    setState((state) => ({
      ...state,
      loading: true,
    }));

    return new Promise((resolve, reject) => {
      if (!queryRefetch) {
        return;
      }

      queryRefetch()
        .then((payload) => {
          const queryCurrentUser = payload?.data?.currentUser;

          setState({
            currentUser: queryCurrentUser ?? null,
            loading: false,
          });

          resolve(queryCurrentUser);
        })
        .catch((error) => {
          endSession();

          reject(error);
        });
    });
  }, [
    queryRefetch,
    endSession,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      handleRefetch();

      return;
    }

    setState({
      currentUser: null,
      loading: false,
    });
  }, [
    handleRefetch,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (queryCalled) {
      return;
    }

    loadQuery();
  }, [
    queryCalled,
    loadQuery,
  ]);

  const isAdmin = currentUser?.role === UserRoleEnum.Admin;
  const isSalesUser = currentUser?.seller?.role === SellerRoleEnum.Sales;

  const contextValue = useMemo<CurrentUserContextPayload>(() => [
    currentUser,
    {
      loading: (!queryCalled || loading || queryLoading),
      refetch: handleRefetch,
      error: queryError,
    },
    {
      isAdmin,
      isSalesUser,
    },
  ], [
    handleRefetch,
    queryLoading,
    queryCalled,
    currentUser,
    queryError,
    loading,
    isAdmin,
    isSalesUser,
  ]);

  return (
    <CurrentUserProvider value={contextValue}>
      {children}
    </CurrentUserProvider>
  );
};

export default CurrentUserContainer;
