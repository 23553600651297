import { gql } from "@apollo/client";

export const IMPORT_DISCOUNTS_MUTATION_REFETCH_QUERIES = [
  "CalculatorOffers",
];

export default gql`
  mutation ImportDiscounts (
    $csvFile: Upload!,
    $customerType: CustomerTypeEnum!,
    $dealerId: String,
    $shouldCreateBrandDiscounts: Boolean!,
    $shouldCreateDealerDiscounts: Boolean!
  ) {
    importDiscountsFromCsv(
      csvFile: $csvFile,
      customerType: $customerType
      dealerId: $dealerId
      shouldCreateBrandDiscounts: $shouldCreateBrandDiscounts
      shouldCreateDealerDiscounts: $shouldCreateDealerDiscounts
    )
  }
`;
