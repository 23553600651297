import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

export function Card(props: BoxProps): JSX.Element {
  return (
    <Box
      boxShadow="xl"
      rounded="lg"
      bg="white"
      px="10"
      py="8"
      {...props}
    />
  );
}
