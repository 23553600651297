import { Flex, Text } from "@chakra-ui/react";
import React from "react";

import Image from "../Image";
import { SelectableImageProps } from "../types";

const SelectableImage: React.FC<SelectableImageProps> = ({
  src,
  label,
  callback,
  ...props
}) => (
  <Flex
    flexDir="column"
    cursor="pointer"
    textAlign="center"
    onClick={callback}
    maxW="min-content"
  >
    <Image
      src={src}
      {...props}
    />

    <Text
      mt={1}
      wordBreak="break-all"
      textStyle="image-label"
    >
      {label}
    </Text>
  </Flex>
);

export default SelectableImage;
