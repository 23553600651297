import React, { FC } from "react";
import { Code } from "@chakra-ui/react";

import { EmptyDataMessage } from "components/EmptyDataMessage";

import { VideoProps } from "./types";

const LogsAsCode:FC<VideoProps> = ({ logs }) => {
  if (logs.length === 0) return <EmptyDataMessage />;

  return (
    <pre>
      <Code>
        {
          logs.map((item) => (
            <p key={item}>
              {item}
            </p>
          ))
        }
      </Code>
    </pre>
  );
};

export default LogsAsCode;
