import React from "react";
import { Flex } from "@chakra-ui/react";

import i18n from "translations/i18n";
import FakeTextField from "components/FormComponents/Inputs/FakeTextField";
import { formatDate, germanDateFormat } from "utils/dateFormats";

import { LeftSectionProps } from "./types";

const LeftSection: React.FC<LeftSectionProps> = ({ lead }) => (
  <Flex flex={1} direction="column">
    <Flex mb={30}>
      <FakeTextField
        value={lead?.salutation}
        label={i18n.t("leads.details.form.salutation")}
        w="25%"
      />

      <FakeTextField
        value={lead?.ceoFirstName}
        label={i18n.t("leads.details.form.first_name")}
        w="25%"
      />

      <FakeTextField
        value={lead?.ceoLastName}
        label={i18n.t("leads.details.form.last_name")}
        w="50%"
      />
    </Flex>

    <Flex mb={30}>
      <FakeTextField
        value={`${lead?.street ?? ""} ${lead?.number ?? ""}`}
        label={i18n.t("leads.details.form.street")}
        w="50%"
      />

      <FakeTextField
        value={lead?.companyZipCode}
        label={i18n.t("leads.details.form.postcode")}
        w="25%"
      />

      <FakeTextField
        value={lead?.companyCity}
        label={i18n.t("leads.details.form.city")}
        w="25%"
      />
    </Flex>

    <Flex mb={30}>
      <FakeTextField
        value={formatDate(lead?.residentSince, germanDateFormat, "-")}
        label={i18n.t("leads.details.form.resident_since")}
        w="50%"
      />

      <FakeTextField
        value={lead?.ceoPhoneNumber}
        label={i18n.t("leads.details.form.phone")}
        w="50%"
      />
    </Flex>
  </Flex>
);

export default LeftSection;
