import React from "react";
import {
  Flex,
  Stack,
  Box,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import i18n from "translations/i18n";
import TextField from "components/FormComponents/Inputs/TextField";
import Textarea from "components/FormComponents/Inputs/Textarea";
import Button from "components/Button";
import BankSelect from "components/FormComponents/Inputs/Selects/BankSelect";
import Select from "components/FormComponents/Inputs/Selects/Select";
import MonetaryField from "components/FormComponents/Inputs/MonetaryField";
import { calculatedWithFactorsOptions } from "constants/offer";

import CustomerTabs from "../CustomerTabs";
import ModalRow from "../ModalRow";
import { EditVehicleFormProps } from "../types";

const EditVehicleForm: React.FC<EditVehicleFormProps> = ({
  vehicle,
  register,
  handleSubmit,
  handleCancel,
  submitState,
  setValue,
  getValues,
  isLoading,
  control,
  formState,
  defaultFormValues,
}) => {
  const {
    baseListPriceNet,
    manufacturerBonusNet,
    configuratorLink,
    configurationNotes,
    calculateWithFactors,
  } = vehicle || {};

  const bankSelectDefaultValue = String(defaultFormValues?.bank?.id ?? "");

  const calculateWithFactorsDefaultValue = calculateWithFactors
    ? calculatedWithFactorsOptions[0].value
    : calculatedWithFactorsOptions[1].value;

  return (
    <form onSubmit={handleSubmit}>
      <ModalRow mt={0} mb={4}>
        <Box minWidth="sm">
          <Flex flexDirection="column" alignItems="center">
            <MonetaryField
              title={i18n.t("components.modals.edit_vehicle_modal.list_price")}
              defaultValue={baseListPriceNet}
              showErrorMessage
              mb={4}
              {...register("listPrice")}
            />

            <MonetaryField
              title={i18n.t("components.modals.edit_vehicle_modal.manufacturer_bonus")}
              defaultValue={manufacturerBonusNet}
              showErrorMessage
              mb={4}
              {...register("manufacturerBonusNet")}
            />

            <TextField
              defaultValue={configuratorLink}
              title={i18n.t("components.modals.edit_vehicle_modal.configurator_url")}
              showErrorMessage
              mb={4}
              {...register("configuratorLink")}
            />
          </Flex>
        </Box>

        <Box w="100%">
          <Textarea
            placeholder={configurationNotes || i18n.t("empty_data_messages.no_configuration_notes_for_this_vehicle").toString()}
            title={i18n.t("components.modals.edit_vehicle_modal.configurator_note")}
            showErrorMessage
            minHeight="203px"
            {...register("configurationNotes")}
          />
        </Box>
      </ModalRow>

      <ModalRow mt="3" mb="5" justifyContent="space-between" alignItems="center">
        <Controller
          control={control}
          name="bankId"
          defaultValue={bankSelectDefaultValue}
          render={({ field, fieldState }) => (
            <BankSelect
              {...field}
              {...fieldState}
              title={i18n.t("components.modals.edit_vehicle_modal.bank")}
              errors={formState?.errors}
              isClearable={false}
            />
          )}
        />

        <Controller
          name="calculateWithFactors"
          control={control}
          defaultValue={calculateWithFactorsDefaultValue}
          render={({
            field,
            fieldState,
          }) => (
            <Select
              {...field}
              {...fieldState}
              title={i18n.t("components.modals.edit_vehicle_modal.calculator_method")}
              options={calculatedWithFactorsOptions}
              errors={formState?.errors}
              isClearable={false}
            />
          )}
        />
      </ModalRow>

      <CustomerTabs
        vehicle={vehicle}
        register={register}
        setValue={setValue}
        getValues={getValues}
      />

      <Stack spacing="40px" mt="14">
        <Flex justifyContent="space-between">
          <Button
            label={i18n.t("components.buttons.cancel")}
            variant="ghost"
            onClick={handleCancel}
          />

          <Button
            type="submit"
            label={i18n.t("components.buttons.update")}
            isLoading={isLoading}
            isDisabled={!submitState}
          />
        </Flex>
      </Stack>
    </form>
  );
};

export default EditVehicleForm;
