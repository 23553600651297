import { AccessoryEnum } from "generated/graphql";
import i18n from "translations/i18n";

import { AccessoryCategoryEnum, ParsedAccessory } from "./types";

const defaultComfortAccessories = {
  category: AccessoryCategoryEnum.Comfort,
  items: [
    AccessoryEnum.AutomaticallyDimmingInteriorMirror,
    AccessoryEnum.HeatedWindshield,
    AccessoryEnum.HeatedSteeringWheel,
    AccessoryEnum.ElectricSideMirrors,
    AccessoryEnum.ElectricWindows,
    AccessoryEnum.ElectricSeatAdjustment,
    AccessoryEnum.AutomaticAirConditioning_2Zones,
    AccessoryEnum.AutomaticAirConditioning_3Zones,
    AccessoryEnum.AutomaticAirConditioning_4Zones,
    AccessoryEnum.LeatherSteeringWheel,
    AccessoryEnum.MassageSeat,
    AccessoryEnum.RainSensor,
    AccessoryEnum.CentralLockingSystem,
    AccessoryEnum.SeatVentilation,
    AccessoryEnum.SeatHeatingAtTheRear,
    AccessoryEnum.SportsSeats,
    AccessoryEnum.AuxiliaryHeating,
    AccessoryEnum.CruiseControl,
    AccessoryEnum.FoldablePassengerSeat,
    AccessoryEnum.ElectricSeatAdjustmentAtTheRear,
    AccessoryEnum.LumbarSupport,
    AccessoryEnum.SeatHeating,
    AccessoryEnum.TunerOrRadio,
    AccessoryEnum.VoiceHandsFreeSystem,
  ],
};

const defaultTechAccessories = {
  category: AccessoryCategoryEnum.Technology,
  items: [
    AccessoryEnum.Mp3Interface,
    AccessoryEnum.Wifi,
    AccessoryEnum.Bluetooth,
    AccessoryEnum.BoardComputer,
    AccessoryEnum.CdPlayer,
    AccessoryEnum.DabRadio,
    AccessoryEnum.HeadUpDisplay,
    AccessoryEnum.MultifunctionalSteeringWheel,
    AccessoryEnum.NavigationSystem,
    AccessoryEnum.SoundSystem,
    AccessoryEnum.VoiceControl,
    AccessoryEnum.AutomaticStartStop,
    AccessoryEnum.TouchScreen,
    AccessoryEnum.Tv,
    AccessoryEnum.Usb,
    AccessoryEnum.ElectricTailgate,
    AccessoryEnum.InductionChargingSmartphones,
    AccessoryEnum.IntegratedMusicStreaming,
    AccessoryEnum.AppleCarplay,
    AccessoryEnum.AndroidAuto,
  ],
};

const defaultSafetyAccessories = {
  category: AccessoryCategoryEnum.Safety,
  items: [
    AccessoryEnum.Abs,
    AccessoryEnum.Airbag,
    AccessoryEnum.AlarmSystem,
    AccessoryEnum.AllWeatherTires,
    AccessoryEnum.AllWheelDrive,
    AccessoryEnum.AmbientLight,
    AccessoryEnum.BiXenonLight,
    AccessoryEnum.BreakdownKit,
    AccessoryEnum.CentralArmrest,
    AccessoryEnum.ChildSeatAttachment,
    AccessoryEnum.CorneringLight,
    AccessoryEnum.DaytimeRunningLights,
    AccessoryEnum.DistanceCruiseControl,
    AccessoryEnum.DistanceWarning,
    AccessoryEnum.EmergencyBrakeAssistant,
    AccessoryEnum.EmergencyCallSystem,
    AccessoryEnum.EmergencyWheel,
    AccessoryEnum.Esp,
    AccessoryEnum.FatigueWarningSystem,
    AccessoryEnum.FogLights,
    AccessoryEnum.FullyDigitalInstrumentCluster,
    AccessoryEnum.GlareFreeHighBeam,
    AccessoryEnum.HeadlightCleaningSystem,
    AccessoryEnum.HighBeamAssistant,
    AccessoryEnum.HillStartAssistant,
    AccessoryEnum.Immobilizer,
    AccessoryEnum.LaneChangeAssistant,
    AccessoryEnum.LaneKeepingAssist,
    AccessoryEnum.LaserLightHeadlights,
    AccessoryEnum.LedDaytimeRunningLights,
    AccessoryEnum.LedHeadlights,
    AccessoryEnum.LightSensor,
    AccessoryEnum.NightVisionAssistant,
    AccessoryEnum.PaddleShifters,
    AccessoryEnum.ParkingAssistance,
    AccessoryEnum.ParkingAssistance_360DegreeCamera,
    AccessoryEnum.ParkingAssistanceCamera,
    AccessoryEnum.ParkingAssistanceFront,
    AccessoryEnum.ParkingAssistanceRear,
    AccessoryEnum.ParkingAssistanceSelfSteeringSystem,
    AccessoryEnum.ParticleFilter,
    AccessoryEnum.PowerSteering,
    AccessoryEnum.SpeedLimitSystem,
    AccessoryEnum.TirePressureMonitoringSystem,
    AccessoryEnum.TractionControl,
    AccessoryEnum.TrafficSignRecognition,
  ],
};

const getAccessoryLabel = (str: string): string => i18n.t(`accessories.${str}`);

/**
 * @param availableAccessories The list of accessories that are available for the given vehicle
 * @returns an list of accessories splitted by accessory category.
 * Empty categories will be ignored and will not be returned.
 */
export const getAccessoriesList = (
  availableAccessories?: AccessoryEnum[],
): ParsedAccessory[][] | [] => {
  const comfort = [] as ParsedAccessory[];
  const safety = [] as ParsedAccessory[];
  const technology = [] as ParsedAccessory[];
  const other = [] as ParsedAccessory[];

  availableAccessories?.forEach(accessory => {
    const label = getAccessoryLabel(accessory);

    if (defaultTechAccessories.items.includes(accessory)) {
      return technology.push({
        category: defaultTechAccessories.category,
        value: accessory,
        label,
      });
    }

    if (defaultSafetyAccessories.items.includes(accessory)) {
      return safety.push({
        category: defaultSafetyAccessories.category,
        value: accessory,
        label,
      });
    }

    if (defaultComfortAccessories.items.includes(accessory)) {
      return comfort.push({
        category: defaultComfortAccessories.category,
        value: accessory,
        label,
      });
    }

    return other.push({
      category: AccessoryCategoryEnum.Other,
      value: accessory,
      label,
    });
  });

  return [
    comfort,
    technology,
    safety,
    other,
  ].filter(el => el.length > 0);
};
