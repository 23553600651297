import React from "react";
import { useTranslation } from "react-i18next";

import { iconsMap } from "constants/icons";
import Button from "components/Button";

import { DownloadButtonProps } from "./types";

const DownloadButton: React.FC<DownloadButtonProps> = ({
  onClick,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Button
      disabled={disabled}
      colorScheme="secondary"
      variant="outline"
      textStyle="p"
      w="130px"
      leftIcon={<iconsMap.DownArrowIcon />}
      onClick={onClick}
      label={t("components.buttons.download")}
      _disabled={{
        opacity: "30%",
      }}
      {...props}
    />
  );
};

export default DownloadButton;
