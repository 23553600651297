import React from "react";
import { Text } from "@chakra-ui/react";

import { FieldErrorMessageProps } from "./types";

const FieldErrorMessage: React.FC<FieldErrorMessageProps> = ({
  validationMessage,
  error,
  hasCustomErrorCss,
  ...props
}) => {
  if (!error) return null;

  return (
    <Text
      textStyle="small"
      textAlign="start"
      color={error ? "error.500" : "gray.500"}
      height="12px"
      css={hasCustomErrorCss ? {
        fontSize: "10px",
        display: "block",
        position: "absolute",
        bottom: "7px",
        textTransform: "uppercase",
      } : undefined}
      {...props}
    >
      {error ?? validationMessage}
    </Text>
  );
};

export default FieldErrorMessage;
