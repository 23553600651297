import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Flex, Stack, Text, useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { useModal } from "contexts/modal";
import {
  CreateBrandParams,
  UpdateBrandParams,
  useCreateBrandMutation,
  useUpdateBrandMutation,
} from "generated/graphql";
import createBrandSchema, { CreateUpdateBrandSchema } from "settings/yup/schemas/createBrandSchema";
import Button from "components/Button";
import Input from "components/FormComponents/Inputs/Input";
import Select from "components/FormComponents/Inputs/Selects/Select";
import Textarea from "components/FormComponents/Inputs/Textarea";
import BankSelect from "components/FormComponents/Inputs/Selects/BankSelect";

import { BrandsModalProps } from "./types";
import { makeImportOptions } from "./makeImportOptions";
import { CREATE_BRAND_REFETCH_QUERIES } from "./graphql/createBrand";
import { UPDATE_BRAND_REFETCH_QUERIES } from "./graphql/updateBrand";
import AlertModal from "../AlertModal";

const BrandsModal: React.FC<BrandsModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();
  const [showModal] = useModal();
  const newToast = useToast();

  const {
    handleSubmit,
    formState,
    register,
    control,
  } = useForm<CreateUpdateBrandSchema>({
    resolver: yupResolver(createBrandSchema),
    mode: "onChange",
  });

  const [createBrand] = useCreateBrandMutation({
    awaitRefetchQueries: true,
    refetchQueries: CREATE_BRAND_REFETCH_QUERIES,
  });

  const [updateBrand] = useUpdateBrandMutation({
    awaitRefetchQueries: true,
    refetchQueries: UPDATE_BRAND_REFETCH_QUERIES,
  });

  const handleCancel = useCallback(() => {
    hideModal();
  }, [
    hideModal,
  ]);

  const onSubmit = useCallback((values: CreateUpdateBrandSchema) => {
    const brandParams = {
      name: values.name || "",
      desktopVideoUrl: values.desktopVideoUrl,
      mobileVideoUrl: values.mobileVideoUrl,
      importMethod: values.importMethod,
      priceNotes: values.priceNotes,
      privateCustomerBankId: Number(values.privateCustomerBankId) || null,
      corporateCustomerBankId: Number(values.corporateCustomerBankId) || null,
    };

    if (componentProps?.brand?.id) {
      return updateBrand({
        variables: {
          id: componentProps.brand.id,
          params: brandParams as UpdateBrandParams,
        },
      })
        .then(() => {
          showModal({
            componentProps: {
              title: t("components.modals.brands.updated_successfully"),
              variant: "success",
            },
            closeOnOverlayClick: false,
            component: AlertModal,
            size: "xl",
          });
        })
        .catch(error => {
          hideModal();
          newToast({
            title: t("errors.something_went_wrong"),
            description: error.message,
            status: "error",
          });
        });
    }

    return createBrand({
      variables: {
        params: brandParams as CreateBrandParams,
      },
    })
      .then(() => {
        showModal({
          componentProps: {
            title: t("components.modals.brands.created_successfully"),
            variant: "success",
          },
          closeOnOverlayClick: false,
          component: AlertModal,
          size: "xl",
        });
      })
      .catch(error => {
        hideModal();
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
        });
      });
  }, [
    componentProps,
    createBrand,
    updateBrand,
    hideModal,
    newToast,
    showModal,
    t,
  ]);

  const importOptions = makeImportOptions();

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        h="full"
        w="full"
        p={6}
        spacing={6}
      >
        <Text
          lineHeight="24px"
          fontSize="20px"
          textStyle="h3"
          mb={6}
        >
          {t("components.modals.brands.create_title")}
        </Text>

        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Flex width="50%" marginRight="10px">
            <Input
              {...register("name")}
              errors={formState.errors}
              title={t("components.modals.brands.name_field")}
              defaultValue={componentProps?.brand?.name}
            />
          </Flex>

          <Flex width="50%">
            <Controller
              name="importMethod"
              control={control}
              defaultValue={componentProps?.brand?.importMethod}
              render={({
                field,
                fieldState,
              }) => (
                <Select
                  {...field}
                  {...fieldState}
                  errors={formState.errors}
                  options={importOptions}
                  title={t("components.modals.brands.import_method_field")}
                />
              )}
            />
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Flex width="50%" marginRight="10px">
            <Input
              {...register("mobileVideoUrl")}
              errors={formState.errors}
              title={t("components.modals.brands.mobile_video_field")}
              defaultValue={componentProps?.brand?.mobileVideoUrl}
            />
          </Flex>

          <Flex width="50%">
            <Input
              {...register("desktopVideoUrl")}
              errors={formState.errors}
              title={t("components.modals.brands.desktop_video_field")}
              defaultValue={componentProps?.brand?.desktopVideoUrl}
            />
          </Flex>
        </Flex>

        <Flex
          width="100%"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Flex width="50%" marginRight="10px">
            <Controller
              name="privateCustomerBankId"
              control={control}
              defaultValue={componentProps?.brand?.privateCustomerBank?.id?.toString()}
              render={({
                field,
                fieldState,
              }) => (
                <BankSelect
                  {...field}
                  {...fieldState}
                  title={t("components.modals.brands.private_customer_bank")}
                />
              )}
            />
          </Flex>

          <Flex width="50%" marginRight="10px">
            <Controller
              name="corporateCustomerBankId"
              control={control}
              defaultValue={componentProps?.brand?.corporateCustomerBank?.id?.toString()}
              render={({
                field,
                fieldState,
              }) => (
                <BankSelect
                  {...field}
                  {...fieldState}
                  title={t("components.modals.brands.corporate_customer_bank")}
                />
              )}
            />
          </Flex>
        </Flex>

        <Textarea
          {...register("priceNotes")}
          errors={formState.errors}
          title={t("components.modals.brands.notes_field")}
          placeholder=""
          defaultValue={componentProps?.brand?.priceNotes}
        />
      </Stack>

      <Flex
        justifyContent="space-between"
        borderRadius="0 0 8px 8px"
        bg="gray.200"
        w="full"
        p={6}
      >
        <Button
          label={t("components.buttons.cancel")}
          onClick={handleCancel}
          variant="ghost"
          bgColor="white"
          boxShadow="md"
        />

        <Button
          label={t("components.buttons.save")}
          onClick={handleSubmit(onSubmit)}
          isDisabled={!formState.isValid}
          boxShadow="md"
        />
      </Flex>
    </>
  );
};

export default BrandsModal;
