import React from "react";

import Button from "components/Button";

import { isNotSelectedCss, isSelectedCss, toggleButtonCss } from "./styles";
import { PillButtonProps } from "./types";

const PillButton: React.FC<PillButtonProps> = ({
  label,
  onClick,
  isSelected = false,
}) => {
  const isSelectedStyles = isSelected ? isSelectedCss : isNotSelectedCss;

  return (
    (
      <Button
        variant="unstyled"
        label={label}
        colorScheme="secondary"
        textStyle="p"
        __css={{
          ...toggleButtonCss,
          ...isSelectedStyles,
        }}
        onClick={onClick}
      />
    )
  );
};

export default PillButton;
