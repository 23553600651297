import React from "react";
import {
  Control, FormState, UseFormRegister, UseFormSetValue, UseFormWatch,
} from "react-hook-form";

import { OfferFuelTypeEnum } from "generated/graphql";
import { StandardDataSchema } from "settings/yup/schemas/standardDataSchema";

import { RegisterProps, StandardDataFormProps, StandardDataProps } from "../../types";
import { WLTP } from "./Sections/WLTP";
import { NEFZ } from "./Sections/NEFZ";
import { Battery } from "./Sections/Battery";

export interface SectionDefaultProps extends RegisterProps {
  defaultFormValues: StandardDataProps["defaultFormValues"];
  watch: UseFormWatch<StandardDataSchema>;
  control: Control<StandardDataSchema>;
  formState: FormState<StandardDataSchema>;
  register: UseFormRegister<StandardDataSchema>;
  setValue: UseFormSetValue<StandardDataSchema>;
}

type FuelConsumptionMapping = Record<
  OfferFuelTypeEnum, Array<(props: SectionDefaultProps) => JSX.Element> | undefined
>

type SectionComponent = (props: SectionDefaultProps) => JSX.Element

const commonSections = ({
  default: [WLTP, NEFZ],
  withBattery: [WLTP, NEFZ, Battery],
});

const fueldConsumptionSectionsMapping: FuelConsumptionMapping = ({
  [OfferFuelTypeEnum.Diesel]: commonSections.default,
  [OfferFuelTypeEnum.Petrol]: commonSections.default,
  [OfferFuelTypeEnum.Lpg]: commonSections.default,
  [OfferFuelTypeEnum.NaturalGas]: commonSections.default,
  [OfferFuelTypeEnum.Hybrid]: commonSections.withBattery,
  [OfferFuelTypeEnum.HybridDiesel]: commonSections.withBattery,
  [OfferFuelTypeEnum.Electric]: commonSections.withBattery,
  [OfferFuelTypeEnum.Hydrogen]: commonSections.default,
  [OfferFuelTypeEnum.Ethanol]: commonSections.default,
  [OfferFuelTypeEnum.Other]: undefined,
});

const getSections = (fuelType: OfferFuelTypeEnum): SectionComponent[] | undefined => (
  fueldConsumptionSectionsMapping[fuelType]
);

export const FuelConsumption = ({
  defaultFormValues,
  control,
  register,
  formState,
  watch,
  setValue,
  ...rest
}: StandardDataFormProps): JSX.Element | null => {
  const fuelType = watch("fuelType");

  const Components = getSections(fuelType);

  if (!Components) {
    return null;
  }

  return (
    <>
      {
        Components.map((Section) => (
          <Section
            defaultFormValues={defaultFormValues}
            formState={formState}
            register={register}
            control={control}
            watch={watch}
            setValue={setValue}
            key={`${Section.name}`}
            {...rest}
          />
        ))
      }
    </>
  );
};
