import { hasDefaultMileage, hasDefaultMonth } from "components/Modals/EditLeasingRatesModal/LeasingRatesTable/leasingRatesHelper";
import addCustomElements from "components/Modals/EditVehicleModal/LeasingFactorsTable/addCustomElements";
import { TERM_MONTHS, MILEAGES, CUSTOM_FIELD_REPRESENTATION } from "constants/leasing";
import { CustomerTypeEnum } from "generated/graphql";

import { LeasingColumns, FixedLeasingRates } from "../types";

export const makeFixedLeasingRatesRows = (
  fixedLeasingRates?: FixedLeasingRates,
  customerType?: CustomerTypeEnum,
): LeasingColumns[] => {
  const withCustomData = fixedLeasingRates?.map(
    lf => {
      const termMonths = hasDefaultMonth(lf?.termMonths) ? Number(lf?.termMonths) : 0;
      const mileageKm = hasDefaultMileage(lf?.mileageKm) ? Number(lf?.mileageKm) : 0;
      const id = Number(`${termMonths}${mileageKm}`);

      return {
        ...lf,
        id,
      };
    },
  );

  const withCustomMileages = addCustomElements(MILEAGES);
  const withCustomMonths = addCustomElements(TERM_MONTHS);

  const rows = withCustomMileages.map((mileage) => {
    const columns = withCustomMonths?.reduce((acc, month) => {
      const factor = withCustomData?.find((lf) => {
        const id = Number(`${month}${mileage}`);
        const monthAndMileage = (lf.id !== CUSTOM_FIELD_REPRESENTATION && lf.id === id)
          && (month !== CUSTOM_FIELD_REPRESENTATION)
          && (mileage !== CUSTOM_FIELD_REPRESENTATION);

        const customMonth = (lf.id !== CUSTOM_FIELD_REPRESENTATION && lf.id === id)
          && (month === CUSTOM_FIELD_REPRESENTATION);

        const customMileage = (lf.id !== CUSTOM_FIELD_REPRESENTATION && lf.id === id)
          && (mileage === CUSTOM_FIELD_REPRESENTATION);

        const customMonthAndCustomMileage = (lf.id === CUSTOM_FIELD_REPRESENTATION)
          && (month === CUSTOM_FIELD_REPRESENTATION)
          && (mileage === CUSTOM_FIELD_REPRESENTATION);

        return (monthAndMileage
          || customMonth
          || customMileage
          || customMonthAndCustomMileage
        ) && lf.type === customerType;
      });

      return {
        ...acc,
        [month]: factor || {},
      };
    }, {});

    return columns as LeasingColumns;
  });

  return rows;
};
