import React from "react";
import {
  Flex, Stack, Text, useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "components/Button";
import { EditCalculatorOfferSchema, editCalculatorOfferSchema } from "settings/yup/schemas/editCalculatorOfferSchema";
import { CustomerTypeEnum, useUpdateVehicleCalculatorInfoMutation } from "generated/graphql";

import { EditCalculatorOfferModalProps } from "./types";
import Form from "./Form";
import { UPDATE_VEHICLE_CALCULATOR_INFO_REFETCH_QUERIES } from "./graphql/updateVehicleCalculatorInfo";
import { getDefaultValues } from "./getDefaultValues";
import { getParsedParams } from "./getParsedParams";

export const EditCalculatorOfferModal: React.FC<EditCalculatorOfferModalProps> = ({
  componentProps,
  hideModal,
}) => {
  const { t } = useTranslation();

  const newToast = useToast();

  const data = componentProps?.data;

  const defaultValues = getDefaultValues(data);

  const {
    setValue,
    control,
    formState,
    handleSubmit,
  } = useForm<EditCalculatorOfferSchema>({
    resolver: yupResolver(editCalculatorOfferSchema),
    defaultValues,
    mode: "onChange",
  });

  const [
    updateVehicleCalculatorInfo,
  ] = useUpdateVehicleCalculatorInfoMutation();

  const onSubmit = React.useCallback((formData: EditCalculatorOfferSchema): void => {
    if (!data?.dealer?.id || !data?.vehicle?.id || !formData.customerType) {
      return;
    }

    const params = getParsedParams(formData);

    updateVehicleCalculatorInfo({
      variables: {
        customerType: CustomerTypeEnum[formData.customerType],
        dealerId: data.dealer.id,
        vehicleId: data.vehicle.id,
        params,
      },
      refetchQueries: UPDATE_VEHICLE_CALCULATOR_INFO_REFETCH_QUERIES,
    })
      .then(() => {
        newToast({
          title: t("success.success"),
          description: t("success.vehicle_updated"),
          status: "success",
        });

        hideModal();
      })
      .catch((error) => {
        newToast({
          title: t("errors.something_went_wrong"),
          description: error.message,
          status: "error",
        });
      });
  }, [
    updateVehicleCalculatorInfo,
    hideModal,
    newToast,
    data,
    t,
  ]);

  return (
    <Flex
      overflow="hidden"
      flexDir="column"
      p="6"
    >
      <Flex
        flexDir="column"
        overflow="auto"
      >
        <Stack>
          <Flex p={4}>
            <Text
              fontWeight="600"
              fontSize="24px"
              textStyle="h2"
            >
              {t("components.modals.edit_calculator_offer_modal.title")}
            </Text>
          </Flex>

          <Form
            deliveryPricesDefaultValues={defaultValues?.deliveryPrices}
            formState={formState}
            setValue={setValue}
            control={control}
          />

        </Stack>
      </Flex>

      <Stack
        justifyContent="space-between"
        direction="row"
        spacing="40px"
        mt="6"
      >
        <Button
          label={t("actions.cancel")}
          onClick={() => hideModal()}
          variant="contained"
          color="primary.600"
          boxShadow="md"
          fontSize="sm"
          size="md"
        />

        <Button
          label={t("actions.update")}
          onClick={handleSubmit(onSubmit)}
        />
      </Stack>
    </Flex>
  );
};
