import React from "react";
import { createIcon } from "@chakra-ui/react";

import { IconProps } from "components/Icons/types";

const FlagIcon: IconProps = createIcon({
  viewBox: "0 0 45.721 49.531",
  path: (
    <path
      fill="currentColor"
      d="M27.387,5.827,26.623,2a2.477,2.477,0,0,0-2.445-2H3.138A3.138,3.138,0,0,0,0,3.138V46.616a2.915,2.915,0,0,0,2.912,2.915h0a2.915,2.915,0,0,0,2.915-2.915V29.135H22.144l.782,3.824a2.477,2.477,0,0,0,2.445,2H41.723a4,4,0,0,0,4-4V9.825a4,4,0,0,0-4-4Z"
    />
  ),
});

export default FlagIcon;
