import React, {
  useMemo, useCallback, useEffect, useState,
} from "react";
import { UrlUpdateType, useQueryParams } from "use-query-params";
import {
  Wrap, Flex, Tooltip, Box,
} from "@chakra-ui/react";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";
import ConditionalWrap from "conditional-wrap";
import { useHistory } from "react-router-dom";

import {
  OfferCustomerTypeEnum,
  CustomerTypeEnum,
  OfferCreationTypeEnum,
  VehicleCategoryEnum,
  VehicleTypeEnum,
  LeadStatusEnum,
} from "generated/graphql";
import PillButton from "components/PillButton";
import SearchIcon from "components/Icons/SearchIcon";
import DateRangeFilter from "components/DateRangeFilter";
import Input from "components/FormComponents/Inputs/Input";
import Select from "components/FormComponents/Inputs/Selects/Select";
import DealerSelect from "components/FormComponents/Inputs/Selects/DealerSelect";
import VehicleSelect from "components/FormComponents/Inputs/Selects/VehicleSelect";
import CategorySelect from "components/FormComponents/Inputs/Selects/CategorySelect";
import DealerUsersSelect from "components/FormComponents/Inputs/Selects/DealerUsersSelect";
import LeadStatusMultiSelect from "components/FormComponents/Inputs/Selects/LeadStatusMultiSelect";
import { SelectedVehicleProps } from "components/FormComponents/Inputs/Selects/VehicleSelect/types";
import { parseNulls } from "utils/parseNulls";
import { formatDate } from "utils/dateFormats";
import { parseBoolean } from "utils/parseBoolean";
import { GRID_GAP } from "constants/layout";
import { usePagination } from "contexts/pagination";
import { PAGINATION_LIMIT } from "constants/pagination";
import { BILLING_LEADS_OVERVIEW_PAGE_PATH } from "routes";

import {
  Filter,
  Filters,
  UseFilterProps,
  UseFiltersPayload,
  OptionsProps,
} from "./types";
import vehicleTypeOptions from "./vehicleTypeOptions";
import customerTypeOptions from "./customerTypeOptions";
import offerCustomerTypeOptions from "./offerCustomerTypeOptions";
import creationTypeOptions from "./creationTypeOptions";
import { buildFilters } from "./filters";
import { wrapChildrenStyles } from "./styles";

/**
 * Hook responsible for managing filters state in query params.
 *
 * It allows users to select:
 * - Dealer
 * - Brand & Model Group
 * - Creation Type
 * - Customer Type
 * - Offer Customer Type
 * - Only active
 * - Top Offer
 * - Marketing Offer
 * - Has pricing information
 * - Search
 * - Vehicle Category
 * - Has residual information
 * - Is possible to enable new configurator
 * - Is new configurator enabled
 */

const useFilters = (filters: UseFilterProps, options?: OptionsProps): UseFiltersPayload => {
  const [{
    dealerId,
    brandId,
    brandName,
    modelGroup,
    model,
    vehicleCategory,
    vehicleModel,
    vehicleType,
    customerType,
    offerCustomerType,
    creationType,
    topOffer,
    active,
    marketingOffer,
    hasPricingInformation,
    search,
    hasResidualInformation,
    step,
    period,
    leadStatus,
    salesperson,
    isPossibleToEnableNewConfigurator,
    isNewConfiguratorEnabled,
  }, setQuery] = useQueryParams(buildFilters(filters));

  const { location } = useHistory();

  const [dirtyFields, setDirtyField] = useState({});

  const [searchTerm] = useDebounce(search, 500);

  const { t } = useTranslation();

  const { setPaginationLimit } = usePagination();

  useEffect(() => {
    setPaginationLimit(PAGINATION_LIMIT);
  }, [
    setPaginationLimit,
    dealerId,
    brandId,
    modelGroup,
    model,
    customerType,
  ]);

  const isFilterEnabled = useCallback((filter: Filter): boolean => (
    filters.includes(filter)
  ), [filters]);

  const handleSearchChange = useCallback(({ target: { value } }) => {
    setQuery({ search: value });
  }, [setQuery]);

  const handleVehicleChange = useCallback((changes: SelectedVehicleProps): void => {
    setQuery({ ...changes });
  }, [setQuery]);

  const handleChange = useCallback((field: string) => (value: unknown): void => {
    setQuery({ [field]: value });

    setDirtyField({
      ...dirtyFields,
      [field]: value,
    });
  }, [setQuery, setDirtyField, dirtyFields]);

  const handleSetFilter = useCallback((
    filter: string,
    value: unknown,
    mode: UrlUpdateType = "pushIn",
  ): void => {
    setQuery({ [filter]: value }, mode);
  }, [setQuery]);

  const toggle = useCallback((field: string) => (): void => {
    const value = {
      active,
      topOffer,
      marketingOffer,
      hasPricingInformation,
      hasResidualInformation,
      isPossibleToEnableNewConfigurator,
      isNewConfiguratorEnabled,
    }[field];

    setQuery({ [field]: !value });
  }, [
    setQuery,
    active,
    topOffer,
    marketingOffer,
    hasPricingInformation,
    hasResidualInformation,
    isPossibleToEnableNewConfigurator,
    isNewConfiguratorEnabled,
  ]);

  const renderFilters = useMemo(() => {
    const availableFilters = {
      [Filters.Dealer]: () => (
        <Flex w={200} key="dealerId">
          <DealerSelect
            onlyCalculatorDealers={options?.Dealer?.onlyCalculatorDealers}
            onChange={handleChange("dealerId")}
            value={dealerId}
          />
        </Flex>
      ),
      [Filters.VehicleType]: () => (
        <Flex w={200} key="vehicleType">
          <Select
            placeholder={t("components.selects.vehicle_type")}
            options={vehicleTypeOptions}
            onChange={handleChange("vehicleType")}
            value={vehicleType}
          />
        </Flex>
      ),
      [Filters.Vehicle]: () => (
        <VehicleSelect
          key="vehicleChange"
          onChange={handleVehicleChange}
          isModelActive={isFilterEnabled(Filters.VehicleModel)}
          value={{
            brandId,
            brandName,
            modelGroup,
            model,
          }}
        />
      ),
      [Filters.VehicleCategory]: () => (
        <Flex w={200} key="vehicleCategory">
          <CategorySelect
            onChange={handleChange("vehicleCategory")}
            value={vehicleCategory}
          />
        </Flex>
      ),
      [Filters.Period]: () => (
        <Flex key="period">
          <DateRangeFilter
            onChange={(dates: [Date | null, Date | null]) => {
              const [startDate, endDate] = dates;

              handleChange("period")([formatDate(startDate || ""), formatDate(endDate || "")]);
            }}
            defaultStartDate={period?.[1] ? new Date(period?.[0]) : null}
            defaultEndDate={period?.[1] ? new Date(period?.[1]) : null}
            isClearable
            isMonthFilter={location.pathname === BILLING_LEADS_OVERVIEW_PAGE_PATH}
          />
        </Flex>
      ),
      [Filters.Salesperson]: () => (
        <Flex w={200} key="salesperson">
          <ConditionalWrap
            condition={!dealerId}
            wrap={(content) => (
              <Tooltip
                label={t("leads.footer.please_select_a_dealer")}
                color="secondary.500"
                bg="gray.100"
                hasArrow
              >
                <Box w={200}>{content}</Box>
              </Tooltip>
            )}
          >
            <DealerUsersSelect
              placeholder={t("leads.details.form.salesperson")}
              onChange={handleChange("salesperson")}
              defaultValue={salesperson}
              dealerId={dealerId}
            />
          </ConditionalWrap>
        </Flex>
      ),
      [Filters.LeadStatus]: () => (
        <Flex minW={150} maxW={713} key="leadStatus">
          <LeadStatusMultiSelect
            placeholder={t("leads.details.form.status")}
            onChange={handleChange("leadStatus")}
            defaultValue={leadStatus}
            initialValues={options?.LeadStatus?.initialValues}
            availableLeadStatus={options?.LeadStatus?.availableLeadStatus}
            isClearable
          />
        </Flex>
      ),
      [Filters.HasResidualInformation]: () => (
        <PillButton
          isSelected={hasResidualInformation}
          label={t("components.table.filters.has_residual_information")}
          onClick={toggle("hasResidualInformation")}
          key="hasResidualInformation"
        />
      ),
      [Filters.CustomerType]: () => (
        <Flex w={200} key="customerType">
          <Select
            placeholder={t("components.selects.customer_type")}
            options={customerTypeOptions}
            onChange={handleChange("customerType")}
            value={customerType}
          />
        </Flex>
      ),
      [Filters.OfferCustomerType]: () => (
        <Flex w={200} key="offerCustomerType">
          <Select
            placeholder={t("components.selects.customer_type")}
            options={offerCustomerTypeOptions}
            onChange={handleChange("offerCustomerType")}
            value={offerCustomerType}
          />
        </Flex>
      ),
      [Filters.CreationType]: () => (
        <Flex w={200} key="creationType">
          <Select
            placeholder={t("components.selects.creation_type")}
            options={creationTypeOptions}
            onChange={handleChange("creationType")}
            value={creationType}
          />
        </Flex>
      ),
      [Filters.TopOffer]: () => (
        <PillButton
          isSelected={topOffer}
          label={t("components.table.filters.top_offer")}
          onClick={toggle("topOffer")}
          key="topOffer"
        />
      ),
      [Filters.OnlyActive]: () => (
        <PillButton
          isSelected={active}
          label={t("components.table.filters.only_active")}
          onClick={toggle("active")}
          key="active"
        />
      ),
      [Filters.MarketingOffer]: () => (
        <PillButton
          isSelected={marketingOffer}
          label={t("components.table.filters.marketing_offer")}
          onClick={toggle("marketingOffer")}
          key="marketingOffer"
        />
      ),
      [Filters.HasPricingInformation]: () => (
        <PillButton
          isSelected={hasPricingInformation}
          label={t("components.table.filters.has_pricing_information")}
          onClick={toggle("hasPricingInformation")}
          key="hasPricingInformation"
        />
      ),
      [Filters.IsPossibleToEnableNewConfigurator]: () => (
        <PillButton
          isSelected={isPossibleToEnableNewConfigurator}
          label={t("components.table.filters.is_possible_to_enable_new_configurator")}
          onClick={toggle("isPossibleToEnableNewConfigurator")}
          key="isPossibleToEnableNewConfigurator"
        />
      ),
      [Filters.IsNewConfiguratorEnabled]: () => (
        <PillButton
          isSelected={isNewConfiguratorEnabled}
          label={t("components.table.filters.is_new_configurator_enabled")}
          onClick={toggle("isNewConfiguratorEnabled")}
          key="isNewConfiguratorEnabled"
        />
      ),
      [Filters.Search]: () => (
        <Input
          value={search}
          onChange={handleSearchChange}
          key="search"
          name="search"
          leftElement={React.createElement(SearchIcon, { color: "gray.400" })}
          rightElementProps={{
            workBreak: "break-all",
            overflow: "hidden",
          }}
          pl={10}
          showErrorMessage={false}
        />
      ),
    };

    const enabledFilters = Object.entries(availableFilters)
      .filter(e => isFilterEnabled(e[0] as Filter));

    return (
      <Wrap
        shouldWrapChildren
        spacing={GRID_GAP.LG}
        css={wrapChildrenStyles}
      >
        {
          enabledFilters.map(([, filterElementBuilder]) => filterElementBuilder())
        }
      </Wrap>
    );
  }, [
    t,
    active,
    topOffer,
    marketingOffer,
    search,
    toggle,
    brandId,
    brandName,
    dealerId,
    modelGroup,
    model,
    customerType,
    offerCustomerType,
    creationType,
    handleChange,
    isFilterEnabled,
    vehicleCategory,
    handleSearchChange,
    handleVehicleChange,
    hasPricingInformation,
    hasResidualInformation,
    options,
    vehicleType,
    period,
    leadStatus,
    salesperson,
    location,
    isPossibleToEnableNewConfigurator,
    isNewConfiguratorEnabled,
  ]);

  const payload = useMemo(() => ({
    renderFilters,
    dealerId: parseNulls(dealerId) as string | undefined,
    brandId: parseNulls(brandId) as number | undefined,
    brandName: parseNulls(brandName) as string | undefined,
    vehicleCategory: parseNulls(vehicleCategory) as VehicleCategoryEnum | undefined,
    vehicleModel: parseNulls(vehicleModel) as VehicleCategoryEnum | undefined,
    vehicleType: parseNulls(vehicleType) as VehicleTypeEnum | undefined,
    period: parseNulls(period) as [string, string],
    leadStatus: parseNulls(leadStatus) as [LeadStatusEnum],
    salesperson: parseNulls(salesperson) as string | undefined,
    modelGroup: parseNulls(modelGroup) as string | undefined,
    model: parseNulls(model) as string | undefined,
    customerType: parseNulls(customerType) as CustomerTypeEnum | undefined,
    offerCustomerType: parseNulls(offerCustomerType) as OfferCustomerTypeEnum | undefined,
    creationType: parseNulls(creationType) as OfferCreationTypeEnum | undefined,
    active: parseBoolean(active) as true | undefined,
    topOffer: parseBoolean(topOffer) as true | undefined,
    marketingOffer: parseBoolean(marketingOffer) as true | undefined,
    hasPricingInformation: parseBoolean(hasPricingInformation) as true | undefined,
    search: parseNulls(searchTerm) as string | undefined,
    hasResidualInformation: parseBoolean(hasResidualInformation) as true | undefined,
    isPossibleToEnableNewConfigurator: (
      parseBoolean(isPossibleToEnableNewConfigurator) as true | undefined
    ),
    isNewConfiguratorEnabled: (
      parseBoolean(isNewConfiguratorEnabled) as true | undefined
    ),
    step: parseNulls(step) as number | undefined,
    handleSetFilter,
    dirtyFields,
  }), [
    active,
    brandId,
    brandName,
    dealerId,
    modelGroup,
    model,
    vehicleCategory,
    vehicleModel,
    searchTerm,
    customerType,
    offerCustomerType,
    creationType,
    renderFilters,
    hasPricingInformation,
    hasResidualInformation,
    topOffer,
    marketingOffer,
    step,
    handleSetFilter,
    vehicleType,
    period,
    leadStatus,
    salesperson,
    dirtyFields,
    isPossibleToEnableNewConfigurator,
    isNewConfiguratorEnabled,
  ]);

  return payload;
};

export default useFilters;
