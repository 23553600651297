import create, { StoreApi } from "zustand";

import { Lead } from "generated/graphql";

import { LeadsStoreStateProps } from "./types";

const createStore = (): StoreApi<LeadsStoreStateProps> => create((set, get) => ({
  selectedLeads: get()?.selectedLeads || [],
  clearSelectedLeads: () => {
    set({ selectedLeads: [] });
  },
  toggleLead: (item: Lead) => {
    set(({ selectedLeads }) => {
      const selectedRow = selectedLeads.find(({ id }) => id === item.id);

      if (!selectedRow) {
        return {
          selectedLeads: [
            ...selectedLeads,
            item,
          ],
        };
      }

      return {
        selectedLeads: selectedLeads.filter(({ id }) => id !== item.id),
      };
    });
  },
  toggleAllLeads: (isAllSelected: boolean, items: Lead[]) => {
    set(() => {
      if (isAllSelected) {
        return {
          selectedLeads: [],
        };
      }

      return {
        selectedLeads: items,
      };
    });
  },
}));

export default createStore;
