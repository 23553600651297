import React from "react";
import classNames from "classnames";
import { Box } from "@chakra-ui/react";

import { ProgressBarProps } from "./types";

import "./styles.scss";

const CustomProgressBar: React.FC<ProgressBarProps> = ({
  colorScheme = "red",
  value,
}) => {
  const buttonClass = classNames("progressBarColor", {
    [`progressBarColor--${colorScheme}`]: colorScheme,
  });

  return (
    <Box
      className="progressBar"
      borderRadius={4}
      w="full"
      h={7}
    >
      <Box
        className={buttonClass}
        w={`${value}%`}
        h={7}
      />
    </Box>
  );
};

export default CustomProgressBar;
