import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";

import { downloadLink } from "utils/downloadLink";
import { useDownloadFileLinkLazyQuery } from "generated/graphql";

/**
 * Exposes a function to download files from the backend.
 * @param {*} id The file id.
 */
const useDownloadFile = (): (id: number) => void => {
  const { t } = useTranslation();
  const showToast = useToast();

  const [refetchDownloadLink] = useDownloadFileLinkLazyQuery({});

  const downloadFile = useCallback((id: number) => {
    showToast({
      status: "info",
      title: t("progress.downloading_file"),
    });

    refetchDownloadLink({
      variables: {
        id,
      },
    })
      .then(({ data: result }) => {
        const link = result?.downloadFileLink;

        if (link) {
          downloadLink(link);
        }
      })
      .catch(() => {
        showToast({
          status: "error",
          title: t("errors.something_went_wrong"),
        });
      });
  }, [
    refetchDownloadLink,
    showToast,
    t,
  ]);

  return downloadFile;
};

export default useDownloadFile;
