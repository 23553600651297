import { TestConfig } from "yup";

import i18n from "translations/i18n";
import { PASSWORD_MIN_LENGTH } from "constants/passwordMinLength";

const passwordLengthTest = (): TestConfig<string | undefined> => ({
  name: "lengthTest",
  message: i18n.t("yup.password_length", { min: PASSWORD_MIN_LENGTH }),
  test: (value): boolean => (
    !value || (
      !!value
      && value.length >= PASSWORD_MIN_LENGTH
    )
  ),
});

export default passwordLengthTest;
