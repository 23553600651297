import React from "react";

import Input from "../Input";
import { InputProps } from "../Input/types";

const TextField = React.forwardRef<HTMLInputElement, InputProps>((
  {
    errors,
    title,
    ...props
  },
  ref,
) => (
  <Input
    errors={errors}
    title={title}
    type="text"
    ref={ref}
    {...props}
  />
));

export default TextField;
