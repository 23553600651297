import React, { useMemo, forwardRef } from "react";
import { Box, Skeleton } from "@chakra-ui/react";
import ReactSelect from "react-select";

import i18n from "translations/i18n";
import { useBanksQuery } from "generated/graphql";

import Select from "../Select";
import { BankSelectProps } from "./types";
import { parseBank } from "./parse";

const BankSelect = forwardRef<ReactSelect, BankSelectProps>((props, ref) => {
  const {
    placeholder = i18n.t("components.selects.bank"),
    value,
    defaultValue,
    isDisabled,
  } = props;

  const { data, loading } = useBanksQuery();

  const banks = data?.banks || [];
  const options = banks.map(parseBank);

  const selectDefaultValue = useMemo(() => {
    if (loading) {
      return undefined;
    }

    options.unshift({
      value: "",
      label: i18n.t("components.modals.edit_vehicle_modal.bank_default_message"),
    });

    return options
      .find((option) => [value || defaultValue || ""].includes(option.value));
  }, [
    loading,
    defaultValue,
    value,
    options,
  ]);

  if (loading) {
    return (
      <Box width="full">
        <Skeleton height="40px" />
      </Box>
    );
  }

  return (
    <Select
      {...props}
      ref={ref}
      placeholder={placeholder}
      options={options}
      isDisabled={loading || isDisabled}
      defaultValue={selectDefaultValue}
    />
  );
});

export default BankSelect;
