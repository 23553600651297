import React from "react";
import {
  Stack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { Container } from "components/Container";
import { SETTINGS_PAGE_PATH } from "routes";
import i18n from "translations/i18n";
import { AlertBox } from "components/AlertBox";

import { BudgetOverviewProps } from "./types";
import { BudgetButton } from "./BudgetButton";
import SpendingsBar from "./SpendingsBar";

const BudgetOverview: React.FC<BudgetOverviewProps> = ({
  isUnlimited = false,
  isLoading = false,
  dealerId,
  budget,
  spent,
}) => {
  const router = useHistory();

  if (!dealerId) {
    return (
      <AlertBox
        label={i18n.t("leads.footer.please_select_a_dealer")}
        height="86px"
      />
    );
  }

  return (
    <Container
      padding="16px !important"
      justifyContent="start"
      alignCenter
      w="full"
    >
      <Stack
        alignItems="end"
        direction="row"
        align="center"
        spacing={2}
      >
        <BudgetButton
          onClick={() => router.push({
            pathname: SETTINGS_PAGE_PATH,
            search: dealerId && `?dealerId=${dealerId}`,
          })}
          isUnlimited={isUnlimited}
          isLoading={isLoading}
          budget={budget}
        />

        <SpendingsBar
          isUnlimited={isUnlimited}
          isLoading={isLoading}
          budget={budget}
          spent={spent}
        />
      </Stack>
    </Container>
  );
};

export default BudgetOverview;
