import React, { useCallback, useState } from "react";
import { useMount } from "react-use";
import {
  Image as ChakraImage,
  Skeleton,
  Flex,
} from "@chakra-ui/react";

import ImageFallback from "components/Images/ImageFallback";
import { ImageProps } from "components/Images/types";

const MAX_LOADING_TIME = 4000;

/**
 * Component that is responsible to render an image.
 *
 * Uses the `Image` component from Chakra UI, along with a fallback option to display in case the
 * image fails to load or is not available via the `src` prop.
 *
 * This component also displays a skeleton while loading the image to improve on loading UX.
 */
const Image = React.forwardRef<HTMLImageElement, ImageProps>((
  {
    src,
    width = "full",
    height = "full",
    borderRadius = 8,
    objectFit = "contain",
    ...rest
  },
  ref,
) => {
  const [isLoaded, setIsLoaded] = useState(!src);

  const onFinishLoading = useCallback(() => {
    setIsLoaded(true);
  }, []);

  useMount(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, MAX_LOADING_TIME);
  });

  return (
    <Flex
      width="240px"
      height="170px"
      borderRadius="8px"
      position="relative"
      bgColor="transparent"
      justifyContent="center"
    >
      <Skeleton
        width={width}
        height={height}
        isLoaded={isLoaded}
      >
        <ChakraImage
          ref={ref}
          src={src}
          width={width}
          height={height}
          objectFit={objectFit}
          onLoad={onFinishLoading}
          onError={onFinishLoading}
          borderRadius={borderRadius}
          fallback={(
            <ImageFallback
              src={src}
              minW="100%"
              minH="100%"
              width={width}
              height={height}
              rounded={borderRadius}
              borderRadius={borderRadius}
            />
          )}
          {...rest}
        />
      </Skeleton>
    </Flex>
  );
});

export default Image;
