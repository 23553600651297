import { gql } from "@apollo/client";

export const UPDATE_BUDGET_REFETCH_QUERIES = [
  "GetBudgetSettings",
];

export default gql`
  mutation UpdateBudget ($id: Int!, $params: UpdateBudgetParams!){
    updateBudget(id: $id, params: $params) {
      id
      budgetSpent
      currentTotalBudget
      isUnlimited
    }
  }
`;
