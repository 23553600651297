import React, { useEffect, useMemo } from "react";
import { useQueryParams } from "use-query-params";

import { tabs, tabsQueryParams } from "../../tabs";
import { TabComponentProps } from "../../types";

export const SettingsContainer: React.FC = () => {
  const [queryParam, setQueryParam] = useQueryParams(tabsQueryParams);

  const currentTab = useMemo(() => (
    tabs.find(tab => tab.queryString === queryParam.tab)
    || tabs.find(tab => tab.isDefault)
  ), [
    queryParam,
  ]);

  const Component = currentTab?.Component as React.FC<TabComponentProps>;

  const componentProps = currentTab?.componentProps;

  useEffect(() => {
    if (!currentTab?.isDefault) {
      return;
    }

    setQueryParam({ tab: currentTab.queryString });
  }, [
    setQueryParam,
    currentTab,
  ]);

  if (!Component) {
    return null;
  }

  return (
    <Component {...componentProps} />
  );
};
