import React, {
  useEffect, useMemo, useState,
  useRef,
} from "react";
import { Flex, useToast, Stack } from "@chakra-ui/react";
import { StringParam, useQueryParams } from "use-query-params";

import {
  Lead,
  LeadStatusEnum,
  useGetClassicLeadsLazyQuery,
  GetClassicLeadsQueryVariables,
  useGetBudgetSettingsLazyQuery,
} from "generated/graphql";
import { availableClassicLeadStatus } from "constants/availableLeadsStatus";
import { useCurrentUser } from "contexts/currentUser";
import { usePagination } from "contexts/pagination";
import { Container } from "components/Container";
import { Filters } from "hooks/useFilters/types";
import useFilters from "hooks/useFilters";
import BudgetOverview from "components/BudgetOverview";
import ExportLeadsButton from "views/Leads/components/ExportLeadsButton";

import LeadsTable from "./LeadsTable";

const ENABLE_BUDGET_OVERVIEW = process.env.REACT_APP_FF_ENABLE_BUDGET_OVERVIEW;

const ClassicLeads: React.FC = () => {
  const newToast = useToast();

  const [currentUser, , { isSalesUser }] = useCurrentUser();

  const [, setQueryParam] = useQueryParams({ dealerId: StringParam });

  const { paginationLimit } = usePagination();

  const [variables, setVariables] = useState<GetClassicLeadsQueryVariables>({});

  const shouldPreSelectDealer = useRef(true);

  const initialLeadStatusValues = useMemo(() => [
    LeadStatusEnum.OfferSent,
    LeadStatusEnum.Unprocessed,
    LeadStatusEnum.Contacted,
    LeadStatusEnum.ContractSent,
    LeadStatusEnum.ContractSigned,
  ], []);

  const filters = [
    Filters.Dealer,
    Filters.VehicleType,
    Filters.Vehicle,
    Filters.Search,
    Filters.Period,
    Filters.LeadStatus,
    Filters.Salesperson,
  ];

  const {
    renderFilters,
    dealerId,
    brandName,
    modelGroup,
    vehicleType,
    search,
    period,
    leadStatus,
    salesperson,
    dirtyFields,
  } = useFilters(
    filters,
    {
      LeadStatus: {
        initialValues: initialLeadStatusValues,
        availableLeadStatus: availableClassicLeadStatus,
      },
    },
  );

  const [
    getBudgetSettings,
    {
      data: budgetData,
      loading: budgetDataLoading,
    },
  ] = useGetBudgetSettingsLazyQuery();

  useEffect(() => {
    if (!shouldPreSelectDealer.current || !currentUser) {
      return;
    }

    setQueryParam({ dealerId: currentUser.seller.dealer.id });
    shouldPreSelectDealer.current = false;
  }, [
    setQueryParam,
    currentUser,
  ]);

  useEffect(() => {
    if (!currentUser?.seller.dealer.id && !dealerId) {
      return;
    }

    getBudgetSettings({
      variables: {
        id: dealerId || currentUser?.seller.dealer.id || "",
      },
    });
  }, [
    getBudgetSettings,
    currentUser,
    dealerId,
  ]);

  useEffect(() => {
    const isValidPeriod = period && !!period[1];

    setVariables({
      search,
      dealerId,
      period: isValidPeriod ? {
        startDate: isValidPeriod && period?.[0],
        endDate: isValidPeriod && period?.[1],
      } : undefined,
      where: {
        modelGroup,
        status: (dirtyFields.leadStatus || leadStatus?.length)
          ? leadStatus
          : initialLeadStatusValues,
        vehicleType: vehicleType && [vehicleType],
        assignee: salesperson,
        brand: brandName,
        isCheckoutLead: false,
      },
    });
  }, [
    paginationLimit,
    dealerId,
    search,
    modelGroup,
    brandName,
    vehicleType,
    period,
    salesperson,
    initialLeadStatusValues,
    dirtyFields,
    leadStatus,
  ]);

  const [listClassicLeads, { loading, data }] = useGetClassicLeadsLazyQuery({
    variables: {
      ...variables,
      limit: paginationLimit,
    },
  });

  useEffect(() => {
    listClassicLeads()
      .catch((error) => {
        if (error) {
          newToast({
            title: error?.name,
            description: error?.message,
            status: "error",
          });
        }
      });
  }, [
    newToast,
    variables,
    listClassicLeads,
    paginationLimit,
  ]);

  return (
    <Stack spacing={6}>
      {
        ENABLE_BUDGET_OVERVIEW && !isSalesUser && (
          <BudgetOverview
            budget={budgetData?.dealer?.currentBudget.currentTotalBudget}
            isUnlimited={budgetData?.dealer?.currentBudget.isUnlimited}
            spent={budgetData?.dealer?.currentBudget.budgetSpent}
            dealerId={dealerId}
            isLoading={budgetDataLoading}
          />
        )
      }

      <Container
        paddingBottom="0"
        paddingTop="0"
        paddingX="0"
        padding="0"
        alignCenter
      >
        <Flex
          justifyContent="space-between"
          alignItems="flex-end"
          padding="6"
        >
          {renderFilters}

          <ExportLeadsButton
            variables={variables}
          />
        </Flex>

        <LeadsTable
          data={data?.leads as Lead[] || []}
          isLoading={loading}
        />
      </Container>
    </Stack>
  );
};

export default ClassicLeads;
