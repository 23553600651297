import i18n from "translations/i18n";

const getLeasedBeforeLabel = (leasedBefore: boolean): string => {
  const alreadyExists = Boolean(leasedBefore);

  if (alreadyExists) {
    return i18n.t("leasing_contract_already_exists");
  }

  return i18n.t("leasing_contract_does_not_exist");
};

export default getLeasedBeforeLabel;
