import { Bank } from "generated/graphql";

import { OptionProps } from "../BaseSelect/types";

/**
 * Parses a Bank object into a select option.
 */
export const parseBank = ({ id, name }: Pick<Bank, "id" | "name">): OptionProps => ({
  label: name,
  value: id.toString(),
});
